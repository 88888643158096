import * as Yup from 'yup';

export const customValidations = Yup.object().shape({
  cardNumber: Yup.string().required('Usuario  es requerido'),
  cardNumber: Yup.string()
    .max(16, 'Máximo 16 dígitos')
    .required('El número de tarjeta es requerido'),
  cardExpiry: Yup.string()
    .required('La fecha de expiración es requerida')
    .min(5, 'Se requiere 4 dígitos'),
  cardCvv: Yup.string()
    .required('El CVV  es requerido')
    .max(4, 'Máximo 4 dígitos')
    .min(3, 'Mínimo 3 dígitos'),
});

export const validationsExpiryDate = (dateMMYY) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [month, year] = dateMMYY.split('/').map(Number);
  if (month < 1 || month > 12) return 'Ingrese un mes correcto';

  const fullYear = currentYear - (currentYear % 100) + year;
  let error = '';
  if (fullYear < currentYear || (fullYear === currentYear && month < currentMonth))
    error = 'Debe ser mayor que fecha actual';
  return error;
};
