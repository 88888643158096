import React, { useState, useEffect } from 'react';
import './index.scss';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import { Routes } from '../../../routes';
import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';
import GetTicket from '../../shared/services/TicketServices';
import {useDispatch, useSelector} from "react-redux";
import {createErrorPage} from "../../redux/auth";

const img = 'update-data';
const imgMobile = 'update-data-mobile';

const headerTableFather = [
  { elementProp: 'codigo', headerColumn: 'Nro. de Trámite' },
  { elementProp: 'poliza', headerColumn: 'Nro. Póliza/ Certificado' },
  { elementProp: 'ramo', headerColumn: 'Ramo' },
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'fecRecepcion', headerColumn: 'Fecha de recepción' },
  { elementProp: 'fecRegistro', headerColumn: 'Fecha de registro' },
  { elementProp: 'viaRecepcion', headerColumn: 'Vía de recepción' },
  { elementProp: 'estado', headerColumn: 'Estado' },
];

const data = [
  {
    codigo: '123546789',
    nroPolicy: 145,
    name: 'Luisa Ruiz',
    dateOcurrencia: '15/04/2014',
    dateDeclaracion: '15/04/2019',
    estado: 'En análisis',
    action: '-',
  },
  {
    codigo: '123546722',
    nroPolicy: 45,
    name: 'Julia Medina',
    dateOcurrencia: '15/04/2014',
    dateDeclaracion: '15/04/2019',
    estado: 'En análisis',
    action: 'Pagado',
  },
];

export default function HomeClaims(props) {
  const [loading, setLoading] = useState(false);
  const [listProcedure, setListProcedure] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness :false;
  const dispatch = useDispatch();

  const ga = React.useContext(GAProvider);

  const loadList = async () => {
    try {
      setLoading(true);
      const response = await GetTicket.responseGetTickets();
      if (response.statusCode === 200) {
        const listData = response.data.filter((item) => {
          return item.codigo.search(new RegExp('TRA')) !== -1;
        });
        setListProcedure(listData);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      dispatch(createErrorPage());

    }
  };

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Estado de Trámites`,
      action: 'click',
      label: 'El usuario visitó la página de Estado de Trámites',
      value: 1,
    });
  }, [ga]);

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div className="home-stateClaim-container">
      <PSBodyProduct
        questions={FrequentQuestions.AHORRO_TOTAL}
        coverages={Coverage.AHORRO_TOTAL}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader="Consulta de estado de trámites"
        messageToProduct ="no tenemos trámites registrados a tu nombre."
        withButtonToProduct = {false}
        loading={loading}
        data={listProcedure}
        showCheck={false}
        headerTable={headerTableFather}
        {...props}
      />
    </div>
  );
}
