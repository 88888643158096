import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { useLazyQuery } from '@apollo/client';
import { Routes } from '../../../routes';
import { setAttributesCore } from '../../redux/auth/actions/user-login';
import { setEdit } from '../../redux/auth';

import doInitQueryQL from '../../shared/services/GraphqlLocations';
import { clearValue, getValueDocumentName, mapToSelect } from '../../../util/Util';
import { formInitial, MAX_LENGTH, structFields, options, detailAddress } from './constants';
import ClientsService from '../../shared/services/ClientsService';

import { Customer } from '../../shared/model/Customer';
import { PSModalSuccess, PSModalErrorResponse } from '../../components/organisms/index';
import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSIcon from '../../components/PSIcon';
import PSButton from '../../components/PSButton';
import PSComponentForm from '../../components/PSComponentForm';
import PSDynamicRow from '../../components/PSDynamicRow';
import PSLoading from '../../components/PSLoading';
import { GAProvider } from '../../components/PSAnalytics';
import PSTextInput from '../../components/PSTextInput';
import OTPValidation from '../OTPValidation';

import './index.scss';

const img = 'update-data';
const imgMobile = 'update-data-mobile';

const validateFormatEmail = (email) => {
  const re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  return re.test(email);
};

const validateFormatPhone = (phone) => {
  const re = /^[9][0-9]{8,8}/g;
  return re.test(phone);
};

export default function HomeUpdateData(props) {
  const { userInfo, attributesCore, isEditData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const attributes = userInfo ? userInfo.attributes : {};
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [firstAddress, setAddress] = useState([]);
  const [changeAddress, setChangeAddres] = useState(false);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [form, setForm] = useState([...formInitial]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrMessage] = useState('');
  const [editAdress, setEditAdress] = useState(false);
  const [reboot, setReboot] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [currentInfoForm, setCurrentInfoForm] = useState(null);
  const ga = React.useContext(GAProvider);
  const [setLocationQL, { data }] = useLazyQuery(doInitQueryQL.getQuery(), {
    variables: { department: 12, province: 1401 },
  });

  const onchangeForm = (e, index) => {
    const { value } = e.target;
    if (form[index].type !== 'select') {
      form[index].value = value;
      setForm([...form]);
    } else if (value) {
      form[index].value = value;
      setForm([...form]);
    }

    if (form[index].field === 'departamento' && value) {
      getProvinces(parseInt(value, 10));
    }

    if (form[index].field === 'provincia' && value) {
      getDistricts(parseInt(form[1].value, 10), parseInt(value, 10));
    }

    if (form[index].field === 'tipoVivienda' && value !== '') {
      form[9].disabled = false;
      setForm([...form]);
    }

    if (form[index].field === 'tipoVivienda' && value === '-1') {
      form[9].disabled = true;
      setForm([...form]);
    }
    setModoEdit();
  };
  const getProvinces = (value) => {
    setLocationQL({ variables: { department: value } });
    if (data) {
      form[2].options = sortArrayByDescription(
        mapToSelect(data.provinces.items, 'id', 'description')
      );
      setForm([...form]);
    }
  };

  const getDistricts = (department, province) => {
    setLocationQL({ variables: { department, province } });
    if (data) {
      form[3].options = sortArrayByDescription(
        mapToSelect(data.districts.items, 'id', 'description')
      );
      setForm([...form]);
    }
  };

  const addTelephone = () => {
    if (phones.length < MAX_LENGTH) {
      const phone = { P_SPHONE: 0, P_NPHONE_TYPE: 2, change: true };
      phones.push(phone);
      setPhones([...phones]);
      setModoEdit();
    }
  };
  const addMail = () => {
    if (emails.length < MAX_LENGTH) {
      const email = { P_SRECTYPE: 4, P_SE_MAIL: '', change: true };
      emails.push(email);
      setEmails([...emails]);
      setModoEdit();
    }
  };

  // todo cambiar a custom hooks traer la direccion del usuario
  const loadClient = async () => {
    try {
      setLoading(true);
      const response = await ClientsService.responseGetClients();
      if (response.statusCode === 200) {
        const customer = response.data[0] ? new Customer(response.data[0]) : {};
        const address = customer.address ? customer.address[0] : {};

        if (customer.address?.length > 0) {
          form[0].value = address.P_NCOUNTRY;
          form[1].value = address.P_NPROVINCE;
          form[2].value = address.P_NLOCAL;
          form[3].value = address.P_NMUNICIPALITY;
          form[4].value = address.P_SRECTYPE;
          form[5].value = address.P_STI_DIRE;
          form[6].value = address.P_SNUM_DIRECCION;
          form[7].value = address.P_SNOM_DIRECCION;
          form[8].value = address.P_STI_INTERIOR;
          form[9].value = address.P_SNUM_INTERIOR;
          form[10].value = address.P_SREFERENCE;
          if (address.P_STI_INTERIOR) {
            form[9].disabled = true;
          }
          setForm([...form]);
          loadDepartments(parseInt(address.P_NPROVINCE, 10), parseInt(address.P_NLOCAL, 10));
        } else {
          loadDepartments(parseInt(12, 10), parseInt(1401, 10));
        }
        const currentAddress = {
          P_NCOUNTRY: clearValue(address.P_NCOUNTRY),
          P_NPROVINCE: clearValue(address.P_NPROVINCE),
          P_NLOCAL: clearValue(address.P_NLOCAL),
          P_NMUNICIPALITY: clearValue(address.P_NMUNICIPALITY),
          P_SRECTYPE: clearValue(address.P_SRECTYPE),
          P_STI_DIRE: clearValue(address.P_STI_DIRE),
          P_SNUM_DIRECCION: clearValue(address.P_SNUM_DIRECCION),
          P_SNOM_DIRECCION: clearValue(address.P_SNOM_DIRECCION),
          P_STI_INTERIOR: clearValue(address.P_STI_INTERIOR),
          P_SNUM_INTERIOR: clearValue(address.P_SNUM_INTERIOR),
          P_SREFERENCE: clearValue(address.P_SREFERENCE),
        };
        setAddress(currentAddress);

        if (customer.phones?.length > 0) {
          setPhones(customer.phones.filter((item) => item.P_NPHONE_TYPE === '2'));
        }

        if (customer.emails?.length > 0) {
          setEmails(customer.emails);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const setModoEdit = () => {
    if (!isEditData) {
      dispatch(setEdit(true));
    }
  };

  const changesForm = () => {
    const changesEmail = emails.some((item) => !!item.change && item.P_SE_MAIL);
    const changesPhone = phones.some((item) => !!item.change && item.P_SPHONE);

    const currentAddress = {
      P_NCOUNTRY: clearValue(form[0].value),
      P_NPROVINCE: clearValue(form[1].value),
      P_NLOCAL: clearValue(form[2].value),
      P_NMUNICIPALITY: clearValue(form[3].value),
      P_SRECTYPE: clearValue(form[4].value),
      P_STI_DIRE: clearValue(form[5].value),
      P_SNUM_DIRECCION: clearValue(form[6].value),
      P_SNOM_DIRECCION: clearValue(form[7].value),
      P_STI_INTERIOR: clearValue(form[8].value),
      P_SNUM_INTERIOR: clearValue(form[9].value),
      P_SREFERENCE: clearValue(form[10].value),
    };

    const changesAddress = JSON.stringify(firstAddress) !== JSON.stringify(currentAddress);
    if (changesAddress !== changeAddress) setChangeAddres(changesAddress);
    return !(changesEmail || changesPhone || changesAddress);
  };
  const validateEmail = () => {
    let error = false;
    emails.forEach((item) => {
      error = error || item.error;
    });

    return !error;
  };

  const validatePhone = () => {
    let error = false;
    phones.forEach((item) => {
      error = error || item.error;
    });

    return !error;
  };

  const onOTPValidation = (typeOTP = 'send', typeSend) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    const labelTypeSendGA = typeSend === 'phoneNumber' ? 'celular' : 'email';
    let labelGA = '';
    switch (typeOTP) {
      case 'init':
        labelGA = `El usuario inició el flujo de Actualización de datos`;
        break;
      case 'send':
        labelGA = `El usuario solicitó el envío de la clave OTP por ${labelTypeSendGA}`;
        break;
      case 'success':
        labelGA = `El usuario actualizó sus datos exitosamente`;
        break;
      case 'error':
        labelGA = `El usuario introdujo una clave OTP errónea enviada por ${labelTypeSendGA}`;
        break;
      default:
        labelGA = `El usuario solicitó el envío de la clave OTP por ${labelTypeSendGA}`;
    }

    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Actualización de Datos`,
      action: 'click',
      label: labelGA,
      value: 1,
    });
  };

  const onSave = async () => {
    setReboot(true);

    try {
      if (validateEmail() && validatePhone()) {
        const emailsUpdate = emails.filter((item) => !!item.change && item.P_SE_MAIL);
        const phonesUpdate = phones.filter((item) => !!item.change && item.P_SPHONE);
        setLoadingSave(true);

        const addressObject = {
          country: form[0].value,
          province: form[1].value,
          local: form[2].value,
          municipality: form[3].value,
          type: form[4].value,
          dire: form[5].value,
          numDirection: form[6].value,
          nomDirection: form[7].value,
          interior: form[8].value,
          numInterior: form[9].value,
          reference: form[10].value,
        };

        const email = [];
        const phone = [];

        for (let i = 0; i < emailsUpdate.length; i++) {
          email.push({ id: 'EMAIL', email: emailsUpdate[i].P_SE_MAIL });
        }
        for (let i = 0; i < phonesUpdate.length; i++) {
          phone.push({ id: 'MOBILE_NUMBER', phone: phonesUpdate[i].P_SPHONE });
        }

        let dataRequest = {};

        if (phone.length > 0) {
          dataRequest = { ...dataRequest, contactPhone: phone };
        }

        if (email.length > 0) {
          dataRequest = { ...dataRequest, contactEmail: email };
        }
        if (changeAddress) {
          dataRequest = { ...dataRequest, contactAddress: addressObject };
        }

        const response = await ClientsService.responseUpdateClient(dataRequest);
        if (response?.needValidation) {
          const { url } = response;
          setCurrentInfoForm({ url, data: dataRequest });
          setShowModalValidation(true);
          onOTPValidation('init');
          setHasError(false);
          dispatch(setEdit(false));
        } else {
          setErrMessage(response?.data || '');
          setHasError(true);
        }
      }
    } catch (error) {
      setHasError(true);
      setErrMessage(error);
    } finally {
      setLoadingSave(false);
    }
  };
  const onChangePhone = (index, field, value, valid) => {
    const phonesUpdate = phones;
    phonesUpdate[index].error = !validateFormatPhone(value);

    if (value.length <= 9 && valid) {
      phonesUpdate[index][field] = value;
      phonesUpdate[index].change = true;
      setPhones([...phonesUpdate]);
    }

    // Mensaje de error
    let errorMessage = '';
    if (phonesUpdate[index].error) {
      if (value == '' || value == 0) errorMessage = 'Campo requerido.';
      else if (value.length < 9) errorMessage = 'Debe tener 9 dígitos.';
      else errorMessage = 'Debe iniciar con el número 9.';
      phonesUpdate[index].errorMessage = errorMessage;
    }
  };

  const onBlurPhone = (index, field, fieldsE) => {
    const phonesUpdate = phones;
    const value = phonesUpdate[index][fieldsE];
    phonesUpdate[index][field] = !validateFormatPhone(value);
    if (value.length === 0 || value === 0) phonesUpdate[index].errorMessage = 'Campo requerido.';
    setPhones([...phonesUpdate]);
  };

  const onBlurMail = (index, field, fieldsE) => {
    const emailsUpdate = emails;
    const value = emailsUpdate[index][fieldsE];
    emailsUpdate[index][field] = !validateFormatEmail(value);
    if (value.length === 0) emailsUpdate[index].errorMessage = 'Campo requerido.';

    setEmails([...emailsUpdate]);
  };

  const onChangeMail = (index, field, value) => {
    const emailsUpdate = emails;
    emailsUpdate[index][field] = value;
    emailsUpdate[index].change = true;
    setModoEdit();

    // Validacion y mesaje de error
    emailsUpdate[index].error = !validateFormatEmail(value);

    if (emailsUpdate[index].error) {
      if (value.length === 0) emailsUpdate[index].errorMessage = 'Campo requerido.';
      else emailsUpdate[index].errorMessage = 'Formato de correo incorrecto.';
    }
    setEmails([...emailsUpdate]);
  };

  const onAccept = async () => {
    await dispatch(setAttributesCore());
    history.push(Routes.HOME);
  };

  const onAcceptError = async () => {
    setHasError(false);
    setErrMessage('');
  };

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Actualización de Datos`,
      action: 'click',
      label: 'El usuario visitó la página de Actualización de Datos',
      value: 1,
    });
  }, [ga]);

  const loadDepartments = (department, province) => {
    setLocationQL({ variables: { department, province } });
    if (data) {
      form[1].options = sortArrayByDescription(
        mapToSelect(data.departments.items, 'id', 'description')
      );
      form[2].options = sortArrayByDescription(
        mapToSelect(data.provinces.items, 'id', 'description')
      );
      form[3].options = sortArrayByDescription(
        mapToSelect(data.districts.items, 'id', 'description')
      );
      setForm([...form]);
    }
  };

  const sortArrayByDescription = (array) => {
    array.sort((a, b) => {
      if (a.description > b.description) return 1;
      if (a.description < b.description) return -1;
      return 0;
    });
    return array;
  };

  useEffect(() => {
    loadClient();
  }, []);

  useEffect(() => {
    if (data) {
      form[1].options = sortArrayByDescription(
        mapToSelect(data.departments.items, 'id', 'description')
      );
      form[2].options = sortArrayByDescription(
        mapToSelect(data.provinces.items, 'id', 'description')
      );
      form[3].options = sortArrayByDescription(
        mapToSelect(data.districts.items, 'id', 'description')
      );
      setForm([...form]);
    }
  }, [data]);

  return (
    <div className="container-update-data">
      <div className="header">
        <PSHeaderIntranet
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description="Actualización de Datos"
          {...props}
        />
      </div>
      <div className="summary-update-data">
        <PSIcon type={isBusiness ? 'businessOrange' : 'user'} className="icon-summary" />
        <div className="section-summary">
          <div className="w-100">
            <div className="title-summary-data">
              <label>{isBusiness ? 'Datos de la empresa' : 'Datos Personales'}</label>
            </div>
            <div className="text-capitalize">
              <label>
                {`${`${attributes?.middleName} ${attributes?.familyName}`}, ${
                  attributes?.givenName
                }`.toLowerCase()}
              </label>
            </div>
          </div>
          <div className="w-40">
            <div className="title-summary-data">
              <label>{getValueDocumentName(userInfo?.documentType)}</label>
            </div>
            <div>
              <label>{userInfo.document || ''}</label>
            </div>
          </div>
          {!isBusiness && (
            <div className="w-40">
              <div className="title-summary-data">
                <label>Nacimiento</label>
              </div>
              <div>
                <label>{attributes?.birthDate}</label>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className="loading-pages-intranet">
          <PSLoading />
        </div>
      ) : (
        <div className="body-update-data">
          <div className="container-data-contact">
            <label>Datos Contacto</label>
            <PSIcon
              type="info"
              className="ml-2 input-row-icon"
              data-html="true"
              data-tip="
              <p style='font-size : 120%;'>
              Para eliminar un dato de contacto, comuníquese:
              <br>
              Lima : (01)3913000
              <br>
              Provincias: 080111278
              <br>
              clientes@protectasecurity.pe
              </p>"
            />
          </div>
          <div className={phones.length === 0 ? 'd-flex' : ' '}>
            <div className="pl-1 title-summary-data">
              <label>Teléfono:</label>
            </div>
            <PSDynamicRow
              reboot={reboot}
              labelOption="Número/Teléfono"
              className="dynamicRow-mails"
              rows={phones}
              addRow={addTelephone}
              onChange={onChangePhone}
              onBlur={onBlurPhone}
              maxLength={MAX_LENGTH}
              struct={structFields.phone}
              options={options.optionsPhone}
            />
          </div>

          <div className={emails.length === 0 ? 'd-flex' : ' '}>
            <div className="pl-1 title-summary-data">
              <label> Emails:</label>
            </div>
            <PSDynamicRow
              reboot={reboot}
              labelOption="Correo electrónico"
              className="dynamicRow-mails"
              rows={emails}
              addRow={addMail}
              onChange={onChangeMail}
              onBlur={onBlurMail}
              maxLength={MAX_LENGTH}
              struct={structFields.email}
              options={options.optionsEmail}
            />
          </div>

          <div>
            <div className="body-container-form">
              <div className="pl-1 mt-1 title-summary-data">
                <label>Datos de dirección:</label>
              </div>
              {editAdress ? (
                <PSComponentForm from={form} onChange={onchangeForm} className="container-form" />
              ) : (
                <div className="container-address">
                  <PSTextInput className="input-address" value={detailAddress(form)} readOnly />
                  <PSIcon
                    type="pencil"
                    className="inputIcon-address"
                    onClick={() => setEditAdress(true)}
                    data-tip="Editar"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="container-buttons">
            <PSButton
              onClick={onSave}
              loading={loadingSave}
              disabled={changesForm() || !validateEmail() || !validatePhone()}
            >
              Guardar
            </PSButton>
          </div>
        </div>
      )}
      {showModalValidation && (
        <Modal
          animationDuration={0}
          open={showModalValidation}
          onClose={() => {}}
          showCloseIcon={false}
          onOverlayClick={() => {}}
          closeOnOverlayClick={false}
        >
          <OTPValidation
            url={currentInfoForm.url}
            dataSave={currentInfoForm.data}
            onValid={(isValid, response) => {
              setShowModalValidation(false);

              if (isValid) {
                setOpenModal(true);
                dispatch(setEdit(false));
                setHasError(false);
                onOTPValidation('success');
              } else {
                setHasError(true);
                setErrMessage(response?.data?.message || '');
              }
            }}
            onSendValidation={(typeSend) => {
              onOTPValidation('send', typeSend);
            }}
            onSendErrorCode={(typeSend) => {
              onOTPValidation('error', typeSend);
            }}
            onCloseModal={() => {
              setShowModalValidation(false);
            }}
            headerParams={{}}
          />
        </Modal>
      )}
      {openModal && (
        <PSModalSuccess
          openModal={openModal}
          onClick={() => onAccept()}
          message="Sus datos fueron actualizados correctamente"
        />
      )}
      {hasError && (
        <PSModalErrorResponse
          openModal={hasError}
          onClick={() => onAcceptError()}
          message={errorMessage}
        />
      )}
    </div>
  );
}
