import Axios from 'axios/index';
import { doGetWithParams, doGet, doPost, doPostFormFile, doPostWithParams } from './Api';

export default class FormsService {
  static async saveForms2(data) {
    const response = await doPost(`${process.env.REACT_APP_MANAGE_FORMS}`, data);
    const dataResponse = response.data;
    if (dataResponse.status.id === 403 && dataResponse.status.name === 'UNAUTHORIZED') {
      const { config } = response;

      return { url: config.url, needValidation: true, ...response.data };
    }
    return response.data;
  }

  static async validateFormByPolicy(policyCurrent, segmentID) {
    try {
      const response = await doGetWithParams(
        `${process.env.REACT_APP_BASE_URL_NET}/application-forms/v0/validate`,
        {
          policy: policyCurrent.toString().padStart(10, '0'),
          'segment-id': segmentID,
        }
      );

      return response.data;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  }

  static async saveForms(data, header) {
    const response = await doPostWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/jira/v0/tickets/simulate`,
      data,
      header
    );
    const dataResponse = response.data;
    if (dataResponse.status.id === 403 && dataResponse.status.name === 'UNAUTHORIZED') {
      const { config } = response;

      return { url: config.url, needValidation: true, ...response.data };
    }
    return response.data;
  }

  static async uploadFileForms2(dataFile) {
    const response = await doPostFormFile(
      `${process.env.REACT_APP_FILE_NEW}/attachments?file=${dataFile.file}&&time=${dataFile.time}&field=${dataFile.field}`,
      dataFile.data
    );
    return response;
  }

  static async uploadFileForms(dataFile, header) {
    const response = await doPostWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/clientsDocuments/v0/jira`, // clientsDocuments solo temporalmente para dev
      dataFile,
      header
    );
    return response.data;
  }

  static async uploadFile(url, data, type) {
    const config = {
      method: 'put',
      url,
      headers: {
        'content-type': `${type}`,
      },
      data,
    };

    const response = await Axios(config);
    return response;
  }

  static async crearOTPForms(data) {
    const response = await doPost(`${process.env.REACT_APP_OTP_FORM}/crear`, data);
    return response.data;
  }

  static async validarOTPForms(data) {
    const response = await doPost(`${process.env.REACT_APP_OTP_FORM}/validar`, data);
    return response.data;
  }
}
