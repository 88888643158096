import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import styled from 'styled-components';

import { capitalizeFirstLetter } from '../../util/Util';

import zoomIn from '../../assets/images/zoomIn.svg';
import zoomOut from '../../assets/images/zoomOut.svg';
import PSIcon from './PSIcon';
import PSLabel from './PSLabel';
import PSFileConstanciaAsegurado from './PSFileConstanciaAsegurado';
import { GAProvider } from './PSAnalytics';
import PSLoading from './PSLoading';

const objPoliza = {
  dateStart: '19/08/2018',
  dateEnd: '19/08/2019',
  producto: 'Seguro de Accidentes Universitarios',
  nroPolicy: 340,
};

const StyledPSPreviewFileHtml = styled.div`
  opacity: 1;
  width: 100%;
  position: relative;
  margin-top: 7px;
  .container-wrapper {
    background: #d9d9d9;
  }
  .canvas-wrapper {
    /* Track */
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      //box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      background: #cbcbcb;
      margin-left: 0.8rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ed6e00;
      border-radius: 10px;
    }

    /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //   background: #cbcbcb;
    // }
    background: #d9d9d9;
    overflow: scroll;
    height: 400px;
    padding: 4% 12%;
  }
  .header-wrapper {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    display: grid;
    padding: 0 3%;
    .header-filters {
      width: 65%;
      display: grid;
      text-align: center;
      margin-top: 3%;
      grid-template-columns: 1fr 1fr;
      .d-flex:hover {
        cursor: pointer;
      }
    }
    .a {
      fill: #e16d19 !important;
    }
    .icon-svg svg {
      width: 32px !important;
      height: 32px !important;
      padding-top: 0 !important;
      margin-right: 10px !important;
    }
    .icon-svg:hover {
      cursor: pointer;
    }
    .header-title {
      text-align: right;
      margin-top: 3%;
      label {
        display: initial;
      }
    }
    label {
      font-size: 16px;
      color: #ffffff;
      margin-left: 12px;
      font-family: 'BlissPro-Regular', sans-serif;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .custom-wrapper {
    width: 100%;
    height: 60px;
    padding: 15px 5px;
    background: #2b265a;
    border-radius: 19px;
    & div:nth-child(1) {
      position: absolute;
      right: 30px;
    }
    .material-icons {
      display: none;
    }
    .custom-zoom-in {
      outline: none;
      width: 34px;
      height: 24px;
      border: none;
      background-color: #2c265a;
      background-repeat: no-repeat;
      background-image: url(${zoomOut});
    }
    .separator-zoom {
      height: 24px;
      margin-right: 10px;
      border-right: 1px solid #e16d19;
    }
    .custom-zoom-out {
      outline: none;
      margin-right: 10px;
      width: 34px;
      height: 24px;
      padding-right: 4px;
      border: none;
      background-color: #2c265a;
      background-repeat: no-repeat;
      background-image: url(${zoomIn});
    }
  }
  @media (max-width: 768px) {
    .header-wrapper {
      label {
        font-size: 15px;
      }
    }
    .custom-wrapper {
      height: 55px;
      & div:nth-child(1) {
        right: 55px;
      }
      .custom-zoom-out {
        background-size: 3.7vw;
      }
      .custom-zoom-in {
        background-size: 3.7vw;
      }
    }
  }

  @media (max-width: 576px) {
    .canvas-wrapper {
      padding: 5% 2%;
      width: 88vw;
      background: white;
    }
    table {
      width: initial !important;
    }
    .header-filters {
      & div:nth-child(2) {
        margin-left: 15px;
      }
    }
    .separator-zoom {
      height: 18px !important;
      margin-top: -8px;
    }
    .header-wrapper {
      .icon-svg {
        svg {
          width: 4vw !important;
        }
      }

      label {
        margin: 6px 0 0 0;
        font-size: 14px;
      }
    }
    .custom-wrapper {
      height: 45px;
      button {
        width: 24px !important;
      }
      & div:nth-child(1) {
        right: 45px;
        top: 12px;
      }

      .custom-zoom-out {
        background-size: 4vw;
      }
      .custom-zoom-in {
        background-size: 4vw;
      }
    }
  }
  @media print {
    html,
    body {
      height: 99%;
    }
  }
`;

const PSPreviewFileHtml = React.forwardRef(
  ({ children, poliza, descriptionHeader, typeDocument, useForBoletas, typeClassPrint }, ref) => {
    // const [polizaSelected, setPolizaSelected] = useState(poliza);
    const [loading, setLoading] = useState(false);
    const componentRef = useRef();
    const ga = React.useContext(GAProvider);
    const { userInfo } = useSelector((state) => state.auth);
    const isBusiness = userInfo ? userInfo.isBusiness : false;

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const downloadFile = async () => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-${typeDocument}`,
        action: 'download',
        label: `El usuario descargó su ${typeDocument} de ${descriptionHeader}`,
        value: 1,
      });

      const pdf = new jsPDF('p', 'pt', 'a4');
      const name = `${capitalizeFirstLetter(typeDocument)}-${descriptionHeader}-${
        poliza ? poliza.nroPolicy : '7000468446'
      }`;
      const documento = document.getElementById('canvas-wrapper-content');
      const clonDocumento = documento.cloneNode('canvas-wrapper-content');
      if (typeClassPrint) {
        if (typeClassPrint === 'sctr') {
          clonDocumento.classList.add('download-file-constancia');
        } else if (typeClassPrint === 'asegurado') {
          clonDocumento.classList.add('download-file-asegurado');
        } else {
          clonDocumento.classList.add('download-file-boleta');
        }
      } else {
        clonDocumento.classList.add('download-file');
      }

      setLoading(true);
      await pdf.html(clonDocumento, {
        callback(pdf) {
          pdf.save(name);
        },
      });
      setLoading(false);
      setTimeout(() => {
        alert('Su archivo se ha descargado con éxito');
      }, 1000);
    };

    useEffect(() => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-${typeDocument}`,
        action: 'click',
        label: `El usuario visualizó su ${typeDocument} de ${descriptionHeader}`,
        value: 1,
      });
    }, []);

    return (
      <StyledPSPreviewFileHtml ref={ref}>
        <div id="test" />
        <div className="header-wrapper">
          <div className="header-filters">
            <div className="d-flex display-none-mobile" onClick={handlePrint}>
              <PSIcon type="printFile" className="icon-svg" /> <PSLabel>Imprimir</PSLabel>
            </div>
            {!loading ? (
              <div className="d-flex" onClick={downloadFile}>
                <PSIcon type="downloadFile" className="icon-svg" /> <PSLabel>Descargar</PSLabel>
              </div>
            ) : (
              <div>
                <PSLoading height={30} width={30} />
              </div>
            )}
          </div>
          <div className="header-title">
            <PSLabel>Zoom</PSLabel>
          </div>
        </div>

        <TransformWrapper
          defaultScale={1}
          wheel={{
            wheelEnabled: false,
            touchPadEnabled: false,
          }}
          pan={{ disabled: true }}
          pinch={{ disabled: true }}
          defaultPositionX={200}
          defaultPositionY={100}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <div>
                <div className="columns is-gapless custom-wrapper">
                  <div className="column is-2 buttons are-small has-addons d-flex">
                    <button onClick={zoomOut} type="button" className="custom-zoom-out" />
                    <div className="separator-zoom" />
                    <button onClick={zoomIn} type="button" className="custom-zoom-in" />
                  </div>
                </div>
              </div>
              <TransformComponent>
                <div className="canvas-wrapper" id="canvas-wrapper" ref={componentRef}>
                  {children}
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </StyledPSPreviewFileHtml>
    );
  }
);

PSPreviewFileHtml.displayName = 'PSPreviewFileHtml';

PSPreviewFileHtml.defaultProps = {
  poliza: objPoliza,
  descriptionHeader: '',
  typeDocument: '',
  useForBoletas: false,
  typeClassPrint: null,
};

PSPreviewFileHtml.propTypes = {
  poliza: PropTypes.object,
  descriptionHeader: PropTypes.string,
  typeDocument: PropTypes.string,
  useForBoletas: PropTypes.bool,
  typeClassPrint: PropTypes.string,
};

export default PSPreviewFileHtml;
