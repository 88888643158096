import { COUPON_VIGENCY } from "../../../util/Constant";

export class ExpiredCoupon {
  constructor(props) {
    this.effecDate = props.effecDate;
    this.limitDate = props.limitDate;
    this.premiun = props.premiun;
    this.mensual = props.mensual;
    this.statusPre = props.statusPre;

    this.expirDat = props.expirDat;
    this.statDate = props.statDate;
    this.billNum = props.billNum;
    this.nreceipt = props.nreceipt;
    this.reciboPendiente = props.reciboPendiente;
    this.cuponVigente = props.cuponVigente;
    
    this.isDisabled = props.cuponVigente === COUPON_VIGENCY.PROCESS;
    this.id = props.nreceipt;
    this.value = props.nreceipt;
  }
}
