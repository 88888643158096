import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import PSHeaderPageHome from './PSHeaderPageHome';
import { GAConsumer } from './PSAnalytics';
import PSNavBarNewSletter from './PSNewSletterNavBar';
import {Routes} from "../../routes";
import {STORAGE_TOKEN_COGNITO} from "../../util/Constant";

const BodyContainer = styled.div`
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  .columns {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const PageContainer = styled.div`
  .navbar-home-only-mobile {
    display: none;
  }
  @media (max-width: 576px) {
    .header-home {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .header-home {
      display: none;
    }
    .navbar-home-only-mobile {
      display: block;
    }
  }
`;
export default function PSNewSletterHome({ children }) {
  const [showBodyContainer, setShowBodyContainer] = useState(true);
  const history = useHistory();
  const openAsideBar = () => {
    setShowBodyContainer(false);
  };

  const closeAsideBar = () => {
    setShowBodyContainer(true);
  };

  useEffect(() => {
    const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO);
    if (idTokenCognito === null) {
      history.push(Routes.LOGIN);
    }
  }, []);

  return (
    <PageContainer>
      <PSHeaderPageHome hasTitle className="header-home" />
      <div className="navbar-home-only-mobile">
        <PSNavBarNewSletter openAsideBar={openAsideBar} closeAsideBar={closeAsideBar} />
      </div>
      {showBodyContainer && (
        <BodyContainer expandBar className="columns is-desktop is-mobile">
          <div className="columns w-100">{children}</div>
        </BodyContainer>
      )}
    </PageContainer>
  );
}

export const withHeader = (Component) => (props) => {
  return (
    <GAConsumer>
      {(ga) => (
        <PSNewSletterHome>
          <Component {...props} ga={ga} />
        </PSNewSletterHome>
      )}
    </GAConsumer>
  );
};
