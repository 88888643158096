import React from 'react';
import styled from 'styled-components';

import { pathServer } from '../../util/Constant';

import PSLabel from './PSLabel';
import PSIcon from './PSIcon';

const StyledPsContainerFooter = styled.div`
  width: 100%;
  background: #242525;
  opacity: 1;
`;

const StyledPSFooterPageHome = styled.div`
  padding: 2rem 2rem;
  text-align: left;

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  label {
    text-align: left !important;
  }
  a {
    color: white;
    &:hover {
      text-decoration: underline;
      color: #ed6e00;
    }
  }
  .icon-follow {
    &:hover {
      svg {
        fill: #ed6e00;
      }
    }
  }
  .icon-container {
    display: flex;
    svg {
      fill: white;
      width: 21px;
      height: auto;
    }
  }
`;

const linksPrimaryFooter = [
  {
    id: 0,
    name: 'Reporte Normativos',
    link: 'https://protectasecurity.pe/reportes-normativos-reclamos/',
    enabled: true,
    order: 1,
  },
  {
    id: 1,
    name: 'Enlaces de Interés',
    link: 'https://protectasecurity.pe/enlaces-de-interes/',
    enabled: true,
    order: 1,
  },
  {
    id: 2,
    name: 'Reclamos',
    link: 'https://protectasecurity.pe/formulario-reclamo/',
    enabled: true,
    order: 1,
  },
  { id: 3, name: 'Memorias', link: '#', enabled: false, order: 1 },
  {
    id: 4,
    name: 'Hechos de importancia',
    link: 'https://protectasecurity.pe/hechos-de-importancia/',
    enabled: true,
    order: 1,
  },
  {
    id: 5,
    name: 'Estados Finacieros',
    link: 'https://protectasecurity.pe/estados-financieros/',
    enabled: true,
    order: 1,
  },
  { id: 6, name: 'SIC-CRM', link: '#', enabled: false, order: 1 },
  {
    id: 7,
    name: 'Preguntas Frecuentes',
    link: '#',
    enabled: false,
    order: 1,
  },
  {
    id: 8,
    name: 'Canal de Denuncias',
    link: 'https://protecta.ines.cl/protecta/formulario/',
    enabled: true,
    order: 1,
  },
  {
    id: 9,
    name: 'Beneficiarios no ubicados-Ley N 29355',
    link: 'https://protectasecurity.pe/reportes-normativos-beneficiarios-no-ubicados/',
    enabled: true,
    order: 2,
  },
  {
    id: 10,
    name: 'Enlaces de Interés',
    link: 'https://protectasecurity.pe/enlaces-de-interes/',
    enabled: true,
    order: 2,
  },
  {
    id: 11,
    name: 'Portal de brokers de SCTR',
    link: 'http://broker.sanitasperu.com/',
    enabled: true,
    order: 3,
  },
  {
    id: 12,
    name: 'Pagos en línea de SCTR',
    link: 'https://pagosenlinea.sanitasperu.com/#202cb962ac59075b964b07152d234b70',
    enabled: true,
    order: 3,
  },
  {
    id: 13,
    name: 'Delitos de Corrupción',
    link:
      'https://protectasecurity.pe/wp-content/uploads/2018/10/Politica-de-Prevencion-de-Delitos-de-Corrupcion.pdf',
    enabled: true,
    order: 4,
  },
  {
    id: 14,
    name: 'Consultas Comprobantes',
    link: 'https://efacturacion.protectasecurity.pe/descarga/',
    enabled: true,
    order: 5,
  },
];

const PSFooterPageHome = React.forwardRef(({ className }, ref) => {
  return (
    <StyledPsContainerFooter className={className} ref={ref}>
      <div className="columns">
        <div className="column is-three-fifths is-offset-one-fifth">
          <StyledPSFooterPageHome className="columns">
            <div className="column mb-1">
              <div className="mb-1">
                <PSLabel className="text-info mb-1">Transparencia de la información</PSLabel>
                {linksPrimaryFooter
                  .filter((o) => o.order === 1)
                  .map((obj, index) => {
                    return (
                      <PSLabel key={index} className="text-white">
                        <a
                          style={!obj.enabled ? { pointerEvents: 'none' } : {}}
                          href={obj.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.name}
                        </a>
                      </PSLabel>
                    );
                  })}
              </div>
              <div className="">
                <PSLabel className="text-info mb-1">
                  Indemnizaciones por muerte pendiente de pago
                </PSLabel>
                {linksPrimaryFooter
                  .filter((o) => o.order === 2)
                  .map((obj, index) => {
                    return (
                      <PSLabel key={index} className="text-white">
                        <a
                          style={!obj.enabled ? { pointerEvents: 'none' } : {}}
                          href={obj.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.name}
                        </a>
                      </PSLabel>
                    );
                  })}
              </div>
            </div>
            <div className="column mb-1">
              <div className=" mb-1">
                <PSLabel className="text-info mb-1">Nuestra alianza con Sanitas</PSLabel>
                {linksPrimaryFooter
                  .filter((o) => o.order === 3)
                  .map((obj, index) => {
                    return (
                      <PSLabel key={index} className="text-white">
                        <a
                          style={!obj.enabled ? { pointerEvents: 'none' } : {}}
                          href={obj.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.name}
                        </a>
                      </PSLabel>
                    );
                  })}
                <img src={`${pathServer.PATH_ICONS}Logo-Sanitasr.png`} width="40%" />
              </div>
              <div className="">
                <PSLabel className="text-info mb-1 f-extraBold">Políticas de prevencíon</PSLabel>
                {linksPrimaryFooter
                  .filter((o) => o.order === 4)
                  .map((obj, index) => {
                    return (
                      <PSLabel key={index} className="text-white">
                        <a
                          style={!obj.enabled ? { pointerEvents: 'none' } : {}}
                          href={obj.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.name}
                        </a>
                      </PSLabel>
                    );
                  })}
              </div>
            </div>
            <div className="column mb-1">
              <div className=" mb-1">
                <PSLabel className="text-info mb-1">Facturas electronicas</PSLabel>
                {linksPrimaryFooter
                  .filter((o) => o.order === 5)
                  .map((obj, index) => {
                    return (
                      <PSLabel key={index} className="text-white">
                        <a
                          style={!obj.enabled ? { pointerEvents: 'none' } : {}}
                          href={obj.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {obj.name}
                        </a>
                      </PSLabel>
                    );
                  })}
              </div>
              <div className="">
                <PSLabel className="text-info mb-1">Contáctenos:</PSLabel>
                <PSLabel className="text-white">Lima 01-391-300</PSLabel>
                <PSLabel className="text-white">Provincia: 0801-1-1278</PSLabel>
                <PSLabel className="text-white">clientes@protectasecurity.pe</PSLabel>
                <PSLabel className="text-white">Av. Domingo Orué N 165</PSLabel>
                <PSLabel className="text-white">8vo Piso, Lima 34-Perú</PSLabel>
              </div>
            </div>
            <div className="column mb-1">
              <div className="">
                <PSLabel className="text-info mb-1">Síguenos</PSLabel>
                <div className="icon-container">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/ProtectaSecurity/"
                  >
                    <PSIcon type="facebook" className="icon-follow" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCLDUNm7ULAC4jbie_7S8beQ"
                  >
                    <PSIcon type="youtube" className="icon-follow ml-1" /></a>
                </div>
              </div>
            </div>
          </StyledPSFooterPageHome>
        </div>
      </div>
    </StyledPsContainerFooter>
  );
});

export default PSFooterPageHome;
