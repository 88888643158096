export class User {
  constructor(props) {
    this.birthDate = props['birthdate'];
    this.email = props['email'];
    this.emailVerified = props['email_verified'];
    this.familyName = props['family_name'];
    this.givenName = props['given_name'];
    this.middleName = props['middle_name'];
    this.phoneNumber = props['phone_number'];
    this.phoneNumberVerified = props['phone_number_verified'];
    this.sub = props['sub'];
    this.productRentaTotal = props['custom:renta-total'];
    this.productAhorroTotal = props['custom:ahorro-total'];
    this.productRentaVitalicia = props['custom:rentas-vitalicias'];
    this.productSoat = props['custom:soat'];
    this.productDesgravamen = props['custom:desgravamen'];
    this.firstLogin = props['custom:first-login'] ? JSON.parse(props['custom:first-login']) : false;
    this.legalName= props['custom:legal_name'];
    this.pdpNetPrivada =  props['custom:pdp_net_privada'] || false;
    this.firstApperAgain =  props['custom:first_appear_again'] || false;
    this.appOrigin = props['custom:aap_origin'];
    this.lastSurvey = props['custom:last_survey'];
  }
}
