import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { Routes } from '../../../routes';
import { isEmpty, validateDocumentPass, validationSequencialPass } from '../../../util/Util';
import {
  Timer,
  NEED_UPDATE_PASSWORD,
  validateEqualsConsecutive,
  validateLowerCase,
  validateUpperCase,
  validateNumber,
  validateSpecialCharacter,
  validateSpace,
} from '../../../util/Constant';
import { updatePassword, cleanAuth, validateLoginUser } from '../../redux/auth';
import { setInvalidPassword } from '../../redux/auth/actions/invalid-password';

import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSBox from '../../components/PSBox';
import PSTextInput from '../../components/PSTextInput';
import PSLabel from '../../components/PSLabel';
import PSButton from '../../components/PSButton';
import PSCheckBox from '../../components/PSCheckBox';
import PSPrivacyPolicies from '../../components/PSPrivacyPolicies';
import PSError from '../../components/PSError';
import PSMessageResponse from '../../components/PSMessageResponse';
import PSInputIcon from '../../components/PSInputIcon';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const MIN_CHARACTERS = 8;
const MAX_CHARACTERS = 24;
const MESSAGE_WRONG_PASS = 'Contraseña no cumple con políticas de seguridad';

export default function SignUpPassword({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    updatedPassword,
    signUp,
    forgotPassword,
    userInfo,
    userValidationInfo,
    invalidPassword,
  } = useSelector((state) => state.auth);
  const checkValue = userValidationInfo ? userValidationInfo.checkValue : false;
  const [showPrivacyPolices, setShowPrivacyPolices] = useState(false);
  const [successResponse, setSuccessResponse] = useState();
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passValidation, setPassValidation] = useState(false);
  const [passwordRepeatHidden, setPasswordRepeatHidden] = useState(false);
  const [messagePassword, setMessagePassword] = useState(MESSAGE_WRONG_PASS);
  const ga = React.useContext(GAProvider);
  const [isFistLoginBusiness, setIsFistLoginBusiness] = useState(false);
  const [messageInvalidPass, setMessageInvalidPass] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      pdpNetPrivada: false,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(MIN_CHARACTERS, `${MESSAGE_WRONG_PASS}: Mínimo ${MIN_CHARACTERS} caracteres`)
        .max(MAX_CHARACTERS, `${MESSAGE_WRONG_PASS}: Máximo ${MAX_CHARACTERS} caracteres`)
        .matches(validateSpace, `${MESSAGE_WRONG_PASS}: No incluir espacios en blanco`)
        .matches(validateLowerCase, `${MESSAGE_WRONG_PASS}: Mínimo 1 minúscula`)
        .matches(validateUpperCase, `${MESSAGE_WRONG_PASS}: Mínimo 1 mayúscula`)
        .matches(validateNumber, `${MESSAGE_WRONG_PASS}: Mínimo 1 número`)
        .matches(validateSpecialCharacter, `${MESSAGE_WRONG_PASS}: Mínimo 1 caracter especial`)
        .matches(validateEqualsConsecutive, `${MESSAGE_WRONG_PASS}: Máximo 3 consecutivos iguales`)
        .required('La contraseña es requerida'),
      repeatPassword: Yup.string()
        .min(MIN_CHARACTERS, `Necesita mínimo ${MIN_CHARACTERS} caracteres`)
        .max(MAX_CHARACTERS, `Máximo ${MAX_CHARACTERS} caracteres`)
        .oneOf([Yup.ref('password'), null], 'Contraseñas deben ser iguales')
        .required('La contraseña es requerida'),
    }),

    onSubmit(val) {
      if (
        userInfo &&
        (userInfo.documentType === 'R' ||
          (userInfo.attributes && userInfo.attributes.appOrigin === 'NPBACKOF')) &&
        userInfo.attributes &&
        userInfo.attributes.firstLogin
      ) {
        dispatch(updatePassword(val.password, userInfo, true, val.pdpNetPrivada));
      } else {
        const isUpdatePassword = !isEmpty(forgotPassword);
        const user = signUp.documentId ? signUp : forgotPassword;
        const pdpNetPrivada = isEmpty(forgotPassword) ? checkValue || val.pdpNetPrivada : null;

        dispatch(updatePassword(val.password, user, false, pdpNetPrivada, isUpdatePassword));
      }
    },
  });
  const validationWrongPass =
    formik.touched.password &&
    (formik.errors.password || (!formik.errors.password && !passValidation));

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (formik.values.password.length >= MIN_CHARACTERS) {
      const { password } = formik.values;
      const documentNumber = userInfo.document || signUp.documentId || forgotPassword.documentId;

      const validationSequencial = validationSequencialPass(password);
      const validationDocument = validateDocumentPass(password, documentNumber);

      if (!validationSequencial) {
        setMessagePassword(`${MESSAGE_WRONG_PASS}: Máximo 3 consecutivos secuenciales`);
      } else if (!validationDocument) {
        setMessagePassword(`${MESSAGE_WRONG_PASS}: No debe incluir el N° de documento`);
      }
      setPassValidation(validationSequencial && validationDocument);
    }
    setMessageInvalidPass(false);
  }, [formik.values]);

  useEffect(() => {
    if (invalidPassword) {
      setMessageInvalidPass(true);
      dispatch(setInvalidPassword(false));
    }
  }, [invalidPassword]);

  useEffect(() => {
    if (updatedPassword) {
      if (signUp.documentId) {
        const documentId = signUp.documentId.toString();
        const documentType = signUp.documentType.toString();

        const typeUser = documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
        ga.pageview(window.location.pathname + window.location.search);
        ga.event({
          category: `${typeUser}Enrollment`,
          action: 'click',
          label: 'El usuario ha completado su enrollment satisfactoriamente',
          value: 1,
        });

        dispatch(cleanAuth());
        setSuccessResponse(true);
        setTimeout(() => {
          dispatch(validateLoginUser(documentId, formik.values.password, documentType));
          if (userInfo.valid) {
            history.push(Routes.HOME);
          } else if (userInfo.codeError) {
            dispatch(cleanAuth());
            history.push(Routes.LOGIN);
          }
        }, Timer.waitingApiLogin);
      } else if (
        userInfo &&
        (userInfo.documentType === 'R' ||
          (userInfo.attributes && userInfo.attributes.appOrigin === 'NPBACKOF')) &&
        userInfo.attributes &&
        userInfo.attributes.firstLogin
      ) {
        const category =
          userInfo.documentType === 'R' ? 'EMPRESA-Enrollment' : 'PERSONA-Enrollment';
        const label =
          userInfo.documentType === 'R'
            ? 'El usuario-empresa, ha completado su enrollment satisfactoriamente'
            : 'El usuario creado del backoffice, ha completado su enrollment satisfactoriamente';

        const documentId = userInfo.document.toString();
        const documentType = userInfo.documentType.toString();
        localStorage.setItem(NEED_UPDATE_PASSWORD, '0');
        dispatch(cleanAuth());
        setSuccessResponse(true);

        setTimeout(() => {
          dispatch(validateLoginUser(documentId, formik.values.password, documentType));
          if (userInfo.valid) {
            ga.pageview(window.location.pathname + window.location.search);
            ga.event({
              category,
              action: 'click',
              label,
              value: 1,
            });
            history.push(Routes.HOME);
          }
        }, Timer.waitingApiLogin);
      } else {
        if (!isEmpty(forgotPassword)) {
          const messageTypeUser = forgotPassword.documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
          ga.pageview(window.location.pathname + window.location.search);
          ga.event({
            category: `${messageTypeUser}Recover Password`,
            action: 'click',
            label: 'El usuario cambió su contraseña',
            value: 1,
          });
        }

        setSuccessResponse(true);
        dispatch(cleanAuth());

        setTimeout(() => {
          history.push(Routes.LOGIN);
        }, Timer.waitingApi);
      }
    }
  }, [updatedPassword]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo.documentType === 'R' &&
      userInfo.attributes &&
      userInfo.attributes.firstLogin
    ) {
      setIsFistLoginBusiness(true);
    } else {
      setIsFistLoginBusiness(false);
    }
  }, []);

  return (
    <div className="create-password-container">
      {!successResponse && (
        <PSLoginContainer
          showCloseIcon={!showPrivacyPolices}
          className="password-container"
          onClose={onClose}
          loading={loading}
        >
          {!showPrivacyPolices ? (
            <>
              <PSHeaderLogin
                className="create-password-header"
                title={
                  isFistLoginBusiness
                    ? 'Ingresa una nueva contraseña'
                    : isEmpty(forgotPassword)
                    ? 'Crea tu contraseña'
                    : 'Crea tu nueva contraseña'
                }
                icon="home"
              />
              <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
                <PSBox className="password-register">
                  <div className="password-register-container">
                    <PSInputIcon
                      isShow={passwordHidden}
                      showPassword={() => setPasswordHidden(!passwordHidden)}
                    >
                      <PSTextInput
                        type={passwordHidden ? 'text' : 'password'}
                        {...formik.getFieldProps('password')}
                        placeholder="********"
                        className={validationWrongPass ? 'with-error' : ''}
                      />
                      <p> {formik.password}</p>
                    </PSInputIcon>
                    <PSError>
                      {formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : validationWrongPass
                        ? messagePassword
                        : ''}
                    </PSError>
                    <PSInputIcon
                      isShow={passwordRepeatHidden}
                      showPassword={() => setPasswordRepeatHidden(!passwordRepeatHidden)}
                    >
                      <PSTextInput
                        type={passwordRepeatHidden ? 'text' : 'password'}
                        {...formik.getFieldProps('repeatPassword')}
                        placeholder="********"
                        className={
                          formik.touched.repeatPassword && formik.errors.repeatPassword
                            ? 'with-error'
                            : ''
                        }
                      />
                    </PSInputIcon>

                    <PSError>
                      {formik.touched.repeatPassword && formik.errors.repeatPassword}
                    </PSError>
                  </div>
                  {messageInvalidPass && (
                    <PSLabel className="invalid-pass">{MESSAGE_WRONG_PASS}</PSLabel>
                  )}
                  <PSLabel>
                    *Al menos 8 caracteres con 1 mayúscula, 1 minúscula, 1 número y 1 caracter
                    especial.
                  </PSLabel>
                </PSBox>
                <PSBox>
                  <PSButton
                    className="button-link f-extraBold"
                    onClick={() =>
                      window.open('https://protectasecurity.pe/politica-de-privacidad/')
                    }
                  >
                    Política de privacidad de datos personales
                  </PSButton>
                </PSBox>

                {(isFistLoginBusiness || isEmpty(forgotPassword)) && !checkValue ? (
                  <PSBox className="password-acept-terms">
                    <PSCheckBox
                      className="d-flex"
                      {...formik.getFieldProps('pdpNetPrivada')}
                      value={formik.values.pdpNetPrivada}
                    >
                      Acepto el envío de comunicaciones comerciales y publicidad de acuerdo a lo
                      señalado en la Política de Privacidad.
                    </PSCheckBox>
                  </PSBox>
                ) : null}
                <PSBox>
                  <PSButton
                    className={`f-bold ${
                      (!isEmpty(formik.errors) || !passValidation) && 'button-disabled'
                    }`}
                    type="submit"
                    loading={loading}
                  >
                    Enviar
                  </PSButton>
                </PSBox>
              </form>
            </>
          ) : (
            <PSPrivacyPolicies
              goBack={() => setShowPrivacyPolices(false)}
              className="password-privacy-policies"
            />
          )}
        </PSLoginContainer>
      )}
      {successResponse && (
        <PSMessageResponse
          title={signUp ? 'Contraseña válida' : 'Contraseña actualizada'}
          icon="successFace"
        >
          {signUp
            ? 'Su contraseña ha sido creada correctamente.'
            : 'Se guardaron los cambios con éxito'}
        </PSMessageResponse>
      )}
    </div>
  );
}
