import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone/index';

import { Routes } from '../../../routes';
import {
  MAGAZINE_BOLETIN_FINANCIERO,
  MAGAZINE_BOLETIN_QUEDATE_CASA,
  MAGAZINE_BOLETIN_VIVE_SALUD,
  optionsHeaderNewSletter,
} from '../../../util/Constant';
import { changeRoute, existInUrl, scrollToTop } from '../../../util/Util';
import { Magazine } from '../../shared/model/magazine/Magazine';
import LoadMagazinesService from '../../shared/services/newSletter/LoadMagazinesService';

import PSNewsletterDetail from '../../components/PSNewsletterDetail';
import PSNewsletterHeader from '../../components/PSNewsletterHeader';
import PSSearch from '../../components/PSSearch';
import PSNewsletterHeaderCarousel from '../../components/PSNewsletterHeaderCarousel';
import PSNewsletterContainerItem from '../../components/PSNewsletterContainerItem';
import PSLoading from '../../components/PSLoading';
import { PSChatBoot } from '../../components/molecules';

import './index.scss';

const footerPrincipal = {
  id: 0,
  title: 'Financiero',
  code: 'Nº 108',
  description: 'Mercado Financiero',
  subDescription: 'Nº 108',
  type: MAGAZINE_BOLETIN_FINANCIERO,
};

const footerMessage = [
  {
    id: 0,
    code: 'Nº 108',
    title: 'Boletín financiero',
    description: 'Boletín semanal Nº 108',
    subDescription: 'Nº 108',
    type: MAGAZINE_BOLETIN_FINANCIERO,
  },
  {
    id: 1,
    code: 'Noviembre 2020',
    title: 'Salud',
    description: 'Boletín Vive Salud ',
    subDescription: 'Cáncer de Mama',
    type: MAGAZINE_BOLETIN_VIVE_SALUD,
  },
  {
    id: 2,
    code: 'BON JOVI 2020',
    title: 'Musicales',
    description: 'Bon Jovi 2020',
    subDescription: '',
    type: MAGAZINE_BOLETIN_QUEDATE_CASA,
  },
  {
    id: 3,
    code: 'HARVARD University',
    title: 'Cursos gratis',
    description: 'HARVARD University',
    subDescription: 'Revisa los cursos que tiene Harvard University',
    type: MAGAZINE_BOLETIN_QUEDATE_CASA,
  },
];

const NRO_X_PAGE = 6;

const reloadRelatedMagazine = (magazineSel, arrMagazine) => {
  const arrRelated = arrMagazine.slice(0, 3);
  const indexDuplicated = magazineSel
    ? arrRelated.findIndex((arr) => arr.idContenido === magazineSel.idContenido)
    : -1;
  if (indexDuplicated !== -1) {
    arrRelated.splice(indexDuplicated, 1);
    arrRelated.push(arrMagazine[3]);
  }
  return arrRelated || arrMagazine.slice(0, 3);
};

export default function HomeNewsletter(props) {
  const history = useHistory();
  const [showDetail, setShowDetail] = useState(false);
  const [arrMagazine, setMagazines] = useState([]);
  const [arrMagazineByPage, setMagazinesByPage] = useState([]);

  const [magazineSel, setMagazineSel] = useState(arrMagazine[0]);
  const [currentpagination, setCurrentpagination] = useState(1);
  const [loading, setLoading] = useState(false);
  const [nropagination, setNropagination] = useState([]);
  const optionSelected = optionsHeaderNewSletter.find((option) => existInUrl(option.value));
  const arrRelated = reloadRelatedMagazine(magazineSel, arrMagazine);

  const loadMagazines = async (index, url) => {
    setLoading(true);
    const optionSelected =
      index !== undefined
        ? optionsHeaderNewSletter[index]
        : optionsHeaderNewSletter.find((option) =>
            url ? url === option.value : existInUrl(option.value)
          );
    const response = await LoadMagazinesService.responseGetMagazines(optionSelected);
    const today = moment(Date.now()).format('YYYY-MM-DD');
    // Ocultar a los de la sección 'En Vivo'
    const arrBoletin = response
      .filter(
        (x) =>
          x.dateExpiration * 1000 >= new Date(today).valueOf() && x.idTipoContenido !== '00000001'
      )
      .map((r) => new Magazine(r));
    setMagazines(arrBoletin);
    setInitialpagination(arrBoletin);
    setLoading(false);
  };

  const setInitialpagination = (object) => {
    const nropaginas = Math.ceil(object.length / NRO_X_PAGE);
    setMagazinesByPage(object.slice(0, NRO_X_PAGE));
    const paginator = [];
    for (let i = 1; i <= nropaginas; i++) {
      paginator.push(i);
    }
    setNropagination(paginator);
  };

  const setpagination = (value) => {
    setMagazinesByPage(arrMagazine.slice(NRO_X_PAGE * (value - 1), NRO_X_PAGE * value));
    setCurrentpagination(value);
    scrollToTop();
  };

  const findMagazine = async (type, idMagazine) => {
    history.push(Routes.HOME_NEWSLETTER + changeRoute(type));
    const response = await LoadMagazinesService.responseGetMagazines({ idTipoContenido: type });
    const arrBoletin = response.map((r) => new Magazine(r));
    const findBoletin = arrBoletin.find((b) => b.description === idMagazine);
    setMagazineSel({ ...findBoletin });
    setShowDetail(true);
  };

  const onSearch = (value) => {
    const arrMagazineFilter = arrMagazine.filter((item) => {
      return (
        item.description.search(new RegExp(value, 'i')) !== -1 ||
        item.title.search(new RegExp(value, 'i')) !== -1
      );
    });
    setInitialpagination(arrMagazineFilter);
    setCurrentpagination(1);
  };

  useEffect(() => {
    loadMagazines();
  }, []);

  useEffect(() => {
    const currentUrl = `/${props.history.location.pathname.split('/')[3]}`;
    setCurrentpagination(1);
    loadMagazines(undefined, currentUrl);
  }, [props.history.location.pathname]);

  return (
    <div className="newsletter-container w-100">
      <PSNewsletterHeaderCarousel />
      <PSNewsletterHeader
        reloadMagazines={(index) => {
          loadMagazines(index);
          setShowDetail(false);
        }}
        className="newsletter-header-options display-none-mobile"
      />
      {!showDetail && <PSSearch className="newsletter-header-search" onSearch={onSearch} />}

      {loading ? (
        <div className="loading-container">
          <PSLoading />
        </div>
      ) : (
        <div className="newsletter-body">
          {!showDetail && (
            <PSNewsletterContainerItem
              options={arrMagazineByPage}
              showDetail={(obj) => {
                setShowDetail(true);
                setMagazineSel(obj);
              }}
              nroPagination={nropagination}
              currentPagination={currentpagination}
              setCurrentPagination={(value) => setpagination(value)}
            />
          )}

          {showDetail && (
            <>
              <PSNewsletterDetail
                magazine={magazineSel}
                img="testDetail.png"
                onBack={() => setShowDetail(false)}
              />

              <PSNewsletterContainerItem
                options={arrRelated}
                showDetail={(obj) => {
                  setShowDetail(true);
                  setMagazineSel(obj);
                  scrollToTop();
                }}
                showPagination={false}
                currentPagination={1}
                setCurrentPagination={(value) => setCurrentpagination(value)}
              />
            </>
          )}
        </div>
      )}
      <PSChatBoot />
      {/* <PSNewsletterFooter */}
      {/*  showDetail={(typeContent, id) => findMagazine(typeContent, id)} */}
      {/*  obj={optionSelected ? footerMessage[optionSelected.id] : footerPrincipal} */}
      {/* /> */}
    </div>
  );
}
