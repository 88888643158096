import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import moment from 'moment-timezone';
import map from 'lodash/map';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import find from 'lodash/find';

import { GAProvider } from '../../components/PSAnalytics';
import { createErrorPage } from '../../redux/auth';
import { scrollToTop, showMenuCoupon } from '../../../util/Util';
import { Routes } from '../../../routes';
import { Strings } from '../../shared/constants/Strings';
import CouponSoatServices from '../../shared/services/CouponSoatServices';

import { Coupon } from '../../shared/model/Coupon';
import CouponMain from '../../components/templates/couponMain/CouponMain';
import CouponDetail from '../../components/templates/couponDetail/CouponDetail';
import MyCoupons from '../../components/templates/myCoupons/MyCoupons';
import PSMessageResponse from '../../components/PSMessageResponse';
import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSSubtitle from '../../components/PSSubtitle';
import PSButton from '../../components/PSButton';

import './index.scss';

export default function HomeCoupons(props) {
  const [loading, setLoading] = useState(true);
  const [loadingMyCoupons, setLoadingMyCoupons] = useState(true);
  const [loadingDeleteCoupon, setLoadingDeleteCoupon] = useState(false);
  const [loadingRequestCoupon, setLoadingRequestCoupon] = useState(false);
  const [listCoupons, setListCoupons] = useState([]);
  const [listMyCoupons, setListMyCoupons] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const dispatch = useDispatch();
  const [showPageCoupon, setShowPageCoupon] = useState('main');
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalInvalidRequest, setShowModalInvalidRequest] = useState(false);
  const [genericRequestError, setGenericRequestError] = useState(false);
  const [showModalConfirmDeleteCoupon, setShowModalConfirmDeleteCoupon] = useState(false);
  const [showModalCodeCoupon, setShowModalCodeCoupon] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const ga = React.useContext(GAProvider);
  const servicesDetail = userInfo?.productsDetail || [];
  const history = useHistory();
  const banners = useSelector((state) => state.auth).banners || {
    promotions: [],
  };
  const issuesDateSoat = userInfo?.issuesDateSoat || [];

  const classNamesModal = {
    overlay: 'customOverlay',
    modal: 'customModalCoupons',
  };

  const img = 'Header-free-car-wash';
  const imgMobile = 'Header-free-car-wash-mobile';

  const analyticsPromoGratis = (labelText) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Promo Gratis`,
      action: 'click',
      label: labelText,
      value: 1,
    });
  };

  const responseListCoupons = async () => {
    try {
      const response = await CouponSoatServices.responseGetCoupons();
      setLoading(false);

      const listCoupons = map(
        response.filter((x) => new Date(x.startDate * 1000).setHours(0, 0, 0, 0) <= new Date()),
        (r) => new Coupon(r)
      ).filter((c) => c.promoCurrentYear);
      setListCoupons([...listCoupons]);
    } catch (error) {
      setLoading(false);
      dispatch(createErrorPage());
    }
  };

  const loadEnrolledCoupons = async () => {
    try {
      const { status, data } = await CouponSoatServices.responseGetEnrolledCoupons();
      const myCoupons = [];

      forEach(data, (dataByType) => {
        forEach(dataByType.couponCodes, (item) => {
          myCoupons.push({
            ...item,
            id: dataByType.discountId,
          });
        });
      });

      if (status.id === 200) {
        const available = map(myCoupons, (d) => {
          const flag = find(listCoupons, (l) => d.id == l.id);
          if (flag) {
            return { ...d, ...flag };
          }
        });
        setListMyCoupons(compact(available));
      } else {
        dispatch(createErrorPage());
      }
    } catch (e) {
      dispatch(createErrorPage());
    } finally {
      setLoadingMyCoupons(false);
    }
  };

  const redirectPage = (value) => {
    switch (value) {
      case 'detail':
        return (
          <CouponDetail
            couponSelected={currentCoupon}
            listCoupon={listCoupons.filter((item) => item.availableCoupons > 0)}
            requestCoupon={(id) => requestCoupon(id)}
            loadingRequestCoupon={loadingRequestCoupon}
            currentCouponSelected={(currentCoupon) => {
              setCurrentCoupon(currentCoupon);
              scrollToTop();
            }}
          />
        );
      case 'my-coupons':
        return (
          <MyCoupons
            loading={loading}
            setLoading={setLoading}
            listCoupon={listCoupons.filter((item) => item.availableCoupons > 0)}
            availableCoupons={listMyCoupons}
            currentCouponSelected={(currentCoupon) => {
              setCurrentCoupon(currentCoupon);
              setShowPageCoupon('detail');
              scrollToTop();
            }}
            onClickDeleteCoupon={(dataCoupon) => {
              setShowModalConfirmDeleteCoupon(true);
              setCurrentCoupon(dataCoupon);
            }}
            onClickShowCodeCoupon={(dataCoupon) => {
              setShowModalCodeCoupon(true);
              setCurrentCoupon(dataCoupon);
              analyticsPromoGratis('El usuario visualizó el código de su cupón asignado');
            }}
          />
        );
      default:
        // main
        return (
          <CouponMain
            loading={loading}
            loadingMyCoupons={loadingMyCoupons}
            listCoupon={listCoupons.filter((item) => item.availableCoupons > 0)}
            listMyCoupon={listMyCoupons}
            currentCouponSelected={(currentCoupon) => {
              setCurrentCoupon(currentCoupon);
            }}
            currentPageCoupon={(currentPage) => {
              setShowPageCoupon(currentPage);
            }}
          />
        );
    }
  };

  const closeModalSuccess = () => {
    responseListCoupons();
    setShowPageCoupon('my-coupons');
    setShowModalSuccess(false);
  };
  const closeModalInvalidRequest = () => {
    setShowPageCoupon('main');
    setShowModalInvalidRequest(false);
    setGenericRequestError(false);
  };

  const requestCoupon = async (id) => {
    if (id) {
      setLoadingRequestCoupon(true);
      const { status } = await CouponSoatServices.responseEnrollCoupon(id);
      if (status?.id === 201) {
        setShowModalSuccess(true);
        analyticsPromoGratis(
          `El usuario solicitó exitosamente un cupón de descuento de ${currentCoupon.companyName}`
        );
      } else if (status?.id === 204 && status?.name === Strings.LIMIT_EXCEEDED) {
        setShowModalInvalidRequest(true);
        analyticsPromoGratis(
          `El usuario solicitó sin éxito un cupón de descuento de ${currentCoupon.companyName}`
        );
      } else {
        setShowModalInvalidRequest(true);
        setGenericRequestError(true);
        analyticsPromoGratis(
          `El usuario solicitó sin éxito un cupón de descuento de ${currentCoupon.companyName}`
        );
      }
      setLoadingRequestCoupon(false);
    }
  };

  const onClickDeleteCoupon = async () => {
    try {
      const data = {
        discountCouponId: currentCoupon.id,
        couponCodes: [{ id: currentCoupon.couponCode }],
      };

      const response = await CouponSoatServices.deleteCoupon(data);
      if (response.status.id === 201) {
        setShowModalConfirmDeleteCoupon(false);
        await responseListCoupons();
        await loadEnrolledCoupons();
      } else {
        setShowModalConfirmDeleteCoupon(false);
        dispatch(createErrorPage());
      }
      setLoadingDeleteCoupon(false);
    } catch (error) {
      console.error(error);
      setLoadingDeleteCoupon(false);
    }
  };

  useEffect(() => {
    if (
      showMenuCoupon(servicesDetail, banners.promotions, issuesDateSoat, userInfo.showPromoFree)
    ) {
      responseListCoupons();
    } else {
      history.push(Routes.HOME);
    }
  }, []);

  useEffect(() => {
    if (listCoupons.length > 0) {
      loadEnrolledCoupons();
    } else {
      setLoadingMyCoupons(false);
    }
  }, [listCoupons]);

  useEffect(() => {
    analyticsPromoGratis('El usuario visitó la página de Promo Gratis');
  }, [ga]);

  useEffect(() => {
    if (showPageCoupon === 'detail')
      analyticsPromoGratis(
        `El usuario visitó el detalle de la promoción de ${currentCoupon.companyName}`
      );
    if (showPageCoupon === 'my-coupons')
      analyticsPromoGratis('El usuario visitó la página de sus cupones');
  }, [showPageCoupon]);

  return (
    <div className="home-coupon-container">
      <div className="header">
        <PSHeaderIntranet
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description="Encuentra tu promo SOAT aqui"
          icon="protectionPrize"
          {...props}
          className="prize-banner"
          goBackFunction={() => {
            if (showPageCoupon !== 'main') {
              setShowPageCoupon('main');
              setCurrentCoupon(null);
            } else {
              props.history.goBack();
            }
          }}
        />
      </div>
      {redirectPage(showPageCoupon)}
      {showModalSuccess && (
        <Modal
          animationDuration={0}
          open={showModalSuccess}
          onClose={() => closeModalSuccess()}
          showCloseIcon={false}
          onOverlayClick={() => {}}
          className="modal-success"
          classNames={classNamesModal}
        >
          <PSMessageResponse
            typeIconClose="closeBlue"
            icon="successFaceGray"
            onClose={() => closeModalSuccess()}
            title="¡Canje exitoso!"
            className="modal-success__content"
          >
            <p>Ahora disfruta de tu lavado de auto</p>
          </PSMessageResponse>
        </Modal>
      )}
      {showModalInvalidRequest && (
        <Modal
          animationDuration={0}
          open={showModalInvalidRequest}
          onClose={() => {}}
          showCloseIcon={false}
          onOverlayClick={() => {}}
          className="modal-invalidRequest"
          classNames={classNamesModal}
        >
          <PSMessageResponse
            typeIconClose="closeBlue"
            icon="failed"
            onClose={() => closeModalInvalidRequest()}
            title="Solicitud Inválida"
            className="modal-invalidRequest__content"
          >
            <p className="invalid">
              {genericRequestError
                ? 'No se pudo procesar su solicitud'
                : `Tiene ${listMyCoupons.length} ${
                    listMyCoupons.length === 1 ? 'cupón asignado.' : 'cupones asignados.'
                  }`}
            </p>
          </PSMessageResponse>
        </Modal>
      )}

      {showModalConfirmDeleteCoupon && (
        <Modal
          animationDuration={0}
          open={showModalConfirmDeleteCoupon}
          onClose={() => setShowModalConfirmDeleteCoupon(false)}
          showCloseIcon={false}
          onOverlayClick={() => {}}
          className="modal-deleteCoupon"
        >
          <PSMessageResponse
            typeIconClose="closeBlue"
            onClose={() => setShowModalConfirmDeleteCoupon(false)}
            title="Quitar Cupón"
            className="modal-deleteCoupon__content"
          >
            <PSSubtitle className="mt-1 modal-deleteCoupon__subTitle">
              ¿Estás seguro que deseas quitar el Cupón ?
            </PSSubtitle>

            <div className="modal-deleteCoupon__buttons">
              <PSButton
                className="button-white mr-1"
                onClick={() => {
                  setShowModalConfirmDeleteCoupon(false);
                }}
              >
                Cancelar
              </PSButton>
              <PSButton
                className="button-orange"
                onClick={() => {
                  setLoadingDeleteCoupon(true);
                  onClickDeleteCoupon();
                }}
                loading={loadingDeleteCoupon}
              >
                <p>Quitar</p>
              </PSButton>
            </div>
          </PSMessageResponse>
        </Modal>
      )}
      {showModalCodeCoupon && (
        <Modal
          animationDuration={0}
          open={showModalCodeCoupon}
          onClose={() => setShowModalCodeCoupon(false)}
          showCloseIcon={false}
          onOverlayClick={() => {}}
          className="modal-barcode"
          classNames={classNamesModal}
        >
          <PSMessageResponse
            typeIconClose="closeBlue"
            icon="couponGray"
            className="modal-barcode__content"
            title="Código del Cupón"
            onClose={() => setShowModalCodeCoupon(false)}
          >
            <div className="modal-barcode__container">
              <div className="code">
                <span> {currentCoupon.couponCode}</span>
              </div>
              <span> *El código promocional podrá ser usado una sola vez.</span>
            </div>
          </PSMessageResponse>
        </Modal>
      )}
    </div>
  );
}
