import { transformUnixToDateGMT, dateCurrentYear } from '../../../util/Util';

export class Coupon {
  constructor(props) {
    this.id = props.discountId;
    this.principalTitle = props.principalTitle;
    this.secondaryTitle = props.secondaryTitle;
    this.companyName = props.companyName;
    this.shortDescription = props.shortDescription;
    this.name = props.name;
    this.description = props.description;
    this.status = props.status;
    this.endDate = transformUnixToDateGMT(props.endDate * 1000);
    this.startDate = transformUnixToDateGMT(props.startDate * 1000);
    this.availableCoupons = props.availableCoupons;

    this.description = props.description;
    this.details = props.details;
    this.availableDescription = props.details.find(
      (item) => item.label === 'availableDescription'
    )?.value;
    this.terms = props.details.find((item) => item.label === 'terms')?.value;
    this.paragraphs = props.paragraphs;
    this.iconMain = props.icons?.principal;
    this.iconDetail = props.icons?.detail;
    this.promoCurrentYear = dateCurrentYear(props.endDate);
    this.daysValidity = props.daysValidity;
    this.tyc = props.terms.find((item) => item.label === 'Términos y condiciones')?.value;
  }
}
