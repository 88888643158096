export const Routes = {
  BASE: '/',
  TEST: '/test',
  LOGIN: '/auth',
  SIGN_UP: '/auth/sign-up',
  SIGN_UP_VALIDATION: '/auth/sign-up/validation',
  SIGN_UP_REGISTER_COMPANY: '/auth/sign-up/register-company',
  SIGN_UP_CREATE_PASSWORD: '/auth/sign-up/create-password',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_PASSWORD_VALIDATION: '/auth/forgot-password/validation',
  FORGOT_PASSWORD_CREATE_PASSWORD: '/auth/forgot-password/create-password',
  HOME: '/intranet',
  HOME_DOWNLOAD_FORMAT: '/intranet/download-format',
  HOME_TICKET: '/intranet/ticket',
  HOME_REWARDS: '/intranet/rewards',
  HOME_COUPONS: '/intranet/coupons',
  HOME_FREQUENT_QUESTION: '/intranet/frequent-question',
  HOME_PRODUCT_SOAT: '/intranet/product/soat',
  HOME_PRODUCT_RRVV: '/intranet/product/rrvv',
  HOME_PRODUCT_RENTATOTAL: '/intranet/product/rentaTotal',
  HOME_PRODUCT_AHORROTOTAL: '/intranet/product/ahorroTotal',
  HOME_PRODUCT_DESGRAVAMEN: '/intranet/product/desgravamen',
  HOME_PRODUCT_VIDALEY: '/intranet/product/vidaLey',
  HOME_PRODUCT_VIDAINDIVIDUAL: '/intranet/product/vidaIndividual',
  HOME_PRODUCT_ACCIDENTES: '/intranet/product/accidente',
  HOME_PRODUCT_SCTR: '/intranet/product/sctr',
  HOME_PRODUCT_LIFE_DEVOLUTION: '/intranet/product/vida-devolution',
  HOME_PRODUCT_LIFE_CREDIT: '/intranet/product/vidaCredito',
  HOME_SCHEDULE: '/intranet/schedule',
  HOME_REGISTER_CLAIM: '/intranet/claim',
  HOME_UPDATE_DATA: '/intranet/update-data',
  HOME_STATE_CLAIM: '/intranet/claim-state',
  HOME_STATE_PROCEDURE: '/intranet/procedure-state',
  HOME_STATE_REQUEST: '/intranet/request-state',
  HOME_NEWSLETTER: '/intranet/newsletter',

  HOME_PRODUCT_RENTATOTAL_TICKET: '/intranet/product/rentaTotal/ticket',
  HOME_PRODUCT_AHORROTOTAL_TICKET: '/intranet/product/ahorroTotal/ticket',
  HOME_PRODUCT_RRVV_TICKET: '/intranet/product/rrvv/ticket',

  HOME_PRODUCT_RENTATOTAL_SCHEDULE: '/intranet/product/rentaTotal/schedule',
  HOME_PRODUCT_AHORROTOTAL_SCHEDULE: '/intranet/product/ahorroTotal/schedule',
  HOME_PRODUCT_RRVV_SCHEDULE: '/intranet/product/rrvv/schedule',
  LOADING_PAYMENT: '/intranet/product/vida-devolution/loading',
};
