import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import map from 'lodash/map';

import { scrollToTop } from '../../../../util/Util';

import PSLoading from '../../PSLoading';
import PSBox from '../../PSBox';
import PSIcon from '../../PSIcon';
import PSCouponCard from '../../organisms/PSCouponCard/PSCouponCard';

import './index.scss';

const MAX_PER_PAGE = 6;
const CouponMain = ({
  loading,
  loadingMyCoupons,
  listCoupon,
  listMyCoupon,
  currentPageCoupon,
  currentCouponSelected,
}) => {
  const [pageSelected, setPageSelected] = useState(1);

  const listPaginate = listCoupon.slice(
    (pageSelected - 1) * MAX_PER_PAGE,
    pageSelected * MAX_PER_PAGE
  );
  const itemsIterable = Math.ceil(listCoupon.length / MAX_PER_PAGE);

  return (
    <div className="coupon-main">
      <div className="coupon-main__header">
        <div className="section-title">
          <h3 className="title">Canjea tu lavado de auto gratis</h3>
          <p>¡Aprovecha promociones con tiempo limitado!</p>
        </div>
        {loadingMyCoupons ? (
          <PSLoading className="my-coupons" />
        ) : (
          <button
            className="my-coupons"
            onClick={() => {
              if (listMyCoupon.length > 0) {
                currentPageCoupon('my-coupons');
              }
            }}
          >
            <PSBox className="my-coupons__icon">
              <PSIcon type="myCoupons" />
              <label className="cont-coupons"> {listMyCoupon.length}</label>
            </PSBox>
            <label className="my-coupons__text">Cupones disponibles</label>
            <div className={`message-coupons ${listMyCoupon.length > 0 ? 'd-none' : ''} `}>
              <div>
                <p className="message-coupons__main">No tienes ningún cupón :(</p>
                <p className="message-coupons__secondary">
                  ¡Selecciona todos los productos y servicios que quieras para canjearlos!
                </p>
              </div>
            </div>
          </button>
        )}
      </div>
      <div className="coupon-main__body">
        {loading ? (
          <div className="loading-pages-intranet">
            <PSLoading />
          </div>
        ) : listCoupon.length === 0 ? (
          <div className="content-coupons">
            <div className="content-message">
              <label>¡Próximamente verás nuevas promociones!</label>
            </div>
          </div>
        ) : (
          <div>
            <div className="coupon-container">
              {map(listPaginate, (item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (!item.couponFinished) {
                      currentCouponSelected(item);
                      currentPageCoupon('detail');
                    }
                  }}
                >
                  <PSCouponCard data={item} />
                </div>
              ))}
            </div>
            {listCoupon.length > MAX_PER_PAGE && (
              <div className="container-pagination">
                <ReactPaginate
                  pageCount={itemsIterable}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  marginPagesDisplayed={2}
                  initialPage={pageSelected - 1}
                  onPageChange={(page) => {
                    setPageSelected(page.selected + 1);
                    scrollToTop();
                  }}
                  pageRangeDisplayed={MAX_PER_PAGE}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CouponMain.displayName = 'CouponMain';

CouponMain.defaultProps = {
  loading: true,
  loadingMyCoupons: true,
};

CouponMain.propTypes = {
  loading: PropTypes.bool,
  loadingMyCoupons: PropTypes.bool,
};

export default CouponMain;
