import React from 'react';
import styled from 'styled-components';
import PSIcon from './PSIcon';

const StyledPSQuestion = styled.div`
  .container-accordion {
    margin: 1rem;
    .container-question {
      display: flex;
      .question-item {
        font-family: 'BlissPro-ExtraBold', sans-serif !important;
        color: #2b0d61;
        text-align: justify;
      }
      .question-icon {
        margin-right: 0.5rem;
        .a {
          fill: #ed6e00;
        }
        path {
          fill: #ed6e00;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    .container-answer {
      color: #2b0d61;
      display: none;
      p {
        font-size: 14px;
      }
    }
    .container-answer-display {
      color: #2b0d61;
      p {
        padding: 2.5% 0;
        margin-left: 2.5%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #7a7a7a;
        text-align: justify;
      }
    }
  }
`;

const PSQuestion = ({ questions }) => {
  const [showAnswer, setShowAnswer] = React.useState([]);
  const [indexShow, setIndexShow] = React.useState(0);

  const displayAnswer = (index) => {
    showAnswer[index].show = !showAnswer[index].show;
    setShowAnswer([...showAnswer]);

    setIndexShow(index);
  };

  React.useEffect(() => {
    const showAnswerInitial = [];
    questions.forEach((item, index) => {
      if (index === 0) {
        showAnswerInitial.push({ show: true });
      } else {
        showAnswerInitial.push({ show: false });
      }
    });
    setShowAnswer([...showAnswerInitial]);
  }, []);

  return (
    <StyledPSQuestion>
      {questions.map((question, index) => (
        <div className="container-accordion" key={question.id}>
          <div className="container-question" onClick={() => displayAnswer(index)}>
            {
              showAnswer[index] && showAnswer[index].show && indexShow === index ?
                <PSIcon
                  type="arrowUp"
                  className="question-icon"
                />:
                <PSIcon
                type="arrowDown"
                className="question-icon"
                />
            }
            <div className="question-item">{question.question}</div>
          </div>
          <div
            className={`container-answer${
              showAnswer[index] && showAnswer[index].show && indexShow === index ? '-display' : ''
            }`}
          >
            <p>{question.description}</p>
          </div>
        </div>
      ))}
    </StyledPSQuestion>
  );
};

export default PSQuestion;
