import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import './index.scss';
import { pathServer, userDefault } from '../../../../util/Constant';
import GetCertificates from '../../../shared/services/GetCertificates';
import PSLoading from '../../PSLoading';
import { isEmptyArray } from '../../../../util/Util';
import {PSModalErrorResponse} from "../index";

const RowTitle = ({ field, value }) => (
  <div className="filter-row">
    <h6>{field}</h6>
    <span>:</span>
    <h5 className="span-right">{value}</h5>
  </div>
);
const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
let dateFormatted = dateNow.replace('-', 'de');
dateFormatted = dateFormatted.replace('-', 'de');
const ROWS_FIRST_PAGE = 9;
const ROWS_MAX_TABLE = 16;

const PSConstanciaAseguradoSeguro = React.forwardRef(
  ({ id, className, onCloseOption, poliza }, ref) => {
    const [showModalBroken, setShowModalBroken] = useState(false);
    const [loading, setLoading] = useState(false);
    const [asegurado, setAsegurado] = useState([]);
    const [firstTable, setFirstTable] = useState([]);
    const [restTable, setRestTable] = useState([]);
    const {userInfo} = useSelector((state) => state.auth);
    const attributes = userInfo ? userInfo.attributes : userDefault;
    const fullName = attributes.legalName;
    const datePoliza = poliza.dateVigencia ? poliza.dateVigencia.split(' ') : null;
    const dateInitial = datePoliza ? datePoliza[0] : null;
    const dateEnd = datePoliza ? datePoliza[datePoliza.length - 1] : null;
    const hasOnlyEmployee = firstTable.length === 1;
    let indexTable = 0;
    let indexTableChildren = 0;

    function* chunks(arr, n) {
      for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
      }
    }

    const responseListAsegurados = async () => {
      try {
        setLoading(true);
        const response = await GetCertificates.responseListaAsegurados(poliza);
        let { data, statusCode } = response;
        data = data.listaAsegurados;
        if (statusCode === 500) {
          setShowModalBroken(true);
        } else if (isEmptyArray(data)) {
          setTimeout(() => {
            alert('No se encontraron asegurados');
          }, 1000);
          onCloseOption();
        } else if (data.length > ROWS_FIRST_PAGE) {
          setFirstTable(data.slice(0, ROWS_FIRST_PAGE));
          const restTableInitial = data.slice(ROWS_FIRST_PAGE, data.length);
          const tableGroup = [...chunks(restTableInitial, ROWS_MAX_TABLE)];
          setRestTable(tableGroup);
          setLoading(false);
        } else {
          setFirstTable(data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    useEffect(() => {
      responseListAsegurados();
    }, [poliza]);

    return (
      <div>
        <PSModalErrorResponse openModal={showModalBroken} onClick={()=>{setShowModalBroken(false); onCloseOption()}}/>
        {loading ?
          (<div className="loading">
            <h1>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at dolorem fugiat
              hic magni molestias mollitia non officiis optio. Alias architecto consequatur facilis
              maxime nisi quos recusandae sapiente tempora?
            </h1>
            <PSLoading/>
          </div>) :
          <div ref={ref} id={id} className={`app-file-constancia ${className}`}>
            <div className="report-header">
              <div>
                <img alt="" src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`}/>
              </div>
            </div>
            <div className="report-body">
              <div className="report-body-title">
                <h6>CONSTANCIA DE SEGURO</h6>
              </div>
              <div className="report-body-title-secondary">
                <h6 className="mb-1">
                  Seguro de {poliza ? poliza.ramo.descripcion : 'Accidentes Personales'}
                </h6>
                <RowTitle field="Contratante" value={fullName}/>
                <RowTitle field="Nº de Póliza" value={poliza ? poliza.nroPolicy : 2045}/>
                <RowTitle field="Fecha de Inicio de Vigencia" value={dateInitial}/>
                <RowTitle field="Fecha de Fin de Vigencia" value={dateEnd}/>
                <RowTitle field="Suma Asegurada" value="Según las condiciones de la Póliza"/>
              </div>
              <div className="report-description">
                {hasOnlyEmployee ?
                  <h6>
                    {' '}
                    En atención a lo solicitado y de acuerdo con la información proporcionada por el
                    contratante, por medio del presente documento dejamos constancia de que la persona mencionada a
                    continuación se encuentra <b>asegurada</b> bajos los
                    alcances de la presente póliza.
                  </h6>
                  :
                  <h6>
                    {' '}
                    En atención a lo solicitado y de acuerdo con la información proporcionada por el
                    contratante, por medio del presente documento dejamos constancia de que las personas mencionadas a
                    continuación se encuentran <b>aseguradas</b> bajos los
                    alcances de la presente póliza.
                  </h6>

                }

              </div>
              <div className="report-table">
                <table>
                  <thead>
                  <tr>
                    <th width="5%">N°</th>
                    <th width="14%">DOC. IDENTIDAD</th>
                    <th width="17%">APELLIDOS</th>
                    <th width="20%">NOMBRES</th>
                    <th width="15%">FECHA NACIMIENTO</th>
                    <th width="15%">INICIO DE VIGENCIA</th>
                    <th width="15%">FIN DE VIGENCIA</th>
                  </tr>
                  </thead>
                  <tbody>
                  {firstTable.map((l, index) => {
                    const names = l.nombre.split(' ');
                    const lengthNames = names.length;
                    const fullNames = names.slice(lengthNames - 2, lengthNames);
                    const fullLastNames = names.slice(0, lengthNames - 2);
                    const document = l.documento.split('-');
                    indexTable = index;
                    return (
                      <tr key={index}>
                        <td align="center">{index + 1}</td>
                        <td>
                          <div style={{height: '30px', overflow: 'hidden'}}>
                            {document[0] + '-'} <br/> {document[1]}
                          </div>
                        </td>
                        <td>{fullLastNames.map((f) => `${f} `)}</td>
                        <td>{fullNames.map((f) => `${f} `)}</td>
                        <td align="center">-</td>
                        <td align="center">{l.fechaInicio}</td>
                        <td align="center">{l.fechaFin}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              {restTable.map((r, index) => {
                indexTableChildren = index === 0 ? indexTable + 1 : indexTableChildren;
                return (
                  <div
                    className={`report-table rest-table ${index === 0 ? 'first-table' : ''} ${index === restTable.length - 1 ? 'last-table' : ''} `}>
                    <table>
                      <thead>
                      <tr>
                        <th width="5%">N°</th>
                        <th width="14%">DOC. IDENTIDAD</th>
                        <th width="17%">APELLIDOS</th>
                        <th width="20%">NOMBRES</th>
                        <th width="15%">FECHA NACIMIENTO</th>
                        <th width="15%">INICIO DE VIGENCIA</th>
                        <th width="15%">FIN DE VIGENCIA</th>
                      </tr>
                      </thead>
                      <tbody>
                      {r.map((l, index) => {
                        const names = l.nombre.split(' ');
                        const lengthNames = names.length;
                        const fullNames = names.slice(lengthNames - 2, lengthNames);
                        const fullLastNames = names.slice(0, lengthNames - 2);
                        const indexTable2 = indexTableChildren + index;
                        const document = l.documento.split('-');
                        indexTableChildren = index === ROWS_MAX_TABLE - 1 ? indexTable2 + 1 : indexTableChildren;
                        return (
                          <tr key={indexTable2}>
                            <td align="center">{indexTable2 + 1}</td>
                            <td>
                              <div style={{height: '30px', overflow: 'hidden'}}>
                                {document[0] + '-'} <br/> {document[1]}
                              </div>
                            </td>
                            <td>{fullLastNames.map((f) => `${f} `)}</td>
                            <td>{fullNames.map((f) => `${f} `)}</td>
                            <td align="center">-</td>
                            <td align="center">{l.fechaInicio}</td>
                            <td align="center">{l.fechaFin}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
              <div className="report-footer">
                <h5 className="footer-date"> {`Surquillo, ${dateFormatted}`}</h5>
              </div>
              <div className="report-firm">
                <div>
                  <img src={`${pathServer.PATH_ICONS}firma.png`}/>
                  <div className="report-firm-asignee">
                    <h5>Guadalupe Robles Benzaquen</h5>
                    <h5>Jefe de Soluciones de Clientes</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  });

PSConstanciaAseguradoSeguro.displayName = 'PSConstanciaAseguradoSeguro';

PSConstanciaAseguradoSeguro.defaultProps = {
  poliza: null,
  onCloseOption: () => {},
};

PSConstanciaAseguradoSeguro.propTypes = {
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
};

export default PSConstanciaAseguradoSeguro;
