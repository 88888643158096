import { doGetWithParams, doGet } from './Api';

export default class BeneficiaryRents {
  static async responseGetBeneficiary(policy, product, type) {

    const response = await doGetWithParams(`${process.env.REACT_APP_BENEFICIARY}/private-rent/beneficiaries`, { policy,'product-id': product, type });
    return response.data;
  }

  static async responseGetInfoByDocument(document) {
    const response = await doGet(`${process.env.REACT_APP_BASE_URL_NET}/customers-identities/v0/contact/${document}`);
    return response.data;
  }
}
