export const USER_SIGNUP = 'Auth/USER_SIGNUP';

export const setUserSignUp = (user) => ({
  type: USER_SIGNUP,
  payload: user,
});

export const reducer = (state, action) => ({
  ...state,
  signUp: { ...state.signUp, ...action.payload },
});
