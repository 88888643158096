import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPSLabel = styled.label`
  letter-spacing: 0px;
  text-align: center;
  line-height: initial;
  color: #242425;
  opacity: 1;
  font-size: 14px;
  margin: 4px 2px;
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`;

const PSLabel = React.forwardRef(({ inline, ...props }, ref) => (
  <StyledPSLabel {...props} inline={inline} ref={ref} />
));

PSLabel.displayName = 'PSLabel';

PSLabel.defaultProps = {
  inline: false,
};

PSLabel.propTypes = {
  inline: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PSLabel;
