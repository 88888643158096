import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import PSIcon from './PSIcon';
import PSButton from './PSButton';
import { CodeOptProduct } from '../shared/constants/Strings';

const StyledPSOptionsBodyProduct = styled.div`
  display: flex;

  .button-line {
    display: flex;
    align-items: center;
  }
  .option-text {
    position: relative;
    padding: 5px;
  }
  .option-text.active::before,
  .option-text::before {
    content: '';
    position: absolute;
    right: -180px;
    bottom: -2px;
    height: 3px;
    width: calc(100% + 28px);
    background: rgb(43, 13, 97);
    background: linear-gradient(90deg, rgba(43, 13, 97, 1) 0%, rgba(237, 110, 0, 1) 100%);
    transition: all 0.3s ease;
    opacity: 0;
    @media (max-width: 1023px) {
      width: 100%;
      right: 0;
    }
    @media (max-width: 720px) {
      display: none;
    }
  }

  .button-line:hover .option-text::before,
  .option-text.active::before {
    right: 0;
    opacity: 1;
  }

  .button-line:hover .option-text,
  .button-line .option-text.active {
    color: #ed6e00;
  }
  .button-line div div div .c {
    fill: #2b0d61;
  }
  div.active div div .c,
  .button-line:hover div div div .c {
    fill: #ed6e00;
  }
`;

const PSOptionsBodyProduct = ({
  hasToolTip,
  children,
  onClick,
  typeIcon,
  title,
  id,
  hasError,
  policyCurrent,
  activeOption,
}) => {
  const showContent =
    hasToolTip &&
    (id === CodeOptProduct.CPS ||
      id === CodeOptProduct.DP ||
      id === CodeOptProduct.CDG ||
      id === CodeOptProduct.DCP ||
      id === CodeOptProduct.DCG ||
      id === CodeOptProduct.CDA ||
      id === CodeOptProduct.DCD ||
      id === CodeOptProduct.CDLA ||
      id === CodeOptProduct.CICA ||
      id === CodeOptProduct.BLT ||
      id === CodeOptProduct.LDP ||
      id === CodeOptProduct.DCDVI ||
      // code more
      id === CodeOptProduct.CMP ||
      id === CodeOptProduct.IBGS ||
      id === CodeOptProduct.EBGS ||
      id === CodeOptProduct.INCB ||
      id === CodeOptProduct.EXCB ||
      id === CodeOptProduct.CPC ||
      id === CodeOptProduct.CPC ||
      id === CodeOptProduct.MDT ||
      id === CodeOptProduct.CUP ||
      id === CodeOptProduct.HCT);
  const isDisabled =
    id === CodeOptProduct.DCDVI &&
    policyCurrent &&
    policyCurrent.idProduct !== '7' &&
    policyCurrent.nbranch !== '72';
  const active = activeOption ? 'active' : '';
  return (
    <div key={id}>
      <StyledPSOptionsBodyProduct className="d-flex">
        {!isDisabled && (
          <>
            {showContent ? (
              <PSButton
                data-tip="Debes seleccionar una póliza"
                className="button-small button-line"
                onClick={() => {}}
              >
                <PSIcon className={`m-medium ${active}`} type={typeIcon} />
                <span className={`option-text ${active}`}>{title}</span>
              </PSButton>
            ) : (
              <PSButton
                id={id}
                className="button-small button-line"
                data-tip={hasError ? 'Problemas al cargar el documento' : null}
                onClick={hasError ? () => {} : onClick}
              >
                <>
                  <PSIcon className={`m-medium ${active}`} type={typeIcon} />
                  <span className={`option-text ${active}`}>{title}</span>
                </>
              </PSButton>
            )}
          </>
        )}
      </StyledPSOptionsBodyProduct>
      {!showContent && <div>{children}</div>}
    </div>
  );
};

PSOptionsBodyProduct.defaultProps = {
  hasToolTip: false,
  onClick: () => {},
  typeIcon: null,
  title: null,
  activeOption: false,
};

PSOptionsBodyProduct.propTypes = {
  hasToolTip: PropTypes.bool,
  onClick: PropTypes.func,
  typeIcon: PropTypes.string,
  title: PropTypes.string,
  policyCurrent: PropTypes.object,
  activeOption: PropTypes.bool,
};

export default PSOptionsBodyProduct;
