import { getBannerAvailable } from '../../../util/Util';

export class BannersModel {
  constructor(props) {
    this.date = props.Date;
    this.default = props.Default;
    this.startDate = props.StartDate;
    this.endDate = props.EndDate;
    this.id = props.Id;
    this.image = props.Image;
    this.sort = props.Sort;
    this.status = props.Status;
    this.type = props.Type;
    this.products = props.Products;
    this.link = props.Link;
    this.discountId = props.RelatedDiscountId;
    this.name = props.Name;
    this.isAvailable = getBannerAvailable(props.StartDate, props.EndDate, props.RelatedDiscountId);
  }
}
