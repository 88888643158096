import { gql, useQuery, useMutation } from '@apollo/client';

export default class doInitQueryQL {
  static getQuery() {
    return gql`
      query($department: ID!, $province: ID!) {
        departments {
          items {
            id
            description
          }
          nextToken
        }
        provinces(departmentId: $department) {
          items {
            id
            description
          }
          nextToken
        }
        districts(provinceId: $province) {
          items {
            id
            description
          }
          nextToken
        }
      }
    `;
  }

  static baseQuery(index, policy, idProduct) {
    const nroPolicy = policy.toString().padStart(10,'0');
    return `item${index}:policyById(id: "${nroPolicy}", productId: "${idProduct}"){\n        currency {\n          description\n            id\n            shortDescription\n        }\n        premium\n        id\n        endorsement\n      statusCode\n }`;
  }

  static structDataPrima(list) {
    let msg = '';
    let index = 1;
    for (const value of list) {
      msg += this.baseQuery(index, value.nroPolicy,value.productoCode.toString().toUpperCase());
      index++;
    }
    return gql`query Rentas{${msg}}`;
  }
}
