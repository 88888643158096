import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSIcon from './PSIcon';

const StyledPsCarousel = styled.div`
  .container-children {
    display: flex;
    .container-icon {
      margin: auto;
      cursor: pointer;
    }
  }
  .item-no-active {
    display: none;
  }
`;

const PSCarousel = React.forwardRef(({ className, children, action, typeIcon }, ref) => {
  const [currentItemActive, setCurrentItemActive] = useState(0);
  const [iconLeft, setIconLeft] = useState('');
  const [iconRight, setIconRight] = useState('');

  const back = () => {
    if (currentItemActive > 0) {
      setCurrentItemActive(currentItemActive - 1);
      action(currentItemActive - 1);
    } else {
      setCurrentItemActive(children.length - 1);
      action(children.length - 1);
    }
  };

  const advance = () => {
    if (currentItemActive < children.length - 1) {
      setCurrentItemActive(currentItemActive + 1);
      action(currentItemActive + 1);
    } else {
      setCurrentItemActive(0);
      action(0);
    }
  };

  useEffect(() => {
    if (typeIcon === 'primary') {
      setIconLeft('arrowLeftBig');
      setIconRight('arrowRightBig');
    } else {
      setIconLeft('arrowLeftMedium');
      setIconRight('arrowRightMedium');
    }
  }, []);

  return (
    <StyledPsCarousel className={className} ref={ref}>
      {children.map((item, index) => (
        <div key={index}
          className={`container-children${index === currentItemActive ? ' ' : ' item-no-active'}`}
        >
          <PSIcon className="container-icon icon-left" type={iconLeft} onClick={back} />
          {item}
          <PSIcon className="container-icon icon-right" type={iconRight} onClick={advance} />
        </div>
      ))}
    </StyledPsCarousel>
  );
});

PSCarousel.displayName = 'PSCarousel';

PSCarousel.defaultProps = {
  className: null,
  action: () => {},
  typeIcon: 'primary',
};

PSCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  action: PropTypes.func,
  typeIcon: PropTypes.string,
};

export default PSCarousel;
