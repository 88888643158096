import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPSParagraph = styled.div`
  text-align: center;
  letter-spacing: 0px;
  color: #242425;
  opacity: 1;
  font-size: 13px;
  line-height: 15px;
`;

const PSParagraph = React.forwardRef(({ children, className }, ref) => (
  <StyledPSParagraph className={className} ref={ref}>
    <label>{children}</label>
  </StyledPSParagraph>
));

PSParagraph.displayName = 'PSParagraph';

PSParagraph.defaultProps = {
  className: null,
};

PSParagraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PSParagraph;
