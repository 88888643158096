import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import PSButton from '../../PSButton';

import './index.scss';

const classNamesDefault = { overlay: 'customOverlaySuccess', modal: 'customModalError' };
const PSModalErrorResponse = React.forwardRef(
  (
    { className, classNames, title, message, buttonText, openModal, onClick, loading, icon },
    ref
  ) => {
    return (
      <div className={`modal-error-response ${className}`} ref={ref}>
        <PSModal open={openModal} showCloseIcon classNames={classNames || classNamesDefault}>
          <div className="container-modal-error">
            <PSIcon type={icon} className="modal-success-icon" />
            <div className="modal-success-message">
              <div className="modal-title">{title}</div>
              <div className="modal-message">{message}</div>
            </div>
            <div className="modal-success-button">
              <PSButton onClick={onClick} loading={loading}>
                {buttonText}
              </PSButton>
            </div>
          </div>
        </PSModal>
      </div>
    );
  }
);

PSModalErrorResponse.displayName = 'PSModalErrorResponse';

PSModalErrorResponse.defaultProps = {
  className: '',
  title: '¡Ups!',
  message: 'Estamos trabajando para reestablecer nuestros sistemas pronto.',
  buttonText: 'Aceptar',
  icon: 'broken',
  onClick: () => {},
  openModal: true,
  loading: false,
};

PSModalErrorResponse.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  openModal: PropTypes.bool,
  loading: PropTypes.bool,
  buttonText: PropTypes.string,
  icon: PropTypes.string,
};

export default PSModalErrorResponse;
