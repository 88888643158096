import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PSHeaderPageHome from './PSHeaderPageHome';
import PSFooterPageHome from './PSFooterPageHome';
import PSOptionsLanding from './PSOptionsLanding';
import { Routes } from '../../routes';
import { cleanAuth } from '../redux/auth';
import { GAConsumer } from './PSAnalytics';

const BackgroudContainer = styled.div`
  background: url('${process.env.PUBLIC_URL}/images/landing/home.png');
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 570px;
  display: flex;
  align-content: center;
  align-items: center !important;
  justify-content: center;

  h1 {
    font-size: 36px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #fff;
    display: none;
    width: 300px;
  }
  h2 {
    font-size: 30px;
    line-height: 25px;
    margin-top: 5px;
    font-size: 26px;
    margin-top: 15px;
    display: none;
    width: 350px;
  }

  @media (min-width: 768px) {
    justify-content: space-around;
    h1,
    h2 {
      display: block;
      text-align: left;
      color: #2b0d61;
    }
  }

  @media (max-width: 970px) {
    h2 {
      width: 290px;
    }
  }
`;

export default function PSLanding({ children }) {
  return (
    <div>
      <PSHeaderPageHome />
      <div>
        <BackgroudContainer className="bg-container">
          <div className="title">
            <h1 className="f-extraBold">¡Bienvenido a la Net Privada!</h1>
            <h2 className="f-extraBold">Continuamos protegiendo lo que más valoras.</h2>
          </div>

          <div>{children}</div>
        </BackgroudContainer>
      </div>
      <PSOptionsLanding />

      <PSFooterPageHome className="display-none-response" />
    </div>
  );
}

export const withLanding = (Component) => (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    dispatch(cleanAuth());
    history.push(Routes.LOGIN);
  };

  const closeOnOverlay = () => {
    if (
      props.location.pathname === Routes.SIGN_UP_CREATE_PASSWORD ||
      props.location.pathname === Routes.FORGOT_PASSWORD_VALIDATION ||
      props.location.pathname === Routes.FORGOT_PASSWORD_CREATE_PASSWORD ||      
      props.location.pathname === Routes.SIGN_UP_REGISTER_COMPANY ||
      props.location.pathname === Routes.SIGN_UP
    ) {
      return false;
    }
    return true;
  };

  return (
    <GAConsumer>
      {(ga) => (
        <Modal
          animationDuration={0}
          open={open}
          onClose={closeOnOverlay() ? onClose : () => {}}
          showCloseIcon={false}
          onOverlayClick={closeOnOverlay() ? onClose : () => {}}
          closeOnOverlayClick={false}
        >
          <Component {...props} closeModal={onClose} ga={ga} />
        </Modal>
      )}
    </GAConsumer>
  );
};
