import { formatClinics, groupBy2, orderArray } from '../../../util/Util';
import { Clinical } from '../model/Clinical';
import { doGet } from './Api';

export default class ClinicsServices {
  static async getClinics() {
    const response = await doGet(`${process.env.REACT_APP_GET_CLINIC}`);
    const dataResponse = response?.data?.data;
    if (dataResponse) {
      const list = dataResponse?.map((d) => new Clinical(d));
      const dataClinics = formatClinics(list);
      orderArray(dataClinics, 'value');
      dataClinics.map((dep) => orderArray(dep.districts, 'value'));
      return dataClinics;
    }
    return null;
  }
}
