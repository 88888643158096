import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PSBox from './PSBox';
import PSLabel from './PSLabel';
import PSTextInput from './PSTextInput';
import { formatCodeInput } from '../../util/Util';
import PSError from './PSError';

const TIME_LIMIT = 198 * 1000;
const CLOCK_MASK = '03:19';

const PSTimerValidation = React.forwardRef(
  (
    { className, value, onChange, startMinutes, clockMask, setDisabledProp, refresh, error },
    ref
  ) => {
    const [disabled, setDisabled] = useState(false);
    const [clock, setClock] = useState(clockMask);
    const [timer, setTimer] = useState();
    const startTime = new Date().getTime();

    const timerHandler = () => {
      let maxTime = startMinutes;
      const timerInterval = setInterval(() => {
        const min = Math.floor(maxTime / (60 * 1000));
        const sec = (maxTime % (60 * 1000)) / 1000;

        maxTime -= 1000;

        setClock(`${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`);

        if (new Date().getTime() - startTime > startMinutes + 1000) {
          setDisabled(true);
          setDisabledProp(true);
          clearInterval(timerInterval);
        }
      }, 1000);

      return timerInterval;
    };

    useEffect(() => {
      const timerFn = timerHandler();
      setTimer(timerFn);
      return () => {
        clearInterval(timerFn);
      };
    }, []);

    useEffect(() => {
      let timerFn = timer;

      if (refresh) {
        clearInterval(timer);
        timerFn = timerHandler();
        setDisabled(false);
        setTimer(timerFn);
      }
    }, [refresh]);
    return (
      <PSBox className={className} ref={ref}>
        <div>
          <PSTextInput
            placeholder="Por favor ingrese el código que le hemos enviado"
            disabled={disabled}
            value={value || ''}
            onChange={onChange}
            className={`align-center ${value || ' size-small'} ${error ? ' with-error' : ''} `}
            maxLength="7"
            onKeyPress={formatCodeInput}
          />
        </div>
        {disabled ? (
          <PSError>
            Se acabó el tiempo. Puedes solicitar un nuevo código dando clic en Reenviar
          </PSError>
        ) : (
          <PSLabel>Quedan: {clock} minutos.</PSLabel>
        )}
      </PSBox>
    );
  }
);

PSTimerValidation.displayName = 'PSTimerValidation';

PSTimerValidation.defaultProps = {
  className: null,
  startMinutes: TIME_LIMIT,
  onChange: () => {},
  setDisabledProp: () => {},
  clockMask: CLOCK_MASK,
  refresh: false,
  error: false,
};

PSTimerValidation.propTypes = {
  className: PropTypes.string,
  startMinutes: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  setDisabledProp: PropTypes.func,
  clockMask: PropTypes.string,
  refresh: PropTypes.bool,
  error: PropTypes.bool,
};

export default PSTimerValidation;
