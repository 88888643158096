import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSButton from '../../PSButton';
import PSModal from '../../PSModal';
import PSIcon from '../../PSIcon';

const PSModalPayment = ({
  title,
  description,
  cancelText,
  okText,
  openModal,
  onCancel,
  onOk,
  closeModal,
  loading,
  iconSuccess,
  iconError,
}) => (
  <div>
    <PSModal
      open={openModal}
      className="customModal__Payment"
     classNames={{ overlay: 'customOverlay__Payment', modal: 'customModal__Payment' }}
    >
      <section className="payment__header">
        <div className="payment__icon">
          {closeModal && <PSIcon className="payment__icon--cancel" type="closeModal" onClick={closeModal} />}
          {iconSuccess && <PSIcon className="payment__icon--type" type="checkPayment" />}
          {iconError && <PSIcon className="payment__icon--type" type="errorPayment" />}
        </div>
        
      </section>
      <section className="payment__body">
        <div className="payment__message">
          {title && <div className="payment__message__title">{title}</div>}
          <div className="payment__message__description">
            <p>{description}</p>
          </div>
        </div>
      </section>
      <section className="payment__footer">
        <div className="payment__button">
          {cancelText && (
            <PSButton className={'payment__button--white'} onClick={onCancel} loading={loading}>
              {cancelText}
            </PSButton>
          )}
          <PSButton className={'payment__button--orange'} onClick={onOk} loading={loading}>
            {okText}
          </PSButton>
        </div>
      </section>
    </PSModal>
  </div>
);

PSModalPayment.displayName = 'PSModalPayment';

PSModalPayment.defaultProps = {
  title: `Cupones pendientes`,
  description: 'Descripcion exitosa',
  okText: 'Generar código',
  onCancel: () => {},
  onOk: () => {},
  closeModal: null,
  loading: false,
};

PSModalPayment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PSModalPayment;
