import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {PSSatisfactionRate} from "../../molecules";

const PSSatisfactionSurvey = React.forwardRef(({ className,numValues, valueSelected, onSelectValue }, ref) => (
  <div className={`container-satisfaction-survey ${className}`} ref={ref}>
    <div className="satisfaction-title">
      <p className="satisfaction-title__first-phrase">¡Hola!</p><p className="satisfaction-title__second-phrase">Según tu experiencia,</p>
      <p>¿Qué tanto recomendarías Net Privada</p>
      <p>como plataforma de autoatención?</p>
    </div>
    <PSSatisfactionRate onSelectValue={onSelectValue} numValues={numValues} valueSelected={valueSelected}/>
  </div>
));

PSSatisfactionSurvey.displayName = 'PSSatisfactionSurvey';

PSSatisfactionSurvey.defaultProps = {
  className: '',
  numValues: 11,
  valueSelected:5,
  onSelectValue: ()=>{}
};

PSSatisfactionSurvey.propTypes = {
  className: PropTypes.string,
};

export default PSSatisfactionSurvey;
