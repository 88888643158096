export const USER_FORGOT_PASSWORD = 'Auth/USER_FORGOT_PASSWORD';

export const setUserForgotPassword = (user) => ({
  type: USER_FORGOT_PASSWORD,
  payload: user,
});

export const reducer = (state, action) => ({
  ...state,
  forgotPassword: { ...state.forgotPassword, ...action.payload },
});
