import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import DownloadFormatService from '../../shared/services/DownloadFormatService';

import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSLabel from '../../components/PSLabel';
import PSGrayDownloadFormat from '../../components/PSGrayDownloadFormat';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const img = 'download-format';
const imgMobile = 'download-format-mobile';
const formatos = [
  {
    type: 'Renta Total',
    listFormat: [
      {
        title: 'Elección o cambio de forma de pago',
        link: 'UkVOVEFfVE9UQUwjQ0FNQklPX0ZPUk1BX1BBR08=',
        id: '11',
      },
      { title: 'Solicitud de endoso', link: 'UkVOVEFfVE9UQUwjU09MSUNJVFVEX0VORE9TTw==', id: '12' },
    ],
  },
  {
    type: 'Rentas Vitalicias',
    listFormat: [
      {
        title: 'Elección o cambio de forma de pago',
        link: 'UlJfVlYjQ0FNQklPX0ZPUk1BX1BBR08=',
        id: '21',
      },
      {
        title: 'Solicitud de pensión de sobrevivencia',
        link: 'UlJfVlYjU09MSUNJVFVEX1BFTlNJT05fU09CUkVWSVZFTkNJQQ==',
        id: '22',
      },
    ],
  },
  {
    type: 'Vida Ley',
    listFormat: [
      {
        title: 'Declaración de beneficiarios',
        link: 'VklEQV9MRVkjREVDTEFSQUNJT05fQkVORUZJQ0lBUklPUw==',
        id: '31',
      },
      {
        title: 'Solicitud de reembolso por gastos de sepelio',
        link: 'VklEQV9MRVkjU09MSUNJVFVEX1JFRU1CT0xTT19HQVNUT1NfU0VQRUxJTw==',
        id: '32',
      },
    ],
  },
  {
    type: 'SCTR',
    listFormat: [
      {
        title: 'Solicitud de pensión de sobrevivencia',
        link: 'U0NUUiNTT0xJQ0lUVURfUEVOU0lPTl9TT0JSRVZJVkVOQ0lB',
        id: '41',
      },
      {
        title: 'Solicitud de pensión de invalidez',
        link: 'U0NUUiNTT0xJQ0lUVURfUEVOU0lPTl9JTlZBTElERVo=',
        id: '42',
      },
      {
        title: 'Solicitud de reembolso por gastos de sepelio',
        link: 'VklEQV9MRVkjU09MSUNJVFVEX1JFRU1CT0xTT19HQVNUT1NfU0VQRUxJTw==',
        id: '43',
      },
    ],
  },
];

export default function HomeServices(props) {
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;

  const responseFiles = async (link) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Descarga de Formatos`,
      action: 'download',
      label: 'El usuario descargó un formato',
      value: 1,
    });
    await DownloadFormatService.responseFiles(link);
  };

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Descarga de Formatos`,
      action: 'click',
      label: 'El usuario visitó la página de Descarga de Formatos',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="download-format-container">
      <div className="header">
        <PSHeaderIntranet
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description="Descarga de formatos"
          icon="downloadFormat"
          {...props}
        />
      </div>
      <div className="body-formats">
        {formatos.map((formato) => (
          <div key={formato.type}>
            <div className="section-titulo mt-2">
              <PSLabel className="titulo">{formato.type}</PSLabel>
            </div>
            <div className="columns">
              {formato.listFormat.map((list) => (
                <div className="column m-1 " key={list.id}>
                  <PSGrayDownloadFormat
                    title={list.title}
                    onClick={() => responseFiles(list.link)}
                    className="box-container-home"
                  />
                </div>
              ))}
              {formato.listFormat.length < 3 ? <div className="column m-1" /> : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
