import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSModal from '../../PSModal';
import {PSSatisfactionSurvey} from "../index";
import PSIcon from "../../PSIcon";


const PSModalSatisfactionSurvey = React.forwardRef(({ className, openModal, onClose,numValues, valueSelected,onSelectValue, ...props }, ref) =>{

 const classNames= {
    overlay: 'customOverlay',
    modal: 'customModalSurvey',
  };

  return (
    <div ref={ref}>
      <PSModal open={openModal} showCloseIcon={false} onClose={onClose}  classNames= {classNames} {...props}>
        <div className={`container-modal-satisfaction-survey ${className}`}>
          <PSIcon
            type="closeModalSurvey"
            className="satisfaction-survey-icon"
            onClick={onClose}
          />
          <PSSatisfactionSurvey
            numValues={numValues}
            valueSelected={valueSelected}
            onSelectValue={onSelectValue}
          />
        </div>
      </PSModal>
    </div>
  )});

PSModalSatisfactionSurvey.displayName = 'PSModalSatisfactionSurvey';

PSModalSatisfactionSurvey.defaultProps = {
  className: '',
  numValues: 11,
  valueSelected:5,
  onSelectValue: ()=>{},
};

PSModalSatisfactionSurvey.propTypes = {
  className: PropTypes.string,
  numValues: PropTypes.number,
  valueSelected:  PropTypes.number,
  onSelectValue: PropTypes.func,
};

export default PSModalSatisfactionSurvey;
