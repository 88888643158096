export class RentaT {
  constructor(props) {
    this.id = `${props.nroPolicy}-${props.nroCertificate}`;
    this.idPolicy = props.idPolicy;
    this.producto = props.descriptionValue;
    this.nroPolicy = props.nroPolicy;
    this.nroCertificate = props.nroCertificate;
    this.rol = props.rol.descriptionRol;
    this.dateStart = props.fechaInicioVigencia;
    this.dateEnd = props.fechaFinVigencia;
    this.dateVigencia = `${props.fechaInicioVigencia} ${props.fechaFinVigencia && 'al'} ${
      props.fechaFinVigencia
    }`;
    this.status = props.status;
    this.idProduct = props.idProduct;
    this.ramo = props.ramo;
    this.nbranch = props.ramo.idRamo;
    this.endoso = props.endoso;
    this.productoCode = props.producto;
  }
}
