import * as axios from 'axios/index';
import FileSaver from 'file-saver';
import { STORAGE_TOKEN_COGNITO } from '../../../util/Constant';
import { doGetWithParams } from './Api';

export default class DownloadPDFClaim {
  static async responseFiles(path, token, documentName) {
    const idTokenCognito = token || localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
    try {
      const { status, data } = await axios({
        url: `${process.env.REACT_APP_GET_CLAIM_PDF}${path}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${idTokenCognito}`,
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      });
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, documentName);
      }
      return status;
    } catch (error) {
      return 400;
    }
  }
}
