import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { GAProvider } from '../../PSAnalytics';
import DevolutionLyfe from '../../../shared/services/DevolutionLyfe';
import {
  pathServer,
  STORAGE_POLICY,
  STORAGE_TOKEN_IDPROCESS,
  STORAGE_TOKEN_INITIAL,
  STORAGE_TOKEN_SESSION,
} from '../../../../util/Constant';
import { Routes } from '../../../../routes';
import {
  createPaymentError,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
} from '../../../redux/auth/actions/payment-validation';
import { menuCode } from '../../../shared/constants/Strings';

import PSIcon from '../../PSIcon';

import './index.scss';

const PSLoadingPayment = React.forwardRef(({ ...props }, ref) => {
  const ga = React.useContext(GAProvider);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { transactionalToken } = useParams();
  const history = useHistory();
  const { userInfo, stateProduct = {} } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const { menuDescription = '' } = stateProduct;

  const messageAnalytics = (labelGA) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${menuDescription}-Modificación de Tarjetas para cobro recurrente`,
      action: 'click',
      label: labelGA,
      value: 1,
    });
  };
  
  const loadFinalize = async (payload) => {
    try {
      const { name } = await DevolutionLyfe.postFinalize(payload);
      if (name === 'SUCCESS OPERATION') {
        // se usara un modal de check dependiendo del client
        dispatch(createPaymentError(STATUS_PAYMENT_SUCCESS));
        messageAnalytics('El usuario pudo actualizar su tarjeta con éxito');
      } else {
        dispatch(createPaymentError(STATUS_PAYMENT_FAILED));
        messageAnalytics('El usuario no pudo procesar su actualización de tarjeta');
      }
    } catch (e) {
      dispatch(createPaymentError(STATUS_PAYMENT_FAILED));
      console.log('error', e);
    }
    localStorage.removeItem(STORAGE_TOKEN_SESSION);
    localStorage.removeItem(STORAGE_TOKEN_INITIAL);
    localStorage.removeItem(STORAGE_POLICY);
    localStorage.removeItem(STORAGE_TOKEN_IDPROCESS);
    history.push({
      pathname:
        stateProduct?.menuId === menuCode.VIDA_DEVOLUCION
          ? Routes.HOME_PRODUCT_LIFE_DEVOLUTION
          : Routes.HOME_PRODUCT_LIFE_CREDIT,
      state: { ...stateProduct },
    });
  };

  useEffect(() => {
    const sessionToken = localStorage.getItem(STORAGE_TOKEN_SESSION) || null;
    const initialToken = localStorage.getItem(STORAGE_TOKEN_INITIAL) || null;
    const policyId = localStorage.getItem(STORAGE_POLICY) || null;
    const idProcess = localStorage.getItem(STORAGE_TOKEN_IDPROCESS) || null;
    const payload = {
      transactionalToken,
      sessionToken,
      initialToken,
      policyId,
      idProcess,
    };
    if (transactionalToken && sessionToken && initialToken && policyId && idProcess) {
      setIsValid(true);
      loadFinalize(payload);
    }
  }, []);

  return (
    <div className="loading-payment">
      {isValid ? (
        <div>
          <div className="container-img">
            <img
              src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`}
              alt="Protecta Security"
              width={275}
            />
          </div>
          <div className="loading">
            <div className="loader-progress">
              <div className="loaderBar" />
            </div>
          </div>
          <div className="footer">
            <p>Espere mientras validamos su información</p>
          </div>
        </div>
      ) : (
        <div className="error-page">
          <PSIcon type="broken" className="modal-success-icon" />
          <h1 className="align-center">404 Error</h1>
        </div>
      )}
    </div>
  );
});

PSLoadingPayment.displayName = 'PSLoadingPayment';

PSLoadingPayment.defaultProps = {};

PSLoadingPayment.propTypes = {};

export default PSLoadingPayment;
