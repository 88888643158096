
export const SET_ERROR_PAGE = 'Auth/SET_ERROR_PAGE';

export const defaultSetError = {
  title:"¡Ups!",
  message:"Estamos trabajando para reestablecer nuestros sistemas pronto.",
  buttonText:"Aceptar",
  onAction: null,
  state:true,
}


export const setErrorPage= (error) => ({
  type: SET_ERROR_PAGE,
  payload: error,
});

export const createErrorPage = (error) => {
  return async (dispatch) => {
    const errorValue = error? error: defaultSetError
    dispatch(setErrorPage(errorValue))
  };
};

export const reducer = (state, action) => ({
  ...state,
  errorPage: {...state.errorPage,  ...action.payload},
});
