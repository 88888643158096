import React from 'react';

const StarSVG = () => {
    return (
        <svg width="91%" height="89%" viewBox="0 0 351 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7065 15.5586C13.6153 22.888 7.52694 28.8147 0 28.8838C7.52694 28.953 13.6153 34.8698 13.7065 42.2091C13.7977 34.8797 19.8861 28.953 27.413 28.8838C19.8861 28.8147 13.7977 22.8978 13.7065 15.5586Z" fill="#6B59C1" />
            <path d="M60 0H328.345C340.846 0 351 9.97122 351 22.2473V55H82.655C70.154 55 60 45.0288 60 32.7527V0Z" fill="#6B59C1" />
            <path d="M329.427 27.4586V35.166C329.451 35.6617 329.582 36.0336 329.82 36.2815C330.082 36.5069 330.476 36.6533 331 36.721L330.893 39.5267C328.843 39.5267 327.258 39.1098 326.138 38.2759C324.231 39.1098 322.312 39.5267 320.381 39.5267C316.83 39.5267 315.054 37.7351 315.054 34.1518C315.054 32.4391 315.53 31.1996 316.484 30.4333C317.461 29.6671 318.951 29.2051 320.953 29.0474L325.565 28.6755V27.4586C325.565 26.5571 325.351 25.9261 324.922 25.5655C324.517 25.205 323.909 25.0247 323.098 25.0247C321.573 25.0247 319.666 25.1148 317.378 25.2951L316.234 25.3627L316.091 22.7598C318.689 22.1738 321.072 21.8809 323.241 21.8809C325.434 21.8809 327.008 22.3316 327.961 23.233C328.938 24.1119 329.427 25.5205 329.427 27.4586ZM321.418 31.6503C319.773 31.7855 318.951 32.6306 318.951 34.1856C318.951 35.7406 319.678 36.5181 321.132 36.5181C322.324 36.5181 323.587 36.3378 324.922 35.9773L325.565 35.7744V31.2785L321.418 31.6503Z" fill="#FEFEFE" />
            <path d="M290.136 39.1549H286.239V22.2527H290.101V23.3006C291.793 22.3541 293.342 21.8809 294.749 21.8809C296.823 21.8809 298.336 22.433 299.29 23.5373C301.459 22.433 303.616 21.8809 305.761 21.8809C307.906 21.8809 309.42 22.5119 310.302 23.7739C311.184 25.0134 311.625 27.1205 311.625 30.0953V39.1549H307.763V30.1967C307.763 28.3713 307.561 27.0755 307.155 26.3092C306.774 25.543 305.964 25.1599 304.724 25.1599C303.652 25.1599 302.496 25.3852 301.256 25.836L300.648 26.0726C300.839 26.5233 300.934 27.9656 300.934 30.3995V39.1549H297.073V30.4672C297.073 28.4614 296.882 27.0755 296.501 26.3092C296.119 25.543 295.285 25.1599 293.998 25.1599C292.806 25.1599 291.698 25.3852 290.673 25.836L290.136 26.0388V39.1549Z" fill="#FEFEFE" />
            <path d="M281.347 27.4586V35.166C281.371 35.6617 281.502 36.0336 281.74 36.2815C282.002 36.5069 282.396 36.6533 282.92 36.721L282.813 39.5267C280.763 39.5267 279.178 39.1098 278.057 38.2759C276.151 39.1098 274.232 39.5267 272.301 39.5267C268.749 39.5267 266.974 37.7351 266.974 34.1518C266.974 32.4391 267.45 31.1996 268.404 30.4333C269.381 29.6671 270.871 29.2051 272.873 29.0474L277.485 28.6755V27.4586C277.485 26.5571 277.271 25.9261 276.842 25.5655C276.437 25.205 275.829 25.0247 275.018 25.0247C273.493 25.0247 271.586 25.1148 269.298 25.2951L268.154 25.3627L268.011 22.7598C270.609 22.1738 272.992 21.8809 275.161 21.8809C277.354 21.8809 278.927 22.3316 279.881 23.233C280.858 24.1119 281.347 25.5205 281.347 27.4586ZM273.338 31.6503C271.693 31.7855 270.871 32.6306 270.871 34.1856C270.871 35.7406 271.598 36.5181 273.052 36.5181C274.244 36.5181 275.507 36.3378 276.842 35.9773L277.485 35.7744V31.2785L273.338 31.6503Z" fill="#FEFEFE" />
            <path d="M255.198 39.1549V22.2527H259.06V24.281C261.086 23.0415 263.112 22.2414 265.138 21.8809V25.5655C263.088 25.9486 261.336 26.4444 259.882 27.0529L259.095 27.3572V39.1549H255.198Z" fill="#FEFEFE" />
            <path d="M252.038 41.4569C252.038 45.1528 249.214 47.0008 243.565 47.0008C240.847 47.0008 238.833 46.6515 237.522 45.9528C236.211 45.2542 235.556 43.9809 235.556 42.1329C235.556 41.2991 235.77 40.5779 236.199 39.9695C236.628 39.3835 237.32 38.7412 238.273 38.0426C237.486 37.5468 237.093 36.7243 237.093 35.5749C237.093 35.1242 237.415 34.3918 238.058 33.3776L238.38 32.8706C236.664 31.9015 235.806 30.2113 235.806 27.7999C235.806 25.7717 236.45 24.2843 237.737 23.3378C239.024 22.3912 240.764 21.918 242.957 21.918C244.006 21.918 245.042 22.0307 246.067 22.256L246.604 22.3574L252.146 22.2222V25.197L249.178 25.028C249.822 25.8167 250.143 26.7407 250.143 27.7999C250.143 30.0085 249.547 31.5409 248.356 32.3973C247.188 33.2311 245.352 33.6481 242.85 33.6481C242.23 33.6481 241.705 33.603 241.276 33.5128C240.943 34.2791 240.776 34.8763 240.776 35.3045C240.776 35.7101 240.99 35.9918 241.419 36.1496C241.872 36.3073 242.945 36.3975 244.637 36.42C247.474 36.4426 249.416 36.8031 250.465 37.5018C251.514 38.2004 252.038 39.5187 252.038 41.4569ZM239.381 41.7949C239.381 42.5837 239.715 43.1471 240.382 43.4851C241.05 43.8232 242.194 43.9922 243.815 43.9922C246.699 43.9922 248.141 43.2147 248.141 41.6597C248.141 40.7808 247.879 40.2174 247.355 39.9695C246.854 39.7441 245.841 39.6202 244.315 39.5976L240.812 39.3948C240.287 39.8004 239.918 40.1723 239.703 40.5103C239.489 40.8709 239.381 41.2991 239.381 41.7949ZM239.667 27.7999C239.667 28.8366 239.93 29.6028 240.454 30.0986C240.978 30.5719 241.825 30.8085 242.993 30.8085C244.161 30.8085 244.995 30.5719 245.495 30.0986C246.02 29.6028 246.282 28.8366 246.282 27.7999C246.282 26.7633 246.02 26.0083 245.495 25.535C244.971 25.0392 244.125 24.7913 242.957 24.7913C240.764 24.7913 239.667 25.7942 239.667 27.7999Z" fill="#FEFEFE" />
            <path d="M218.419 24.0781C219.659 22.6133 221.709 21.8809 224.569 21.8809C227.429 21.8809 229.467 22.6133 230.683 24.0781C231.922 25.543 232.542 27.7403 232.542 30.67C232.542 33.5997 231.946 35.8082 230.754 37.2956C229.563 38.783 227.501 39.5267 224.569 39.5267C221.637 39.5267 219.575 38.783 218.383 37.2956C217.192 35.8082 216.596 33.5997 216.596 30.67C216.596 27.7403 217.204 25.543 218.419 24.0781ZM221.351 35.0645C221.899 35.966 222.972 36.4167 224.569 36.4167C226.166 36.4167 227.239 35.966 227.787 35.0645C228.335 34.1631 228.609 32.687 228.609 30.6362C228.609 28.5854 228.311 27.1318 227.715 26.2754C227.143 25.419 226.094 24.9909 224.569 24.9909C223.043 24.9909 221.983 25.419 221.387 26.2754C220.815 27.1318 220.529 28.5854 220.529 30.6362C220.529 32.687 220.803 34.1631 221.351 35.0645Z" fill="#FEFEFE" />
            <path d="M204.675 39.1549V22.2527H208.536V24.281C210.562 23.0415 212.588 22.2414 214.614 21.8809V25.5655C212.565 25.9486 210.813 26.4444 209.359 27.0529L208.572 27.3572V39.1549H204.675Z" fill="#FEFEFE" />
            <path d="M185.471 46.4228V22.2527H189.332V23.3006C190.977 22.3541 192.514 21.8809 193.944 21.8809C196.304 21.8809 198.032 22.5682 199.129 23.9429C200.225 25.2951 200.774 27.5825 200.774 30.8052C200.774 34.0053 200.142 36.259 198.879 37.5661C197.639 38.8732 195.601 39.5267 192.765 39.5267C191.787 39.5267 190.655 39.4253 189.368 39.2225V46.4228H185.471ZM193.265 25.1599C192.145 25.1599 191.025 25.3965 189.904 25.8698L189.368 26.1064V36.0787C190.321 36.259 191.37 36.3491 192.514 36.3491C194.135 36.3491 195.255 35.9096 195.875 35.0307C196.495 34.1518 196.805 32.6532 196.805 30.5348C196.805 26.9515 195.625 25.1599 193.265 25.1599Z" fill="#FEFEFE" />
            <path d="M159.62 24.0781C160.86 22.6133 162.91 21.8809 165.77 21.8809C168.63 21.8809 170.668 22.6133 171.884 24.0781C173.124 25.543 173.743 27.7403 173.743 30.67C173.743 33.5997 173.147 35.8082 171.956 37.2956C170.764 38.783 168.702 39.5267 165.77 39.5267C162.838 39.5267 160.776 38.783 159.585 37.2956C158.393 35.8082 157.797 33.5997 157.797 30.67C157.797 27.7403 158.405 25.543 159.62 24.0781ZM162.552 35.0645C163.1 35.966 164.173 36.4167 165.77 36.4167C167.367 36.4167 168.44 35.966 168.988 35.0645C169.536 34.1631 169.81 32.687 169.81 30.6362C169.81 28.5854 169.512 27.1318 168.916 26.2754C168.344 25.419 167.296 24.9909 165.77 24.9909C164.245 24.9909 163.184 25.419 162.588 26.2754C162.016 27.1318 161.73 28.5854 161.73 30.6362C161.73 32.687 162.004 34.1631 162.552 35.0645Z" fill="#FEFEFE" />
            <path d="M139.691 22.252H143.767L147.2 36.0103H148.344L151.919 22.252H155.888L151.24 39.1541H144.304L139.691 22.252Z" fill="#FEFEFE" />
            <path d="M136.189 36.1125L137.19 36.0111L137.262 38.7492C134.544 39.2675 132.137 39.5267 130.039 39.5267C127.394 39.5267 125.487 38.8394 124.319 37.4646C123.175 36.0899 122.603 33.8927 122.603 30.8728C122.603 24.8782 125.189 21.8809 130.361 21.8809C135.367 21.8809 137.87 24.4613 137.87 29.622L137.619 32.2588H126.536C126.559 33.656 126.881 34.6814 127.501 35.335C128.121 35.9885 129.277 36.3153 130.969 36.3153C132.661 36.3153 134.401 36.2477 136.189 36.1125ZM134.044 29.4192C134.044 27.7515 133.758 26.5909 133.186 25.9374C132.638 25.2613 131.696 24.9232 130.361 24.9232C129.026 24.9232 128.049 25.2726 127.429 25.9712C126.833 26.6698 126.524 27.8192 126.5 29.4192H134.044Z" fill="#FEFEFE" />
            <path d="M114.881 22.252H118.743V39.1541H114.881V38.1062C113.141 39.0527 111.532 39.526 110.055 39.526C107.599 39.526 105.955 38.9062 105.121 37.6667C104.286 36.4047 103.869 34.2074 103.869 31.0749V22.252H107.766V31.1087C107.766 33.1369 107.945 34.5004 108.303 35.199C108.66 35.8976 109.494 36.2469 110.805 36.2469C112.093 36.2469 113.273 36.0216 114.345 35.5709L114.881 35.368V22.252Z" fill="#FEFEFE" />
            <path d="M80 39.156V16H86.9363L94.3732 35.8431H94.9095V16H98.8782V39.156H92.0849L84.4693 19.279H83.9687V39.156H80Z" fill="#FEFEFE" />
            <g clip-path="url(#clip0_0_1)">
                <path d="M307.163 125.967V108.467H309.788V125.967H307.163ZM307.163 104.512V101.467H309.788V104.512H307.163Z" fill="#FEFEFE" />
                <path d="M303.958 110.741H298.393V119.106C298.393 121.113 298.533 122.431 298.813 123.061C299.117 123.691 299.817 124.006 300.913 124.006L304.028 123.796L304.203 125.966C302.64 126.223 301.45 126.351 300.633 126.351C298.813 126.351 297.553 125.908 296.853 125.021C296.153 124.135 295.803 122.443 295.803 119.946V110.741H293.318V108.466H295.803V103.111H298.393V108.466H303.958V110.741Z" fill="#FEFEFE" />
                <path d="M282.024 113.75V122.71C282.094 123.574 282.771 124.087 284.054 124.25L283.949 126.315C282.106 126.315 280.717 125.849 279.784 124.915C277.684 125.849 275.584 126.315 273.484 126.315C271.874 126.315 270.649 125.86 269.809 124.95C268.969 124.04 268.549 122.734 268.549 121.03C268.549 119.327 268.981 118.079 269.844 117.285C270.707 116.469 272.061 115.967 273.904 115.78L279.399 115.255V113.75C279.399 112.56 279.142 111.709 278.629 111.195C278.115 110.682 277.416 110.425 276.529 110.425C274.662 110.425 272.726 110.542 270.719 110.775L269.634 110.88L269.529 108.885C272.096 108.372 274.371 108.115 276.354 108.115C278.337 108.115 279.772 108.57 280.659 109.48C281.569 110.39 282.024 111.814 282.024 113.75ZM271.244 120.89C271.244 123.037 272.131 124.11 273.904 124.11C275.491 124.11 277.054 123.842 278.594 123.305L279.399 123.025V117.25L274.219 117.74C273.169 117.834 272.41 118.137 271.944 118.65C271.477 119.164 271.244 119.91 271.244 120.89Z" fill="#FEFEFE" />
                <path d="M257.568 125.965V108.465H260.158V110.845C262.188 109.445 264.323 108.523 266.563 108.08V110.74C265.583 110.927 264.557 111.218 263.483 111.615C262.433 112.012 261.628 112.362 261.068 112.665L260.193 113.12V125.965H257.568Z" fill="#FEFEFE" />
                <path d="M252.39 113.75V122.71C252.46 123.574 253.137 124.087 254.42 124.25L254.315 126.315C252.472 126.315 251.083 125.849 250.15 124.915C248.05 125.849 245.95 126.315 243.85 126.315C242.24 126.315 241.015 125.86 240.175 124.95C239.335 124.04 238.915 122.734 238.915 121.03C238.915 119.327 239.347 118.079 240.21 117.285C241.073 116.469 242.427 115.967 244.27 115.78L249.765 115.255V113.75C249.765 112.56 249.508 111.709 248.995 111.195C248.482 110.682 247.782 110.425 246.895 110.425C245.028 110.425 243.092 110.542 241.085 110.775L240 110.88L239.895 108.885C242.462 108.372 244.737 108.115 246.72 108.115C248.703 108.115 250.138 108.57 251.025 109.48C251.935 110.39 252.39 111.814 252.39 113.75ZM241.61 120.89C241.61 123.037 242.497 124.11 244.27 124.11C245.857 124.11 247.42 123.842 248.96 123.305L249.765 123.025V117.25L244.585 117.74C243.535 117.834 242.777 118.137 242.31 118.65C241.843 119.164 241.61 119.91 241.61 120.89Z" fill="#FEFEFE" />
                <path d="M221.577 133.735V108.465H224.167V109.725C225.94 108.652 227.69 108.115 229.417 108.115C231.634 108.115 233.244 108.827 234.247 110.25C235.25 111.65 235.752 113.972 235.752 117.215C235.752 120.459 235.157 122.792 233.967 124.215C232.8 125.615 230.852 126.315 228.122 126.315C226.699 126.315 225.392 126.187 224.202 125.93V133.735H221.577ZM229.032 110.46C228.332 110.46 227.574 110.577 226.757 110.81C225.964 111.044 225.334 111.277 224.867 111.51L224.202 111.86V123.655C225.859 123.912 227.119 124.04 227.982 124.04C229.872 124.04 231.19 123.504 231.937 122.43C232.707 121.357 233.092 119.607 233.092 117.18C233.092 114.754 232.742 113.027 232.042 112C231.365 110.974 230.362 110.46 229.032 110.46Z" fill="#FEFEFE" />
                <path d="M203.113 110.46C200.57 110.46 199.298 111.347 199.298 113.12C199.298 113.937 199.59 114.52 200.173 114.87C200.756 115.197 202.086 115.547 204.163 115.92C206.24 116.27 207.71 116.772 208.573 117.425C209.436 118.079 209.868 119.304 209.868 121.1C209.868 122.897 209.285 124.215 208.118 125.055C206.975 125.895 205.295 126.315 203.078 126.315C201.631 126.315 199.928 126.152 197.968 125.825L196.918 125.65L197.058 123.375C199.718 123.772 201.725 123.97 203.078 123.97C204.431 123.97 205.458 123.76 206.158 123.34C206.881 122.897 207.243 122.174 207.243 121.17C207.243 120.144 206.94 119.455 206.333 119.105C205.726 118.732 204.396 118.382 202.343 118.055C200.29 117.729 198.831 117.25 197.968 116.62C197.105 115.99 196.673 114.824 196.673 113.12C196.673 111.417 197.268 110.157 198.458 109.34C199.671 108.524 201.176 108.115 202.973 108.115C204.396 108.115 206.181 108.279 208.328 108.605L209.308 108.78L209.238 111.02C206.648 110.647 204.606 110.46 203.113 110.46Z" fill="#FEFEFE" />
                <path d="M178.356 117.18C178.356 113.984 178.928 111.674 180.071 110.25C181.215 108.827 183.14 108.115 185.846 108.115C188.576 108.115 190.501 108.827 191.621 110.25C192.765 111.674 193.336 113.984 193.336 117.18C193.336 120.377 192.8 122.699 191.726 124.145C190.653 125.592 188.693 126.315 185.846 126.315C183 126.315 181.04 125.592 179.966 124.145C178.893 122.699 178.356 120.377 178.356 117.18ZM181.051 117.145C181.051 119.689 181.355 121.474 181.961 122.5C182.591 123.527 183.886 124.04 185.846 124.04C187.83 124.04 189.125 123.539 189.731 122.535C190.338 121.509 190.641 119.712 190.641 117.145C190.641 114.579 190.303 112.817 189.626 111.86C188.95 110.88 187.69 110.39 185.846 110.39C184.026 110.39 182.766 110.88 182.066 111.86C181.39 112.817 181.051 114.579 181.051 117.145Z" fill="#FEFEFE" />
                <path d="M160.938 108.467H163.738L167.833 123.692H169.128L173.363 108.467H176.058L171.123 125.967H165.873L160.938 108.467Z" fill="#FEFEFE" />
                <path d="M154.927 125.967V108.467H157.552V125.967H154.927ZM154.927 104.512V101.467H157.552V104.512H154.927Z" fill="#FEFEFE" />
                <path d="M144.153 110.46C141.61 110.46 140.338 111.347 140.338 113.12C140.338 113.937 140.63 114.52 141.213 114.87C141.796 115.197 143.126 115.547 145.203 115.92C147.28 116.27 148.75 116.772 149.613 117.425C150.476 118.079 150.908 119.304 150.908 121.1C150.908 122.897 150.325 124.215 149.158 125.055C148.015 125.895 146.335 126.315 144.118 126.315C142.671 126.315 140.968 126.152 139.008 125.825L137.958 125.65L138.098 123.375C140.758 123.772 142.765 123.97 144.118 123.97C145.471 123.97 146.498 123.76 147.198 123.34C147.921 122.897 148.283 122.174 148.283 121.17C148.283 120.144 147.98 119.455 147.373 119.105C146.766 118.732 145.436 118.382 143.383 118.055C141.33 117.729 139.871 117.25 139.008 116.62C138.145 115.99 137.713 114.824 137.713 113.12C137.713 111.417 138.308 110.157 139.498 109.34C140.711 108.524 142.216 108.115 144.013 108.115C145.436 108.115 147.221 108.279 149.368 108.605L150.348 108.78L150.278 111.02C147.688 110.647 145.646 110.46 144.153 110.46Z" fill="#FEFEFE" />
                <path d="M130.984 108.467H133.609V125.967H130.984V124.742C129.234 125.792 127.507 126.317 125.804 126.317C123.424 126.317 121.837 125.698 121.044 124.462C120.274 123.225 119.889 120.938 119.889 117.602V108.467H122.514V117.567C122.514 120.11 122.735 121.813 123.179 122.677C123.622 123.54 124.625 123.972 126.189 123.972C126.959 123.972 127.74 123.867 128.534 123.657C129.327 123.423 129.934 123.202 130.354 122.992L130.984 122.677V108.467Z" fill="#FEFEFE" />
                <path d="M112.174 125.966V100.836H114.799V125.966H112.174Z" fill="#FEFEFE" />
                <path d="M103.264 108.115C104.034 108.115 105.341 108.255 107.184 108.535L108.024 108.64L107.919 110.775C106.053 110.565 104.676 110.46 103.789 110.46C101.806 110.46 100.453 110.939 99.7294 111.895C99.0294 112.829 98.6794 114.579 98.6794 117.145C98.6794 119.689 99.006 121.462 99.6594 122.465C100.336 123.469 101.724 123.97 103.824 123.97L107.954 123.655L108.059 125.825C105.889 126.152 104.268 126.315 103.194 126.315C100.464 126.315 98.5744 125.615 97.5244 124.215C96.4977 122.815 95.9844 120.459 95.9844 117.145C95.9844 113.809 96.5444 111.475 97.6644 110.145C98.7844 108.792 100.651 108.115 103.264 108.115Z" fill="#FEFEFE" />
                <path d="M79.5537 108.467H82.4237L86.6937 115.327L90.9637 108.467H93.8337L88.2687 117.147L93.7987 125.967H90.9287L86.6937 119.142L82.4237 125.967H79.5537L85.0487 117.217L79.5537 108.467Z" fill="#FEFEFE" />
                <path d="M75.67 123.795L76.685 123.69L76.755 125.755C74.095 126.129 71.82 126.315 69.93 126.315C67.41 126.315 65.625 125.592 64.575 124.145C63.525 122.675 63 120.4 63 117.32C63 111.184 65.4383 108.115 70.315 108.115C72.6717 108.115 74.4333 108.78 75.6 110.11C76.7667 111.417 77.35 113.482 77.35 116.305L77.21 118.3H65.66C65.66 120.237 66.01 121.672 66.71 122.605C67.41 123.539 68.6233 124.005 70.35 124.005C72.1 124.005 73.8733 123.935 75.67 123.795ZM74.725 116.2C74.725 114.054 74.375 112.537 73.675 111.65C72.9983 110.764 71.8783 110.32 70.315 110.32C68.775 110.32 67.6083 110.787 66.815 111.72C66.045 112.654 65.6483 114.147 65.625 116.2H74.725Z" fill="#FEFEFE" />
                <path d="M247.35 83.9759C244.807 83.9759 243.535 84.8625 243.535 86.6359C243.535 87.4525 243.827 88.0359 244.41 88.3859C244.993 88.7125 246.323 89.0625 248.4 89.4359C250.477 89.7859 251.947 90.2875 252.81 90.9409C253.673 91.5942 254.105 92.8192 254.105 94.6159C254.105 96.4125 253.522 97.7309 252.355 98.5709C251.212 99.4109 249.532 99.8309 247.315 99.8309C245.868 99.8309 244.165 99.6675 242.205 99.3409L241.155 99.1659L241.295 96.8909C243.955 97.2875 245.962 97.4859 247.315 97.4859C248.668 97.4859 249.695 97.2759 250.395 96.8559C251.118 96.4125 251.48 95.6892 251.48 94.6859C251.48 93.6592 251.177 92.9709 250.57 92.6209C249.963 92.2475 248.633 91.8975 246.58 91.5709C244.527 91.2442 243.068 90.7659 242.205 90.1359C241.342 89.5059 240.91 88.3392 240.91 86.6359C240.91 84.9325 241.505 83.6725 242.695 82.8559C243.908 82.0392 245.413 81.6309 247.21 81.6309C248.633 81.6309 250.418 81.7942 252.565 82.1209L253.545 82.2959L253.475 84.5359C250.885 84.1625 248.843 83.9759 247.35 83.9759Z" fill="#FEFEFE" />
                <path d="M222.594 90.6959C222.594 87.4992 223.165 85.1892 224.309 83.7659C225.452 82.3425 227.377 81.6309 230.084 81.6309C232.814 81.6309 234.739 82.3425 235.859 83.7659C237.002 85.1892 237.574 87.4992 237.574 90.6959C237.574 93.8925 237.037 96.2142 235.964 97.6609C234.89 99.1075 232.93 99.8309 230.084 99.8309C227.237 99.8309 225.277 99.1075 224.204 97.6609C223.13 96.2142 222.594 93.8925 222.594 90.6959ZM225.289 90.6609C225.289 93.2042 225.592 94.9892 226.199 96.0159C226.829 97.0425 228.124 97.5559 230.084 97.5559C232.067 97.5559 233.362 97.0542 233.969 96.0509C234.575 95.0242 234.879 93.2275 234.879 90.6609C234.879 88.0942 234.54 86.3325 233.864 85.3759C233.187 84.3959 231.927 83.9059 230.084 83.9059C228.264 83.9059 227.004 84.3959 226.304 85.3759C225.627 86.3325 225.289 88.0942 225.289 90.6609Z" fill="#FEFEFE" />
                <path d="M215.707 99.4805V81.9805H218.332V99.4805H215.707ZM215.707 78.0255V74.9805H218.332V78.0255H215.707Z" fill="#FEFEFE" />
                <path d="M207.009 81.6309C207.779 81.6309 209.085 81.7709 210.929 82.0509L211.769 82.1559L211.664 84.2909C209.797 84.0809 208.42 83.9759 207.534 83.9759C205.55 83.9759 204.197 84.4542 203.474 85.4109C202.774 86.3442 202.424 88.0942 202.424 90.6609C202.424 93.2042 202.75 94.9775 203.404 95.9809C204.08 96.9842 205.469 97.4859 207.569 97.4859L211.699 97.1709L211.804 99.3409C209.634 99.6675 208.012 99.8309 206.939 99.8309C204.209 99.8309 202.319 99.1309 201.269 97.7309C200.242 96.3309 199.729 93.9742 199.729 90.6609C199.729 87.3242 200.289 84.9909 201.409 83.6609C202.529 82.3075 204.395 81.6309 207.009 81.6309Z" fill="#FEFEFE" />
                <path d="M192.807 99.4805V81.9805H195.432V99.4805H192.807ZM192.807 78.0255V74.9805H195.432V78.0255H192.807Z" fill="#FEFEFE" />
                <path d="M184.58 84.255V99.48H181.955V84.255H179.75V81.98H181.955V80.405C181.955 77.9317 182.305 76.2517 183.005 75.365C183.705 74.455 184.93 74 186.68 74L190.215 74.245L190.18 76.415C188.873 76.3683 187.788 76.345 186.925 76.345C186.085 76.345 185.478 76.625 185.105 77.185C184.755 77.745 184.58 78.83 184.58 80.44V81.98H189.655V84.255H184.58Z" fill="#FEFEFE" />
                <path d="M175.449 97.3109L176.464 97.2059L176.534 99.2709C173.874 99.6442 171.599 99.8309 169.709 99.8309C167.189 99.8309 165.404 99.1075 164.354 97.6609C163.304 96.1909 162.779 93.9159 162.779 90.8359C162.779 84.6992 165.218 81.6309 170.094 81.6309C172.451 81.6309 174.213 82.2959 175.379 83.6259C176.546 84.9325 177.129 86.9975 177.129 89.8209L176.989 91.8159H165.439C165.439 93.7525 165.789 95.1875 166.489 96.1209C167.189 97.0542 168.403 97.5209 170.129 97.5209C171.879 97.5209 173.653 97.4509 175.449 97.3109ZM174.504 89.7159C174.504 87.5692 174.154 86.0525 173.454 85.1659C172.778 84.2792 171.658 83.8359 170.094 83.8359C168.554 83.8359 167.388 84.3025 166.594 85.2359C165.824 86.1692 165.428 87.6625 165.404 89.7159H174.504Z" fill="#FEFEFE" />
                <path d="M147.375 99.4809H144.75V81.9809H147.34V83.2059C149.23 82.1559 151.038 81.6309 152.765 81.6309C155.098 81.6309 156.662 82.2609 157.455 83.5209C158.272 84.7809 158.68 87.0209 158.68 90.2409V99.4809H156.09V90.3109C156.09 87.8842 155.845 86.2275 155.355 85.3409C154.888 84.4309 153.873 83.9759 152.31 83.9759C151.563 83.9759 150.77 84.0925 149.93 84.3259C149.113 84.5359 148.483 84.7459 148.04 84.9559L147.375 85.2709V99.4809Z" fill="#FEFEFE" />
                <path d="M138.98 97.3109L139.995 97.2059L140.065 99.2709C137.405 99.6442 135.13 99.8309 133.24 99.8309C130.72 99.8309 128.935 99.1075 127.885 97.6609C126.835 96.1909 126.31 93.9159 126.31 90.8359C126.31 84.6992 128.748 81.6309 133.625 81.6309C135.981 81.6309 137.743 82.2959 138.91 83.6259C140.076 84.9325 140.66 86.9975 140.66 89.8209L140.52 91.8159H128.97C128.97 93.7525 129.32 95.1875 130.02 96.1209C130.72 97.0542 131.933 97.5209 133.66 97.5209C135.41 97.5209 137.183 97.4509 138.98 97.3109ZM138.035 89.7159C138.035 87.5692 137.685 86.0525 136.985 85.1659C136.308 84.2792 135.188 83.8359 133.625 83.8359C132.085 83.8359 130.918 84.3025 130.125 85.2359C129.355 86.1692 128.958 87.6625 128.935 89.7159H138.035Z" fill="#FEFEFE" />
                <path d="M116.391 81.6296C118.748 81.6296 120.393 82.3063 121.326 83.6596C122.283 84.9896 122.761 87.3346 122.761 90.6946C122.761 94.0546 122.166 96.4229 120.976 97.7996C119.81 99.1529 117.651 99.8296 114.501 99.8296C113.521 99.8296 111.9 99.7363 109.636 99.5496L108.656 99.4446V74.3496H111.246V82.8546C113.02 82.0379 114.735 81.6296 116.391 81.6296ZM114.466 97.4846C116.753 97.4846 118.258 96.9713 118.981 95.9446C119.728 94.8946 120.101 93.1329 120.101 90.6596C120.101 88.1863 119.81 86.4596 119.226 85.4796C118.643 84.4763 117.605 83.9746 116.111 83.9746C114.758 83.9746 113.37 84.2313 111.946 84.7446L111.246 84.9896V97.3096C112.763 97.4263 113.836 97.4846 114.466 97.4846Z" fill="#FEFEFE" />
                <path d="M95.1954 97.3109L96.2104 97.2059L96.2804 99.2709C93.6204 99.6442 91.3454 99.8309 89.4554 99.8309C86.9354 99.8309 85.1504 99.1075 84.1004 97.6609C83.0504 96.1909 82.5254 93.9159 82.5254 90.8359C82.5254 84.6992 84.9637 81.6309 89.8404 81.6309C92.1971 81.6309 93.9587 82.2959 95.1254 83.6259C96.2921 84.9325 96.8754 86.9975 96.8754 89.8209L96.7354 91.8159H85.1854C85.1854 93.7525 85.5354 95.1875 86.2354 96.1209C86.9354 97.0542 88.1487 97.5209 89.8754 97.5209C91.6254 97.5209 93.3987 97.4509 95.1954 97.3109ZM94.2504 89.7159C94.2504 87.5692 93.9004 86.0525 93.2004 85.1659C92.5237 84.2792 91.4037 83.8359 89.8404 83.8359C88.3004 83.8359 87.1337 84.3025 86.3404 85.2359C85.5704 86.1692 85.1737 87.6625 85.1504 89.7159H94.2504Z" fill="#FEFEFE" />
                <path d="M78.21 74.3496V99.4796H75.62V98.2896C73.8233 99.3163 72.05 99.8296 70.3 99.8296C69.3667 99.8296 68.55 99.7129 67.85 99.4796C67.15 99.2463 66.4967 98.8263 65.89 98.2196C64.63 96.9596 64 94.6379 64 91.2546C64 87.8479 64.56 85.3979 65.68 83.9046C66.8233 82.3879 68.7017 81.6296 71.315 81.6296C72.6683 81.6296 74.1033 81.7813 75.62 82.0846V74.3496H78.21ZM67.92 96.3296C68.2933 96.7729 68.69 97.0763 69.11 97.2396C69.53 97.4029 70.0667 97.4846 70.72 97.4846C71.3967 97.4846 72.1433 97.3796 72.96 97.1696C73.8 96.9363 74.4533 96.7146 74.92 96.5046L75.62 96.1896V84.3246C74.1267 84.0446 72.7383 83.9046 71.455 83.9046C69.6817 83.9046 68.445 84.4763 67.745 85.6196C67.045 86.7396 66.695 88.4896 66.695 90.8696C66.695 93.5763 67.1033 95.3963 67.92 96.3296Z" fill="#FEFEFE" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="340" height="66" fill="white" transform="translate(4 67)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default StarSVG;