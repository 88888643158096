export const SET_ERROR_PAYMENT = 'Payment/ErrorPage-Validtion';
export const STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED';
export const STATUS_PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const STATUS_PAYMENT_DEFAULT = 'PAYMENT_DEFAULT';

export const setErrorPayment = (error) => ({
  type: SET_ERROR_PAYMENT,
  payload: error,
});

export const createPaymentError = (error) => {
  return async (dispatch) => {
    dispatch(setErrorPayment(error));
  };
};

export const reducer = (state, action) => ({
  ...state,
  errorPayment: action.payload,
});
