import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSLabel from './PSLabel';

const StyledPSCheckBox = styled.input`
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  font-size: 14px;
  text-align: left;
  height: 15px;
  margin: 4px 2px;
  border-radius: 4px;
  border: none;
`;

const PSCheckBox = React.forwardRef(({ className, children, value, ...props }, ref) => {
  const id = Math.random();
  return (
   <div className={className} onClick={props.onChange}>
     <StyledPSCheckBox {...props} checked={value} value={value} type="checkbox" ref={ref} id={`check${id}`}/>
     <PSLabel htmlFor={`check${id}`} inline>{children}</PSLabel>
   </div>
 )
});

PSCheckBox.displayName = 'PSCheckBox';

PSCheckBox.defaultProps = {
  className: null,
  onChange: () => {},
};
PSCheckBox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
};

export default PSCheckBox;
