import { doGetWithParams, doPost, doPostWithParams } from './Api';

export default class DevolutionLyfe {
  static async getAuthUser(user) {
    const payload = {
      authentication: {
        applicationId: '19100001',
        authenticationType: '01',
        userData: user,
      },
    };
    const response = await doPost(
      `${process.env.REACT_APP_BASE_URL_NET}/security/globalAuthentication/v0/authentication`,
      payload
    );
    return response.data.authenticationResponse.authenticationData.securityToken;
  }

  static async getCouponToken() {
    const response = await doGetWithParams(`${process.env.REACT_APP_GET_TOKEN_COUPON}`);
    const dataResponse = response?.data?.data;

    if (dataResponse) {
      return dataResponse;
    }
    return null;
  }

  static async getCurrentCard(id) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/individual-life/v0/return-life/cards`,
      { id }
    );
    const {data, status} = response?.data;
    if (status?.id === 201 && data) return data;  
    return ;
  }

  static async getHistoryChangeCard(id) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/individual-life/v0/return-life/cards/history`,
      { id }
    );
    return response?.data?.data;
  }

  static async getDataModalNiubiz(policy, merchantCode) {
    const payload = { merchantCode };
    const response = await doPostWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/individual-life/v1/niubiz/cards/init`,
      payload,
      { policy }
    );
    const dataResponse = response.data;
    if (dataResponse.status.id === 403 && dataResponse.status.name === 'UNAUTHORIZED') {
      const { config } = response;

      return { url: config.url, needValidation: true, ...response.data };
    }
    return response.data;
  }

  static async postFinalize(data) {
    const { transactionalToken, sessionToken, initialToken, policyId, idProcess } = data;
    const payload = {
      authorization: {
        authorizationData: [
          {
            idAuthorization: 'initialToken',
            authorizationData: initialToken,
          },
          {
            idAuthorization: 'transactionalToken',
            authorizationData: transactionalToken,
          },
          {
            idAuthorization: 'sessionToken',
            authorizationData: sessionToken,
          },
          {
            idAuthorization: 'processId',
            authorizationData: idProcess,
          },
        ],
      },
    };
    const response = await doPostWithParams(
      `${process.env.REACT_APP_BASE_URL_NET}/individual-life/v0/niubiz/cards/finalize`,
      payload,
      { id: policyId }
    );
    return response.data.status;
  }

  static async getBinInfo(binNumber, publicKey) {
    const response = await doGetWithParams(`${process.env.REACT_APP_VALIDATE_CARD}/${binNumber}`, {
      'Public-Merchant-Id': publicKey,
    });
    return response?.data;
  }

  static async getExpiredCoupons(header) {
    const response = await doGetWithParams(`${process.env.REACT_APP_GET_EXPIRED_COUPONS}`, header);
    return response?.data?.data;
  }

  static async postPaymentDebit(payload) {
    const data = {};
    let error = null;
    try {
      const response = await doPostWithParams(`${process.env.REACT_APP_GENERATE_COUPON}`, payload);
      const dataResponse = response?.data;
      return { data: dataResponse?.data, error };
    } catch (e) {
      error = e;
      return { data, error };
    }
  }
  static async postUpdateCardKushki(payload, header) {
    const data = {};
    let error = null;
    try {
      const response = await doPostWithParams(
        `${process.env.REACT_APP_UPDATE_CARD_KUSHKI}`,
        payload, header
      );
      const dataResponse = response?.data;
      return { data: dataResponse?.data, error };
    } catch (e) {
      error = e;
      return { data, error };
    }
  }
}
