import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone/index';
import { Modal } from 'react-responsive-modal';

import findIndex from 'loadsh/findIndex';
import cloneDeep from 'loadsh/cloneDeep';

import { GAProvider } from '../../components/PSAnalytics';
import { Routes } from '../../../routes';
import { typeMotives, userDefault } from '../../../util/Constant';
import { findValueByField, mapToSelect, capitalizeSentence } from '../../../util/Util';
import { Customer } from '../../shared/model/Customer';
import { getTypeVia } from '../HomeUpdateData/constants';
import GetTicket from '../../shared/services/TicketServices';
import FormsService from '../../shared/services/FormsService';
import ClientsService from '../../shared/services/ClientsService';

import PSLoading from '../../components/PSLoading';
import { PSModalErrorResponse } from '../../components/organisms';
import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSList from '../../components/PSList';
import PSComponentForm from '../../components/PSComponentForm';
import PSButton from '../../components/PSButton';
import PSMessageResponse from '../../components/PSMessageResponse';

import './index.scss';

const img = 'header-reclamos';
const imgMobile = 'header-reclamos-mobile';

const dataTest = [
  {
    header: 'Nombre Completo:',
    description: 'Vizcarra Iparraguirre, Gabriel',
  },
  {
    header: 'Tipo de Documento:',
    description: 'DNI',
  },
  {
    header: 'Numero de Identidad:',
    description: '70583645',
  },
  {
    header: 'Via de Respuesta:',
    description: 'Correo Electronico',
  },
  {
    header: 'Via de Respuesta:',
    description: 'Correo Electronico',
  },
  {
    header: 'Correo Electronico:',
    description: 'gvizcarra@protectasecurity.pe',
  },
  {
    header: 'Telefono Fijo:',
    description: '04-956-2145',
  },
  {
    header: 'Celular:',
    description: '942183384',
  },
];

const form = [
  {
    field: 'producto',
    label: 'Producto*:',
    type: 'select',
    value: '',
    width: 45,
    options: [],
    error: null,
    required: true,
  },
  {
    field: 'poliza',
    label: 'Póliza:',
    type: 'select',
    value: '',
    width: 45,
    options: [],
    error: null,
    required: false,
    disabled: true,
  },
  {
    field: 'canal',
    label: 'Recibir respuesta por*:',
    type: 'select',
    value: '',
    width: 45,
    error: null,
    required: true,
  },
  {
    field: 'motivo',
    label: 'Motivo*:',
    type: 'select',
    value: '',
    width: 45,
    options: [],
    error: null,
    required: true,
  },
  {
    field: 'subMotivo',
    label: 'Sub-Motivo*:',
    type: 'select',
    value: '',
    options: [],
    width: 45,
    error: null,
    required: true,
  },
  {
    field: 'tipoMoneda',
    label: 'Tipo de moneda:',
    type: 'select',
    value: '',
    width: 45,
    options: [
      { value: '', label: 'Seleccione' },
      { value: 'Soles', label: 'Soles' },
      { value: 'Dolares', label: 'Dólares' },
    ],
    error: null,
    required: false,
  },
  {
    field: 'descripcion',
    label: 'Descripción:',
    type: 'textarea',
    value: '',
    width: 100,
    error: null,
    required: false,
  },
  {
    field: 'documents',
    label: 'Adjuntar documentos*:',
    type: 'drop',
    value: '',
    width: 60,
    error: null,
    required: false,
  },
];

export default function HomeClaims(props) {
  const ga = React.useContext(GAProvider);
  const history = useHistory();
  const { userInfo, attributesCore } = useSelector((state) => state.auth);
  const servicesDetail = userInfo?.productsDetail || [];
  const listProducts = servicesDetail.map((e) => {
    return {
      ...e,
      branchId: Number(e?.policies[0].nbranch),
      productId: Number(e?.policies[0].idProduct),
    };
  });

  const attributes = userInfo && userInfo.attributes ? userInfo.attributes : userDefault;
  const fullName = `${attributes?.middleName} ${attributes?.familyName} ${attributes?.givenName}`;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [showPreview, setShowPreview] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formClaims, setFormClaims] = useState(cloneDeep(form));
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInital] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dateNow = moment().tz('America/Lima', true).locale('es').format('DD/MM/YYYY');
  const [address, setAddress] = useState(null);
  const [hasUpdateDate, setHastUpdateDate] = useState(false);

  const onClose = () => {
    setOpenModal(!openModal);
    history.push(Routes.HOME);
  };
  const goBackFunction = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      props.history.goBack();
    }
  };
  const onchangeForm = (e, index) => {
    const value = e.target ? e.target.value : e;
    if (value !== null) {
      formClaims[index].value = value;
      setFormClaims([...formClaims]);

      if (formClaims[index].field === 'producto') {
        const optionSelected = formClaims[index].options.find(
          (item) => item.value?.toString() === value
        );

        if (optionSelected) {
          getPolicyByProduct(optionSelected.product);
        }
      }
      if (formClaims[index].field === 'motivo') {
        getSubMotive(value);
      }

      if (formClaims[index].field === 'canal') {
        const valueIndex = formClaims.findIndex(
          (opt) => opt.field === 'direccion' || opt.field === 'email'
        );
        const formNew = [...formClaims];
        if (value !== '') {
          const item = {
            field: 'direccion',
            label: 'Dirección:',
            type: 'input',
            value: '',
            width: 45,
            error: null,
            required: true,
          };

          if (value === '1') {
            item.field = 'direccion';
            item.label = 'Dirección:';
            if (address === null) {
              setHastUpdateDate(true);
              return true;
            }
            const via = `${getTypeVia(address.P_STI_DIRE)} ${address.P_SNOM_DIRECCION || ''} ${
              address.P_SNUM_DIRECCION || ''
            }`?.trim();
            item.value = `${via}${via && ', '}${address.P_DESDEPARTAMENTO}, ${
              address.P_DESPROVINCIA
            }, ${address.P_DESDISTRITO}`?.toLocaleUpperCase();
          }
          if (value === '2') {
            item.field = 'email';
            item.label = 'Email';
            if (!attributesCore.email) {
              setHastUpdateDate(true);
              return true;
            }

            item.value = attributesCore.email;
          }
          if (valueIndex === -1) {
            formNew.splice(3, 0, { ...item });
            setFormClaims([...formNew]);
          } else {
            formNew[valueIndex] = { ...item };
            setFormClaims([...formNew]);
          }
        } else if (valueIndex !== -1) {
          formNew.splice(valueIndex, 1);
          setFormClaims([...formNew]);
        }
      }

      if (formClaims[index].field === 'tipoMoneda') {
        const valueIndex = formClaims.findIndex((opt) => opt.field === 'reclamo');

        const formNew = [...formClaims];
        if (value !== '') {
          const item = {
            field: 'reclamo',
            label: 'Monto reclamado:',
            type: 'number',
            value: '',
            width: 45,
            error: null,
            required: false,
          };

          if (valueIndex === -1) {
            formNew.splice(index + 1, 0, item);
            setFormClaims([...formNew]);
          } else {
            formNew[valueIndex] = { ...item };
            setFormClaims([...formNew]);
          }
        } else if (valueIndex !== -1) {
          formNew.splice(valueIndex, 1);
          setFormClaims([...formNew]);
        }
      }
    }
  };

  const getPolicyByProduct = async (product) => {
    // call api to get polices
    const index = findIndex(formClaims, (item) => item.field === 'poliza');
    formClaims[index].value = '';
    formClaims[index].disabled = true;
    formClaims[index].required = false;

    if (product) {
      try {
        const data = listProducts.find((e) => e.product === product).policies;
        if (data) {
          formClaims[index].disabled = false;
          formClaims[index].required = true;
          setOptionInForm(data, 'poliza', 'nroPolicy', 'nroPolicy');
        } else {
          setFormClaims([...formClaims]);
        }
      } catch (error) {
        setFormClaims([...formClaims]);
        console.error(error);
      }
    } else {
      setFormClaims([...formClaims]);
    }
  };

  const setOptionInForm = (data, property, propertyId, propertyLabel) => {
    const index = findIndex(formClaims, (item) => item.field === property);
    if (index !== -1) {
      formClaims[index].options = mapToSelect(data, propertyId, propertyLabel);
      setFormClaims([...formClaims]);
    }
  };

  const getMotive = async () => {
    try {
      const response = await GetTicket.responseGetMotives(typeMotives.RECLAMOS);
      if (response.status === 200) {
        setOptionInForm(response.data, 'motivo', 'id', 'descripcion');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSubMotive = async (value) => {
    try {
      const response = await GetTicket.responseGetSubMotives(typeMotives.RECLAMOS, value);
      if (response.status === 200) {
        setOptionInForm(response.data, 'subMotivo', 'id', 'descripcion');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getViaRespuesta = async () => {
    try {
      const response = await GetTicket.responseGetViaRespuesta();
      if (response.status === 200) {
        setOptionInForm(response.data, 'canal', 'id', 'descripcion');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInfoPreview = () => {
    return formClaims.map((item) => {
      if (item.type === 'select' && item.options) {
        const valueFind = item.options.find((opt) => opt.value?.toString() === item.value);

        return {
          header: item.label,
          description: valueFind ? (valueFind.value !== '' ? valueFind.label : '') : '',
          error: item.error,
        };
      }
      if (item.type === 'drop') {
        let value = '';
        if (item.value) {
          item.value.forEach((t, index) => {
            value = value + t.file.name + (index !== item.value.length - 1 ? ', ' : '');
          });
        }
        return { header: item.label, description: value, error: item.error };
      }
      return { header: item.label, description: item.value, error: item.error };
    });
  };

  const getTypeDocuementSave = (documentType) => {
    switch (documentType) {
      case 'L':
        return '2';
      case 'R':
        return '1';
      case 'E':
        return '4';
      default:
        return '2';
    }
  };

  const validateForm = () => {
    let error = false;
    formClaims.forEach((item) => {
      if (item.required) {
        if (item.value === '') {
          item.error = 'Campo requerido';
          error = error || true;
        } else {
          error = error || false;
          item.error = null;
        }
      }
    });
    setFormClaims([...formClaims]);

    return error;
  };

  const registerReclamo = async () => {
    try {
      if (!validateForm()) {
        setLoading(true);
        const files = findValueByField(formClaims, 'documents');
        let statusUpload = false;
        let attachments = [];
        if (files) {
          const headers = { policy: '01', 'form-id': 'REC' };

          const dataFilePost = files.map((file) => {
            const nameSplit = file.file.name.split('.');
            const typeSplit = file.file.type.split('/');
            return {
              file: nameSplit[0],
              type: typeSplit[1],
            };
          });

          const responseFile = await FormsService.uploadFileForms(dataFilePost, headers);

          if (responseFile.status.id === 200) {
            attachments = responseFile.data.map((item) => {
              return { id: item.id };
            });

            try {
              for (let i = 0; i < files.length; i++) {
                await FormsService.uploadFile(
                  responseFile.data[i].presingedUrl,
                  files[i].file,
                  files[i].file.type
                );
              }
              statusUpload = true;
            } catch (e) {
              statusUpload = false;
            }
          }
        } else {
          statusUpload = true;
        }

        if (statusUpload) {
          const productDescription = formClaims[0].options.find(
            (item) => item.value == formClaims[0].value
          );
          const policy = formClaims[1].options.find((item) => item.idPolicy == formClaims[1].value);
          const responseDescription = formClaims[2].options.find(
            (item) => item.value == formClaims[2].value
          );
          const indexMontoReclamo = formClaims.findIndex((item) => item.field === 'reclamo');

          const motivoDescription = formClaims[4].options.find(
            (item) => item.value == formClaims[4].value
          );
          const submotivoDescription = formClaims[5].options.find(
            (item) => item.value == formClaims[5].value
          );

          const divisaDescription = formClaims[6].options.find(
            // tipo de moneda
            (item) => item.value == formClaims[6].value && item?.value?.trim() !== ''
          );

          const indexDescripcion = formClaims.findIndex((item) => item.field === 'descripcion');
          const headers = { policy: '01', 'form-id': 'REC' };

          const data = {
            fullName: capitalizeSentence(fullName),
            branchId: formClaims[0].value,
            product: productDescription?.label,
            response: responseDescription?.label,
            motivo: motivoDescription?.label,
            subMotivo: submotivoDescription?.label,
            divisa: divisaDescription == undefined ? null : divisaDescription?.label,
            productId: productDescription?.productId,
            email: formClaims[3].field === 'email' ? formClaims[3].value : attributesCore.email,
            address: formClaims[3].field === 'direccion' ? formClaims[3].value : '',
            policy: policy?.nroPolicy,
            ubigeo: null,
            monto: indexMontoReclamo === -1 ? null : formClaims[indexMontoReclamo]?.value, // okl
            description: formClaims[indexDescripcion].value, // ok
            attachments,
            viaRespuesta: { id: parseInt(formClaims[2].value) },
            viaRecepcion: { id: 21 },
            // subMotivo: { id: formClaims[4].value, motivo: { id: formClaims[3].value } },
          };
          const response = await GetTicket.responseRegisterTickets(data, headers);

          if (response.status && response.status.id === 201) {
            setOpenModal(true);

            const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
            ga.pageview(window.location.pathname + window.location.search);
            ga.event({
              category: `${messageTypeUser}Registro de Reclamos`,
              action: 'click',
              label: 'El usuario registro un reclamo',
              value: 1,
            });
          } else {
            setHasError(true);
          }

          setLoading(false);
        } else {
          setLoading(false);
          setHasError(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setHasError(true);
      console.log(error);
    }
  };

  const acceptModalError = () => {
    setHasError(false);
    history.push(Routes.HOME);
  };

  const loadClient = async () => {
    try {
      setLoadingInital(true);
      const response = await ClientsService.responseGetClients();
      if (response.statusCode === 200) {
        const customer = new Customer(response.data[0]);
        if (customer.address.length > 0) {
          const addressValue = customer.address[0];
          setAddress(addressValue);
          setLoadingInital(false);
        } else {
          setLoadingInital(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingInital(false);
    }
  };

  useEffect(() => {
    loadClient();
    getMotive();
    getViaRespuesta();
    setOptionInForm(listProducts, 'producto', 'branchId', 'productDescription');
  }, []);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Registro de Reclamos`,
      action: 'click',
      label: 'El usuario visitó la página de Registro de Reclamos',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="register-claims-container">
      <div className="header-claims">
        <PSHeaderIntranet
          hasEffectGray
          img={img}
          imgMobile={imgMobile}
          description="Registro de reclamos"
          goBackFunction={goBackFunction}
          {...props}
        />
      </div>

      {loadingInitial ? (
        <div className="container-loading">
          <PSLoading />
        </div>
      ) : (
        <>
          {showPreview && (
            <div className="container-header-preview">
              <div className="section-header-preview">
                <div className="header-preview-title">Fecha de Recepción: </div>
                <div className="header-preview-answer"> {dateNow}</div>
              </div>
              <div className="section-header-preview">
                <div className="header-preview-title">Vía de Recepción</div>
                <div className="header-preview-answer">Net Privada</div>
              </div>
            </div>
          )}

          <div className="body-claims">
            {!showPreview ? (
              <div className="body-container-form">
                <PSComponentForm
                  from={formClaims}
                  onChange={onchangeForm}
                  className="container-form"
                />
              </div>
            ) : (
              <div>
                <PSList items={getInfoPreview()} />
              </div>
            )}
            <div className="container-buttons">
              <div className="container-option-button">
                <PSButton onClick={() => setShowPreview(!showPreview)}>
                  {showPreview ? 'Editar' : 'Vista previa'}
                </PSButton>
              </div>
              <div className="container-option-button">
                <PSButton onClick={() => registerReclamo()} loading={loading}>
                  Enviar
                </PSButton>
              </div>
            </div>
          </div>

          {openModal && (
            <Modal
              animationDuration={0}
              open={openModal}
              onClose={onClose}
              showCloseIcon={false}
              onOverlayClick={onClose}
            >
              <PSMessageResponse icon="checkMail" onClose={onClose}>
                <p>
                  Tu reclamo se envió correctamente. En un plazo no mayor a 48 horas útiles nuestros
                  ejecutivos estarán generando tu hoja de reclamación. Asimismo, agradeceremos
                  considerar que nuestro horario de atención es de lunes a viernes de 9 a.m. a 6
                  p.m. excepto feriados.
                </p>
              </PSMessageResponse>
            </Modal>
          )}

          {hasError && <PSModalErrorResponse onClick={acceptModalError} />}
          {hasUpdateDate && (
            <PSModalErrorResponse
              title={`Necesita registrar su ${
                formClaims[2].value === '1' ? 'dirección' : 'correo'
              }`}
              message="Le redireccionaremos, para que pueda actualizar sus datos"
              onClick={() => history.push(Routes.HOME_UPDATE_DATA)}
              icon="face"
            />
          )}
        </>
      )}
    </div>
  );
}
