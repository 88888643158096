import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from "../../PSIcon";
import PSModal from "../../PSModal";
import PSButton from "../../PSButton";

const PSModalSuccess = React.forwardRef(({ className, title, message, buttonText,openModal, onClick, loading }, ref) => {

    return (
      <div  ref={ref}>
        <PSModal open={openModal} showCloseIcon={false} classNames={{overlay: 'customOverlaySuccess', modal: 'customModalSuccess'}}>
          <div className={`container-modal-success ${className}`}>
            <PSIcon type="successFace" className="modal-success-icon"/>
            <div className="modal-success-message">
              {
                title && <div className="modal-title">{title}</div>
              }
              <div className="modal-message">
                <p>{message}</p>
                </div>
            </div>
            <div  className="modal-success-button">
              <PSButton onClick={onClick} loading={loading}>{buttonText}</PSButton>
            </div>

          </div>
        </PSModal>
      </div>
    );
  }
);

PSModalSuccess.displayName = 'PSModalSuccess';

PSModalSuccess.defaultProps = {
  className: '',
  title: `Actualización exitosa`,
  message: 'Se publicaron los cambios exitosamente',
  buttonText: 'OK',
  onClick: ()=>{},
  openModal: false,
  loading: false,

};

PSModalSuccess.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  openModal: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PSModalSuccess;
