import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';

import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSLabel from '../../components/PSLabel';
import PSGrayBoxContainer from '../../components/PSGrayBoxContainer';
import PSQuestion from '../../components/PSQuestion';
import { GAProvider } from '../../components/PSAnalytics';
import PSCarousel from '../../components/PSCarousel';

import './index.scss';

const img = 'frequent-question';
const imgMobile = 'frequent-question-mobile';
const services = [
  {
    description: 'Renta Total Protecta',
    nameProduct: 'Renta Total Protecta',
    icon: 'renta',
    questions: FrequentQuestions.RENTA_TOTAL,
    code: 'RentaTotal',
  },
  {
    description: 'Ahorro Total Protecta',
    nameProduct: 'Ahorro Total Protecta',
    icon: 'ahorro',
    questions: FrequentQuestions.AHORRO_TOTAL,
    code: 'AhorroTotal',
  },
  {
    description: 'SCTR Seguro Complementario de Trabajo de Riesgo',
    nameProduct: 'SCTR',
    icon: 'riesgo',
    questions: FrequentQuestions.SCTR,
    code: 'SCTR',
    className: 'column box-container-home',
  },
];
const services1 = [
  {
    description: 'Rentas Vitalicias',
    nameProduct: 'Rentas Vitalicias',
    icon: 'bag',
    questions: FrequentQuestions.RENTA_VITALICIA,
    code: 'RRVV',
  },
  {
    description: 'SOAT',
    nameProduct: 'SOAT',
    icon: 'soat',
    questions: FrequentQuestions.SOAT,
    code: 'SOAT',
  },
  {
    description: 'Vida Ley',
    nameProduct: 'Vida Ley',
    icon: 'coins',
    questions: FrequentQuestions.VIDA_LEY,
    code: 'VidaLey',
  },
];
const services2 = [
  {
    description: 'Accidentes Personales Estudiantiles',
    nameProduct: 'Accidentes Personales',
    icon: 'personalAccident',
    questions: FrequentQuestions.ACCIDENTES_PERSONALES,
    code: 'Accidentes',
  },
  {
    description: 'Seguros de Accidentes',
    nameProduct: 'Accidentes Personales',
    icon: 'cross',
    questions: FrequentQuestions.SEGURO_ACCIDENTES,
    code: 'Accidentes',
  },
  {
    description: 'Seguro de Desgravamen',
    nameProduct: 'Desgravamen',
    icon: 'bill',
    questions: FrequentQuestions.SEGURO_DESGRAVAMEN,
    code: 'Desgravamen',
  },
];

export default function HomeServices(props) {
  const [currentProduct, setCurrentProduct] = React.useState(null);
  const ga = React.useContext(GAProvider);
  const servicesToShowMobile = services.concat(services1).concat(services2);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;

  const back = () => {
    if (currentProduct) setCurrentProduct(null);
    else props.history.goBack();
  };

  const analyticsFrequentQuestion = (labelText) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Preguntas Frecuentes`,
      action: 'click',
      label: labelText,
      value: 1,
    });
  };

  const detailProduct = (product) => {
    setCurrentProduct(product);
    analyticsFrequentQuestion(
      `El usuario visitó las preguntas frecuentes de ${product.nameProduct}`
    );
  };

  useEffect(() => {
    setCurrentProduct(null);
  }, [props.history.location.key]);

  useEffect(() => {
    analyticsFrequentQuestion('El usuario visitó la página de Preguntas Frecuentes');
  }, [ga]);

  return (
    <div className="frecuent-question-container">
      <div className="header">
        <PSHeaderIntranet
          goBackFunction={back}
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description="Preguntas frecuentes"
          icon="questionSmall"
          {...props}
        />
      </div>
      <div className="m-1">
        <div className="section-titulo m-1">
          <PSLabel className={`${currentProduct === null ? '' : 'titulo-select'} titulo`}>
            {currentProduct === null
              ? 'Elige el producto que quieras consultar'
              : currentProduct.description}
          </PSLabel>
        </div>

        {currentProduct === null ? (
          <>
            <div className="m-1 display-none-mobile">
              <div className="columns mt-1 ">
                {services.map((service) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className={service.className || 'column box-container-home'}
                    onClick={() => detailProduct(service)}
                  />
                ))}
              </div>
              <div className="columns mt-1">
                {services1.map((service) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className="column box-container-home"
                    onClick={() => detailProduct(service)}
                  />
                ))}
              </div>
              <div className="columns mt-1">
                {services2.map((service) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className="column box-container-home"
                    onClick={() => detailProduct(service)}
                  />
                ))}
              </div>
            </div>

            <div className="columns display-only-mobile">
              <PSCarousel typeIcon="secondary">
                {servicesToShowMobile.map((service) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className="column box-container-frequent-question"
                    onClick={() => detailProduct(service)}
                  />
                ))}
              </PSCarousel>
            </div>
          </>
        ) : (
          <div>
            <PSQuestion questions={currentProduct.questions} />
          </div>
        )}
      </div>
    </div>
  );
}
