import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import PSLoading from './PSLoading';
import PSIcon from './PSIcon';

const StyledButton = styled.button`
  background-color: #eeeeee;
  cursor: pointer;
  font-size: 15px;

  color: #2b265a;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  height: 35px;
  border: #2b265a 1px solid;
  outline: none;
  font-family: 'BlissPro-Regular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #ed6e00;
    color: #eeeeee;
    border: none;
  }
  svg {
    display:flex;
    height: 90%;
  }

`;

const PSButtonIcon = React.forwardRef(
    ({ tooltip, placeTooltip, children, loading, disabled, icon, ...props }, ref) => (
      <>
        <StyledButton
          {...props}
          ref={ref}
          data-tip={tooltip}
          data-for="Tooltip"
          disabled={disabled || loading}
        >
          {loading ? <PSLoading width={25} height={25} /> : null}
          {icon && <PSIcon className="icon" type={icon} />}
          {children}
        </StyledButton>
        <ReactTooltip id="Tooltip" place={placeTooltip} effect="solid" />
      </>
    )
  );

PSButtonIcon.displayName = 'PSButtonIcon';

PSButtonIcon.defaultProps = {
  onClick: () => {},
  className: null,
  loading: false,
  icon: null,
};

PSButtonIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  icon: PropTypes.string,
};

export default PSButtonIcon;
