import { doGet, doPost, doGetImagesorFiles, doDelete } from './Api';

export default class CouponSoatServices {
  static async responseGetCoupons() {
    const response = await doGet(`${process.env.REACT_APP_COUPONS}coupons`);
    return response.data.data;
  }
  static async responseCreateCoupon(data) {
    const response = await doPost(`${process.env.REACT_APP_COUPONS}coupon/create`, data);
    return response.data.data;
  }
  static async responseUpdateCoupon(data) {
    const response = await doPost(`${process.env.REACT_APP_COUPONS}coupon/update`, data);
    return response.data.data;
  }

  static async responseEnrollCoupon(id) {
    const data = { discountCoupons: [{ id }] };
    const response = await doPost(`${process.env.REACT_APP_COUPONS}coupons/clients`, data);
    return response?.data;
  }

  static async responseGetEnrolledCoupons() {
    const response = await doGet(`${process.env.REACT_APP_COUPONS}coupons/clients`);
    return response.data;
  }

  static async deleteCoupon(data) {
    const response = await doDelete(`${process.env.REACT_APP_COUPONS}coupons/clients`, data);
    return response.data;
  }

  static async responseGetImages(s3UrlImage) {
    const responseImage = await doGetImagesorFiles(process.env.REACT_APP_BASE_URL_NET + s3UrlImage);
    if (responseImage) {
      return responseImage.data.data;
    }
    return null;
  }
}
