import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSIcon from './PSIcon';

const StyledPSGrayBoxContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  color: #ed6e00;
  height: 180px;
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  background: #e4e4e4;
  font-family: 'BlissPro-ExtraBold', sans-serif;
  cursor: pointer;
  margin: 1rem 2rem 1rem 0;
  border-radius: 10px;
  display: inline-block;
  padding: 14px 2px 2px 10px;

  .content-service {
    text-align: center;
    margin-top: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .columns {
    height: 100%;
    .column {
      justify-content: center;
      align-items: center;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }
  .column {
    line-height: 28px;
    font-size: 24px;
  }

  &:hover {
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #ed6e00, #2b0d61);
    border-image-slice: 1;
  }

  @media (max-width: 576px) {
    margin: 1rem 0.5rem 1rem 0.5rem;
    min-height: 145px;
    .columns {
      display: flex;
    }
    .content-service {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 1350px) and (min-width: 1000px) {
    & {
      height: 200px;
    }
  }
`;

const PSGrayBoxContainer = React.forwardRef(({ className, description, icon, onClick }, ref) => (
  <StyledPSGrayBoxContainer className={className} ref={ref} onClick={onClick}>
    <div className="columns">
      <div className="column is-half">{description}</div>
      <div className="column content-service">
        <PSIcon type={icon} />
      </div>
    </div>
  </StyledPSGrayBoxContainer>
));

PSGrayBoxContainer.defaultProps = {
  className: null,
  description: '',
  icon: 'serviceUpdate',
  onClick: () => {},
};

PSGrayBoxContainer.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default PSGrayBoxContainer;
