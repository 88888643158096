import {doGetImages} from "../../services/Api";

export class Magazine {
  constructor(props) {
    this.idTipo = props ? props['idTipoContenido'] : '';
    this.idContenido = props ? props['idContenido'] : '';
    this.title = props ? props['principalTitle'] : '';
    this.description = props ? props['shortDescription'] : '';
    this.paragraphs = props ? props['paragraphs'] : '';
    this.content = props ? props['content'] : '';
    this.buttons = props ? props['buttons'] : '';
    this.s3UrlPdf = props ? props['s3UrlPdf'] : '';
    this.s3UrlImage = props ? props['s3UrlImage'] : '';
    this.dateExpiration = props ? props['dateExpiration']: Date.now();
  }

}
