import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledPSTextInput = styled.input`
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  opacity: 1;
  padding: 10px 40px;
  font-family: 'BlissPro-Regular', sans-serif;
  ${(props) => `background: url(/images/icons/${props.icon}) #ffffff00 no-repeat calc(10px);`};
  background-size: 12px !important;
`;

const PSTextInput = React.forwardRef(({ icon, ...props }, ref) => (
  <StyledPSTextInput {...props} ref={ref} icon={icon} />
));

PSTextInput.displayName = 'PSTextInput';

PSTextInput.defaultProps = {
  icon: 'pencil.svg',
}

PSTextInput.propTypes = {
  icon: PropTypes.string,
}

export default PSTextInput;
