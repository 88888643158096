import FileSaver from 'file-saver';
import { doGet, doPost } from './Api';
import { base64toBlob, fixStructBase64 } from '../../../util/Util';

export default class GetBoletasRRVVServices {
  static async responseGetComprobantePoliza(year, policy, type) {
    if (type === 'rrvv') {
      const response = await doGet(
        `${process.env.REACT_APP_PRODUCT_RRVV_BOLETAS}?year=${year}&policy=${policy}`
      );
      return response.data;
    }
    const typeRequest = type === 'rentaTotal' ? 'RT' : type === 'ahorroTotal' ? 'AT' : '';
    const response = await doGet(
      `${process.env.REACT_APP_PRODUCT_RENTAS_BOLETAS}?year=${year}&product=${typeRequest}&policy=${policy}`
    );
    return response.data;
  }

  static async responseGetComprobantePolizaDetail(id, type) {
    if (type === 'rrvv') {
      const response = await doGet(`${process.env.REACT_APP_PRODUCT_RRVV_BOLETAS_DETAIL}${id}`);
      return response.data;
    }
    const response = await doGet(`${process.env.REACT_APP_PRODUCT_RENTAS_BOLETAS_DETAIL}${id}`);

    return response.data;
  }

  static async responseGetDocuments(policyId, type, nameFile) {
    const data = { data: [{ id: policyId }] };
    const typeRequest = type === 'rrvv' ? 'crearRV' : 'crearRT';
    const response = await doPost(
      `${process.env.REACT_APP_DOCUMENTS}/${typeRequest}`,
      JSON.stringify(data)
    );

    if (response.data.status === 'SUCCEEDED') {
      const base64 = response.data.output;
      const fixBase64 = fixStructBase64(base64);
      const blob = base64toBlob(fixBase64, 'application/pdf');
      FileSaver.saveAs(blob, nameFile);
    }
  }
}
