import AuthService from '../../../shared/services/AuthService';
import { setAuthLoading } from './auth-loading';
import { setErrorCodeStatus } from './error-verification-code';

export const SEND_VERIFICATION_CODE = 'Auth/SEND_VERIFICATION_CODE';

export const setVerificationCodeStatus = (code) => ({
  type: SEND_VERIFICATION_CODE,
  payload: code,
});

export const sendVerificationCode = (user) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const { status } = await AuthService.sendValidationOtp(user);
      if (status?.id === 200) dispatch(setVerificationCodeStatus(true));
      else dispatch(setErrorCodeStatus(false));
    } catch (error) {
      dispatch(setVerificationCodeStatus(false));
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  codeSent: action.payload,
});
