import { doPostValidateOtp } from './Api';
import { STORAGE_TOKEN_COGNITO } from '../../../util/Constant';

export default class ValidateOTPService {
  static async responsePostValidate(url, data, type, otp, header) {
    const token = localStorage.getItem(STORAGE_TOKEN_COGNITO);
    const response = await doPostValidateOtp(url, data, token, type, otp, header);
    return response.data;
  }
}
