import React from 'react';
import PropTypes from 'prop-types';
import infoProblems from '../../../../assets/images/infoProblems.png'

import './index.scss';

const PSInfoProblems = React.forwardRef(({ type, className, isBold, name }, ref) => (
  <div className={`container-problems ${className}`} ref={ref}>
    <img src={infoProblems}/>
    <div className='description'>
      Por favor ponte en contacto con nuestra Central de Servicio al Cliente: (01) 391-3000 Lima y 0-801-1-1278 Provincias.
    </div>
  </div>
));

PSInfoProblems.displayName = 'PSInfoProblems';

PSInfoProblems.defaultProps = {
  className: '',
};

PSInfoProblems.propTypes = {
  className: PropTypes.string,
};

export default PSInfoProblems;
