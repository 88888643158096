export const ERROR_VERIFICATION_CODE = 'Auth/ERROR_VERIFICATION_CODE';

export const setErrorCodeStatus = (user) => ({
  type: ERROR_VERIFICATION_CODE,
  payload: user,
});

export const reducer = (state, action) => ({
  ...state,
  errorCodeSent: { ...state.errorCodeSent, ...action.payload },
});
