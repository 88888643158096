import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PSIcon from './PSIcon';

const MIN_ROWS_TABLE = 5;
const StyledPSDynamicTable = styled.div`
  /* Track */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #cbcbcb;
    margin-left: 0.8rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ed6e00;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #cbcbcb;
  // }

  overflow-y: scroll;
  height: ${(props) => (props.lengthTable > MIN_ROWS_TABLE ? '200px' : '100%')};
  .container-table {
    width: 97%;
    min-width: 850px;
    margin: 20px 16px 25px 16px;
    .tr-disabled {
      td {
        cursor: not-allowed;
        color: #7a7a7a !important;
      }
    }
    .table-header,
    .table-item {
      text-align: center;
      .item-input-edit {
        width: 70%;
        text-align: center;
        height: 25px;
        border-radius: 8px;
        border: 1px solid #7a7a7aed;
      }
      .item-input-par {
        background: #f0f0f0;
      }
    }
    .table-header {
      font-weight: bold;
      color: #ed6e00;
      padding: 0.2rem 0.2rem 0.4rem 0.2rem;
    }
    .table-item {
      background-color: #fff;
      color: #2b0d61;
      padding: 0.4rem 0.2rem 0.1rem 0.2rem;
    }
    .table-item-par {
      background-color: #f0f0f0;
    }
    .first-column {
      border-radius: 25px 0 0 25px;
    }
    .last-column {
      border-radius: 0 25px 25px 0;
    }
    .item-edit {
      cursor: pointer;
    }
  }
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      // display: none; /* Ocultar scroll */
    }
  }

  @media (max-width: 768px) {
    .container-table {
      min-width: 780px;
    }
  }
`;

const PSDynamicTableEditable = ({
  dataQl,
  showCheck,
  headers,
  items,
  onSelect,
  valueSelected,
  hasEdit,
  onEdit,
  hasDelete,
  onChange,
  onDelete,
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const lengthTable = items ? items.length : 1;
  const [currentEdit, setCurrentEdit] = useState(-1);

  const hasActions = (hasEdit || hasDelete) && valueSelected !== -1;

  const edit = (index) => {
    onEdit();
    if (currentEdit !== index) setCurrentEdit(index);
    else setCurrentEdit(-1);
  };

  const onKeyPres = (e, index) => {
    if (e.charCode === 13) {
      edit(index);
    }
  };

  return (
    <StyledPSDynamicTable
      lengthTable={lengthTable}
      showCheck={showCheck}
      numColumn={headers.length}
    >
      <table className="container-table">
        <thead>
          <tr className="">
            {showCheck && <th className="table-header" />}
            {headers.map((header, index) => (
              <th key={header.elementProp} className="table-header">
                {header.headerColumn}
              </th>
            ))}
            {hasActions && <th className="table-header" />}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            if (isBusiness && item.dateEnd === '') {
              item.dateVigencia = `Desde ${item.dateStart}`;
            }
            return (
              <tr className="tr-enabled" key={item.nroDocument}>
                {showCheck ? (
                  <td
                    className={`table-item first-column ${
                      index % 2 === 0 ? '' : ' table-item-par'
                    }`}
                  >
                    <input
                      type="radio"
                      name="1"
                      value={valueSelected}
                      checked={index === valueSelected}
                      onChange={() => onSelect(index)}
                    />
                  </td>
                ) : null}

                {headers.map((header, i) => {
                  return (
                    <td
                      key={item.id + header.elementProp}
                      className={`table-item ${i === 0 && !showCheck ? 'first-column' : ''}${
                        i === headers.length - 1 && !hasActions ? ' last-column' : ''
                      }${index % 2 === 0 ? '' : ' table-item-par'}`}
                    >
                      {header.isEdit && currentEdit === index ? (
                        <input
                          value={item[header.elementProp]}
                          className={`item-input-edit ${index % 2 === 0 ? 'item-input-par' : ' '}`}
                          onChange={(e) => onChange(e.target.value, header.elementProp, index)}
                          onKeyPress={(e) => onKeyPres(e, index)}
                        />
                      ) : (
                        item[header.elementProp]
                      )}
                    </td>
                  );
                })}

                {hasActions && valueSelected === index && (
                  <td
                    className={`table-item d-flex last-column ${
                      index % 2 === 0 ? '' : ' table-item-par'
                    }`}
                  >
                    {hasEdit && (
                      <PSIcon type="pencil" className="item-edit" onClick={() => edit(index)} />
                    )}
                    {hasDelete && (
                      <PSIcon
                        type="delete"
                        className="item-edit ml-2"
                        onClick={() => onDelete(index)}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledPSDynamicTable>
  );
};

PSDynamicTableEditable.displayName = 'PSDynamicTableEditable';

PSDynamicTableEditable.defaultProps = {
  showCheck: false,
  headers: [],
  onSelect: () => {},
  hasEdit: false,
  hasDelete: false,
  onEdit: () => {},
  onChange: () => {},
  onDelete: () => {},
};

PSDynamicTableEditable.propTypes = {
  showCheck: PropTypes.bool,
  headers: PropTypes.array,
  onSelect: PropTypes.func,
  hasEdit: PropTypes.bool,
  hasDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PSDynamicTableEditable;
