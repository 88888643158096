import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Calendar from 'react-calendar';
import PSIcon from './PSIcon';
import { chunkArray, formatDaysWeek, reorderMonths } from '../../util/Util';

const localeLanguage = 'es-419';
const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const WEEK_DAYS = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
const StyledPSCalendar = styled.div`
  .react-calendar {
    background: none;
    border: none;
    min-width: 400px;
    line-height: 2em;
    pointer-events: none;
    * {
      font-family: 'BlissPro-Regular', sans-serif;
      text-transform: capitalize;
      text-decoration: none;
    }
    .react-calendar__navigation__label {
      pointer-events: none !important;
    }

    .react-calendar__tile--now {
      color: initial !important;
      background: initial !important;
    }
    .react-calendar__navigation {
      button {
        pointer-events: all;
        span {
          font-size: 25px;
          color: #ed6e00;
          font-weight: 600;
          font-family: 'BlissPro-Light', sans-serif;
        }
      }
    }
    .react-calendar__month-view__weekdays__weekday,
    button {
      font-size: 15px;
      color: #1b1944;
    }

    .react-calendar__tile--now {
      background: #1b1944;
      border-radius: 50%;
      color: white;
      max-width: 11% !important;
      margin-left: 2%;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #b1b1b1 !important;
    }
    .react-calendar__month-view__days__day--weekend {
      color: #1b1944;
    }
    .react-calendar__tile--active {
      background: #ed6e00;
      color: white;
      max-width: 11%;
      margin-left: 2.5%;
      margin-right: 0;
      padding-top: 2%;
      height: 31px;
      border-radius: 50%;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__tile {
      padding: 0.2em 0;
    }
    .react-calendar__month-view__weekdays__weekday {
      abbr {
        color: #cacaca;
        font-weight: 300;
      }
    }
    .react-calendar__navigation {
      margin-bottom: 0;
    }
  }
  @media (max-width: 576px) {
    .react-calendar {
      min-height: 215px;
    }
  }
`;
const PSCalendar = React.forwardRef(
  ({ className, onChange, value, number, isMobile, ...props }, ref) => {
    const onLoadCalendar = () => {
      const monthsDisplay = isMobile ? reorderMonths(MONTHS) : MONTHS;
      const titleMonth = document.getElementsByClassName(
        'react-calendar__navigation__label__labelText'
      );
      let titleWeekDay = document.getElementsByClassName(
        'react-calendar__month-view__weekdays__weekday'
      );
      titleWeekDay = chunkArray(titleWeekDay, 7);
      formatDaysWeek(WEEK_DAYS, titleWeekDay);
      monthsDisplay.map((m, index) => (titleMonth[index + 12 * number].innerHTML = m));
    };

    useEffect(() => {
      onLoadCalendar();
    }, []);

    return (
      <StyledPSCalendar className={className} ref={ref}>
        <Calendar
          {...props}
          nextLabel={<PSIcon type="arrowRight" className="arrow-right" />}
          prevLabel={<PSIcon type="arrowLeft" className="arrow-left" />}
          onChange={onChange}
          locale={localeLanguage}
          value={value}
        />
      </StyledPSCalendar>
    );
  }
);

PSCalendar.displayName = 'PSCalendar';

PSCalendar.defaultProps = {
  className: null,
  onChange: () => {},
  value: new Date(),
  number: 0,
  isMobile: false,
};
PSCalendar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  isMobile: PropTypes.bool,
  number: PropTypes.number,
};

export default PSCalendar;
