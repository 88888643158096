import React from 'react';
import ReactGA from 'react-ga';

class PSAnalytics {
  constructor() {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
  }

  pageview(path) {
    ReactGA.pageview(path);
    return this;
  }

  // even({ category: 'Home', value: 2 })
  event({ category, action = undefined, label = undefined, value = undefined }) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  exposeGA() {
    return ReactGA.ga();
  }
}

const GAProvider = React.createContext(new PSAnalytics());
const GAConsumer = GAProvider.Consumer;

export { GAProvider, GAConsumer };
