import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSBox from './PSBox';
import PSTitle from './PSTitle';
import PSLabel from './PSLabel';
import PSButton from './PSButton';

const StyledPrivacyPolicies = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    //border-radius: 10px;
    background: #070621;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ed6e00;
    //border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ed6e00;
  }
`;

const Company = {
  address:
    'PROTECTA S.A. COMPAÑÍA DE SEGUROS (en adelante PROTECTA SECURITY), ubicado en Av. Domingo Orué 165, Piso 8, Surquillo-Lima.',
  policies:
    'Gestiones relacionadas con la Net Privada de PROTECTA SECURITY.\n' +
    '\n' +
    '  Adicionalmente, en caso lo haya autorizado usted, PROTECTA SECURITY podrá usar sus datos personales para enviarle comunicaciones comerciales de sus productos y publicidad, envío de encuestas e invitaciones a talleres, charlas, eventos y cursos.\n' +
    '\n' +
    '  En caso no acepte el tratamiento adicional de sus datos personales antes descrito, esto no afectará la prestación del servicio a través de la Net Privada.\n',

  personalData: 'Para llevar a cabo las finalidades descritas en la presente política de privacidad, es obligatorio que nos proporcione los siguientes datos personales: número de documento de identidad.\n' +
  'No utilizaremos datos sensibles.',

  consequences: 'De no proporcionar los datos obligatorios mencionados en el recuadro anterior, no podrá acceder a los servicios de la Net Privada.',
  addressee: 'Su información será compartida con los siguientes destinatarios: \n' +
  'Inversiones Security Perú S.A.C., (empresa del Grupo Empresarial),\n' +
  'Travex S.A., (empresa del Grupo Empresarial), \n' +
  'Viajes Security S.A.C., (empresa del Grupo Empresarial), \n' +
  'Asimismo, se podrá compartir con la Administración Pública en los casos que así se requiera de acuerdo con la legislación vigente.',

  transfer:'No se transfieren los datos personales fuera de Perú.',
  backData : 'La información será almacenada en el banco de datos de “Clientes Masivos” inscrito en el Registro Nacional de Protección de Datos Personales con el Código RNPDP N° 01567.',
  time:'Los datos serán conservados durante el tiempo necesario para prestar nuestros servicios a través de la Net Privada. Posteriormente al mismo, y para las finalidades adicionales si usted lo ha autorizado, se conservarán hasta que revoque su consentimiento. Asimismo, se conservarán los datos para cumplir con requerimientos legales.',

  rights: 'Puede ejercer los derechos de acceso, rectificación, cancelación y oposición, así como revocar su consentimiento para las finalidades no necesarias para la ejecución previa y durante la relación contractual, en nuestras oficinas ubicadas en la dirección señalada líneas arriba o a través del siguiente correo electrónico: clientes@protectasecurity.pe\n'+
    'De considerar que no ha sido atendido en el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle Scipion Llona 350, Miraflores, Lima, Perú llenando el formulario publicado en el siguiente enlace https://www.minjus.gob.pe/wp-content/uploads/2018/12/FORMULARIO-DE-PROCEDIMIENTO-TRILATERAL-DE-TUTELA.pdf',
  additionalInformation: 'De tener alguna inquietud, puede comunicarse con nosotros vía correo electrónico a clientes@protectasecurity.pe o llamando al (01) 391-3000 o al 0-801-11278'
};

const PSPrivacyPolicies = React.forwardRef(({ className, goBack }, ref) => (
  <StyledPrivacyPolicies className={className} ref={ref}>
    <PSTitle className="size-medium f-extraBold">Política de privacidad</PSTitle>
    <PSBox useScroll className="mt-2 pl-3 pr-3">
      <PSLabel className="align-left f-extraBold">Identidad y domicilio</PSLabel>
      <PSLabel className="align-justify">{Company.address}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Finalidad</PSLabel>
      <PSLabel className="align-justify">{Company.policies}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Datos personales obligatorios</PSLabel>
      <PSLabel className="align-justify">{Company.personalData}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Consecuencias de proporcionar los datos o su negativa a hacerlo</PSLabel>
      <PSLabel className="align-justify">{Company.consequences}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Destinatarios</PSLabel>
      <PSLabel className="align-justify">{Company.addressee}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Transferencia</PSLabel>
      <PSLabel className="align-justify">{Company.transfer}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Banco de datos</PSLabel>
      <PSLabel className="align-justify">{Company.backData}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Tiempo</PSLabel>
      <PSLabel className="align-justify">{Company.time}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Ejercicio de los derechos arco</PSLabel>
      <PSLabel className="align-justify">{Company.rights}</PSLabel>
      <PSLabel className="align-left mt-1 f-extraBold">Información adicional</PSLabel>
      <PSLabel className="align-justify">{Company.additionalInformation}</PSLabel>
    </PSBox>
    <PSBox className="mt-2">
      <PSButton onClick={goBack}>Volver</PSButton>
    </PSBox>
  </StyledPrivacyPolicies>
));

PSPrivacyPolicies.displayName = 'PSPrivacyPolicies';

PSPrivacyPolicies.defaultProps = {
  className: null,
  goBack: () => {},
};

PSPrivacyPolicies.propTypes = {
  className: PropTypes.string,
  goBack: PropTypes.func,
};

export default PSPrivacyPolicies;
