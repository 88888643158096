import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PSButton from './PSButton';
import {
  MAGAZINE_BOLETIN_FINANCIERO,
  optionsHeaderNewSletter,
  pathServer,
} from '../../util/Constant';
import { Magazine } from '../shared/model/magazine/Magazine';
import LoadMagazinesService from '../shared/services/newSletter/LoadMagazinesService';
import PSLoading from './PSLoading';
import { GAProvider } from './PSAnalytics';

const imgDefault = 'testItem.png';
const StyledPSItemNewsletter = styled.div`
  height: 450px;
  width: 350px;
  .container-item-newsletter {
    height: 100%;
    display: flex;
    flex-direction: column;
    .section-img {
      background: url('${(props) => (props.url ? props.url : '#ffff')}');
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 25px 25px 0 0;
      height: 60%;
    }
    .section-info-type-finance {
      background: #bababa;
      border-radius: 25px 25px 0 0;
      height: 60%;
      text-align: center;
      display: flex;
      .info-title-finance {
        font-size: 34px;
        color: #2b0d61;
        font-family: 'BlissPro-Heavy', sans-serif;
        margin: auto 3rem;
        line-height: 1;
        .finance-description {
          padding-top: 1rem;
          font-size: 20px;
          font-family: 'BlissPro-Regular', sans-serif;
        }
      }
    }
    .section-info {
      background: #2b0d61;
      height: 40%;
      border-radius: 0 0 25px 25px;
      text-align: center;
      color: #fff;
      .info-title {
        margin-top: 1rem;
        font-size: 30px;
        font-family: 'BlissPro-Heavy', sans-serif;
      }
      .info-description {
        // line-height: 12px;
        font-size: 20px;
      }
    }
    .button-newsletter {
      margin: auto;
      width: 50%;
      margin-top: -20px;
      border-radius: 10px;
      font-size: 24px;
      font-family: 'BlissPro-Bold', sans-serif;
    }
  }

  @media (max-width: 576px) {
    height: 320px;
    .section-info {
      height: 32% !important;
    }

    .container-item-newsletter {
      .section-info-type-finance {
        .info-title-finance {
          margin: auto 1rem;
          .finance-title {
            font-size: 25px;
          }

          .finance-description {
            font-size: 16px;
          }
        }
      }

      .button-newsletter {
        width: 60%;
        font-size: 22px;
      }
      .info-title {
        margin: 0 !important;
        label {
          font-size: 20px;
        }
      }
      .info-description {
        margin: 0 !important;
        label {
          font-size: 12px;
        }
      }

      .button-newsletter {
        font-size: 16px;
        padding: 7px 0;
        height: auto;
      }
    }
  }
`;

const PSNewsletterItem = React.forwardRef(({ className, onClick, magazine, ...props }, ref) => {
  const [magazineObj, setMagazine] = useState(magazine);
  const { userInfo } = useSelector((state) => state.auth);
  const [flag, setFlag] = useState(false);
  const url = `${pathServer.PATH_ICONS + imgDefault}`;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const ga = React.useContext(GAProvider);

  const loadImages = async () => {
    const responseImage = await LoadMagazinesService.responseGetImages(magazine);
    magazineObj.s3UrlImageNew = responseImage;
    setMagazine(magazineObj);
    setFlag(true);
  };
  useEffect(() => {
    loadImages();
  }, []);

  const loadImagespagination = async () => {
    setFlag(false);
    setMagazine(magazine);
    const responseImage = await LoadMagazinesService.responseGetImages(magazine);
    magazineObj.s3UrlImageNew = responseImage;
    setMagazine({ ...magazine, s3UrlImageNew: responseImage });
    setFlag(true);
  };

  const onClickMagazine = () => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    const nameBoletin = `${magazineObj.title} ${magazineObj.description}`;
    const nameCategory = optionsHeaderNewSletter.find(
      (option) => option.idTipoContenido === magazineObj.idTipo
    );
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Newsletter-${nameCategory.shortDescription}-${nameBoletin}-Detalle`,
      action: 'click',
      label: `El usuario visitó el detalle del boletín: ${nameBoletin}`,
      value: 1,
    });
    onClick(magazineObj);
  };

  useEffect(() => {
    loadImagespagination();
  }, [magazine]);
  return (
    <>
      {flag ? (
        <StyledPSItemNewsletter
          {...props}
          ref={ref}
          className={className}
          url={flag ? magazineObj.s3UrlImageNew : url}
        >
          <div className="container-item-newsletter">
            {magazineObj.idTipo === MAGAZINE_BOLETIN_FINANCIERO ? (
              <div className="section-info-type-finance">
                {magazineObj.content.map(
                  (op, index) =>
                    index === 0 && (
                      <div className="info-title-finance" key={index}>
                        <div className="finance-title">{op.title}</div>
                        <div className="finance-description">{op.description}</div>
                      </div>
                    )
                )}
              </div>
            ) : (
              <div className="section-img" />
            )}
            <div className="section-info">
              <div className="info-title">
                <label>{magazineObj.title || 'Boletin N°1 xxx'}</label>
              </div>
              <div className="info-description">
                <label>{magazineObj.description || 'Agosto 2020 xxx'}</label>
              </div>
            </div>
            <PSButton className="button-newsletter" onClick={onClickMagazine}>
              Ver más
            </PSButton>
          </div>
        </StyledPSItemNewsletter>
      ) : (
        <PSLoading />
      )}
    </>
  );
});

PSNewsletterItem.defaultProps = {
  magazine: new Magazine(),
  onClick: () => {},
};

PSNewsletterItem.propTypes = {
  magazine: PropTypes.object,
  onClick: PropTypes.func,
};

export default PSNewsletterItem;
