import { setAuthLoading } from './auth-loading';
import AuthService from '../../../shared/services/AuthService';

export const CREATE_USER_COGNITO = 'Auth/CREATE_USER_COGNITO';

export const setCreatedUserCognito = (created) => ({
  type: CREATE_USER_COGNITO,
  payload: created,
});

export const createUserCognito = (payload, code) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await AuthService.createCognitoUser(payload, code);
      dispatch(setCreatedUserCognito(response.statusCode === 200));
      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch(setAuthLoading(false));
      dispatch(setCreatedUserCognito(false));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  cognitoCreated: action.payload,
});
