export class Clinical {
  constructor(props) {
    this.anexoTelefonico = props.anexoTelefonico;
    this.name = props.clinica;
    this.department = props.departamento;
    this.province = props.province;
    this.district = props.distrito;
    this.direccion = props.direccion;
    this.idClinica = props.idClinica;
    this.idRamo = props.idRamo;
    this.lat = props.latitud ? Number(props.latitud) : null;
    this.lng = props.longitud ? Number(props.longitud) : null;
    this.nombreComercial = props.nombreComercial;
    this.phone = props.telefono;
  }
}
