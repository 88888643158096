import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { menuCode } from '../shared/constants/Strings';

import PSIcon from './PSIcon';

const MIN_ROWS_TABLE = 5;
const ID_SEGMENT_RRVV = menuCode.RENTA_VITALICIA;

const StyledPSDynamicTable = styled.div`
  /* Track */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #cbcbcb;
    margin-left: 0.8rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ed6e00;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #cbcbcb;
  // }

  overflow-y: scroll;
  height: ${(props) => (props.lengthTable > MIN_ROWS_TABLE ? '200px' : '100%')};
  .container-table {
    width: 97%;
    margin: 20px 16px 25px 16px;
    .tr-disabled {
      td {
        cursor: not-allowed;
        color: #7a7a7a !important;
      }
    }
    .table-header,
    .table-item {
      text-align: center;
    }
    .table-header {
      font-weight: bold;
      color: #ed6e00;
      padding: 0.2rem 0.2rem 0.4rem 0.2rem;
    }
    .table-item {
      background-color: #fff;
      color: #2b0d61;
      padding: 0.4rem 0.2rem 0.1rem 0.2rem;
    }
    .table-item-par {
      background-color: #f0f0f0;
    }
    .first-column {
      border-radius: 25px 0 0 25px;
    }
    .last-column {
      border-radius: 0 25px 25px 0;
    }
    .item-edit {
      cursor: pointer;
    }
    .vertical-center {
      vertical-align: middle;
    }
  }
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      // display: none; /* Ocultar scroll */
    }
  }

  @media (max-width: 768px) {
    .container-table {
      min-width: 780px;
    }
  }
`;

const PSDynamicTable = ({
  dataQl,
  showCheck,
  headers,
  items,
  onSelect,
  valueSelected,
  hasEdit,
  onEdit,
  isLastColumnCentered,
  menuId,
  showCheckBox,
  listSelect,
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const hasPrima = dataQl.length > 0;
  const lengthTable = items ? items.length : 1;
  const indexColumnPrima = 2;
  
  return (
    <StyledPSDynamicTable
      lengthTable={lengthTable}
      showCheck={showCheck}
      numColumn={headers.length}
    >
      <table className="container-table">
        <thead>
          <tr className="">
            {showCheck && <th className="table-header" />}
            {showCheckBox && <th className="table-header" />}
            {headers.map((header, index) => (
              <th key={header.elementProp} className="table-header">
                {header.headerColumn}
              </th>
            ))}
            {hasEdit && <th className="table-header" />}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const id = `${item.nroPolicy}${item.nroCertificate}`;
            const isDevolutionLife = item.idProduct === '1' && item.nbranch === '71';

            /* TODO : remove disabled validation in policy table
               let isDisabled = isDevolutionLife
             ? item.status !== 'Vigente' && item.status !== 'Suspendida'
             : item.status !== 'Vigente';
             sisDisabled = isDisabled || (isDevolutionLife && item.rol === 'Asegurado');
            */
            const isDisabled = false;

            if ((isBusiness && item.dateEnd === '') || menuId === ID_SEGMENT_RRVV) {
              item.dateVigencia = `Desde ${item.dateStart}`;
            }

            return (
              <tr key={item.id || id} className={isDisabled ? 'tr-disabled ' : 'tr-enabled'}>
                {showCheck ? (
                  <td
                    className={`table-item first-column ${
                      index % 2 === 0 ? '' : ' table-item-par'
                    }`}
                  >
                    <input
                      disabled={isDisabled}
                      type="radio"
                      name="1"
                      value={valueSelected}
                      checked={index === valueSelected}
                      onChange={() => {
                        onSelect(index);
                      }}
                    />
                  </td>
                ) : null}
                {showCheckBox ? (
                  <td
                    className={`table-item first-column ${
                      index % 2 === 0 ? '' : ' table-item-par'
                    }`}
                  >
                    <input
                      disabled={item.isDisabled}
                      name={`checkbox${index}`}
                      type="checkbox"
                      style={{ display: item.reciboPendiente === 0 ? 'none' : '' }}
                      value={item.value}
                      checked={listSelect.includes(item.value)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        onSelect(item.value, isChecked);
                      }}
                    />
                  </td>
                ) : null}

                {headers.map((header, i) => {
                  const useExponent =
                    item.producto === 'Vida con Devolución Protecta' &&
                    header.elementProp === 'productoCode';
                  return (
                    <td
                      key={item.id + header.elementProp}
                      className={`table-item ${
                        i === 0 && !showCheck && !showCheckBox ? 'first-column' : ''
                      }${i === headers.length - 1 && !hasEdit ? ' last-column' : ''}${
                        index % 2 === 0 ? '' : ' table-item-par'
                      } ${
                        isLastColumnCentered && i === headers.length - 1 ? 'vertical-center' : ''
                      }`}
                    >
                      {hasPrima && i === indexColumnPrima ? (
                        <NumberFormat
                          value={dataQl[index].premium}
                          className="foo"
                          displayType="text"
                          thousandSeparator
                          prefix={`${dataQl[index].currency.shortDescription} `}
                          renderText={(value, props) => <div {...props}>{value}</div>}
                        />
                      ) : useExponent ? (
                        <div>
                          {item[header.elementProp]} <sup>+</sup>
                        </div>
                      ) : (
                        item[header.elementProp]
                      )}
                    </td>
                  );
                })}

                {hasEdit && (
                  <td
                    className={`table-item last-column ${index % 2 === 0 ? '' : ' table-item-par'}`}
                  >
                    <PSIcon type="pencil" className="item-edit" onClick={() => onEdit(item)} />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledPSDynamicTable>
  );
};

PSDynamicTable.displayName = 'PSDynamicTable';

PSDynamicTable.defaultProps = {
  showCheck: false,
  headers: [],
  onSelect: () => {},
  dataQl: [],
  hasEdit: false,
  onEdit: () => {},
  isLastColumnCentered: false,
};

PSDynamicTable.propTypes = {
  showCheck: PropTypes.bool,
  headers: PropTypes.array,
  onSelect: PropTypes.func,
  dataQl: PropTypes.array,
  hasEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  isLastColumnCentered: PropTypes.bool,
};

export default PSDynamicTable;
