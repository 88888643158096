import moment from 'moment-timezone';
import { capitalizeSentence, } from '../../../util/Util';
import 'moment/locale/es';
export class Beneficiary {


  constructor(props) {
    // eslint-disable-next-line radix
    const date = parseInt(props.fechaNacimiento);
    this.typeDocument =  props.documento
    this.nroDocument = props.numeroDocBeneficiario; // no trae el api
    this.name = props.nombre;
    this.fullName = `${props.nombre} ${props.segundoNombre? props.segundoNombre:''} ${props.apellidoPaterno} ${props.apellidoMaterno}`;
    this.lastName = props.apellidoPaterno;
    this.lastName2 = props.apellidoMaterno;
    this.parent = props.parentesco; // no trae el api
    this.percentage = props.porcentaje;
    this.status = 'Vigente';
    this.fullName = capitalizeSentence(this.fullName);
    this.date = props.fechaNacimiento
      ? `${props.fechaNacimiento.substr(0,4)}-${props.fechaNacimiento.substr(4,2)}-${props.fechaNacimiento.substr(6,2)}`
      : '-';
  }
}
