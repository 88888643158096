import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import PSBox from './PSBox';
import PSLabel from './PSLabel';
import PSTitle from './PSTitle';

const StyledPSLogingMessageValidation = styled.div`
  .message-validation {
    font-family: 'BlissPro-ExtraBold', sans-serif;
    color: #ed6e00 !important;
    padding: 0 10px;
  }
`;
const Message = {
  email: 'Le enviamos un código de activación al correo registrado:',
  phoneNumber: 'Le enviamos un SMS con el código de activación al celular registrado',
};

const PSLogingMessageValidation = React.forwardRef(({ className, type }, ref) => {
  const { userValidationInfo } = useSelector((state) => state.auth);
  const { attributesCore } = useSelector((state) => state.auth);
  const messageChannel = type === 'email' ? attributesCore?.email : attributesCore?.phone;
  const messageLength = messageChannel?.length;
  const fontSizeTitle = messageLength < 21 ? 30 : messageLength < 38 ? 17 : 14;

  return (
    <StyledPSLogingMessageValidation>
      <PSBox className={className} ref={ref}>
        <PSBox>
          <PSLabel className="message-validation">{Message[type]}</PSLabel>
        </PSBox>
        {userValidationInfo[type] ? (
          <PSBox>
            <PSTitle>{userValidationInfo[type]} </PSTitle>
          </PSBox>
        ) : (
          messageChannel && (
            <PSBox>
              <PSTitle level={fontSizeTitle}>{messageChannel} </PSTitle>
            </PSBox>
          )
        )}
      </PSBox>
    </StyledPSLogingMessageValidation>
  );
});

PSLogingMessageValidation.displayName = 'PSLogingMessageValidation';

PSLogingMessageValidation.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['email', 'phoneNumber']),
};

export default PSLogingMessageValidation;
