export const INVALID_PASSWORD = 'Auth/INVALID_PASSWORD';

export const setInvalidPassword = (invalidRequest) => ({
  type: INVALID_PASSWORD,
  payload: invalidRequest,
});

export const reducer = (state, action) => ({
  ...state,
  invalidPassword: action.payload,
});
