import { doGet } from './Api';

export default class LoadProductosServices {
  // deprecated
  static async responseLoadProductos() {
    const response = await doGet(`${process.env.REACT_APP_GET_PRODUCTS}`);
    return response.data;
  }

  static async responseProductosPolicies() {
    const response = await doGet(`${process.env.REACT_APP_GET_PRODUCTS_POLICIES}`);
    return response.data;
  }
}
