/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'loadsh/isEmpty';

import { pathServer } from '../../../../util/Constant';
import { maxLengthCheck } from '../../../../util/Util';
import { validationsExpiryDate } from '../../templates/PSUpdateCard/constants';
import DevolutionLyfe from '../../../shared/services/DevolutionLyfe';

import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import PSButton from '../../PSButton';
import PSError from '../../PSError';

import './index.scss';

const classNamesKushki = { overlay: 'customOverlayKushki', modal: 'customModalKushki' };
const PSModalKushki = React.forwardRef(
  ({ onClose, openModal, formik, loading, llavePublica, errorApi, onChangeForm }, ref) => {
    const [errorExpiry, setErrorExpiry] = useState('');
    const [sixDigitsCard, setSixDigitsCard] = useState(null);
    const [typeCard, setTypeCard] = useState(null);

    const formattedDateExpiry = (value) => {
      let phoneNumber = value.replace(/\D/g, '');
      phoneNumber = phoneNumber.slice(0, 4);

      const phoneNumberLength = phoneNumber.length;

      if (phoneNumberLength > 2) {
        phoneNumber = `${phoneNumber.slice(0, 2)}/${phoneNumber.slice(2)}`;
      }
      if (phoneNumber.length === 5) {
        const errorMessage = validationsExpiryDate(phoneNumber);
        console.log('🚀 ~  errorMessage:', errorMessage);
        setErrorExpiry(errorMessage);
      }
      return phoneNumber;
    };

    const handleCardExpiryChange = (event) => {
      onChangeForm();
      const formatted = formattedDateExpiry(event.target.value);
      formik.setFieldValue('cardExpiry', formatted);
    };

    const handleCardCVVChange = (event) => {
      onChangeForm();
      formik.setFieldValue('cardCvv', event.target.value);
    };

    const handleCardNumberChange = async (event) => {
      onChangeForm();
      const { value } = event.target;
      formik.setFieldValue('cardNumber', value);
      await getTypeCard(value);
    };

    const getTypeCard = async (cardNumber) => {
      const sixDigitsCurrent = cardNumber.substring(0, 6);
      if (cardNumber.length > 5 && sixDigitsCurrent !== sixDigitsCard) {
        setSixDigitsCard(sixDigitsCurrent);
        const response = await DevolutionLyfe.getBinInfo(cardNumber, llavePublica);
        showIconByCardType(response?.brand);
      } else if (sixDigitsCard === null && cardNumber.length > 5) {
        setSixDigitsCard(sixDigitsCurrent);
      } else if (cardNumber.length <= 5) {
        setSixDigitsCard(null);
        setTypeCard(null);
      }
    };

    const showIconByCardType = (tipoTarjeta) => {
      if (['visa', 'mastercard', 'amex'].includes(tipoTarjeta)) {
        setTypeCard(tipoTarjeta);
      } else setTypeCard('unknow');
    };

    return (
      <div ref={ref}>
        <PSModal open={openModal} showCloseIcon classNames={classNamesKushki}>
          <div className="container-modal-kushki">
            <div className="modal-header">
              <div>
                <span>EN ESP </span>
                <PSIcon type="close" className="modal-close-icon" onClick={onClose} />
              </div>    
            <PSIcon className="modal-logo" type="logoProtecta" />
            </div>

            <div className="modal-body">
              <form id="payment-form" onSubmit={formik.handleSubmit}>
                <div className="container-input">
                  <PSIcon type="cardNumber" />
                  <input
                    placeholder="Número de Tarjeta"
                    type="text"
                    name="cardNumber"
                    maxLength="16"
                    onKeyPress={(e) => maxLengthCheck(e)}
                    {...formik.getFieldProps('cardNumber')}
                    onChange={handleCardNumberChange}
                  />
                  {typeCard && <img src={`${pathServer.PATH_PAYMENT}${typeCard}.png`} />}
                </div>
                <PSError className="error-message">
                  {formik.touched.cardNumber && formik.errors.cardNumber}
                </PSError>

                <div className="d-flex">
                  <div>
                    <div className="container-input">
                      <PSIcon type="cardExpiry" />
                      <input
                        placeholder="MM/AA"
                        type="text"
                        name="cardExpiry"
                        maxLength="5"
                        {...formik.getFieldProps('cardExpiry')}
                        onChange={handleCardExpiryChange}
                      />
                    </div>
                    <PSError className="error-message">
                      {formik.touched.cardExpiry
                        ? isEmpty(formik.errors.cardExpiry)
                          ? errorExpiry
                          : formik.errors.cardExpiry
                        : ''}
                    </PSError>
                  </div>

                  <div>
                    <div className="container-input">
                      <PSIcon type="cardCvv" />
                      <input
                        placeholder="CVV"
                        type="text"
                        name="cardCvv"
                        maxLength="4"
                        {...formik.getFieldProps('cardCvv')}
                        onChange={handleCardCVVChange}
                      />
                      <PSIcon type="cardDoubt" />
                    </div>
                    <PSError className="error-message">
                      {formik.touched.cardCvv && formik.errors.cardCvv}
                    </PSError>
                  </div>
                </div>

                <div className="modal-button">
                  <PSButton loading={loading} type="submit">
                    Actualizar
                  </PSButton>
                </div>
                {errorApi && <PSError className="error-message">{errorApi}</PSError>}
              </form>
            </div>
            <div className="modal__footer">
              <PSIcon className="pci" type= "pciDss" />
              <PSIcon type= "logoCards" />
            </div>
          </div>
        </PSModal>
      </div>
    );
  }
);

PSModalKushki.displayName = 'PSModalKushki';

PSModalKushki.defaultProps = {
  className: '',
  title: '¡Ups!',
  message: 'Estamos trabajando para reestablecer nuestros sistemas pronto.',
  buttonText: 'Aceptar',
  icon: 'broken',
  onClick: () => {},
  openModal: true,
  loading: false,
};

PSModalKushki.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  openModal: PropTypes.bool,
  loading: PropTypes.bool,
  buttonText: PropTypes.string,
  icon: PropTypes.string,
};

export default PSModalKushki;
