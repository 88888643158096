import React, { useState, useEffect } from 'react';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';

import filter from 'loadsh/filter';
import groupBy from 'loadsh/groupBy';
import map from 'loadsh/map';
import jsPDF from 'jspdf';
import Modal from 'react-responsive-modal';
import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSLabel from '../../components/PSLabel';
import PSGrayDownloadFiles from '../../components/PSGrayDownloadFiles';
import PSSelect from '../../components/PSSelect';
import { getCurrentYear, getLastThreeYear, getMes } from '../../../util/Util';
import SendBoletasService from '../../shared/services/SendBoletasService';
import PSButton from '../../components/PSButton';
import PSMessageResponse from '../../components/PSMessageResponse';
import { GAProvider } from '../../components/PSAnalytics';
import GetBoletasRRVVServices from '../../shared/services/GetLisBoletasRRVV';
import { PSFileBoleta } from '../../components/organisms';
import PSFileBoleta2 from '../../components/organisms/PSFileBoleta2/PSFileBoleta2';

const img = 'download-ticket';
const imgMobile = 'download-ticket-mobile';

export default function TicketRAT(props) {
  const dispatch = useDispatch();
  const { token, userInfo, attributesCore } = useSelector((state) => state.auth);
  const [listTickets, setListTickets] = useState([]);
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const [currentBeneficiary, setCurrentBeneficiary] = useState('');
  const [optionsBeneficiary, setOptionsBeneficiary] = useState([]);
  const [listTicketsSendEmail, setListTicketsSendEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const ga = React.useContext(GAProvider);
  const [currentBoleta, setCurrentBoleta] = useState(null);
  const [type, setType] = useState(null);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
  const responseBoletas = async (type, boleta) => {
    const idBoleta = type === 'rrvv' ? 'canvas-wrapper-content-rrvv' : 'canvas-wrapper-content';
    const messageEvent =
      type === 'rrvv'
        ? 'Rentas Vitalicias'
        : type === 'ahorroTotal'
        ? 'Liquidacion de Pago de Ahorro Total'
        : ' Liquidacion de Pago de Renta Total';
    const product =
      type === 'rrvv'
        ? 'Rentas Vitalicias'
        : type === 'ahorroTotal'
        ? 'Ahorro Total'
        : 'Renta Total';
    const option = type === 'rrvv' ? 'Boletas' : 'Liquidación de Pago';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${product}-${option}`,
      action: 'download',
      label: `El usuario descargó boletas de ${messageEvent}`,
      value: 1,
    });
    const pdf = new jsPDF('p', 'pt', 'a4');
    const nameBase =
      type === 'rrvv'
        ? 'boleta_rrvv'
        : type === 'ahorroTotal'
        ? 'liquidacionDePago_at'
        : 'liquidacionDePago_rt';
    const documentReceptor = boleta ? boleta.receiver.document.number : '08228344';
    const date = boleta ? boleta.period : '202001';
    const name = `${nameBase}_${documentReceptor}_${date}`;
    const documento = document.getElementById(idBoleta);
    const clonDocumento = documento.cloneNode(true);
    clonDocumento.classList.remove('hidden-content-force');
    clonDocumento.classList.add('download-file-boleta');
    await pdf.html(clonDocumento, {
      callback(pdf) {
        pdf.save(name);
      },
    });
    clonDocumento.classList.add('hidden-content-force');
    setTimeout(() => {
      alert('Su archivo se ha descargado con éxito');
    }, 1000);
  };
  const sendToEmail = async () => {
    try {
      setLoading(true);
      const res = await SendBoletasService.responseFilesRentas(
        listTicketsSendEmail,
        attributesCore,
        type
      );

      if (res.statusCode === 200) {
        setOpenModal(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getCurrentListTickets = () =>
    filter(listTickets, (list) => {
      return optionsBeneficiary.length > 2
        ? list.year === currentYear.toString() &&
            list.beneficiaryName.toString() === currentBeneficiary
        : list.year === currentYear.toString();
    });

  const goBack = () => {
    props.history.goBack();
  };

  const selectTicketsToSend = (isSelect, id) => {
    if (isSelect) {
      setListTicketsSendEmail([...listTicketsSendEmail, { id }]);
    } else {
      const filterListTickets = filter(listTicketsSendEmail, (list) => {
        return list.id !== id;
      });
      setListTicketsSendEmail([...filterListTickets]);
    }
  };

  const onClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setListTicketsSendEmail([]);
  }, [currentYear, currentYear]);

  useEffect(() => {
    const typeProduct = props.location.pathname.split('/')[3];
    const product =
      typeProduct === 'rrvv'
        ? 'Rentas Vitalicias'
        : typeProduct === 'ahorroTotal'
        ? 'Ahorro Total'
        : 'Renta Total';
    const option = typeProduct === 'rrvv' ? 'Boletas' : 'Liquidación de Pago';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${product}-${option}`,
      action: 'click',
      label: `El usuario visitó la página de ${option}`,
      value: 1,
    });
  }, [ga]);

  const comprobante = async () => {
    const type = props.location.pathname.split('/')[3];
    const polocyId = props.match.params.id;

    setLoadingData(true);
    const response = await GetBoletasRRVVServices.responseGetComprobantePoliza(
      currentYear,
      polocyId.padStart(10, '0'),
      type
    );

    let list = response.map((item) => {
      return {
        id: item.id,
        beneficiaryName: `${item.beneficary.firstName} ${
          item.beneficary.middleName ? item.beneficary.middleName : ''
        } ${item.beneficary.lastName} ${item.beneficary.secondLastName}`,
        description: getMes(item.period.substring(4, 6)),
        year: item.period.substring(0, 4),
        document: item.receiver.document.number || '-',
        type: item.type || '-',
      };
    });
    list = validateIsBeneficiary(list);
    setListTickets([...list]);

    const optionsBeneficiario = map(groupBy(list, 'beneficiaryName'), (item, index) => {
      return { value: index, label: item[0].beneficiaryName };
    });

    optionsBeneficiario.unshift({ value: '', label: 'Seleccione beneficiario' });
    setOptionsBeneficiary(optionsBeneficiario);
    setLoadingData(false);
  };

  const getDetailBoleta = async (file) => {
    const type = props.location.pathname.split('/')[3];
    const response = await GetBoletasRRVVServices.responseGetComprobantePolizaDetail(file, type);
    setCurrentBoleta(response);
    return response;
  };

  const validateIsBeneficiary = (list) => {
    const isBeneficiary = list.find((l) => l.document === userInfo.document) || false;
    if (isBeneficiary) {
      return list.filter((l) => l.document === userInfo.document);
    }
    return [];
  };

  const downloadBoletas = async (id) => {
    const type = props.location.pathname.split('/')[3];
    try {
      const nameBase =
        type === 'rrvv'
          ? 'boleta_rrvv'
          : type === 'ahorroTotal'
          ? 'liquidacionDePago_at'
          : 'liquidacionDePago_rt';

      const documentReceptor = currentBoleta ? currentBoleta.receiver.document.number : '08228344';
      const date = currentBoleta ? currentBoleta.period : '202001';
      const name = `${nameBase}_${documentReceptor}_${date}`;
      await GetBoletasRRVVServices.responseGetDocuments(id, type, name);
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      const messageEvent =
        type === 'rrvv'
          ? 'Rentas Vitalicias'
          : type === 'ahorroTotal'
          ? 'Liquidacion de Pago de Ahorro Total'
          : ' Liquidacion de Pago de Renta Total';
      const product =
        type === 'rrvv'
          ? 'Rentas Vitalicias'
          : type === 'ahorroTotal'
          ? 'Ahorro Total'
          : 'Renta Total';
      const option = type === 'rrvv' ? 'Boletas' : 'Liquidación de Pago';

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${product}-${option}`,
        action: 'download',
        label: `El usuario descargó boletas de ${messageEvent}`,
        value: 1,
      });
      setTimeout(() => {
        alert('Su archivo se ha descargado con éxito');
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    comprobante();
  }, [currentYear]);

  useEffect(() => {
    const type = props.location.pathname.split('/')[3];
    setType(type);
  }, []);

  useEffect(() => {
    setListTicketsSendEmail([]);
  }, [currentBeneficiary]);

  return (
    <div className="ticket-container">
      <div className="header">
        <PSHeaderIntranet
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description={type === 'rrvv' ? 'Mis boletas' : 'Liquidación de pago'}
          icon="findTicket"
          {...props}
        />
      </div>
      <div className="ticket-body">
        <div className="section-titulo">
          <div className="section-title-first-part">
            <PSLabel className="titulo titleHome">Boletas</PSLabel>
            <PSSelect
              className="select-year"
              options={getLastThreeYear()}
              selectedValue={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
            />
          </div>
          <div className="section-title-second-part">
            {optionsBeneficiary.length > 2 && (
              <PSSelect
                className="select-beneficiary"
                options={optionsBeneficiary}
                selectedValue={currentBeneficiary}
                onChange={(e) => setCurrentBeneficiary(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="list-cardCalendar">
          {getCurrentListTickets().map((list) => (
            <div key={list.id}>
              <PSGrayDownloadFiles
                withCheck
                type={list.type ? list.type : ''}
                title={list.description}
                subtitle={list.year}
                onClick={() => {
                  downloadBoletas(list.id);
                }}
                onCheck={(e) => selectTicketsToSend(e, list.id)}
              />
            </div>
          ))}
        </div>
        {!loadingData && getCurrentListTickets().length === 0 && (
          <div className="not-boletas">
            <p>No hemos encontrado boletas disponibles en el periodo seleccionado.</p>
          </div>
        )}

        {attributesCore?.email && getCurrentListTickets()?.length > 0 && (
          <div className="align-center">
            <PSButton
              className={`button-big btn-submit${
                listTicketsSendEmail.length === 0 ? ' button-disabled' : ''
              }`}
              loading={loading}
              disabled={attributesCore.email && listTicketsSendEmail.length === 0}
              onClick={sendToEmail}
            >
              Compartir por correo
            </PSButton>
          </div>
        )}
      </div>
      {type === 'rentaTotal' || type === 'ahorroTotal' ? (
        <PSFileBoleta2
          id="canvas-wrapper-content"
          boleta={currentBoleta}
          type={type === 'ahorroTotal' ? 1 : 2}
        />
      ) : type === 'rrvv' ? (
        <PSFileBoleta id="canvas-wrapper-content-rrvv" boleta={currentBoleta} />
      ) : null}
      {openModal && (
        <Modal
          animationDuration={0}
          open={openModal}
          onClose={onClose}
          showCloseIcon={false}
          onOverlayClick={onClose}
        >
          <PSMessageResponse icon="checkMail" onClose={onClose}>
            <p>Las(s) boletas(s) seleccionadas(s) ha(n) sido enviada(s) a tu correo registrado:</p>
            <p>
              <b>{attributesCore.email}</b>
            </p>
          </PSMessageResponse>
        </Modal>
      )}
    </div>
  );
}
