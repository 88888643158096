import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSButton from './PSButton';

const StyledPSRegisterPolicy = styled.div`
  margin: auto;
  .container-icons-register {
    display: flex;
    justify-content: center;
  }
  .container-message-register {
    text-align: center;
    margin: 0rem 1rem 2rem 1rem;
    label {
      color: #2b0d61;
      font-size: 17px;
      font-family: 'BlissPro-Regular', sans-serif;
    }
    .message-pending-claim label {
      font-size: 16px;
    }
    a {
      color: #2b0d61 !important;
      font-weight: bold;
      font-size: 17px;
      font-family: 'BlissPro-Regular', sans-serif;
    }
  }
  .container-message-button {
    width: 300px;
    margin: auto;
  }
`;

const PSOptionRegisterPolicy = ({ message, textButton, url, withLink, withButton, isClaim }) => {
  return (
    <StyledPSRegisterPolicy>
      {/* <div className="container-icons-register"> */}
      {/* <PSIcon type="optionChurch" className="m-1" /> */}
      {/* <PSIcon type="optionHeart" className="m-1" /> */}
      {/* <PSIcon type="optionPercentage" className="m-1" /> */}
      {/* <PSIcon type="optionRuleta" className="m-1 display-none-response" /> */}
      {/* </div> */}

      <div className="container-message-register">
        <div>
          <label>
            {`Estimado cliente,`}
            {message}
          </label>
        </div>
        {withLink && (
          <div>
            <label>
              Te invitamos a conocer más de nuestros seguros en{' '}
              <a href="https://protectasecurity.pe/" target="_black">
                www.protectasecurity.pe
              </a>
            </label>
            <br />
            <label>
              o si lo prefieres comunícate a nuestra Central de Servicio al Cliente al 391-3000 en
              Lima o 0-801-1-1278 en Provincias.
            </label>
          </div>
        )}

        {isClaim && (
          <div className="message-pending-claim d-block my-0 mt-1">
            <label>
              *En caso hayas generado tu reclamo por la Net Privada, podrás visualizar tu registro
              en esta sección en un plazo no mayor de 48 horas hábiles.
            </label>
            <label>
              Asimismo, agradecemos considerar que nuestro horario de atención es de Lunes a Viernes
              de 09:00 am a 06:00 pm, excepto feriados.
            </label>
          </div>
        )}
      </div>
      {withButton && (
        <div className="container-message-button">
          <PSButton onClick={() => window.open(url)}>{textButton}</PSButton>
        </div>
      )}
    </StyledPSRegisterPolicy>
  );
};

PSOptionRegisterPolicy.defaultProps = {
  message: 'no hemos encontrado pólizas vigentes.',
  textButton: '¡Miralos aquí!',
  url: 'https://protectasecurity.pe/productos/',
  withLink: true,
  withButton: true,
  isClaim: false,
};

PSOptionRegisterPolicy.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textButton: PropTypes.string,
  url: PropTypes.string,
  withLink: PropTypes.bool,
  withButton: PropTypes.bool,
  isClaim: PropTypes.bool,
};

export default PSOptionRegisterPolicy;
