
import {doGetWithEntity, doPost} from './Api';

export default class AdvertisingService {
  static async getAdvertising() {
    const response = await doGetWithEntity(`${process.env.REACT_APP_ADVERTISING}/promotions/client/20000001`,'promotions');
    return response.data;
  }
}


