import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import styled from 'styled-components';

import CouponSoatServices from '../../../shared/services/CouponSoatServices';

import PSIcon from '../../PSIcon';
import PSLoading from '../../PSLoading';

import './index.scss';

const StyledTitle = styled.div`
  .discount-number {
    font-size: ${(props) => props.fontSizeMainTitle}rem;
    line-height: ${(props) => props.fontSizeMainTitle}rem;
  }
  .discount-txt {
    font-size: ${(props) => props.fontSizeSecondaryTitle}rem;
    line-height: ${(props) => props.fontSizeSecondaryTitle}rem;
  }
`;

const PSCouponCard = ({ isMyCoupon, data, onClickDeleteCoupon, onClickShowCodeCoupon }) => {
  const [responseImage, setResponseImage] = useState(null);
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
  const urlImage = isMobile ? data.iconMain.urlImageMobile : data.iconMain.urlImageWeb;
  const urlImageGeneric = urlImage || data.iconMain.urlImageDefault;
  const loadImages = async () => {
    const responseImage = await CouponSoatServices.responseGetImages(urlImageGeneric);
    setResponseImage(responseImage);
  };
  useEffect(() => {
    loadImages();
  }, [data]);
  const lengthMain = data?.principalTitle.length;
  const lengthSecondaty = data?.secondaryTitle.length;
  const fontSizeMainTitle = lengthMain < 4 ? 2.6 : lengthMain < 10 ? 2.2 : 1.8;
  const fontSizeSecondaryTitle = lengthSecondaty < 12 ? 1.5 : lengthSecondaty < 20 ? 1.2 : 1.1;

  return (
    <div className="coupon-card">
      <div className="coupon-card__container">
        <div className={`coupon-card__header ${isMyCoupon ? 'd-flex' : 'd-none'} `}>
          {/*  TODO : temporary change, to be confirmed */}
          <div className="d-flex header-left">
            <PSIcon type="checkCircle" />
            <p> Vigente hasta el 31/12/{data.endDate.slice(-4)} </p>
          </div>
          <div className="d-flex header-right">
            <PSIcon type="checkVerified" />
          </div>
        </div>
        <div className="coupon-card__body">
          <div className="card-left">
            {responseImage ? (
              <img src={responseImage} alt={data.name} />
            ) : (
              <PSLoading width={40} height={40} color="#2B0D61" />
            )}
          </div>
          <div className="card-right">
            <StyledTitle
              className="discount-title"
              fontSizeMainTitle={fontSizeMainTitle}
              fontSizeSecondaryTitle={fontSizeSecondaryTitle}
            >
              <p className="discount-number">{data?.principalTitle}</p>
              <p className="discount-txt"> {data?.secondaryTitle}</p>
            </StyledTitle>
            <div className="discount-company"> {data?.companyName}</div>
            <div className="discount-text">
              <ul>
                {map(data?.shortDescription, (item) => (
                  <li key={item.paragraph}> - {item?.paragraph}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="coupon-card__footer">
          <div className={`footer-content ${isMyCoupon ? 'd-none' : 'd-flex'} `}>
            {data?.availableCoupons === 1 ? (
              <div className="d-flex align-items-center coupons-availability">
                <PSIcon type="couponSmall" />
                <b>{data?.availableCoupons} disponible</b>
              </div>
            ) : (
              <div className="d-flex align-items-center coupons-availability">
                <PSIcon type="couponSmall" />
                <b>{data?.availableCoupons} disponibles</b>
              </div>
            )}
            <div className="text-right">
              Válido del {data?.startDate} al {data?.endDate}
            </div>
          </div>
          <div className={`button-content ${!isMyCoupon ? 'd-none' : ''} `}>
            <button type="button" onClick={() => onClickShowCodeCoupon(data)}>
              Ver Código
            </button>
          </div>
        </div>
      </div>
      <div className="coupon-card__delete d-none" onClick={() => onClickDeleteCoupon(data)}>
        <PSIcon type="closePurple" />
      </div>
      <div className={` coupon-card__finished d-none`}>
        <div className="finished-content">
          <PSIcon type="couponWhite" />
          <p> AGOTADO !</p>
        </div>
      </div>
    </div>
  );
};

PSCouponCard.displayName = 'PSCouponCard';

PSCouponCard.defaultProps = {
  isMyCoupon: false,
};

PSCouponCard.propTypes = {
  isMyCoupon: PropTypes.bool,
};

export default PSCouponCard;
