import React,{ useState }  from 'react';
import './typeOne.scss';
import PropTypes from 'prop-types';


const TypeTwo= React.forwardRef(({ className }, ref) => {
  const urlBelcorp ='https://somosbelcorp.com/';

  return (
    <div className="container-typeTwo" ref={ref}>
      <div style={{display: 'none', maxHeight: 0, overflow: 'hidden'}}>
        Conoce más de tu póliza Vida Mi Familia de Protecta Security.
      </div>
      <div style={{display: 'none', maxHeight: 0, overflow: 'hidden'}}>
        &nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌&nbsp;‌
      </div>
      <table bgcolor="#ffffff" align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" width="100%" style={{marginTop:10}}>
        <tbody><tr>
          <td>
            <table className='responsive-table' bgcolor="#ffffff" align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" width={700} height={700}>
              <tbody><tr>
                <td className="td-img" bgcolor="#FFFFFF" align="center" width="50%" style={{fontFamily: 'Tahoma, Arial, sans-serif', padding: '0px'}}>
                  <img src="https://i.ibb.co/9VM94Sk/BANNER-Comunicaci-n-y-p-liza.png" alt="BANNER-Comunicaci-n-y-p-liza" border={0} width="100%" height="100%" cellPadding={0} cellSpacing={0} />
                </td>
                <td bgcolor="#e9e7eb" width="50%" align="justify" style={{color: '#2e0563', fontFamily: 'Tahoma, Arial, sans-serif', fontWeight: 'normal', fontSize: '14px', lineHeight: '20px', padding: '60px 20px 20px 20px'}}>
                  En esta oportunidad te invitamos a conocer tu <span style={{color: '#2e0563', fontSize: '14px', fontFamily: 'Tahoma, Arial, sans-serif', fontWeight: 'bold'}}>nueva póliza Vida Mi Familia del programa Familia Protegida</span>.<br /><br />
                  Descarga tu nueva póliza desde donde estés, ingresa a tu producto Vida Mi Familia, selecciona tu póliza y descarga el PDF que se muestra en pantalla. También podrás acceder a tu póliza desde el portal SomosBelcorp a través del siguiente <a href={urlBelcorp} target="_blank" style={{color: '#FF6E00', fontSize: '14px', fontFamily: 'Tahoma, Arial, sans-serif', fontWeight: 'bold', textDecoration: 'none'}}>enlace</a>.<br /><br />
                  Para cualquier consulta sobre tu póliza contáctanos en Lima al 391-3000 y en Provincias al 0801-1-1278, escríbenos al buzón de correo electrónico: <a href="mailto: clientes@protectasecurity.pe" style={{color: '#2b0d61', fontWeight: 'bold', textDecoration: 'none', fontSize: '14px'}}> clientes@protectasecurity.pe</a> y al canal de atención de WhatsApp +51 945811540. Nuestro horario de atención es de lunes a viernes de 9 a.m a 6 p.m.<br /><br />
                </td>
              </tr>
              </tbody></table>
          </td>
        </tr>
        </tbody></table>
    </div>
  );
  }
);

TypeTwo.displayName = 'TypeTwo';

TypeTwo.defaultProps = {
  className: '',

};

TypeTwo.propTypes = {
  className: PropTypes.string,
};

export default TypeTwo;
