import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PSIcon from './PSIcon';
import Dropzone from 'react-dropzone';

const StyledPSInputFile = styled.div`
   background: #ffff;
   border-radius: 4px;
   height: 42px;
   .container-input-file{
      outline: none;
      display: flex;
      justify-content: space-between;
      .container-text{
        text-align: left;
        padding-top: 0.5rem;
        padding-left: 0.8rem;
        font-family: 'BlissPro-Regular' , sans-serif;
        color: #7a7a7a;
        outline: none;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
     }
     .container-icon{
      padding-top: 8px;
      padding-right: 10px;
     }
   }
`;
const StyledPSContainer = styled.div`
   margin: 4px 2px;
   .label-inputFile{
      color: #fff;
      font-size: 13px;
    
   } 
`


const PSInputFile = React.forwardRef(({children, label, observation, onChangeFile,fileProps, ...props }, ref) => {

  const [file,setFile] = useState({})
  const onDrop = (e,file) => {
    if(file.length>0){
      setFile(file[0].file);
      onChangeFile(file[0].file);
    }
  }
  useEffect(()=>{
    if(fileProps !==null || fileProps !== undefined  ){
      setFile(fileProps);
    }

  },[fileProps])

  return (
    <StyledPSContainer>
      <div className="label-inputFile">{label}</div>
      <StyledPSInputFile  ref={ref}>
        <Dropzone maxSize={2048} accept=".jpeg,.jpg,.png, .pdf" multiple={false} onDragEnter={(e)=>{console.log("valores",e)}}
                  onDrop={onDrop}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className={'container-input-file'}>
              <input {...getInputProps()} />
              <p className={"container-text"}>{file && file.name ?file.name : 'Seleccionar archivo' }</p>
              <PSIcon type={'attach'} className="container-icon"/>
            </div>
          )}
        </Dropzone>

      </StyledPSInputFile>
      <div className="label-inputFile">{observation}</div>
    </StyledPSContainer>
    )
});

PSInputFile.displayName = 'PSInputFile';

PSInputFile.defaultProps = {

};

PSInputFile.propTypes = {

};

export default PSInputFile;
