import React, { useState, useEffect } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import { Routes } from '../../../routes';
import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';
import GetTicket from '../../shared/services/TicketServices';
import PSViewPDF from '../../components/PSViewPDF';

const img = 'update-data';
const imgMobile = 'update-data-mobile';

const headerTableFather = [
  { elementProp: 'codigo', headerColumn: 'Nro. de Reclamo' },
  { elementProp: 'poliza', headerColumn: 'Nro. Póliza/ Certificado' },
  { elementProp: 'ramo', headerColumn: 'Ramo' },
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'fecRecepcion', headerColumn: 'Fecha de recepción' },
  { elementProp: 'fecRegistro', headerColumn: 'Fecha de registro' },
  { elementProp: 'viaRecepcion', headerColumn: 'Vía de recepción' },
  { elementProp: 'estado', headerColumn: 'Estado' },
  { elementProp: 'viewPDF', headerColumn: 'Ver respuesta' },
];

const data = [
  {
    nroClaims: '123546789‬',
    nroPolicy: 145,
    ramo: 'Accidente P.',
    product: 'Accidente P.',
    dateReception: '15/04/2019',
    dateRegister: '15/04/2019',
    via: 'Telefónica',
    state: 'En Análisis',
  },
  {
    nroClaims: '123546789‬',
    nroPolicy: 45,
    ramo: 'Accidente P.',
    product: 'Accidente P.',
    dateReception: '15/04/2019',
    dateRegister: '15/04/2014',
    via: 'Mail',
    state: 'Rechazado',
  },
  {
    nroClaims: '123546789‬',
    nroPolicy: 227,
    ramo: 'SOAT',
    product: 'SOAT',
    dateReception: '15/05/2019',
    dateRegister: '17/04/2015',
    via: 'Mail',
    state: 'Aprobado',
  },
];

export default function HomeClaims(props) {
  const [loading, setLoading] = useState(false);
  const [listClaim, setListClaim] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;

  const ga = React.useContext(GAProvider);

  const loadList = async () => {
    try {
      setLoading(true);
      const { statusCode, data: responseData } = await GetTicket.responseGetTickets();
      if (statusCode === 200 && responseData) {
        const listData = responseData
          .filter((item) => item.codigo.search(new RegExp('REC')) !== -1)
          .map((item) => ({
            ...item,
            viewPDF: (
              <PSViewPDF
                isEnabled={item.estado === 'Cerrado 360'}
                code={item.codigo}
                type="REC"
                key={item.codigo}
              />
            ),
          }));
        setListClaim(listData.reverse());
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Consulta de reclamos`,
      action: 'click',
      label: 'El usuario visitó la página de Estado de Reclamos',
      value: 1,
    });
  }, [ga]);

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div className="home-stateClaim-container">
      <PSBodyProduct
        questions={FrequentQuestions.AHORRO_TOTAL}
        coverages={Coverage.AHORRO_TOTAL}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader="Consulta de estado de reclamos"
        messageToProduct="no tenemos reclamos registrados a tu nombre."
        withButtonToProduct={false}
        loading={loading}
        data={listClaim}
        showCheck={false}
        complementInfo="(solo reclamos con certificado)"
        headerTable={headerTableFather}
        isClaim
        {...props}
      />
    </div>
  );
}
