import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { COUPON_TYPE } from '../../../../util/Constant';
import ClientsService from '../../../shared/services/ClientsService';
import DevolutionLyfe from '../../../shared/services/DevolutionLyfe';
import { GAProvider } from '../../PSAnalytics';
import {
  formatCurrency,
  getAddReceipts,
  isEmpty,
  sortCards,
  transformArray,
  typeErrorAction,
  urlLinkCoupon,
} from '../../../../util/Util';
import { Card } from '../../../shared/model/Card';

import PSLoading from '../../PSLoading';
import PSButtonIcon from '../../PSButtonIcon';
import PSModalPayment from '../../organisms/PSModalPayment/PSModalPayment';
import PSTableCoupon from '../../PSTableCoupun';

import './index.scss';

export const COUPON_TEXT = {
    2: {
        title: 'Código de pago',
        description: 'El codigo de pago',
        buttonText: 'Ver código',
    },
    4: {
        title: 'Link transferencia',
        description: 'El link de transferencia',
        buttonText: 'Abrir link',
    },
    5: {
        title: 'Link de pago con tarjeta',
        description: 'El link de pago con tarjeta',
        buttonText: 'Abrir link',
    },
}
export const ERROR_TEXT = {
    ID_PASARELA : 'La pasarela de pago no es valida',
    ID_MONEDA : 'Se encontro un error en el tipo de moneda',
    URL : 'No se pudo generar el link de pago',
    COUPON : 'No se pudo generar el cupón',
    DEFAULT : 'No registra cupones',
} 
const headerTable = [
  { elementProp: 'effecDate', headerColumn: 'Mes de cobertura' },
  { elementProp: 'limitDate', headerColumn: 'Fecha máxima de pago' },
  { elementProp: 'premiun', headerColumn: 'Importe (S/)', currency: '1' },
  { elementProp: 'premiun', headerColumn: 'Importe ($)', currency: '2' },
  { elementProp: 'cuponVigente', headerColumn: 'Estado' },
];

const PSExpiredCoupons = React.forwardRef(
  ({ poliza, onCloseOption, optionDescription, descriptionHeader }, ref) => {
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const [receipts, setReceipts] = useState([]);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [textError, setTextError] = useState('');
  const [typeError, setTypeError] = useState(null);
  const [coupunPremiun , setCoupunPremiun] = useState(null);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showCouponProcess, setShowCouponProcess] = useState(false);
  const [showPendingCoupon, setShowPendingCoupon] = useState(false);
  const [hashMoney, setHashMoney] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [typeCoupon, setTypeCoupon] = useState(null);
  const [hashSelect, setHashSelect] = useState(false);
  const [clients, setClients] = useState([]);
  const [idMoney, setIdMoney] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [listSelect, setListSelect] = useState([]);
  const { rol, id, nroCertificate, idProduct, nbranch, nroPolicy } = poliza;
  const [useKushki, setUseKushki] = useState(false);
  const headerTableFilter = headerTable.filter(({ currency }) => !currency || currency == idMoney);
  
  const initialCoupon = {
    branchId: parseInt(nbranch, 10),
    productId: parseInt(idProduct, 10),
    currency: Number(idMoney),
    policy: nroPolicy,
    clientId: clients,
    coupon: {
      id: typeCoupon,
    },
  };

  const couponDisabled = () => {
    setShowCouponProcess(true);
  };
  const handleError = (typeError) => {
    setTextError(ERROR_TEXT[typeError]);
    setTypeError(typeError);
    setShowModalError(true);
  }

  const handleCouponType = (TYPE_NUMBER_COUPON) => {  
    let premiun = getAddReceipts(cards , receipts);
    setCoupunPremiun(premiun);
    setTypeCoupon(TYPE_NUMBER_COUPON);
    setShowModalSuccess(true);
  };
  const loadData = async () => {
    try {
        setLoading(true);
      const headerParams = {
        id,
        certificate: nroCertificate,
      };

      const cardResponse = await DevolutionLyfe.getExpiredCoupons(headerParams);
      if (cardResponse) {
        const cards = sortCards(cardResponse,idMoney);
        cards.length > 0 ? setCards(cards) : handleError('DEFAULT');
      } else handleError('DEFAULT');

      
    } catch (error) {
      handleError('DEFAULT');
    } finally {
      setLoading(false);
    }
  };

  const onSelectCoupon = (index, checked) => {
    const nextEnabled = cards.find((c) => !c.isDisabled && !listSelect.includes(c?.id))?.id;
    if (checked && (index === nextEnabled) ) {
        setListSelect([...listSelect, index]);  
    } 
    if(!checked){
      const isLastItem = index === listSelect[listSelect.length - 1];
      if (isLastItem) {
        const currentList = [...listSelect];
        const updated = currentList.filter((e) => e !== index);
        setListSelect(updated);
      }
    }
    if (index !== nextEnabled && checked) {
        setShowPendingCoupon(true);
      }  
  };
  const closeModal = () => {
    setListSelect([]);
    setCards([])
    setTextError('');
    setShowModalSuccess(false);
    setShowCouponProcess(false);
    setShowPendingCoupon(false);
    setShowModalPayment(false);
    setShowModalError(false);
    loadData();
    };
  const closeModalError = () => {
      setShowModalError(false);
      onCloseOption();
  };

  const closeWarningSelected = () => setShowPendingCoupon(false);
    
  const handleCoupon = () => {
      const url = urlLinkCoupon(coupon, typeCoupon)
      setShowModalSuccess(false);
      if (url) window.open(url, '_blank');
      else {
          setShowModalPayment(false);
          setHashSelect(false)
          handleError('URL');
      }
  };

  const generateCoupon = async () => {
      try {
          setLoadingModal(true);
          const payload = {
            ...initialCoupon,
            receipts: transformArray(receipts, 'id'),
          };
          const { data, error } = await DevolutionLyfe.postPaymentDebit(payload);
          if (!data?.success && data?.message) setCoupon(data);
          if (data && data?.success) {
              setCoupon(data);
              setShowModalPayment(true);
              onGenerateAnalytics();
          } else handleError('DEFAULT')  
          if (data && isEmpty(data)) {
              setHashSelect(false)
              handleError('COUPON');
          }
          setShowModalSuccess(false);
      } finally {
          setLoadingModal(false);
      }
  };

  const onGenerateAnalytics = () => {
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    let paymentType = '';
    switch (typeCoupon) {
      case COUPON_TYPE.CASH_KUSHKI:
        paymentType = `efectivo.`;
        break;
      case COUPON_TYPE.SMART_LINK_TRANSFER:
        paymentType = `transferencia.`;
        break;
      case COUPON_TYPE.SMART_LINK_CARD:
        paymentType = `tarjeta.`;
        break;
      default:
        paymentType = `efectivo.`;
    }

    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${descriptionHeader}-${optionDescription}`,
      action: 'click',
      label: `El usuario pago su cuota pendiente por medio de ${paymentType}`,
      value: 1,
    });
  };

  const loadClients = async () => {
    const CLIENT = await ClientsService.responseGetClients();
    setClients(CLIENT?.data[0].P_SCLIENT);
  };

  const loadCard = async () => {
    setLoading(true);
    try {
       const paymentCards = await DevolutionLyfe.getCurrentCard(id);
       const newPaymentCard = new Card(paymentCards)
        const {idMoneda } = paymentCards;
        if (idMoneda ){ 
         setIdMoney(idMoneda);
         if (newPaymentCard.existPaymentGateway ) setUseKushki(newPaymentCard.useKushki); 
         else handleError('ID_PASARELA');
         if (newPaymentCard.typeMoneda)  setHashMoney( newPaymentCard.typeMoneda === 'USD' ); 
         else handleError('ID_MONEDA');      
        } else handleError('DEFAULT');
    }catch(error){
     handleError('DEFAULT');
    }finally {
        setLoading(false);
    }
  };

  useEffect(() => { 
    loadClients();
  }, []);

  useEffect(() => {
    setReceipts(listSelect);
    const hasInformation = listSelect.length > 0;
    setHashSelect(hasInformation);
  }, [listSelect, cards]);

  useEffect(() => {
    loadCard();
    if (!idMoney && rol === 'Asegurado') {
        alert('No está habilitado para esta opción');
        onCloseOption();
    }
    if(idMoney) loadData();
  }, [poliza, idMoney]);

  const validateData = !loading && !textError && cards.length > 0 ;
  return (
    <>
      <div className="container-updateCard" ref={ref}>
          <div>
            <div className="couponPending__container">
              { validateData && ( 
              <span className="couponPending__title">
               Para pagar , seleccione primero los cupones más antiguos.
              </span>)}
              {!loading && hashSelect && (
                <div className="container__couponPending">
                  <PSButtonIcon
                    disabled={!hashSelect || hashMoney} 
                    className="container__couponPending__btn1"
                    onClick={() => handleCouponType(COUPON_TYPE.CASH_KUSHKI)}
                    icon="kushkiCash"
                    tooltip={hashMoney ? 'Opción no disponible para dólares.' : ''}
                  >
                    Efectivo
                  </PSButtonIcon>
                  <PSButtonIcon
                    disabled={!hashSelect || hashMoney}
                    className="container__couponPending__btn2"
                    onClick={() => handleCouponType(COUPON_TYPE.SMART_LINK_TRANSFER)}
                    icon="kushkiTransfer"
                    tooltip={hashMoney ? 'Opción no disponible para dólares.' : ''}
                  >
                    Transferencia
                  </PSButtonIcon>
                  <PSButtonIcon
                    disabled={!hashSelect}
                    className="container__couponPending__btn3"
                    onClick={() => handleCouponType(COUPON_TYPE.SMART_LINK_CARD)}
                    icon="kushkiCard"
                  >
                    Tarjeta
                  </PSButtonIcon>
                </div>
              ) }
            </div>
            { validateData ? (
              <PSTableCoupon
                headers={headerTableFilter}
                items={cards}
                couponDisabled={couponDisabled}
                showCheckBox
                onSelect={onSelectCoupon}
                useKushki={useKushki}
                listSelect={listSelect}
              />
            ) : <div className="loading">
                <PSLoading />
            </div>
            }
          </div>
      </div>
      {showModalSuccess && typeCoupon === COUPON_TYPE.CASH_KUSHKI && (
        <PSModalPayment
          openModal={showModalSuccess}
          title={
            <span>
              Confirmar generación de pago
            </span>
          }
          description={
            <span>
              ¿Está seguro de generar un código por el monto de <br />  
              {formatCurrency(coupunPremiun,idMoney)} para que realice el pago en efectivo?<br /> 
            </span>
          }
          loading={loadingModal}
          cancelText="Cancelar"
          okText={loadingModal ? 'Generando...' : 'Si, estoy seguro'}
          onCancel={closeModal}
          onOk={generateCoupon}
        />
      )}
      {showModalSuccess && typeCoupon === COUPON_TYPE.SMART_LINK_TRANSFER && (
        <PSModalPayment
          openModal={showModalSuccess}
          title="Confirmar transferencia"
          description={
            <span>
              ¿Está seguro de generar un link de transferencia<br /> 
              por el monto de {formatCurrency(coupunPremiun,idMoney)}?<br /> 
            </span>
          }
          loading={loadingModal}
          cancelText="Cancelar"
          okText={loadingModal ? 'Generando...' : 'Si, estoy seguro'}
          onCancel={closeModal}
          onOk={generateCoupon}
        />
      )}
      {showModalSuccess && typeCoupon === COUPON_TYPE.SMART_LINK_CARD && (
        <PSModalPayment
          openModal={showModalSuccess}
          title="Confirmar pago con tarjeta"
          description={
            <span>
              ¿Está seguro de generar un link de pago con<br />
              tarjeta por el monto de {formatCurrency(coupunPremiun,idMoney)}?<br /> 
            </span>
          }
          loading={loadingModal}
          cancelText="Cancelar"
          okText={loadingModal ? 'Generando...' : 'Si, estoy seguro'}
          onCancel={closeModal}
          onOk={generateCoupon}
        />
      )}
      {
       showModalPayment && (
        <PSModalPayment
          openModal={showModalPayment}
          title={COUPON_TEXT[typeCoupon].title}
          description={
            <span>
                {COUPON_TEXT[typeCoupon].description} fue enviado a su correo <br/>
                electrónico. 
            </span>
          }
          onCancel={closeModal}
          closeModal={closeModal}
          onOk={handleCoupon}
          okText={COUPON_TEXT[typeCoupon].buttonText}
        />
        )
      }
      {showModalError && (
        <PSModalPayment
          openModal={showModalError}
          title="Cuotas pendientes"
          description={textError || coupon?.message}
          onCancel={()=>typeErrorAction(typeError ,closeModal ,closeModalError , coupon?.message)}
          closeModal={()=>typeErrorAction(typeError ,closeModal ,closeModalError , coupon?.message)}
          onOk={()=>typeErrorAction(typeError ,closeModal ,closeModalError , coupon?.message)}
          okText="Entendido"
        />
      )}
      {showCouponProcess && (
        <PSModalPayment
          openModal={showCouponProcess}
          title='Orden de pago en proceso'
          description=
          {
            <span>
                Usted tiene una orden de pago en proceso. Por <br />
                favor, espere antes de generar una nueva.
            </span>
          }
          onCancel={closeModal}
          closeModal={closeModal}
          onOk={closeModal}
          okText="Entendido"
        />
      )}
      {showPendingCoupon && (
        <PSModalPayment
          openModal={showPendingCoupon}
          title='Selección de cupones'
          description=
          {
            <span>
                Por favor, seleccione primero los cupones más<br />
                antiguos.
            </span>
          }
          onCancel={closeWarningSelected}
          closeModal={closeWarningSelected}
          onOk={closeWarningSelected}
          okText="Entendido"
        />
      )

      }
    </>
  );
});

PSExpiredCoupons.displayName = 'PSExpiredCoupons';

PSExpiredCoupons.defaultProps = {
  poliza: null,
  onCloseOption: null,
};

PSExpiredCoupons.propTypes = {
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
};

export default PSExpiredCoupons;
