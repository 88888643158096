import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import { getIcon } from '../../util/Util';

const StyledPsIcon = styled.div`
  margin: initial;
`;

const PSIcon = React.forwardRef(({ children, className, type, onClick, ...props}, ref) => (
  <StyledPsIcon {...props} ref={ref} className={className}>
    <ReactSVG src={getIcon(type)} onClick={onClick}>
      {children}
    </ReactSVG>
  </StyledPsIcon>
));

PSIcon.displayName = 'PSIcon';

PSIcon.defaultProps = {
  onClick: () => {},
  type: 'default',
  className: null,
};

PSIcon.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.elementType,
  className: PropTypes.string,
};

export default PSIcon;
