import React from 'react';
import { pathServer } from '../../../../util/Constant';
import PSBoletaRow, { BoletaRow } from '../../molecules/PSBoletaRow/PSBoletaRow';
import './index.scss';
import PropTypes from "prop-types";
import {getMes} from "../../../../util/Util";

const PSFileBoleta2 = React.forwardRef(({ id, className, boleta ,type}, ref) => {
  let currency, fullNameReceptor, documentReceptor, fullNameBeneficary, fullDirectionBeneficary2 , mounthDate, dateBoleta;
  if (boleta) {
    fullNameBeneficary = `${boleta.beneficary.firstName + ' '}${boleta.beneficary.middleName + ' '}${boleta.beneficary.lastName + ' '}${boleta.beneficary.secondLastName}`;
    fullDirectionBeneficary2 = `${ boleta.address.description + ''} ${boleta.address.district.description + '-'}  ${boleta.address.province.description  + '-' } ${boleta.address.region.description}`;

    fullNameReceptor = `${boleta.receiver.firstName + '  '}${boleta.receiver.middleName + ' '}${boleta.receiver.lastName  + '  '}${boleta.receiver.secondLastName}`;
    documentReceptor = boleta.receiver.document.number;

    mounthDate = getMes(boleta.period.substring(4, 6));
    dateBoleta = mounthDate + ' ' + boleta.period.substr(0, 4);
  }
  currency = boleta ? (boleta.currency.shortDescription + ' ') : 'S/.';

  return (
    <div ref={ref} id={id} className={`app-file-boleta-2 hidden-content-force ${className}`}>
      <div className="report-header">
        <div className="report-header-company">
          <div className="report-header-ruc">
            <label>Compañia: </label>
            <label>Protecta S.A. Compañia de Seguros</label>
            <label>R.U.C.: 20517207331</label>
          </div>
        </div>
        <div>
          <img alt="" src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`} />
        </div>
      </div>
      <div className="report-body">
        <div className="d-flex">
          <div className="report-body-title-0 w-60">
            <h3>Información de pago</h3>
            <h3>{boleta ? dateBoleta : 'Mayo 2019'}</h3>
          </div>
          <div className="report-body-title w-40">
            <div className="d-flex">
              <h3>Póliza </h3> <span> : </span> <h3> {boleta ? boleta.policy.number : '0000000322'}</h3>
            </div>
            <div className="d-flex">
              <h3>Tipo de renta </h3> <span> : </span> <h3 className="product"> { type === 1 ? 'Ahorro Total Protecta' : 'Renta Total Protecta'}</h3>
            </div>
            <div className="d-flex">
              <h3>Nro Beneficiario </h3> <span> : </span> <h3> {boleta ? boleta.order : '2'}</h3>
            </div>
          </div>
        </div>
        <div className="report-body-content">
          <div className="report-description mt-1">
            <p> Nos comprometimos a rentabilizar el producto de tu esfuerzo para que puedas disfrutarlo cada mes.</p>
          </div>
          <div className="report-content-fields mt-1">
            <div className="row w-70">
              <PSBoletaRow type={BoletaRow.blue} isBold name="Ident. Afiliado" />
              <PSBoletaRow type={BoletaRow.blue} isBold name="Nombre" />
            </div>
            <div className="row w-70 mt-0">
              <PSBoletaRow type={BoletaRow.white}  className="row-small"  name={boleta ? `${boleta.beneficary.document.type === 1 ? 'DNI' : '' }-${  boleta.beneficary.document.number}` : 'DNI-42900189'} />
              <PSBoletaRow type={BoletaRow.white}  className="row-small" name= {boleta ? fullNameBeneficary : "Jose Luis Osorio Dominguez" }/>
            </div>
            <div className="row-1 mt-1">
              <PSBoletaRow type={BoletaRow.blue} isBold name="Dirección" />
            </div>
            <div className="row-1 mt-0">
              <PSBoletaRow type={BoletaRow.white} className="row-small"  name= {boleta? fullDirectionBeneficary2 :  "Av. Las Begonias Mz K Lote 1 Coo. Pablo Patron"}/>
            </div>
            <div className="row-3 mt-1">
              <PSBoletaRow type={BoletaRow.gray2} isBold name="Ident. Receptor" />
              <PSBoletaRow type={BoletaRow.gray2} isBold name="Receptor" />
              <PSBoletaRow type={BoletaRow.gray2} isBold name="Banco" />
            </div>
            <div className="row-3 mt-0">
              <PSBoletaRow type={BoletaRow.white}  className="row-small" name={`DNI - ${documentReceptor || '08363399'}`} />
              <PSBoletaRow type={BoletaRow.white}  className="row-small" name={fullNameReceptor || ''} />
              <PSBoletaRow type={BoletaRow.white}   className="row-small" name={boleta ? boleta.beneficary.bankAccount.bank.description: "Interbank"} />
            </div>
            <div className="row mt-1">
              <PSBoletaRow type={BoletaRow.gray2} isBold name="Nro. CTA" />
              <PSBoletaRow type={BoletaRow.gray2} isBold name="Nro. CCI" className="row-1" />
            </div>
            <div className="row mt-0">
              <PSBoletaRow type={BoletaRow.white}  className="row-small" name={boleta ? boleta.beneficary.bankAccount.number: "7603121987725"} />
              <PSBoletaRow type={BoletaRow.white} className="row-1 row-small"  name={boleta ? boleta.beneficary.bankAccount.cci : "################"}  />
            </div>
            <div className="row-4 mt-1">
              <PSBoletaRow type={BoletaRow.hidden} name="" />
              <PSBoletaRow type={BoletaRow.blue} isBold name="Ingresos" />
              <PSBoletaRow type={BoletaRow.white}  name="Pago R.T." className="small"/>
              <PSBoletaRow type={BoletaRow.gray2}   name= {boleta ?  `${currency} ${  boleta.pensionAmount}` : "S/ 317.25"} className="small"/>
            </div>
            <div className="row-4 mt-0">
              <PSBoletaRow type={BoletaRow.hidden} name="" />
              <PSBoletaRow type={BoletaRow.hidden} name="" />
              <PSBoletaRow type={BoletaRow.white} isBold name="Total pago" className="small bg-white"/>
              <PSBoletaRow type={BoletaRow.orange} isBold name= {boleta ?  `${currency} ${  boleta.liquidAmount}` : "S/ 2417.25"} className="small" />
            </div>
          </div>
        </div>
        <div className="report-footer w-100">
          <h5 className="footer-description">
            Declaro que este importe lo he recibido a mi entera y completa satisfacción, por tanto, Protecta S.A. Compania de Seguros, queda relevada de toda responsabilidad futura por la pensión del mes arriba mencionado.
          </h5>
          <h5 className="footer-description mt-1 bg-blue">
            La suma de: <b> {boleta ? boleta.liquidAmountDescription : 'TRESCIENTOS CUARENTA Y TRES CON 08/100 SOLES'}</b>
          </h5>
        </div>
      </div>
    </div>
    );
  }
);

PSFileBoleta2.displayName = 'PSFileBoleta2';

PSFileBoleta2.defaultProps = {
  className: '',
  boleta: null,
  type: 1, //1 : Ahorro Total . 2: Renta Total
};

PSFileBoleta2.propTypes = {
  className: PropTypes.string,
  boleta: PropTypes.object,
  type: PropTypes.number,
};

export default PSFileBoleta2;
