import { doGet, doGetImagesorFiles } from '../Api';
import {
  MAGAZINE_BOLETIN_EN_VIVO,
  MAGAZINE_BOLETIN_FINANCIERO,
  MAGAZINE_BOLETIN_QUEDATE_CASA,
  MAGAZINE_BOLETIN_VIVE_SALUD,
} from '../../../../util/Constant';
import {sortMagazineByDate} from "../../../../util/Util";

export default class LoadMagazinesService {
  static async responseGetMagazines(optionSelected) {
    let response = [];
    let responseTotal = [];
    if(optionSelected){
      switch (optionSelected.idTipoContenido) {
        case MAGAZINE_BOLETIN_EN_VIVO:
          response = await doGet(
            `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_EN_VIVO}`
          );
          response = response.data.data;
          break;
        case MAGAZINE_BOLETIN_QUEDATE_CASA:
          response = await doGet(
            `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_QUEDATE_CASA}`
          );
          response = response.data.data;
          break;
        case MAGAZINE_BOLETIN_VIVE_SALUD:
          response = await doGet(
            `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_VIVE_SALUD}`
          );
          response = response.data.data;
          break;
        case MAGAZINE_BOLETIN_FINANCIERO:
          response = await doGet(
            `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_FINANCIERO}`
          );
          response = response.data.data;
          break;
        default:
          response = await doGet(
            `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_FINANCIERO}`
          );
          response = response.data.data;
      }
    } else {
      responseTotal = await doGet(
        `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_VIVE_SALUD}`
      );
      response.push(...responseTotal.data.data);

      responseTotal = await doGet(
        `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_EN_VIVO}`
      );
      response.push(...responseTotal.data.data);

      responseTotal = await doGet(
        `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_QUEDATE_CASA}`
      );
      response.push(...responseTotal.data.data);

      responseTotal = await doGet(
        `${process.env.REACT_APP_NEWSLETTER_CATEGORIAS}/${MAGAZINE_BOLETIN_FINANCIERO}`
      );
      response.push(...responseTotal.data.data);
    }
    const arrSorted = sortMagazineByDate(response);
    return arrSorted;
  }

  static async responseGetImages(objMagazine) {
    const responseImage = await doGetImagesorFiles(
      process.env.REACT_APP_BASE_URL_NET + objMagazine.s3UrlImage
    );

    if(responseImage){
      return responseImage.data.data;
    }

    return null;

  }

  static async responseDownloadMagazine(link) {
    const responseFile = await doGetImagesorFiles(process.env.REACT_APP_BASE_URL_NET + link);
    return responseFile.data;
  }
}
