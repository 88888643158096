import { setAuthLoading } from './auth-loading';
import AuthService from '../../../shared/services/AuthService';
import { setInvalidPassword } from './invalid-password';

export const CHANGE_PASSWORD = 'Auth/CHANGE_PASSWORD';

export const setChangePassword = (updated) => ({
  type: CHANGE_PASSWORD,
  payload: updated,
});

export const updatePassword = (password, user, isBusiness, pdpNetPrivada, isUpdate) => {
  return async (dispatch) => {
    try {
      const params = {
        documentId: user.documentId,
        password,
        documentType: user.documentType,
        pdpNetPrivada,
      };
      let response;
      dispatch(setAuthLoading(true));
      if (isBusiness) {
        response = await AuthService.changePasswordBusiness({
          ...params,
          documentId: user.document,
          nombre: user.attributes.legalName,
          email: user.attributes.email,
        });
      } else {
        if (isUpdate) {
          response = await AuthService.recoverPassword(params, user.code);
        } else {
          response = await AuthService.finalizeProcess(params, user.code);
        }
        // await AuthService.changePassword({ documentId: user.documentId, password, documentType: user.documentType, pdpNetPrivada: pdpNetPrivada}, user.code);
      }
      if (response?.status?.id === 201 || response?.statusCode === 200) {
        dispatch(setChangePassword(true));
        dispatch(setInvalidPassword(false));
      } else {
        dispatch(setChangePassword(false));
        dispatch(setInvalidPassword(true));
      }
    } catch (error) {
      dispatch(setChangePassword(false));
      dispatch(setInvalidPassword(true));
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  updatedPassword: action.payload,
});
