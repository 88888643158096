import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pathServer } from '../../util/Constant';

const StyledPSSelect = styled.select`
  top: 279px;
  left: 61px;
  padding-left: 15px;
  width: 100%;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  color: #7a7a7a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 25px 25px;
  background: url('${pathServer.PATH_ICONS}arrow-down.png') white no-repeat calc(100% - 10px);
`;

const PSSelect = React.forwardRef(({ name, selectedValue, options, ...props }, ref) => {
  return (
    <StyledPSSelect value={selectedValue} ref={ref} name={name} {...props}>
      {options.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </StyledPSSelect>
  );
});

PSSelect.displayName = 'PSSelect';

PSSelect.defaultProps = {
  className: null,
  options: [],
  onChange: () => {},
};

PSSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export default PSSelect;
