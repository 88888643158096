import { doGet, doPost } from './Api';

export default class ClientsService {
  static async responseGetClient() {
    const response = await doGet(`${process.env.REACT_APP_GET_CLIENT}`);
    return response.data;
  }

  static async responseGetClients() {
    const response = await doGet(`${process.env.REACT_APP_GET_CLIENTS}`);
    return response?.data;
  }

  static async responseGetClientPersonal() {
    const response = await doGet(`${process.env.REACT_APP_GET_CLIENT_PERSONAL}`);
    return response.data;
  }

  static async responseUpdateClientPersonal(data) {
    const response = await doPost(`${process.env.REACT_APP_GET_CLIENT_PERSONAL}`, data);
    return response.data;
  }

  static async responseUpdateClient(data) {
    const response = await doPost(`${process.env.REACT_APP_GET_UPDATE_CLIENT}`, data);
    const dataResponse = response?.data;
    if (dataResponse?.status?.id === 403 && dataResponse?.status?.name === 'UNAUTHORIZED') {
      const { config } = response;
      return { url: config.url, needValidation: true, ...response.data };
    }
    return response?.data;
  }
}
