import React,{ useEffect }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSModal from "../../PSModal";
import TypeOne from "./infoModal/TypeOne";
import TypeTwo from "./infoModal/TypeTwo";
import PSIcon from "../../PSIcon";
import {GAProvider} from "../../PSAnalytics";

const PSModalBelcorp = React.forwardRef(({ className, openModal, onClick, onClose, type, isBusiness }, ref) => {

  const ga = React.useContext(GAProvider);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}belcorp`,
      action: 'click',
      label: `El usuario del grupo ${type} visualizó el banner`,
      value: 1,
    });
  }, [ga]);

    return (
      <div  ref={ref}>
        <PSModal open={openModal} showCloseIcon={true} classNames={{overlay: 'customOverlayBelcorp', modal: 'customModalBelcorp'}} onClose={onClose}  >
          <PSIcon type="close" className="modalBelcorp-icon__close" onClick={onClose}/>
          {
            type ==="1" &&   <TypeOne/>
          }

          {
            type ==="2" &&   <TypeTwo/>
          }
        </PSModal>
      </div>
    );
  }
);

PSModalBelcorp.displayName = 'PSModalBelcorp';

PSModalBelcorp.defaultProps = {
  className: '',

};

PSModalBelcorp.propTypes = {
  className: PropTypes.string,
};

export default PSModalBelcorp;
