import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { CodeOptProduct } from '../../../shared/constants/Strings';
import { HeaderTableBeneficiaryS } from '../../../../util/Constant';
import FormsService from '../../../shared/services/FormsService';
import { createErrorPage } from '../../../redux/auth';

import PSComponentForm from '../../PSComponentForm';
import PSLoading from '../../PSLoading';
import PSList from '../../PSList';
import PSButton from '../../PSButton';
import PSDynamicTableEditable from '../../PSDynamicTableEditable';
import { StyledContainerTable } from '../../PSBodyProduct';

const PSFormChangeBeneficiaryBurial = React.forwardRef(
  ({
    onCloseOption,
    form,
    isPreview,
    loadingSaveForm,
    onSaveForm,
    getInfoPreview,
    setIsPreview,
    availableSendForm,
    beneficiaryInfoBurial,
    onSelectBeneficiaryBurial,
    onDeleteBeneficiaryBurial,
    onChangeModePage,
    onBlurModePage,
    nroPolicy,
    menuId,
  }) => {
    const dispatch = useDispatch();

    const enabledForm = async () => {
      try {
        const { data, status } = await FormsService.validateFormByPolicy(nroPolicy, menuId);
        if (status.id !== 200) {
          dispatch(
            createErrorPage({
              onAction: () => {},
              state: 'true',
              icon: 'broken',
              title: 'Error!',
              message:
                'La poliza no se encuentra habilitada para Modificación de beneficiarios de gasto de Sepelio',
            })
          );
          onCloseOption();
        }
      } catch (error) {
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message: 'Error al validar poliza',
          })
        );
        onCloseOption();
      }
    };

    useEffect(() => {
      if (nroPolicy !== -1 && menuId === '20000008') {
        enabledForm();
      }
    }, [nroPolicy]);

    return (
        <>
          {!isPreview ? (
            <>
              <PSDynamicTableEditable
                headers={HeaderTableBeneficiaryS}
                showCheck={true}
                valueSelected={beneficiaryInfoBurial.currentBeneficiary}
                items={beneficiaryInfoBurial.listOfBeneficiary}
                onSelect={onSelectBeneficiaryBurial}
                onDelete={onDeleteBeneficiaryBurial}
                hasDelete={true}
              />

              <PSComponentForm
                showInfo={true}
                from={form[CodeOptProduct.CGS]}
                onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.CGS)}
                onBlur={(index) => onBlurModePage(index, CodeOptProduct.CGS)}
                title="Deseo cambiar al beneficiario original de la cobertura de Gasto de Sepelio por el indicado a continuación:"
              />
            </>
          ) : (
            <>
              <PSList items={getInfoPreview(form[CodeOptProduct.CGS])} />
              <StyledContainerTable>
                <PSDynamicTableEditable
                  hasEdit={false}
                  hasDelete={false}
                  showCheck={false}
                  headers={HeaderTableBeneficiaryS}
                  items={beneficiaryInfoBurial.listOfBeneficiary}
                />
              </StyledContainerTable>
            </>
          )}
          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.CGS)}
              disabled={!availableSendForm(CodeOptProduct.CGS)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>     
    );
  }
);

PSFormChangeBeneficiaryBurial.displayName = 'PSFormChangeBeneficiaryBurial';

PSFormChangeBeneficiaryBurial.defaultProps = {
  nroPolicy: null,
  menuId: null,
  onCloseOption: () => {},
  beneficiaryInfoBurial: {
    listOfBeneficiary: [],
    currentBeneficiary: -1,
  },
};

PSFormChangeBeneficiaryBurial.propTypes = {
  nroPolicy: PropTypes.number,
  menuId: PropTypes.string,
  onCloseOption: PropTypes.func,
  beneficiaryInfoBurial: PropTypes.object,
};

export default PSFormChangeBeneficiaryBurial;
