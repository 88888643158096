import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import map from 'lodash/map';

import CouponSoatServices from '../../../shared/services/CouponSoatServices';
import { carouselProps } from '../../../../util/Util';
import PSIcon from '../../PSIcon';
import PSLoading from '../../PSLoading';
import PSCouponCard from '../../organisms/PSCouponCard/PSCouponCard';

import './index.scss';
import PSButton from '../../PSButton';

const CouponDetail = ({
  couponSelected,
  listCoupon,
  requestCoupon,
  loadingRequestCoupon,
  currentCouponSelected,
}) => {
  const [responseImage, setResponseImage] = useState(null);
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
  const urlImage = isMobile
    ? couponSelected.iconDetail.urlImageMobile
    : couponSelected.iconDetail.urlImageWeb;
  const urlImageGeneric = urlImage || couponSelected.iconDetail.urlImageDefault;
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(listCoupon.length);
    }
  };
  const loadImages = async () => {
    const responseImage = await CouponSoatServices.responseGetImages(urlImageGeneric);
    setResponseImage(responseImage);
  };
  useEffect(() => {
    loadImages();
  }, [couponSelected]);

  return (
    <>
      {responseImage === null ? (
        <div className="loading-pages-intranet">
          <PSLoading />
        </div>
      ) : (
        <div className="coupon-detail">
          <div className="coupon-detail__header">
            <div className="section-title">
              <h3 className="title">{couponSelected?.companyName}</h3>
              <p>{couponSelected?.description}</p>
            </div>
          </div>
          <div className="coupon-detail__body">
            <div className="coupon flex-center">
              <div className="coupon--left">
                <img className="coupon__img" src={responseImage} alt={couponSelected.name} />
                <PSButton
                  onClick={() => requestCoupon(couponSelected.id)}
                  className="d-block coupon__btn"
                  loading={loadingRequestCoupon}
                >
                  Solicitar
                </PSButton>
                <hr />

                {couponSelected?.availableCoupons === 1 ? (
                  <div className="d-flex align-items-center amount_available">
                    <PSIcon type="couponSmall" />
                    {couponSelected?.availableCoupons} disponible
                  </div>
                ) : (
                  <div className="d-flex align-items-center amount_available">
                    <PSIcon type="couponSmall" />
                    {couponSelected?.availableCoupons} disponibles
                  </div>
                )}
              </div>
              <div className="coupon--right">
                <div>
                  <div className="coupon__container">
                    <h4 className="title"> Información de la promoción</h4>

                    {couponSelected?.availableDescription && (
                      <div className="content-text">
                        <PSIcon type="calendar" />
                        <p>{couponSelected.availableDescription}</p>
                      </div>
                    )}

                    {couponSelected?.terms && (
                      <div className="content-text">
                        <PSIcon type="time" />
                        <p>{couponSelected.terms}</p>
                      </div>
                    )}

                    {map(couponSelected?.paragraphs, (item, index) => (
                      <div className="content-text" key={index}>
                        <PSIcon type="check" className="check" />
                        <p>{item.paragraph}</p>
                      </div>
                    ))}

                    {couponSelected?.tyc && (
                      <a target="_blank" href={couponSelected.tyc} rel="noreferrer">
                        Ver términos y condiciones
                      </a>
                    )}
                  </div>

                  <div className="coupon__container d-none">
                    <h4 className="title"> Aplica</h4>
                    <div className="content-text">
                      <ul>
                        <li>
                          <div className="content-text">
                            <PSIcon type="check" />
                            <p>{couponSelected.name}</p>
                          </div>
                        </li>
                        <li>
                          <div className="content-text">
                            <PSIcon type="check" />
                            <p>{couponSelected.name}</p>
                          </div>
                        </li>
                        <li>
                          <div className="content-text">
                            <PSIcon type="check" />
                            <p>{couponSelected.description}</p>
                          </div>
                        </li>
                        <li>
                          <div className="content-text">
                            <PSIcon type="check" />
                            <p>{couponSelected.description}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="coupon-detail__footer">
            {listCoupon.length > 1 && (
              <div className="content-footer-detail">
                <p>LOS USUARIOS QUE VIERON ESTE CUPÓN TAMBIÉN VIERON</p>
                <Carousel
                  ref={carouselRef}
                  {...carouselProps}
                  onPrevStart={onPrevStart}
                  onNextStart={onNextStart}
                >
                  {map(
                    listCoupon,
                    (item) =>
                      item.availableCoupons > 0 && (
                        <div
                          className="column"
                          key={item.id}
                          onClick={() => currentCouponSelected(item)}
                        >
                          <PSCouponCard data={item} />
                        </div>
                      )
                  )}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

CouponDetail.displayName = 'CouponDetail';

CouponDetail.defaultProps = {
  loading: false,
  loadingRequestCoupon: false,
};

CouponDetail.propTypes = {
  loading: PropTypes.bool,
  loadingRequestCoupon: PropTypes.bool,
};

export default CouponDetail;
