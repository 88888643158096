import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { GAProvider } from '../../PSAnalytics';
import PSIcon from '../../PSIcon';

import './index.scss';

const PHONE = '51945811540';
const MESSAGE = 'Hola+Protecta+Security';

const PSChatBoot = React.forwardRef(({ className, isMobile }, ref) => {
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const ga = React.useContext(GAProvider);

  const sendAnalytics = (typeSend) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}WhatsApp`,
      action: 'click',
      label: `El usuario entro al link de redireccionamiento de WhatsApp mediante ${typeSend}`,
      value: 1,
    });
  };

  return (
    <div className="chatbot">
      <div>
        <a
          href={`https://api.whatsapp.com/send/?phone=${PHONE}&text=${MESSAGE}`}
          target="__blank"
          aria-label="Open chatbot"
          aria-expanded="false"
          ref={ref}
          className="display-none-mobile"
          onClick={() => {
            sendAnalytics('la computadora');
          }}
        >
          <PSIcon className="main" type="whatsAppClose" />
          <PSIcon className="translate" type="whatsAppOpen" />
        </a>
        <a
          href={`https://api.whatsapp.com/send?phone=${PHONE}&text=${MESSAGE}`}
          target="__blank"
          aria-label="Open chatbot"
          aria-expanded="false"
          ref={ref}
          className="display-only-mobile"
          onClick={() => {
            sendAnalytics('el celular');
          }}
        >
          <PSIcon className="main" type="whatsAppClose" />
          <PSIcon className="translate" type="whatsAppOpen" />
        </a>
      </div>
    </div>
  );
});

PSChatBoot.displayName = 'PSChatBoot';

PSChatBoot.defaultProps = {
  className: '',
};

PSChatBoot.propTypes = {
  className: PropTypes.string,
};

export default PSChatBoot;
