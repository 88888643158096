import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PSMap from './PSMap';
import PSIcon from './PSIcon';
import PSSelect from './PSSelect';
import PSButton from './PSButton';
import ClinicsServices from '../shared/services/ClinicsServices';
import PSLoading from './PSLoading';
import { isEmpty } from '../../util/Util';

const StyledPSGeolocation = styled.div`
  .column {
    padding: 0.75rem 0;
  }
  .location-clinicas {
    margin-top: 15px;
    width: 100%;
    border-radius: 19px;
    background: white;
    padding: 2% 4%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .location-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 0.8fr;
    grid-gap: 25px;
    margin-bottom: 20px;
    select {
      margin-top: 10px;
    }
    select {
      background-color: transparent;
    }
    label {
      color: #2b265a;
      font-size: 22px;
      font-weight: 700;
      font-family: 'BlissPro-Heavy', sans-serif !important;
    }
    & div:nth-child(3) {
      padding-top: 20%;
      padding-left: 25%;
      button {
        font-family: 'BlissPro-ExtraBold', sans-serif;
      }
    }
  }

  .location-title {
    margin-bottom: 10px;
    display: flex;
    .btn-back {
      position: absolute;
      right: 5%;
      font-size: 14px;
      padding-top: 10px;
    }
    label {
      font-family: 'BlissPro-Heavy', sans-serif !important;
      color: #2b265a;
      opacity: 1;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .location-row {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    & div:nth-child(1) {
      margin-top: auto;
      margin-bottom: auto;
      b {
        font-size: 20px;
      }
    }
  }
  svg {
    width: 35px;
    height: 35px;
  }
  .a {
    fill: #da6d19 !important;
  }
  b {
    font-family: 'BlissPro-Regular', sans-serif;
    color: #2b265a;
    font-weight: 500;
  }
  .hasBorder {
    text-align: center;
    border-left: 1px solid #e4e4e4;
    b {
      font-size: 17px;
    }
  }
  .option-map {
    &:hover {
      cursor: pointer;
    }
  }

  .option-call {
    display: none;
  }

  @media (max-width: 768px) {
    .option-call {
      display: none;
    }
    .location-filters {
      display: block !important;
      & div:nth-child(3) {
        padding: 5% 0;
        text-align: center;
        button {
          width: 50%;
        }
      }
    }
    .location-row {
      grid-template-columns: 1fr 0.2fr;
    }
    .location-clinicas {
      margin-bottom: 15%;
    }
  }
`;
const PSGeolocation = React.forwardRef(({ activateBackButton }, ref) => {
  const [showMap, setShowMap] = useState(false);
  const [point, setPoint] = useState(null);
  const [district, setDistrict] = useState(null);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [data, setData] = useState([]);
  const [clinicas, setClinicas] = useState([]);

  useEffect(() => {
    if (!isEmpty(department)) {
      setDistrict(department.districts[0]);
      setClinicas(department?.districts[0].clinicas);
    }
  }, [department]);

  const loadClinicals = async () => {
    try {
      setLoading(true);
      const response = await ClinicsServices.getClinics();
      if (response) {
        const limaDeparment = response?.find((c) => c.value === 'LIMA');
        const firstDepartment = limaDeparment || response[0];
        setData(response);
        setDepartment(firstDepartment);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadClinicals();
  }, []);

  return loading ? (
    <div className="d-flex-center">
      <PSLoading width={130} height={130} />
    </div>
  ) : (
    <StyledPSGeolocation ref={ref}>
      <div className="location-search" />
      <div className="location-container">
        <div className="location-filters columns is-mobile">
          <div className="column">
            <label htmlFor=""> Departamento </label>
            <PSSelect
              options={data}
              selectedValue={department.value}
              onChange={(e) => {
                const department = data.find((dep) => dep.value === e.target.value);
                setDepartment(department);
                setShowMap(false);
              }}
            />
          </div>
          <div className="column">
            <label htmlFor=""> Distrito </label>
            <PSSelect
              options={department.districts}
              selectedValue={district?.value}
              onChange={(e) => {
                const itemSelected = e.target.value;
                const districtSelected = department?.districts.find(
                  (d) => d.value === itemSelected
                );
                setDistrict(districtSelected);
                setClinicas(districtSelected.clinicas);
                setShowMap(false);
              }}
            />
          </div>
          {showMap && (
            <div className="column">
              <PSButton id="btnBack" onClick={() => setShowMap(false)}>
                Buscar
              </PSButton>
            </div>
          )}
        </div>
        <div className="location-title">
          <label>Clínicas cercanas:</label>
        </div>

        {showMap ? (
          <PSMap pointTo={point} />
        ) : (
          <div className="location-clinicas">
            {clinicas.map((c, index) => (
              <div key={index} className="location-row">
                <div>
                  {c.name} <br /> <b>{c.distance}</b>
                </div>
                <div className="hasBorder option-call">
                  <PSIcon type="phone" />
                  <a href={`tel:${c.phone}`}>
                    <b>Llamar</b>
                  </a>
                </div>
                <div
                  className="hasBorder"
                  onClick={() => {
                    setShowMap(!showMap);
                    setPoint(c);
                    activateBackButton();
                  }}
                >
                  <PSIcon type="map" />
                  <b className="option-map">Ver Mapa</b>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledPSGeolocation>
  );
});

PSGeolocation.displayName = 'PSGeolocation';

PSGeolocation.defaultProps = {
  activateBackButton: () => {},
};

PSGeolocation.propTypes = {
  activateBackButton: PropTypes.func,
};

export default PSGeolocation;
