import { STATUS_PAYMENT_DEFAULT } from './actions/payment-validation';

export const initialState = {
  isUserValid: null,
  loading: false,
  userValidationInfo: {},
  codeSent: false,
  errorCodeSent: false,
  validCode: null,
  updatedPassword: false,
  invalidPassword: false,
  cognitoValid: null,
  signUp: {},
  cognitoCreated: false,
  forgotPassword: {},
  userInfo: {},
  attributesCore: null,
  token: null,
  isEditData: false,
  banners: {
    opportunityOfMonth: [],
    pqp: [],
    promotions: [],
  },
  address: null,
  errorPage: {
    title: '¡Ups!',
    message: 'Estamos trabajando para reestablecer nuestros sistemas pronto.',
    buttonText: 'Aceptar',
  },
  errorPayment: STATUS_PAYMENT_DEFAULT,
  stateProduct: null,
};
