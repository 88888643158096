export const AUTH_LOADING = 'Auth/AUTH_LOADING';

export const setAuthLoading = (loading) => ({
  type: AUTH_LOADING,
  payload: loading,
});

export const reducer = (state, action) => ({
  ...state,
  loading: action.payload,
});
