import React, { useState, useEffect } from 'react';
import './index.scss';

import { useFormik } from 'formik';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { maxLengthCheck } from '../../../util/Util';
import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSLabel from '../../components/PSLabel';
import PSBox from '../../components/PSBox';
import PSSelect from '../../components/PSSelect';
import PSError from '../../components/PSError';
import PSTextInput from '../../components/PSTextInput';
import PSCheckBox from '../../components/PSCheckBox';
import PSButton from '../../components/PSButton';
import PSInputFile from '../../components/PSInputFile';
import { getPlaceholderInput } from '../SignUp';
import AuthService from '../../shared/services/AuthService';
import { Strings } from '../../shared/constants/Strings';
import PSMessageResponse from '../../components/PSMessageResponse';
import { emailRegExp, nameRegex } from '../../../util/Constant';
import {GAProvider} from "../../components/PSAnalytics";


const ErrorMessage = ({ userStatus, onClose }) => {
  const getErrorMessage = () => {
    if (userStatus === 'ERROR_REGISTER_BUSINESS') {
      return Strings.ERROR_REGISTER_BUSINESS;
    }

    if (userStatus === 'ERROR_GET_URL') {
      return Strings.ERROR_GET_URL;
    }

    if (userStatus === 'ERROR_UPLOAD_FILE') {
      return Strings.ERROR_UPLOAD_FILE;
    }

    if (userStatus === 'ERROR_CREATE_BUSINESS') {
      return Strings.ERROR_CREATE_BUSINESS;
    }

    return Strings.ERROR_REGISTER_BUSINESS;
  };

  return (
    <PSMessageResponse icon="face" onClose={onClose}>
      {getErrorMessage()}
    </PSMessageResponse>
  );
};
const SignUpForm = ({
  formik,
  formik2,
  onClose,
  loading,
  isSecondPart,
  onChangeFile,
  file,
  showPrivacyPolices,
  setShowPrivacyPolices,
}) => (
  <PSLoginContainer className="company-container" onClose={onClose} showCloseIcon={!showPrivacyPolices} loading={loading}>
    <PSHeaderLogin className="company-header-login" title="Registro" icon="business">
      <PSLabel className="company-header-title">Por favor, ingresa tus datos</PSLabel>
    </PSHeaderLogin>
    {!isSecondPart ? (
      <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
        <PSBox className="user-box-login">
          <PSSelect
            {...formik.getFieldProps('documentType')}
            options={[
              {value: '', label: 'Seleccione documento'},
              {value: 'L', label: 'DNI'},
              {value: 'E', label: 'Carnet de Extranjería'},
            ]}
            selectedValue={formik.values.documentType}
            className={
              formik.touched.documentType && formik.errors.documentType ? 'with-error' : ''
            }
          />
          <PSError>{formik.touched.documentType && formik.errors.documentType}</PSError>
          <PSTextInput
            type="text"
            maxLength={formik.values.documentType === 'L' ? 8 : 11}
            onKeyPress={(e) => maxLengthCheck(e)}
            placeholder={getPlaceholderInput(formik.values.documentType)}
            {...formik.getFieldProps('contactDocument')}
            className={
              formik.touched.contactDocument && formik.errors.contactDocument
                ? 'with-error'
                : ''
            }
          />
          <PSError>{formik.touched.contactDocument && formik.errors.contactDocument}</PSError>

          <PSTextInput
            type="text"
            placeholder="Nombres"
            {...formik.getFieldProps('name')}
            className={formik.touched.name && formik.errors.name ? 'with-error' : ''}
          />
          <PSError>{formik.touched.name && formik.errors.name}</PSError>
          <PSTextInput
            type="text"
            placeholder="Apellido paterno"
            {...formik.getFieldProps('lastName')}
            className={formik.touched.lastName && formik.errors.lastName ? 'with-error' : ''}
          />
          <PSError>{formik.touched.lastName && formik.errors.lastName}</PSError>
          <PSTextInput
            type="text"
            placeholder="Apellido materno"
            {...formik.getFieldProps('lastName2')}
            className={formik.touched.lastName2 && formik.errors.lastName2 ? 'with-error' : ''}
          />
          <PSError>{formik.touched.lastName2 && formik.errors.lastName2}</PSError>

          <PSTextInput
            type="text"
            placeholder="Correo electrónico de la empresa"
            {...formik.getFieldProps('contactEmail')}
            className={
              formik.touched.contactEmail && formik.errors.contactEmail ? 'with-error' : ''
            }
          />
          <PSError>{formik.touched.contactEmail && formik.errors.contactEmail}</PSError>

          <PSTextInput
            type="text"
            placeholder="Cargo dentro de la empresa"
            {...formik.getFieldProps('role')}
            className={formik.touched.role && formik.errors.role ? 'with-error' : ''}
          />
          <PSError>{formik.touched.role && formik.errors.role}</PSError>
        </PSBox>
        <PSBox className="user-footer">
          <PSButton className="btn-submit" type="submit" loading={loading}>
            {!isSecondPart ? 'Siguiente' : 'Enviar'}
          </PSButton>
        </PSBox>
      </form>
    ) : (
      <form className="baseForm" onSubmit={formik2.handleSubmit} noValidate>
        <PSBox className="user-box-login">
          <PSTextInput
            type="text"
            placeholder="Número de teléfono"
            maxLength={9}
            onKeyPress={(e) => maxLengthCheck(e)}
            {...formik2.getFieldProps('contactPhone')}
            className={
              formik2.touched.contactPhone && formik2.errors.contactPhone ? 'with-error' : ''
            }
          />
          <PSError>{formik2.touched.contactPhone && formik2.errors.contactPhone}</PSError>
          <PSTextInput
            type="text"
            disabled={formik2.values.contactPhone.length > 8}
            placeholder="Número de anexo"
            onKeyPress={(e) => maxLengthCheck(e)}
            {...formik2.getFieldProps('contactPhone2')}
          />

          <PSInputFile
            label="*Adjuntar ficha RUC de la empresa:"
            observation = "* Se aceptan archivos con extensión: pdf, jpg, png"
            onChangeFile={onChangeFile}
            fileProps={file}
            {...formik2.getFieldProps('file')}
          />
          <PSError>{formik2.touched.file && file === null && 'Adjunte el archivo'}</PSError>
          {/*<PSCheckBox*/}
            {/*className="d-flex accept-terms"*/}
            {/*{...formik2.getFieldProps('indPDP')}*/}
            {/*value={formik2.values.indPDP}*/}
          {/*>*/}
            {/*<div>*/}
              {/*Acepto el envío de comunicaciones comerciales y publicidad de acuerdo a lo*/}
              {/*señalado en la*/}
            {/*</div>*/}
            {/*<a className="link-term" onClick={() => setShowPrivacyPolices(true)}>*/}
              {/*Política de privacidad de datos*/}
            {/*</a>*/}
          {/*</PSCheckBox>*/}
          <PSBox className="user-footer">
            <PSButton className="btn-submit" type="submit" loading={loading}>
              Enviar
            </PSButton>
          </PSBox>
        </PSBox>
      </form>
    )}
  </PSLoginContainer>
);

export default function SignUpRegisterCompany({ closeModal }) {
  const [showError, setError] = useState(false);
  const [isSecondPart, setIsSecondPart] = useState(false);
  const [formSave, setFormSave] = useState(false);
  const { signUp } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isBusinessValid, setIsBusiness] = useState('');
  const [validationCreate, setValidationCreate] = useState(false);
  const [file, setFile] = useState(null);
  const [showPrivacyPolices, setShowPrivacyPolices] = useState(false);
  const ga = React.useContext(GAProvider);

  const history = useHistory();
  const dispatch = useDispatch();

  const onClose = () => {
    closeModal();
  };

  const formik = useFormik({
    initialValues: {
      documentType: '',
      contactDocument: '',
      name: '',
      lastName: '',
      lastName2: '',
      contactEmail: '',
      role: '',
    },
    validationSchema: Yup.object().shape({
      contactDocument: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('Documento es requerido')
        .when('documentType', {
          is: 'L',
          then: Yup.string().max(8, 'El documento solo tiene 8 caracteres'),
        }),
      documentType: Yup.string().required('Seleccione el tipo de documento'),
      name: Yup.string().required('Nombre es requerido'),
      lastName: Yup.string()
        .matches(nameRegex, 'Ingrese un nombre valido')
        .required('Apellido es requerido'),
      lastName2: Yup.string()
        .matches(nameRegex, 'Ingrese un apellido valido')
        .required('Apellido materno es requerido'),
      contactEmail: Yup.string()
        .matches(emailRegExp, 'Ingrese un correo valido')
        .required('Correo es requerido'),
      role: Yup.string().required('Cargo es requerido'),
    }),

    onSubmit(form) {
      setIsSecondPart(true);
      setFormSave(form);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      contactPhone: '',
      contactPhone2: '',
      file: '',
      indPDP: '', // definir si ira ahi
    },
    validationSchema: Yup.object().shape({
      contactPhone: Yup.string().required('Teléfono es requerido'),
    }),

    onSubmit(form) {
      setFormSave({ ...formSave, ...form });
      if (file !== null) {
        registerBusiness({ ...formSave, ...form });
      }
    },
  });

  const  onChangeFile = (file) => {
    setFile(file);
  };
  const registerBusiness = async (formToSave) => {
    try {
      setLoading(true);
      const CurrentDate = Math.round(new Date().getTime() / 1000);
      const structToGetUrlFile = btoa(
        `${signUp.documentType + signUp.documentId}#${CurrentDate}#${file.name}`
      );
      const response = await AuthService.registerBusiness({
        ...formToSave,
        document_type: signUp.documentType,
        documentID: signUp.documentId,
        date: CurrentDate,
        documentPath: structToGetUrlFile,
      });

      if (response.statusCode === 200) {
        const responseUrl = await AuthService.getUrlDocuments(structToGetUrlFile);
        if (responseUrl.statusCode === 200) {
          const responseUpload = await AuthService.uploadFile(responseUrl.data, file,file.type);
          if (responseUpload) {
            const responseCreate = await AuthService.createCognitoBusiness(
              signUp.documentType + signUp.documentId.toString()
            );
            if (responseCreate.status === 200) {
              setLoading(false);
              setError(false);
              setValidationCreate(true);
              ga.pageview(window.location.pathname + window.location.search);
              ga.event({
                category: 'EMPRESA-Enrollment',
                action: 'click',
                label: 'Una empresa solicitó su enrollment en la Net Privada',
                value: 1,
              });
            } else {
              setLoading(false);
              setError(true);
              setIsBusiness('ERROR_CREATE_BUSINESS');
            }
          } else {
            setLoading(false);
            setError(true);
            setIsBusiness('ERROR_UPLOAD_FILE');
          }
        } else {
          setLoading(false);
          setError(true);
          setIsBusiness('ERROR_GET_URL');
        }
      } else {
        setError(true);
        setIsBusiness('ERROR_REGISTER_BUSINESS');
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setIsBusiness('ERROR_REGISTER_BUSINESS');
      console.log('Error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { documentId, documentType } = signUp;
    const formSave = {};
    formSave.document_type = documentType;
    formSave.documentID = documentType + documentId;
    setFormSave({ ...formSave });
  }, []);

  return (
    <div className="company-register-container">
      {!showError && !validationCreate && (
        <SignUpForm
          formik={formik}
          formik2={formik2}
          onClose={onClose}
          loading={loading}
          file={file}
          isSecondPart={isSecondPart}
          onChangeFile={onChangeFile}
          showPrivacyPolices={showPrivacyPolices}
          setShowPrivacyPolices={setShowPrivacyPolices}
        />
      )}
      {showError && <ErrorMessage registerStatus={isBusinessValid} onClose={onClose} />}
      {validationCreate && (
        <PSMessageResponse icon="successFace" onClose={onClose}>
          <div>¡Gracias!Tu registro ha sido creado correctamente.</div>
          <div> Nos comunicaremos contigo al correo registrado en los próximos días.</div>
        </PSMessageResponse>
      )}
    </div>
  );
}
