import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import PSButton from '../../PSButton';
import {pathServer} from "../../../../util/Constant";


const PSModalCheckPromotions = React.forwardRef(
  ({ className, openModal, onClose, onClick, loading,  ...props }, ref) => {

    const [accept, setAccept] = useState(null);

    const onClickAccept = (value) => {
      setAccept(value);
      onClick(value);
    }

    return (
      <div ref={ref}>
        <PSModal open={openModal} showCloseIcon onClose={onClose} {...props}>
          <div className={`container-modal-check ${className}`}>
            <PSIcon type="checkPromotion" className="modal-check-icon" />
            <div className="section-info">
              <div className="section-info__title">
                {`Acepto el envío de comunicaciones comerciales y publicidad para conocer y
                enterarme de las promociones, descuentos y sorteos,
                de acuerdo a lo señalado
                en la `}
                <a href={`${pathServer.PATH_FILE}politicasPrivacidad.pdf`} target="_blank">
                  <span className="underline">Política de Privacidad</span>
                </a>

              </div>
              <div className="section-info__buttons">
                <PSButton
                  className={`button-modal ${accept===true ?' button-modal-select' : ''}`}
                  onClick={() => {onClickAccept(true)}}
                  disabled={loading}
                  loading={accept===true && loading}
                >
                  Aceptar
                </PSButton>
                <PSButton
                  className={`button-modal ${accept===false ?' button-modal-select' : ''}`}
                  onClick={() => {onClickAccept(false)}}
                  disabled={loading}
                  loading={accept===false && loading}
                >
                  No volver a mostrar
                </PSButton>
              </div>
            </div>
          </div>
        </PSModal>
      </div>
    );
  }
);

PSModalCheckPromotions.displayName = 'PSModalCheckPromotions';

PSModalCheckPromotions.defaultProps = {
  className: '',
  onClose: () => {},
  onClick: () => {},
  openModal: false,
};

PSModalCheckPromotions.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  openModal: PropTypes.bool,
};

export default PSModalCheckPromotions;
