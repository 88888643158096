import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PSLoading from './PSLoading';

const StyledButton = styled.button`
  background-color: #ed6e00;
  font-size: 20px;
  cursor: pointer;
  border-radius: 7px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 42px;
  border: none;
  outline: none;
  font-family: 'BlissPro-Regular', sans-serif;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

`;

const PSButton = React.forwardRef(({ children, loading, disabled, ...props }, ref) => (
  <StyledButton {...props} ref={ref} data-testid="button" disabled={disabled || loading}>
    {loading ? <PSLoading width={25} height={25} /> : children}
  </StyledButton>
));

PSButton.displayName = 'PSButton';

PSButton.defaultProps = {
  onClick: () => {},
  className: null,
  loading: false,
};

PSButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default PSButton;
