import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import PSSelect from './PSSelect';
import PSTextInput from './PSTextInput';
import PSIcon from './PSIcon';

const StyledPsDynamicRow = styled.div`
  .dynamic-rows-header {
    display: flex;
    color: #2b265a;
    font-family: 'BlissPro-Heavy', sans-serif !important;
    margin: 0 10px;
    .header-container-option {
      width: 24%;
      margin-left: 5px;
    }
  }

  .dynamic-rows-body {
    .row-container {
      margin: 10px;
      margin-top: 20px;
      display: flex;

      .container-select-optionRow {
        display: flex;
        width: 23%;
        .row-numeration {
          background: #7a7a7a;
          width: 31px;
          height: 52px;
          display: flex;
          border-radius: 4px 0 0 4px;
          label {
            color: #fff;
            margin: auto;
            font-size: 16px;
            font-family: 'BlissPro-Heavy', sans-serif !important;
          }
        }

        .select-optionRow {
          height: 52px;
          background-color: #f0f0f0;
          border: 1px solid #7a7a7a;
          border-radius: 0 4px 4px 0;
          width: 85%;
        }

        .select-optionRow-active {
          background-color: #ffffff9e;
        }
      }

      .container-input-row {
        display: flex;
        position: relative;
        width: 25%;
        .input-row {
          width: 80%;
          margin: 0 1rem;
          height: 52px;
          border: 1px solid #7a7a7a;
          border-radius: 3px;
          background-color: #f0f0f0;
        }
        .input-row-active {
          background-color: #ffffff9e;
          font-weight: 900;
          border-color: #2a0e61;
        }

        .input-row-icon {
          position: absolute;
          right: 19%;
          top: 16px;
          cursor: pointer;
        }

        .icon-row {
          margin-top: 12px;
        }
      }

      .icon-disabled {
        opacity: 0.4;
      }
    }
  }

  .dynamic-item-error {
    position: absolute;
    bottom: -20px;
    left: 17px;
    font-size: 14px;
    color: #de6d18;
  }

  @media (max-width: 768px) {
    .dynamic-rows-body {
      .row-container {
        display: block;
        .container-select-optionRow {
          width: 98%;
          margin: 1rem 0.8rem;
        }
        .container-input-row {
          width: 100% !important;
        }
      }
    }
  }
`;

const PSDynamicRow = React.forwardRef(
  (
    { className, labelOption, rows, addRow, maxLength, options, struct, onChange, onBlur, reboot },
    ref
  ) => {
    const [indexAvailable, setIndexAvailable] = useState(-1);

    useEffect(() => {
      setIndexAvailable(-1);
    }, [reboot]);

    return (
      <StyledPsDynamicRow className={className} ref={ref}>
        {rows.length > 0 ? (
          <div className="dynamic-rows-header">
            <div className="header-container-option">
              <label className="pr-1">Nº </label>
              <label>Tipo</label>
            </div>
            <div>
              <label>{labelOption} </label>
            </div>
          </div>
        ) : (
          <div>
            <PSIcon className="ml-4 icon-row" type="plusRow" onClick={addRow} />
          </div>
        )}

        <div className="dynamic-rows-body">
          {rows.map((row, index) => {
            return index < maxLength ? (
              <div className="row-container">
                <div className="container-select-optionRow">
                  <div className="row-numeration">
                    <label>{index + 1}</label>
                  </div>
                  <PSSelect
                    className={`select-optionRow ${
                      indexAvailable === index ? ' select-optionRow-active' : ''
                    }`}
                    disabled={indexAvailable !== index}
                    options={options}
                    selectedValue={row[struct.fieldOption]}
                    onChange={(event) => onChange(index, struct.fieldOption, event.target.value)}
                  />
                </div>
                <div className="container-input-row">
                  <PSTextInput
                    className={`input-row ${indexAvailable === index ? ' input-row-active' : ''}`}
                    value={row[struct.fieldValue] ? row[struct.fieldValue] : ''}
                    disabled={indexAvailable !== index}
                    onChange={(event) =>
                      onChange(
                        index,
                        struct.fieldValue,
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                    type={struct.typeValue ? struct.typeValue : 'text'}
                    pattern="[0-9]{0,13}"
                    onBlur={() => onBlur(index, 'error', struct.fieldValue)}
                  />
                  <PSIcon
                    type="pencil"
                    className="input-row-icon"
                    onClick={() => setIndexAvailable(index)}
                    data-tip={indexAvailable !== index ? 'Editar' : null}
                  />
                  {index === 0 && (
                    <PSIcon
                      className={`icon-row ${rows.length < maxLength ? '' : 'icon-disabled'}`}
                      type="plusRow"
                      data-tip={
                        rows.length < maxLength ? null : 'No puede agregar más de 2 registros'
                      }
                      onClick={addRow}
                    />
                  )}
                  {row.error && !row.errorMessage && (
                    <span className="dynamic-item-error">Error en el formato.</span>
                  )}
                  {row.error && row.errorMessage && (
                    <span className="dynamic-item-error">{row.errorMessage}</span>
                  )}
                </div>
              </div>
            ) : null;
          })}
        </div>
        <ReactTooltip place="right" type="dark" />
      </StyledPsDynamicRow>
    );
  }
);

export default PSDynamicRow;
