import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSIcon from './PSIcon';

const StyledPSMapSearch = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  text-align: center;
  input {
    font-size: 17px;
    border: none;
    outline: none;
    color: #7a7a7a;
    background: #dfdfdf;
    width: 100%;
    border-radius: 20px;
    padding: 10px 22px;
    font-family: 'BlissPro-Regular', sans-serif;
  }
  .icon-search {
    svg {
      width: 25px;
      height: auto;
    }
    .a {
      fill: #2b265a !important;
    }
    .b {
      fill: #e06d18;
    }
  }
  ul {
    margin-top: -20px;
    padding: 15px 20px 16px;
    background: #dfdfdf;
    text-align: left;
    font-family: 'BlissPro-Regular', sans-serif;
    li {
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 768px) {
    input {
      font-size: 15px;
    }
  }
`;

const PSMapSearch = React.forwardRef(
  ({ placeholder, updateCoordenadas, icon, isOnlyRead, ...props }, ref) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        componentRestrictions: { country: ['pe'] },
      },
      debounce: 300,
    });

    const registerRef = useOnclickOutside(() => {
      clearSuggestions();
    });

    const handleInput = (evt) => {
      setValue(evt.target.value);
    };

    const handleSelect = ({ description }) => () => {
      setValue(description, false);
      clearSuggestions();
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          updateCoordenadas({ lat, lng });
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });
    };

    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li key={id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });

    return (
      <StyledPSMapSearch ref={registerRef}>
        <div ref={ref}>
          <PSIcon type={icon} className="icon-search" />
        </div>
        <div>
          <input
            value={value}
            onChange={handleInput}
            disabled={!ready}
            readOnly={isOnlyRead}
            placeholder={placeholder}
          />
          {status === 'OK' && <ul>{renderSuggestions()}</ul>}
        </div>
      </StyledPSMapSearch>
    );
  }
);

PSMapSearch.displayName = 'PSMapSearch';

PSMapSearch.defaultProps = {
  placeholder: 'Esto es un ejemplo',
  updateCoordenadas: () => {},
  icon: 'mapFrom',
  isOnlyRead: false,
};

PSMapSearch.propTypes = {
  placeholder: PropTypes.string,
  updateCoordenadas: PropTypes.func,
  icon: PropTypes.string,
  isOnlyRead: PropTypes.bool,
};

export default PSMapSearch;
