import { Routes } from '../routes';
import {
  STORAGE_POLICY,
  STORAGE_TOKEN_IDPROCESS,
  STORAGE_TOKEN_INITIAL,
  STORAGE_TOKEN_SESSION,
} from './Constant';
import { isLocalHost } from './Util';

const URL_LINK_LIBRARY = process.env.REACT_APP_CARD_URL_LIBRARY;
const URL_IMAGE_PUBLIC = 'https://netprivada.protectasecurity.pe/images/icons/logo_oficial.jpeg';
const ACTION_VISA_PAY = 'https://servicios.protectasecurity.pe/wspddev/api/pago/transaction/';
const DOMAIN_URL = isLocalHost() || `${process.env.REACT_APP_DOMAIN}`;
const ACTION = Routes.LOADING_PAYMENT;
const URL_ACTION = `${ACTION_VISA_PAY}${btoa(`${DOMAIN_URL}${ACTION}`)}`;

export function loadInitLibray(payload, policyId, card, user) {
  const script = document.createElement('script');
  script.src = URL_LINK_LIBRARY;
  script.async = true;
  script.onload = () => checkout(payload, policyId, card, user);
  document.body.appendChild(script);
}

function checkout(data, policyId, card, user) {
  const { sessionToken, codigoComercio, amount, idProcess } = data;
  const { tokenInicial, idTokenizacion } = card;
  const link = window.location.href;
  localStorage.setItem(STORAGE_TOKEN_SESSION, tokenInicial);
  localStorage.setItem(STORAGE_POLICY, policyId);
  localStorage.setItem(STORAGE_TOKEN_INITIAL, idTokenizacion);
  localStorage.setItem(STORAGE_TOKEN_IDPROCESS, idProcess);
  const payload = {
    action: URL_ACTION,
    sessiontoken: sessionToken,
    channel: 'paycard',
    merchantid: codigoComercio,
    purchasenumber: '632517660',
    amount,
    cardholdername: user.givenName || 'Juan',
    cardholderlastname: user.middleName || 'Perez',
    cardholderemail: user.email || 'jperez@gmail.com',
    expirationminutes: '20',
    timeouturl: link,
    merchantlogo: URL_IMAGE_PUBLIC,
    formbuttoncolor: '#ed6e00',
    formbuttontext: 'Confirmar',
    showamount: 'FALSE',
  };
  window.VisanetCheckout.configure({
    ...payload,
    complete(params) {
      alert(JSON.stringify(params));
    },
  });
  window.VisanetCheckout.open();
}
