import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';

import {
  base64toBlob,
  capitalizeFirstLetter,
  fixStructBase64,
  isEmptyArray,
  isEmptyValue,
  maxLengthCheck,
  validateOnlyText,
} from '../../../../util/Util';
import { PARAMS_PAGINATE } from '../../../../util/Constant';
import { menuCode } from '../../../shared/constants/Strings';
import GenericConstancyServices from '../../../shared/services/GenericConstancyServices';
import GetCertificates from '../../../shared/services/GetCertificates';
import { GAProvider } from '../../PSAnalytics';

import { PSModalErrorResponse } from '../../organisms';
import PSLoading from '../../PSLoading';
import PSIcon from '../../PSIcon';

import './index.scss';

const HEADERS = [
  { elementProp: 'documento', headerColumn: 'Doc. Identidad' },
  { elementProp: 'nombre', headerColumn: 'Apellidos y Nombres' },
  { elementProp: 'fechaNacimiento', headerColumn: 'Fecha de Nacimiento' },
  { elementProp: 'fechaInicio', headerColumn: 'Inicio de Vigencia' },
  { elementProp: 'fechaFin', headerColumn: 'Fin de Vigencia' },
];
const MAX_PER_PAGE = 10;

const PSTableIndividualAsegurado = ({
  poliza,
  onCloseOption,
  descriptionHeader,
  typeDocument,
  menuId,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingConstancySelect, setLoadingConstancySelect] = useState(false);
  const { token, userInfo, attributesCore } = useSelector((state) => state.auth);
  const [showModalBroken, setShowModalBroken] = useState(false);
  const [listAsegurados, setListAsegurados] = useState([]);
  const [listAseguradosOriginal, setListAseguradosOriginal] = useState([]);
  const [aseguradoSelected, setAseguradoSelected] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
  const ga = React.useContext(GAProvider);
  const dniRef = useRef();
  const nameRef = useRef();
  const listPaginate = listAsegurados.slice(
    (actualPage - 1) * MAX_PER_PAGE,
    actualPage * MAX_PER_PAGE
  );
  const itemsIterable = Math.ceil(listAsegurados.length / MAX_PER_PAGE);

  const responseListAsegurados = async () => {
    try {
      setLoading(true);
      const response = await GetCertificates.responseListaAsegurados(poliza);
      const { data, statusCode } = response;
      if (statusCode === 500) {
        setShowModalBroken(true);
      } else if (isEmptyArray(data)) {
        setTimeout(() => {
          alert('No se encontraron asegurados');
        }, 1000);
        onCloseOption();
      } else {
        setListAsegurados(data.listaAsegurados);
        setListAseguradosOriginal(data.listaAsegurados);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSearch = () => {
    const valueName = nameRef.current.value.trim();
    const valueDNI = dniRef.current.value.trim();
    let filter = listAseguradosOriginal;

    if (isEmptyValue(valueName) && isEmptyValue(valueDNI)) {
      setListAsegurados(listAseguradosOriginal);
    } else {
      if (!isEmptyValue(valueName)) {
        filter = filter.filter((item) => {
          return item.nombre.search(new RegExp(valueName, 'i')) !== -1;
        });
      }
      if (!isEmptyValue(valueDNI)) {
        filter = filter.filter((item) => {
          return item.documento.search(new RegExp(valueDNI, 'i')) !== -1;
        });
      }
      setListAsegurados(filter);
      setActualPage(1);
    }
  };

  const responseBoletas = async () => {
    setLoadingConstancySelect(true);
    const idBoleta = 'canvas-wrapper-content-asegurado';
    const pdf = new jsPDF('p', 'pt', 'a4');
    const documento = document.getElementById(idBoleta);
    const clonDocumento = documento.cloneNode(true);
    clonDocumento.classList.remove('hidden-content-force');
    clonDocumento.classList.add('download-file-asegurado');
    const name = `${capitalizeFirstLetter(typeDocument)}-${descriptionHeader}-${
      poliza ? poliza.nroPolicy : '7000468446'
    }`;

    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${descriptionHeader}-Constancia Individual Asegurado`,
      action: 'download',
      label: `El usuario descargó una boleta de Constancias Individual de Asegurados`,
      value: 1,
    });

    await pdf.html(clonDocumento, {
      callback(pdf) {
        pdf.save(name);
      },
    });
    clonDocumento.classList.add('hidden-content-force');
    setTimeout(() => {
      alert('Su archivo se ha descargado con éxito');
      setLoadingConstancySelect(false);
    }, 1000);
  };

  const responseBoletasLifeLaw = async () => {
    try {
      setLoadingConstancySelect(true);
      const numberDocument = aseguradoSelected.documento.split('-');
      const beneficiary = {
        document: numberDocument[1].trim(),
        fullName: aseguradoSelected?.nombre,
        birthday: aseguradoSelected?.fechaNacimiento,
      };
      const user = userInfo.attributes;
      const data = {
        data: {
          beneficiaries: [beneficiary],
          titular: user ? (isBusiness ? user?.legalName : user?.givenName) : '',
          policy: poliza?.nroPolicy,
          initialDate: poliza?.dateStart,
          endDate: poliza?.dateEnd,
          sendEmail: false,
        },
      };
      const response = await GenericConstancyServices.responseDocument(data);
      if (response.status === 200 && response.data.document) {
        const fixBase64 = fixStructBase64(response.data.document);
        const blob = base64toBlob(fixBase64, 'application/pdf');
        FileSaver.saveAs(blob, 'constancia generica');
      }
      setLoadingConstancySelect(false);
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-Constancia Individual Asegurado`,
        action: 'download',
        label: `El usuario descargó una boleta de Constancias Individual de Asegurados`,
        value: 1,
      });
    } catch (error) {
      setLoadingConstancySelect(false);
    }
  };

  useEffect(() => {
    if (aseguradoSelected) {
      menuId == menuCode.VIDA_LEY ? responseBoletasLifeLaw() : responseBoletas();
    }
  }, [aseguradoSelected]);

  useEffect(() => {
    responseListAsegurados();
  }, [poliza]);

  useEffect(() => {
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${descriptionHeader}-Constancia Individual Asegurado`,
      action: 'click',
      label: 'El usuario visitó la página Constancia Individual de Asegurados',
      value: 1,
    });
  }, [ga]);

  return (
    <div>
      <PSModalErrorResponse
        openModal={showModalBroken}
        onClick={() => {
          setShowModalBroken(false);
          onCloseOption();
        }}
      />
      <div className="table-component">
        {loading ? (
          <div className="flex-center">
            <PSLoading />
          </div>
        ) : (
          listAseguradosOriginal && (
            <>
              {listAseguradosOriginal.length === 0 ? (
                <div className="flex-center empty-list">
                  <span>No se encontraron asegurados relacionados a esta póliza </span>
                </div>
              ) : (
                <>
                  <div className="title">
                    <h4>Búsqueda</h4>
                  </div>
                  <div className="table-filters d-flex">
                    <div className="filter-group">
                      <label htmlFor="searchDNI">DNI</label>
                      <input
                        id="searchDNI"
                        ref={dniRef}
                        type="text"
                        placeholder="Buscar"
                        onKeyPress={(e) => maxLengthCheck(e)}
                        onChange={() => onSearch()}
                        maxLength="8"
                      />
                    </div>

                    <div className="filter-group">
                      <label htmlFor="searchFullName">Apellidos y Nombres</label>
                      <input
                        id="searchFullName"
                        ref={nameRef}
                        type="text"
                        placeholder="Buscar"
                        onKeyPress={(e) => validateOnlyText(e)}
                        onChange={() => onSearch()}
                        maxLength="60"
                      />
                    </div>
                  </div>
                  <div className="container-table">
                    <table>
                      <thead>
                        <tr className="">
                          {HEADERS.map((header, index) => (
                            <th
                              key={header.elementProp}
                              className="table-header"
                              colSpan={index === 0 ? 2 : 1}
                            >
                              {header.headerColumn}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {listPaginate.length === 0 ? (
                          <tr>
                            <td className="table-item" colSpan="6">
                              No se encontraron asegurados con el filtro aplicado
                            </td>
                          </tr>
                        ) : (
                          listPaginate.map((item, index) => {
                            return (
                              <tr key={item.numero}>
                                <td className="table-item">
                                  {aseguradoSelected?.numero === item.numero &&
                                  loadingConstancySelect ? (
                                    <div className="loading">
                                      <PSLoading width={20} height={20} color="#2B0D61" />
                                    </div>
                                  ) : (
                                    <PSIcon
                                      className="icon"
                                      type="downloadFile"
                                      onClick={() => {
                                        setAseguradoSelected(item);
                                      }}
                                    />
                                  )}
                                </td>
                                {HEADERS.map((header) => (
                                  <td className="table-item">{item[header.elementProp] || '-'}</td>
                                ))}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2">
                    <ReactPaginate
                      pageCount={itemsIterable}
                      initialPage={actualPage - 1}
                      onPageChange={(page) => {
                        setActualPage(page.selected + 1);
                      }}
                      forcePage={actualPage - 1}
                      {...PARAMS_PAGINATE}
                    />
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

PSTableIndividualAsegurado.displayName = 'PSTableIndividualAsegurado';

PSTableIndividualAsegurado.defaultProps = {
  poliza: null,
  onCloseOption: () => {},
  descriptionHeader: '',
  typeDocument: '',
};

PSTableIndividualAsegurado.propTypes = {
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
  descriptionHeader: PropTypes.string,
  typeDocument: PropTypes.string,
};

export default PSTableIndividualAsegurado;
