/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PSLoading from '../../PSLoading';
import PSComponentForm from '../../PSComponentForm';
import PSList from '../../PSList';
import { CodeOptProduct, menuCode } from '../../../shared/constants/Strings';
import PSButton from '../../PSButton';
import FormsService from '../../../shared/services/FormsService';
import { createErrorPage } from '../../../redux/auth';
import { HeaderTableBeneficiary } from '../../../../util/Constant';
import PSDynamicTableEditable from '../../PSDynamicTableEditable';
import { StyledContainerTable } from '../../PSBodyProduct';

const PSFormChangeBeneficiary = React.forwardRef(
  ({
    onCloseOption,
    form,
    isPreview,
    loadingSaveForm,
    onSaveForm,
    getInfoPreview,
    setIsPreview,
    availableSendForm,
    beneficiaryInfo,
    onSelectBeneficiary,
    onChangeBeneficiary,
    onDeleteBeneficiary,
    onChangeModePage,
    onBlurModePage,
    nroPolicy,
    dataPolicy,
    menuId,
  }) => {
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.auth);

    const enabledForm = async () => {
      try {
        if (menuId === menuCode.AHORRO_TOTAL && dataPolicy.rol !== 'Asegurado') {
          dispatch(
            createErrorPage({
              onAction: () => {},
              state: 'true',
              icon: 'broken',
              title: 'Error!',
              message: 'La poliza no se encuentra habilitada para Modificación de beneficiarios',
            })
          );
          onCloseOption();
        } else if (menuId === menuCode.RENTA_TOTAL) {
          const { data, status } = await FormsService.validateFormByPolicy(nroPolicy, menuId);
          if (status.id !== 200) {
            dispatch(
              createErrorPage({
                onAction: () => {},
                state: 'true',
                icon: 'broken',
                title: 'Error!',
                message: 'La poliza no se encuentra habilitada para Modificación de beneficiarios',
              })
            );
            onCloseOption();
          }
        }
      } catch (error) {
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message: 'Error al validar poliza',
          })
        );
        onCloseOption();
      }
    };

    useEffect(() => {
      if (nroPolicy !== -1) {
        enabledForm();
      }
    }, [nroPolicy]);

    return loading ? (
      <div className="loading">
        <h1>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at dolorem fugiat
          hic magni molestias mollitia non officiis optio. Alias architecto consequatur facilis
          maxime nisi quos recusandae sapiente tempora?
        </h1>
        <PSLoading />
      </div>
    ) : (
      isValid && (
        <>
          {!isPreview ? (
            <>
              <span>Seleccione beneficiario a modificar: </span>
              <PSDynamicTableEditable
                headers={HeaderTableBeneficiary}
                showCheck
                valueSelected={beneficiaryInfo.currentBeneficiary}
                items={beneficiaryInfo.listOfBeneficiary}
                onSelect={onSelectBeneficiary}
                onChange={onChangeBeneficiary}
                onDelete={onDeleteBeneficiary}
                hasEdit
                hasDelete
              />
              {beneficiaryInfo.listOfBeneficiary.length === 0 && (
                <div className="align-center">
                  <span>
                    No se ha encontrado beneficiarios/Se eliminaron todos los benficiarios
                  </span>
                </div>
              )}
              <PSComponentForm
                showInfo
                from={form[CodeOptProduct.ADB]}
                onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.ADB)}
                onBlur={(index) => onBlurModePage(index, CodeOptProduct.ADB)}
                title="Deseo incluir a nuevos beneficiarios:"
              />
            </>
          ) : (
            <>
              <PSList items={getInfoPreview(form[CodeOptProduct.ADB])} />
              <StyledContainerTable>
                <PSDynamicTableEditable
                  hasEdit={false}
                  hasDelete={false}
                  showCheck={false}
                  headers={HeaderTableBeneficiary}
                  items={beneficiaryInfo.listOfBeneficiary}
                />
              </StyledContainerTable>
            </>
          )}

          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.ADB)}
              disabled={!availableSendForm(CodeOptProduct.ADB)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      )
    );
  }
);

PSFormChangeBeneficiary.displayName = 'PSFormChangeBeneficiary';

PSFormChangeBeneficiary.defaultProps = {
  nroPolicy: null,
  menuId: null,
  onCloseOption: () => {},
  beneficiaryInfo: {
    listOfBeneficiary: [],
    currentBeneficiary: -1,
  },
  dataPolicy: {
    rol: 'Beneficiario',
  },
};

PSFormChangeBeneficiary.propTypes = {
  nroPolicy: PropTypes.number,
  menuId: PropTypes.string,
  onCloseOption: PropTypes.func,
  beneficiaryInfo: PropTypes.object,
  dataPolicy: PropTypes.object,
};

export default PSFormChangeBeneficiary;
