import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_CLIENT_ID,
  },
});

export default Auth;
