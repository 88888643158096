import AdvertisingService from '../../../shared/services/AdvertisingService';
import groupBy from 'lodash/groupBy';
import {BannersModel} from "../../../shared/model/BannersModel";

export const CREATE_BANNERS = 'Auth/CREATE_BANNERSO';

export const setCreateBanners = (created) => ({
  type: CREATE_BANNERS,
  payload: created,
});

export const createBanners = () => {
  return async (dispatch) => {
    try {
      const response = await AdvertisingService.getAdvertising();

      if(response){
        const dataGroupByType = groupBy(response.map(item=>new BannersModel(item)).filter(i=>i.isAvailable===true),'type');
        const dataBanners = {
          opportunityOfMonth:dataGroupByType['1'] ? dataGroupByType['1']:[] ,
          pqp:dataGroupByType['2'] ? dataGroupByType['2']:[] ,
          promotions:dataGroupByType['3'] ? dataGroupByType['3']:[] ,
        };
        dispatch(setCreateBanners(dataBanners));
      }
    } catch (error) {

    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  banners: action.payload,
});
