import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import PSBox from './PSBox';
import PSTitle from './PSTitle';
import PSSubtitle from './PSSubtitle';
import PSIcon from './PSIcon';

const StyledPsContainerIcon = styled.div`
  margin: 30px 0 10px 0;
  text-align: center;
`;

const PSHeaderLogin = React.forwardRef(({ className, title, subtitle, icon, children }, ref) => {
  return (
    <div className={className} ref={ref}>
      {subtitle && (
        <PSBox>
          <PSSubtitle>{subtitle}</PSSubtitle>
        </PSBox>
      )}
      <StyledPsContainerIcon>
        <PSIcon type={icon} />
      </StyledPsContainerIcon>
      <PSBox>
        <PSTitle className="f-extraBold">{title}</PSTitle>
        {children}
      </PSBox>
    </div>
  );
});

PSHeaderLogin.displayName = 'PSHeaderLogin';

PSHeaderLogin.defaultProps = {
  className: null,
  icon: 'home',
  title: 'Welcome',
};

PSHeaderLogin.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default PSHeaderLogin;
