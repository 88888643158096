import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPSList = styled.div`
  .container-list {
    width: 80%;
    margin: 50px auto;
    display: block;
    color: #2b265a;
    font-size: 14px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .error {
    color: red;
  }

  .error-preview {
    color: red;
    font-size: 10px;
    padding: 0 20px 10px;
  }

  .container-row {
    display: flex;
    min-height: 40px;
    .item {
      width: 70%;
      border-radius: 0 50px 50px 0;
      display: flex;
      align-items: center;
    }
    .white {
      background: #ffffff;
    }
    .tittle {
      width: 30%;
      font-weight: 700;
      border-radius: 50px 0 0 50px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .container-list {
      width: 100%;
    }

    .container-row {
      .item {
        width: 60%;
      }

      .tittle {
        width: 40%;
      }
    }
  }
`;

const PSList = ({ items }) => {
  return (
    <StyledPSList>
      <div className="container-list">
        {items.map((item, index) => (
          <div key={item.header}>
            <div className={`container-row  ${item.error ? 'error' : ''}`}>
              <div className={`${index % 2 === 0 ? `white` : ``} tittle`}>
                <span className="ml-20">{item.header}</span>
              </div>
              <div className={`${index % 2 === 0 ? `white` : ``} item`}>
                <span>{item.description}</span>
              </div>
            </div>
            {item.error && (
              <div className="error-preview">
                <span>{item.error}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </StyledPSList>
  );
};

PSList.displayName = 'PSList';

PSList.defaultProps = {
  items: [],
};

PSList.propTypes = {
  items: PropTypes.array,
};

export default PSList;
