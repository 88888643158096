import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-left: 0px !important;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => (color)} transparent transparent transparent ;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
    margin-left: 0px !important;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function PSLoading({ height, width, color}) {
  return (
    <LoadingContainer height={height} width={width} color={color}>
      <div />
      <div />
      <div />
      <div />
    </LoadingContainer>
  );
}

PSLoading.defaultProps = {
  height: 80,
  width: 80,
  color: '#fff',
};
