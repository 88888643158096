import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPsTitle = styled.div`
  letter-spacing: 0px;
  text-align: center;
  line-height: initial;
  color: #242425;
  opacity: 1;
  font-size: ${(props) => props.level}px;
  margin: 4px 2px;
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`;

const PSTitle = React.forwardRef(({ children, className, inline, level }, ref) => (
  <StyledPsTitle className={className} inline={inline} level={level} ref={ref}>
    <label>{children}</label>
  </StyledPsTitle>
));

PSTitle.displayName = 'PSTitle';

PSTitle.defaultProps = {
  className: null,
  inline: false,
  level: 30,
};

PSTitle.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

export default PSTitle;
