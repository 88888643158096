import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCoverage = styled.div`
  color: #2b0d61;
  margin: 1rem;
  .coverage-points {
    text-align: justify;
    letter-spacing: 0px;
    font-family: 'BlissPro-Regular', sans-serif;
  }

  .coverage-exceptions {
    font-family: 'BlissPro-ExtraLight', sans-serif;
  }
  .coverage-observations {
    font-family: 'BlissPro-Regular', sans-serif;
    padding-top: 10px;
  }
`;

const PSCoverage = React.forwardRef(({ className, ListCoverage }, ref) => (
  <StyledCoverage className={className} ref={ref}>
    <ul className="coverage-points">
      {ListCoverage.points.map((point) => (
        <div className="d-flex" key={point}>
          <span>°</span>
          <li className="ml-2">{point}</li>
        </div>
      ))}
    </ul>
    <ul className="coverage-exceptions">
      {ListCoverage.exceptions.map((exception) => (
        <div className="d-flex" key={exception}>
          <span>*</span>
          <li className="ml-2">{exception}</li>
        </div>
      ))}
    </ul>

    <ul className="coverage-observations">
      {ListCoverage.observations.map((exception) => (
        <div className="d-flex">
          <li className="ml-2" key={exception}>
            {exception}
          </li>
        </div>
      ))}
    </ul>
  </StyledCoverage>
));

PSCoverage.displayName = 'PSCoverage';

PSCoverage.defaultProps = {
  className: null,
  ListCoverage: {},
};

PSCoverage.propTypes = {
  className: PropTypes.string,
  ListCoverage: PropTypes.object,
};

export default PSCoverage;
