export const Coverage = {
  RENTA_TOTAL: {
    points: [
      'Renta mensual.',
      'Gastos de sepelio.'
    ],
    observations: [],
    exceptions: [],
  },
  AHORRO_TOTAL: {
    points: [
      'Fallecimiento del Asegurado.',
      'Devolución del Capital.',
      'Fallecimiento Accidental del Asegurado.',
      'Pago de Renta Temporal (pago de intereses una vez al año).',
    ],
    observations: [],
    exceptions: [],
  },
  SCTR: {
    points: [
      'Pensión o indemnización de invalidez total o parcial y temporal o permanente.',
      'Pensión de sobrevivencia.',
      ' Reembolso de gastos de sepelio.',
    ],
    observations: [],
    exceptions: [],
  },
  RENTA_VITALICIA: {
    points: [
      'Moneda soles Ajustados, indexados o dólares ajustados.',
      'Pensión de por Vida.',
      'Riesgo a cargo de la compañía de Seguros.',
      'Derecho a Herencia si cuenta con periodo garantizado.',
      'Derecho a Essalud en forma vitalicia.',
      'Es inembargable.',
      'Periodo Garantizado: Pago al 100% de la pensión del afiliado a sus beneficiarios en cuanto fallezca dentro del plazo pactado.',
    ],
    observations: [],
    exceptions: [],
  },
  SOAT: {
    points: [
      'Indemnización por Muerte.',
      'Incapacidad temporal (a razón de 1/30 de la RMV* por cada día).',
      'Invalidez permanente.',
      'Gastos médicos.',
      'Gastos de Atención Farmacéutica.',
      'Ambulancia.',
      'Gastos de sepelio.',
    ],
    observations: [],
    exceptions: ['RMV: Remuneración mínima vital.'],
  },
  VIDA_LEY: {
    points: [
      'Muerte natural (*)',
      '16 remuneraciones mensuales(**)',
      'Muerte accidental.',
      '32 remuneraciones mensuales(**).',
      'Invalidez Total y Permanente por Accidente.',
      '32 remuneraciones mensuales(**).',
    ],
      observations: [
      '(*)Obligatoria a partir del 01/01/2021 conforme a lo establecido en el Decreto Urgencia N° 044-2019.',
      '(**) Promedio mensual asegurable, considerando el último trimestre hasta el mes previo del accidente.',
    ],
    exceptions: [],
  },

  ACCIDENTES_PERSONALES: {
    points: [
      'Muerte Accidental.',
      'Gastos de Sepelio por Muerte Accidental.',
      'Gastos de Sepelio por Muerte Natural.',
      'Gastos de Curación por Accidente.',
      'Invalidez Total y Permanente por Accidente.',
    ],
    observations: [],
    exceptions: [],
  },
  SEGURO_DESGRAVAMEN: {
    points: [
      'Muerte Natural.',
      'Muerte Accidental.',
      'Invalidez Total y Permanente por Accidente o Enfermedad.'
    ],
    observations: [],
    exceptions: [],
  },
};
