import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledPsDopZone = styled.div`
  width: 100%;
  .section-drop-zone {
    display: flex;
    .container-name-file {
      margin-top: 8px;
      width: 60%;
      height: 39px;
      background: #e3e3e3 0% 0% no-repeat padding-box;
      .container-name {
        display: block;
        li {
          font-size: 12px;
          margin: auto;
          width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          list-style: none;
        }
      }
    }
    .container-label-drop {
      cursor: pointer;
      width: 40%;
      height: 51px;
      border-radius: 4px;
      border: 1px solid #7a7a7a;
      .container-label {
        cursor: pointer;
        display: flex;
        label {
          cursor: pointer;
          margin: auto;
          margin-top: 0.7rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .section-drop-zone {
      .container-label-drop {
        width: 55%;
        .container-label {
          font-size: 12px;
          text-align: center;
          label {
            margin: 0.5rem;
          }
        }
      }

      .container-name-file {
        width: 45%;
        .container-name {
          li {
            width: 200px !important;
          }
        }
      }
    }
  }
`;

const PSDopzone = ({ onChange, FileProps, isMultiple }) => {
  const [file, setFile] = useState([]);
  const onDrop = (e, file) => {
    if (file.length > 0) {
      const regexp = /jpeg|jpg|png|pdf?/gi;
      const isValidFile = !!file[0].file.type.match(regexp);
      if (isValidFile) {
        const fileFilter = file.filter((item, index) => {
          return index <= 1;
        });
        setFile(fileFilter);
        onChange(fileFilter);
      }
    }
  };

  useEffect(() => {
    if (file.length === 0 && FileProps) {
      setFile([...FileProps]);
    }
  }, [FileProps]);

  return (
    <StyledPsDopZone>
      <Dropzone
        maxSize={2048}
        accept=".jpeg,.jpg,.png,.pdf"
        multiple={isMultiple}
        maxFiles={3}
        onDragEnter={(e) => {
          console.log('valores', e);
        }}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="section-drop-zone">
            <div className="container-name-file">
              <div className="container-name">
                {file.map((item) => {
                  return <li key={item.file.name}>{item.file.name}</li>;
                })}
              </div>
            </div>
            <div {...getRootProps()} className="container-label-drop">
              <input {...getInputProps()} />
              <div className="container-label">
                <label>Seleccionar archivo (jpg, png, pdf)</label>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </StyledPsDopZone>
  );
};
PSDopzone.defaultProps = {
  isMultiple: false,
};
PSDopzone.propTypes = {
  isMultiple: PropTypes.bool,
};

export default PSDopzone;
