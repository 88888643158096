import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { optionsHeaderNewSletter, routesSletter } from '../../util/Constant';
import { existInUrl } from '../../util/Util';
import { GAProvider } from './PSAnalytics';

const StyledHeaderNewsletter = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  .container-option,
  .container-option-sel {
    flex-basis: 0;
    flex-grow: 1;
    color: #aeaeae;
    flex-shrink: 1;
    text-align: center;
  }
  .container-option-sel {
    a {
      color: #ed6e00 !important;
    }
    .point {
      background-color: #ed6e00 !important;
    }
  }
  .option-with-dash {
    border-right: 0.5px solid #aeaeae;
  }
  a {
    color: #aeaeae !important;
    &:hover {
      color: #ed6e00 !important;
      cursor: pointer;
    }
  }
  label {
    opacity: 1;
    font-size: 15px;
    font-family: 'BlissPro-Bold', sans-serif;
    display: block;
  }
  .point {
    height: 8px;
    width: 8px;
    margin-right: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    &:hover {
      background-color: #ed6e00 !important;
      cursor: pointer;
    }
  }
`;

const PSNewsletterHeader = React.forwardRef(({ reloadMagazines, className }, ref) => {
  const optionSelected = optionsHeaderNewSletter.find((option) => existInUrl(option.value));
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;

  const goToSection = (index, description) => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Newsletter-${description}`,
      action: 'click',
      label: `El usuario visitó la categoría ${description} de Newsletter`,
      value: 1,
    });
    reloadMagazines(index);
  };

  return (
    <StyledHeaderNewsletter ref={ref} className={className}>
      {optionsHeaderNewSletter.map((option, index) => {
        const isSel = optionSelected ? option.id === optionSelected.id : null;
        return (
          <div key={option.id} className={isSel ? 'container-option-sel' : 'container-option'}>
            <label
              className={index < optionsHeaderNewSletter.length - 1 ? 'option-with-dash' : ' '}
            >
              {option.id === 0 && <span className="point" />}
              <Link to={option.link} onClick={() => goToSection(index, option.description)}>
                {option.description}{' '}
              </Link>
            </label>
          </div>
        );
      })}
    </StyledHeaderNewsletter>
  );
});

PSNewsletterHeader.defaultProps = {
  reloadMagazines: () => {},
};

PSNewsletterHeader.propTypes = {
  reloadMagazines: PropTypes.func,
};

export default PSNewsletterHeader;
