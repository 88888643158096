import {doGetUnauthorized, doPostUnauthorized, doGet, doPost, doPostWithParams} from './Api';

export default class GetTicket {
  static async responseGetMotives(type) {
    return await doGetUnauthorized(`${process.env.REACT_APP_GET_TICKET}GetMotives/${type}`);
    }

  static async responseGetSubMotives(type,motive) {
    return await doGetUnauthorized(`${process.env.REACT_APP_GET_TICKET}GetSubMotives?tipo=${type}&motivo=${motive}`);
  }

  static async responseGetViaRespuesta() {
    return await doGetUnauthorized(`${process.env.REACT_APP_GET_TICKET}GetViaRespuesta`);
  }

    static async responseGetTickets() {
      const response= await doGet(`${process.env.REACT_APP_GET_TICKES}`);
      return response.data;
  }

  static async responseRegisterTickets2(data) {
    const response= await doPost(`${process.env.REACT_APP_GET_TICKES}`,data);
    return response.data;
  }

  static async responseRegisterTickets(data, header) {
    const response= await doPostWithParams(`${process.env.REACT_APP_BASE_URL_NET}/jira/v0/tickets/claims `,data, header);
    return response.data;
  }

  static async responseUploadFileTickets(data) {
    const response= await doPostUnauthorized(`${process.env.REACT_APP_MANAGED_TICKET}RegistrarArchivosAdjunto`,data);
    return response.data;
  }

  static async responseGetTicketDetail(codigo) {
    return await doGetUnauthorized(`${process.env.REACT_APP_GET_TICKET_DETAIL}${codigo}`);
  }
}
