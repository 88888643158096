export const SET_MODE_EDIT = 'Auth/SET_MODE_EDIT';

export const setEdit = (isEdit) => ({
  type: SET_MODE_EDIT,
  payload: isEdit,
});

export const reducer = (state, action) => ({
  ...state,
  isEditData: action.payload,
});
