import React from 'react';
import PropTypes from 'prop-types';
import { pathServer } from '../../../../util/Constant';
import PSButton from '../../PSButton';
import { PSInputIcon } from '../../atoms';
import './index.scss';

const PSFormCard = React.forwardRef(({ className, close }, ref) => (
  <div className={`form-card ${className}`} ref={ref}>
    <div className="form-card-header">
      <img src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`} alt="protecta" />
      <p>Tranquilo tu información de pago se guardará de manera segura</p>
    </div>
    <div className="form-card-body">
      <p>Actualizar tarjeta</p>
      <div className="align-center ">
        <img src={`${pathServer.PATH_INTRANET}payments.png`} alt="" width="300" />
      </div>
      <div className="form-group">
        <PSInputIcon placeholder="1234 1234 1234 1234" icon="ic-card.svg" />
        <div className="d-flex">
          <PSInputIcon placeholder="11/22" icon="ic-calendar.svg" />
          <PSInputIcon placeholder="123" className="ml-2" icon="ic-card.svg" />
        </div>
        <div className="d-flex">
          <PSInputIcon placeholder="Gabriel" icon="ic-user-solid.svg" />
          <PSInputIcon placeholder="Vizcarra" className="ml-2" icon="ic-user-solid.svg" />
        </div>
        <PSInputIcon placeholder="Correo" icon="ic-email.svg" />
      </div>
    </div>
    <div className="form-card-footer">
      <PSButton className="btn-cancel mr-1">Cancelar </PSButton>
      <PSButton>Actualizar </PSButton>
    </div>
  </div>
));

PSFormCard.displayName = 'PSFormCard';

PSFormCard.defaultProps = {
  className: null,
  close: () => {},
};

PSFormCard.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func,
};

export default PSFormCard;
