import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hasPropertyTrue } from '../../util/Util';

const MIN_ROWS_TABLE = 5;

const StyledPSTableCoupon = styled.div`
  /* Track */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #cbcbcb;
    margin-left: 0.8rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ed6e00;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #cbcbcb;
  // }

  overflow-y: scroll;
  height: ${(props) => (props.lengthTable > MIN_ROWS_TABLE ? '200px' : '100%')};
  .container__table {
    width: 97%;
    margin: 20px 16px 25px 16px;
    .tr--disabled {
      td {
        cursor: not-allowed;
        color: #7a7a7a !important;
      }
    }
    .table__header{
        &.first__column{
        border-radius: 8px 0 0 0 ;
        }
        &.last__column{
        border-radius:0 8px 0 0;
        }
    }
   
    .table__header,
    .table__item {
      text-align: flex-start;
    }
    .lastRow--firstColumn{
        border-radius:0 0 0 8px;
    }
    .lastRow--lastColumn{
        border-radius: 0 0 8px 0 ;
    }
    .table__header {
      background:white; 
      font-weight: bold;
      color: #ed6e00;
      padding: 0.2rem 0.2rem 0.4rem 0.2rem;
      border-bottom:2px solid #D9D9D9;
    }
    .table__item {
      background-color: #fff;
      color: #2b0d61;
      padding: 0.4rem 0.2rem 0.1rem 0.2rem;
      &.first__column {
        padding: 0.3rem 0rem 0.1rem 1.5rem;
      }  
    }  
   
   
  
  }
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      // display: none; /* Ocultar scroll */
    }
  }

  @media (max-width: 768px) {
    .container__table {
      min-width: 780px;
    }
  }
`;

const PSTableCoupon = ({
  headers,
  items,
  onSelect,
  hasEdit,
  showCheckBox,
  listSelect,
  useKushki,
  couponDisabled,
}) => {
  const lengthTable = items ? items.length : 1;
  const showDisabled = hasPropertyTrue(items, 'isDisabled');
  const handleShowDisabled = () => {
    if (showDisabled) couponDisabled(); 
    };
  
  return (
    <StyledPSTableCoupon
      lengthTable={lengthTable}
      numColumn={headers.length}
      
    >
      <table className="container__table">
        <thead>
          <tr>
            {showCheckBox && <th className="table__header  first__column" />}
            {headers.map((header, index) => (
              <th key={header.elementProp}
                 className={`table__header${
                 index === headers.length - 1 ? ' last__column' : ''
                }`}
                >
                {header.headerColumn}
              </th>
            ))}
            {hasEdit && <th className="table__header" />}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
          
            const isDisabled = false;

            return (
              <tr key={item.id } className={isDisabled ? 'tr--disabled ' : 'tr--enabled'}>
               
                {showCheckBox ? (
                  <td
                    className={`table__item first__column ${
                      index === items.length - 1 ? 'lastRow--firstColumn' : ''
                    }`}
                  >
                    <input
                      disabled={item.isDisabled}
                      name={`checkbox${index}`}
                      type="checkbox"
                      style={{ display: !useKushki ? 'none' : '' }}
                      value={item.value}
                      checked={listSelect.includes(item.value)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        if(!showDisabled) onSelect(item.value, isChecked);
                        if(isChecked) handleShowDisabled();                   
                      }}
                    />
                  </td>
                ) : null}

                {headers.map((header, i) => {
                  const useExponent =
                    item.producto === 'Vida con Devolución Protecta' &&
                    header.elementProp === 'productoCode';
                  return (
                    <td
                      key={item.id + header.elementProp}
                      className={`table__item ${
                        i === 0  && !showCheckBox ? 'first__column' : ''
                      }${i === headers.length - 1  ? ' last__column' : ''}${
                      index === items.length - 1 ? ' lastRow--lastColumn' : ''
                    } `}
                    >
                      { useExponent ? (
                        <div>
                          {item[header.elementProp]} <sup>+</sup>
                        </div>
                      ) : (
                        item[header.elementProp]
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledPSTableCoupon>
  );
};

PSTableCoupon.displayName = 'PSTableCoupon';

PSTableCoupon.defaultProps = {
  headers: [],
  onSelect: () => {},
  isLastColumnCentered: false,
};

PSTableCoupon.propTypes = {
  headers: PropTypes.array,
  onSelect: PropTypes.func,
  isLastColumnCentered: PropTypes.bool,
};

export default PSTableCoupon;
