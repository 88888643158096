import { findValue } from '../../../../util/Util';
import { getTypeVia } from '../../../pages/HomeUpdateData/constants';

export const LOAD_DIRECTIONS = 'Auth/LOAD_DIRECTIONS';

export const setLoadDirections = (form) => ({
  type: LOAD_DIRECTIONS,
  payload: form,
});

export const loadDirections = (formLocation) => {
  return async (dispatch) => {
    const address = formLocation
      ? `${getTypeVia(formLocation[5].value)} ${formLocation[7].value?.toLocaleUpperCase()} ${
          formLocation[6].value ? formLocation[6].value : ''
        }, ${findValue(formLocation[1].options, formLocation[1].value, 'value')} , ${findValue(
          formLocation[2].options,
          formLocation[2].value,
          'value'
        )} , ${findValue(formLocation[3].options, formLocation[3].value, 'value')}`
      : '-';
    dispatch(setLoadDirections(address));
  };
};

export const reducer = (state, action) => ({
  ...state,
  address: action.payload,
});
