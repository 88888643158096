import React, { useState } from 'react';
import styled from 'styled-components';
import PSIcon from './PSIcon';
import PSLabel from './PSLabel';
import PSCarousel from './PSCarousel';

const messages = [
  {
    id: 0,
    option: 'tramite',
    value:
      'Sabemos lo importante que es para tí pasar más tiempo con los tuyos, ahora puedes realizar los trámites de tu seguro sin moverte de casa.',
  },
  {
    id: 1,
    option: 'tramite',
    value:
      'Te hacemos la vida más simple, conoce más sobre tu póliza, las coberturas de tu seguro y el estado de tus trámites con un solo clic.',
  },
  {
    id: 2,
    option: 'tramite',
    value:
      'Queremos que te sientas especial, Club Protecta Security el programa exclusivo para nuestros clientes con sorteos y promociones a precios imbatibles.',
  },
];

const StyledPsOptionsLanding = styled.div`
  text-align: center;
  @media (max-width: 576px) {
    margin-top: 250px;
  }
  .products {
    padding: 3rem 4rem 1rem 4rem;
    .container-children{
      min-height: 148px;
    }
  }
  label {
    font-family: 'BlissPro-ExtraBold', sans-serif;
    color: #2b0d61;
    font-size: 16px;
  }
  .message {
    background-color: #ed6e00;
    p {
      font-size: 17px;
      width: 70%;
      margin: 0 auto;
      padding: 3% 10%;
      color: white;
      font-family: 'BlissPro-ExtraBold', sans-serif;
    }
  }
  .a {
    fill: #2b0d61 !important;
  }
  .column:hover {
    .a {
      fill: #ed6e00 !important;
    }
    label {
      color: #ed6e00 !important;
    }
  }

  @media (max-width: 700px) and (min-width: 300px) {
    .paragrapht {
      width: 90% !important;
      height: 120px;
      padding: 0 3% !important;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
`;

const PSOptionsLanding = React.forwardRef(({ className }, ref) => {
  const [option, setOption] = useState(0);

  const handleHover = (optionSel) => {
    setOption(optionSel);
  };

  return (
    <StyledPsOptionsLanding ref={ref} className={className}>
      <div className="columns products display-none-response">
        <div className="column">
          <PSIcon onMouseOver={() => handleHover(0)} type="bodyFirst" />
          <PSLabel>Trámites</PSLabel>
        </div>
        <div className="column">
          <PSIcon onMouseOver={() => handleHover(1)} type="bodySecond" />
          <PSLabel>Consultas</PSLabel>
        </div>
        <div className="column">
          <PSIcon onMouseOver={() => handleHover(2)} type="bodyFourth" />
          <PSLabel>Club Protecta Security</PSLabel>
        </div>
      </div>

      <PSCarousel className="products display-only-response" action={handleHover}>
        <div className="column" key={1}>
          <PSIcon onMouseOver={() => handleHover(0)} type="bodyFirst" />
          <PSLabel>Trámites</PSLabel>
        </div>
        <div className="column" key={2}>
          <PSIcon onMouseOver={() => handleHover(1)} type="bodySecond" />
          <PSLabel>Consultas</PSLabel>
        </div>
        <div className="column" key={3}>
          <PSIcon onMouseOver={() => handleHover(2)} type="bodyFourth" />
          <PSLabel>Club Protecta Security</PSLabel>
        </div>
      </PSCarousel>
      <div className="message">
        <p className="paragrapht">{messages[option].value}</p>
      </div>
    </StyledPsOptionsLanding>
  );
});

PSOptionsLanding.displayName = 'PSOptionsLanding';

export default PSOptionsLanding;
