import { doPost, doPostWithParams, doGetWithParams } from './Api';

export default class GenericConstancyServices {
  static async responseDocument(data) {
    const response = await doPost(`${process.env.REACT_APP_CONSTANCIA}`, data);
    return response;
  }

  static async responseDocumentUserNatural(data, header) {
    const response = await doPostWithParams(
      `${process.env.REACT_APP_CONSTANCIA_NATURAL}`,
      data,
      header
    );
    return response;
  }

  static async responseDocumentPensioner(headers) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_CONSTANCIA_PENSIONER}`,
      headers
    );
    return response.data;
  }
}
