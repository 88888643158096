import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'react-responsive-modal';
import { useDispatch } from 'react-redux';

import { createErrorPage } from '../redux/auth';
import GetTicket from '../shared/services/TicketServices';

import zoom from '../../assets/images/zoom.svg';
import PSSimpleTable from './PSSimpleTable';
import PSLoading from './PSLoading';
import PSDownloadPDFButton from './PSDownloadPDFButton';
import PSIcon from './PSIcon';

const StyledPSViewPDF = styled.button`
  background-image: url(${zoom});
  background-color: #ffffff;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  border: none;
  &: disabled {
    cursor: not-allowed;
  }
`;

const StyledModalContent = styled.div`
  padding: 2rem;
  max-width: 600px;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledModalTitle = styled.h3`
  color: #160145;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  padding: 0 50px;
`;

const StyledModalSubtitle = styled.p`
  color: black;
  margin-botton: 16px;
`;

const StyledCloseButtonWrapper = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  & path {
    fill: #e16d19;
  }
`;

const StyledModalBody = styled.div`
  background-color: #ececec;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding: 1rem;
`;

const StyledTableWrapper = styled.div`
  width: 100%;
`;

const StyledDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
`;

const StyledFooterText = styled.p`
  margin: 0 25px;
  text-align: justify;
`;

const StyledBoldData = styled.span`
  font-weight: bold;
`;

const headerTableFather = [
  { elementProp: 'name', headerColumn: 'Nombre del documento' },
  { elementProp: 'path', headerColumn: 'Descargar' },
];

const PSViewPDF = React.forwardRef(({ isEnabled, code, type }, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
    loadList();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const loadList = async () => {
    try {
      setLoading(true);
      const response = await GetTicket.responseGetTicketDetail(code);
      const {
        status,
        data: {
          data: responseData,
          status: { id: responseStatus },
        },
      } = response;
      if (status === 200 && responseStatus === 201) {
        setData(responseData);
      } else {
        setOpen(false);
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message: `No se pudo obtener la respuesta del reclamo ${code}`,
          })
        );
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledPSViewPDF onClick={handleOpen} type="button" disabled={!isEnabled} />
      <Modal
        ref={ref}
        open={open}
        animationDuration={0}
        onClose={() => {}}
        showCloseIcon={false}
        onOverlayClick={() => {}}
        closeOnOverlayClick={false}
      >
        <StyledModalContent>
          <StyledCloseButtonWrapper>
            <PSIcon type="close" onClick={handleClose} />
          </StyledCloseButtonWrapper>
          {loading ? (
            <PSLoading color="#ed6e00" width={40} height={40} />
          ) : (
            data && (
              <StyledModalBody>
                {type === 'REC' && (
                  <>
                    <StyledModalTitle>Respuesta Soluciones de Clientes</StyledModalTitle>
                    <StyledModalSubtitle>
                      Respuesta del área responsable: El día {data?.responseDate}, se absuelve '
                      {data?.absolution}' el reclamo '{code}' con los siguientes documentos
                      adjuntos.
                      <p>
                        <StyledBoldData>Vía de respuesta: </StyledBoldData>
                        {data?.responseChannel}.
                      </p>
                    </StyledModalSubtitle>
                  </>
                )}

                {type === 'SOL' && (
                  <>
                    <StyledModalTitle>Detalle de atención del ticket {code}</StyledModalTitle>
                    <StyledDataWrapper>
                      <p>
                        <StyledBoldData>Vía de respuesta: </StyledBoldData>
                        <span>{data?.responseChannel}</span>
                      </p>
                      <p>
                        <StyledBoldData>Fec. envío respuesta: </StyledBoldData>
                        <span>{data?.responseDate}</span>
                      </p>
                    </StyledDataWrapper>
                  </>
                )}

                {data.attachments && data.attachments.length > 0 ? (
                  <StyledTableWrapper>
                    <PSSimpleTable
                      headers={headerTableFather}
                      items={data.attachments.map((file, index) => ({
                        ...file,
                        path: (
                          <PSDownloadPDFButton
                            path={file.path}
                            fileName={file.name}
                            key={index}
                            setLoading={setLoading}
                          />
                        ),
                      }))}
                      setLoading={setLoading}
                    />
                  </StyledTableWrapper>
                ) : (
                  <span>No se encontraron documentos para mostrar.</span>
                )}
                <StyledFooterText>
                  En caso tengas alguna duda puedes comunicarte a nuestra Central de Servicio al
                  Cliente al 391-3000 en Lima o 0-801-1-1278 en Provincias.
                </StyledFooterText>
              </StyledModalBody>
            )
          )}
        </StyledModalContent>
      </Modal>
    </>
  );
});

PSViewPDF.displayName = 'PSViewPDF';

PSViewPDF.propTypes = {
  isEnabled: PropTypes.bool,
  code: PropTypes.string,
};

export default PSViewPDF;
