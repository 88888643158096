import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'moment/locale/es';
import PropTypes from 'prop-types';

import GetCertificates from '../../../shared/services/GetCertificates';
import { menuCode } from '../../../shared/constants/Strings';

import PSLoading from '../../PSLoading';
import { GAProvider } from '../../PSAnalytics';

import './index.scss';

const PSCertificadoDigital = React.forwardRef(
  ({ descriptionHeader, typeDocument, onCloseOption, poliza, menuId }) => {
    const [loading, setLoading] = useState(false);
    const { userInfo } = useSelector((state) => state.auth);
    const ga = React.useContext(GAProvider);
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const enableProduct =
      menuId === menuCode.VIDA_INDIVIDUAL
        ? 'VIDAINDIV'
        : menuId === menuCode.ACCIDENTES_PERSONALES
        ? 'ACCIDPERS'
        : '';

    const responseListAsegurados = async () => {
      try {
        setLoading(true);
        const document = userInfo.documentType + userInfo.document;
        const response = await GetCertificates.responseDigitalDocument(document);
        const findConstancia = response?.find(
          (r) => Number(r.policy) === poliza.nroPolicy && r.documentDescription === enableProduct
        );
        if (findConstancia) {
          downLoadFile(findConstancia.s3DocumentUrl, findConstancia.documentDescription);
        } else {
          setTimeout(() => {
            alert(
              `No se encontró ${
                menuCode.VIDA_INDIVIDUAL ? 'una póliza digital' : 'un certificado disponible'
              }`
            );
          });
          onCloseOption();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const downLoadFile = async (s3Url, workflow) => {
      const headerParams = { workflow };
      const responseUrl = await GetCertificates.responseFromPressingUrl(s3Url, headerParams);
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-${typeDocument}`,
        action: 'download',
        label: `El usuario descargó su ${typeDocument} de ${descriptionHeader}`,
        value: 1,
      });

      const link = document.createElement('a');
      link.href = responseUrl?.data;
      link.download = 'test.pdf';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

     useEffect(() => {
       responseListAsegurados();
     }, [poliza]);

    return loading ? (
      <div className="loading">
        <PSLoading />
      </div>
    ) : (
      menuId === menuCode.VIDA_INDIVIDUAL && (
        <div>
          <small className="">*Su contraseña es su doc de identidad</small>
        </div>
      )
    );
  }
);

PSCertificadoDigital.displayName = 'PSCertificadoDigital';

PSCertificadoDigital.defaultProps = {
  poliza: null,
  onCloseOption: () => {},
  descriptionHeader: '',
  typeDocument: '',
};

PSCertificadoDigital.propTypes = {
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
  typeDocument: PropTypes.string,
  descriptionHeader: PropTypes.string,
};

export default PSCertificadoDigital;
