import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPsError = styled.div`
  text-align: center;
  line-height: initial;
  color: #ed6e00;
  font-size: 12px;
`;

const PSError = React.forwardRef(({ children, className }, ref) => (
  <StyledPsError className={className} ref={ref}>
    {children}
  </StyledPsError>
));

PSError.displayName = 'PSError';

PSError.defaultProps = {
  className: null,
};

PSError.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PSError;
