import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSIcon from './PSIcon';
import PSCheckBox from './PSCheckBox';

const StyledPSGrayDownloadFiles = styled.div`
  width: 100%;
  height: 100%;
  .bd-notification {
    position: relative;
    height: 100%;
  }
  .is-primary {
    background-color: #e4e4e4;
    border-radius: 7px;
  }
  .container-description {
    color: #ed6e00;
    padding-right: 0.8em;
    padding-left: 0.8em;
    padding-top: 0.6em;
    padding-bottom: 0.5em;
    min-height: 80px;
    .description-header {
      display: flex;
      justify-content: space-between;
      span {
        border-left: 2px solid;
        background-color: #f6f4f4;
        padding: 0 0.25rem;
        line-height: 22px;
        height: 22px;
        font-weight: 1000;
        font-size: 0.8rem;
        &.mensual {
          color: #2b0d61;
        }
        &.regularizacion {
          color: #ed6e00;
        }
      }
    }
    .description-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
    }
    .description-subtitle {
      font-size: 16px;
      margin-top: -0.4rem;
    }
  }
  .content {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    .icon {
      padding-bottom: 0.2rem;
    }
    a {
      font-size: 14px;
      font-weight: bold;
      margin-right: 0.7rem;
      margin-bottom: 0.3rem;
      color: #2c265a;
    }
  }
  .option-web {
    display: block;
  }
  .option-mobile {
    display: none;
  }
  @media (max-width: 768px) {
    .description-subtitle {
      display: none;
    }
    .option-mobile {
      display: block;
    }
    .option-web {
      display: none;
    }
    .container-description {
      min-height: 60px;
    }
  }
`;

const PSGrayDownloadFiles = React.forwardRef(
  ({ type, className, title, onClick, onCheck, subtitle, withCheck }, ref) => (
    <StyledPSGrayDownloadFiles ref={ref} className={className}>
      <div className="bd-notification is-primary">
        <div className="container-description ">
          <div className="description-header">
            <div className="description-title">
              <label>{title}</label>
            </div>
            {type === 'M' ? (
              <span className="regularizacion">Regularización</span>
            ) : (
              <span className="mensual">Mensual</span>
            )}
          </div>

          {subtitle && (
            <div className="description-subtitle">
              <label>{subtitle}</label>
            </div>
          )}
        </div>
        <br />
        <div className="content">
          {withCheck && <PSCheckBox onChange={(e) => onCheck(e.target.checked)} />}

          <PSIcon type="downloadSmall" className="icon" />
          <a onClick={onClick} target="_blank" className="option-web">
            Descargar boleta
          </a>
          <a onClick={onClick} target="_blank" className="option-mobile">
            Descargar
          </a>
        </div>
      </div>
    </StyledPSGrayDownloadFiles>
  )
);

PSGrayDownloadFiles.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  onClick: () => {},
  withCheck: false,
  type: 'R',
};

PSGrayDownloadFiles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  withCheck: PropTypes.bool,
  type: PropTypes.string,
};

export default PSGrayDownloadFiles;
