export class Reward {
  constructor(props) {
    this.img = props['photo'];
    this.title = props['establishment']['name'];
    this.subtitle = props['title'];
    this.description = props['description'];
    this.dateEnd = '-';
    this.dateStart = '-';
  }
}
