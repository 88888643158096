import { currencyType, typePayment } from "../../../util/Constant";

export class Card {
  constructor(props) {
    this.nrCard = props.numeroTarjeta;
    this.codComercio = props.codigoComercio;
    this.tokenInicial = props.tokenInicial;
    this.status = 'Vigente';
    this.idTokenizacion = props.idTokenizacion;
    this.correo = props.correo;
    this.idMoneda = props.idMoneda;
    this.useKushki = isPaymentKushki(props.idPasarela);
    this.existPaymentGateway = validateGateway(props.idPasarela);
    this.typeMoneda = validateCurrency(props.idMoneda);
    this.typeCard = props.tipoTarjeta;
    this.llavePublica = props.llavePublica;
  }
}

const isPaymentKushki  = (id)  =>{
  return id === typePayment.kushki;
}
const validateGateway  = (id)  =>{
  return [typePayment.kushki, typePayment.niubiz].includes(id);
}
const validateCurrency = (id) => {
  if (id === currencyType.PEN) return 'PEN';
  if (id === currencyType.USD) return 'USD';
  return ;
};
