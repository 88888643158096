export const listParent = [
  { label: 'Seleccione', value: '' },
  { label: 'Titular', value: 'Titular' },
  { label: 'Hijo', value: 'Hijo' },
  { label: 'Padre del titular', value: 'Padre del titular' },
  { label: 'Madre del titular', value: 'Madre del titular' },
  { label: 'Conyuge con hijos', value: 'Conyuge con hijos' },
  { label: 'Concubino sin hijos', value: 'Concubino sin hijos' },
  { label: 'Otros', value: 'Otros' },
];

export const formCmp = [
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  { field: 'cobrante', label: 'Cobrante:', type: 'onlyText', value: '', readOnly: false },
  { field: 'pensionista', label: 'Pensionista:', type: 'onlyText', value: '', readOnly: false },
  {
    field: 'formaDePago',
    label: 'Forma de Pago:',
    type: 'input',
    value: 'Abono en cuenta',
    readOnly: true,
  },
  {
    field: 'banco',
    label: 'Banco:',
    type: 'select',
    value: '',
    options: [
      { value: '', label: 'Seleccione' },
      { value: 'BCP', label: 'BCP' },
      { value: 'BBVA', label: 'BBVA' },
      { value: 'Interbank', label: 'Interbank' },
      { value: 'Scotiabank', label: 'Scotiabank' },
      { value: 'otros', label: 'Otros' },
    ],
    required: true,
  },
  {
    field: 'tipoDeCuenta',
    label: 'Tipo de cuenta:',
    type: 'select',
    value: '',
    options: [
      { value: '', label: 'Seleccione' },
      { value: 'Ahorros', label: 'Ahorros' },
      { value: 'Cta Corriente', label: 'Cta Corriente' },
      { value: 'Maestra', label: 'Maestra' },
    ],
    required: true,
  },
  {
    field: 'moneda',
    label: 'Moneda:',
    type: 'select',
    value: '',
    options: [
      { value: '', label: 'Seleccione' },
      { value: 'Soles', label: 'Soles' },
      { value: 'Dolares', label: 'Dólares' },
    ],
    required: true,
  },
  {
    field: 'account',
    label: 'Número de cuenta:',
    type: 'number',
    value: '',
    required: true,
    maxLength: 13,
    info: 'Colocar números sin guiones',
  },
  {
    field: 'cci',
    label: 'Número de CCI:',
    type: 'number',
    value: '',
    required: true,
    maxLength: 20,
    info: 'Colocar números sin guiones',
    onlySelect: true,
  },
  {
    field: 'documents',
    label: 'Adjuntar voucher del banco:',
    type: 'drop',
    value: '',
    info: '(Comprobante oficial del banco donde se indique información consignada y datos del titular de la cuenta)',
    required: true,
    isMultiple: true,
  },
];

export const formAdb = [
  // actualizacion de beneficiario
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  {
    field: 'afiliado',
    label: 'Contratante:',
    type: 'input',
    value: '',
    readOnly: true,
    required: true,
    lastFieldDefault: true,
    numberFields: 7,
    className: 'mb-2',
  },
  {
    field: 'addField',
    label: 'Agregar más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 0,
    disabled: false,
  },
  {
    field: 'documents',
    label: 'Adjuntar DNI de los nuevos beneficiarios:',
    type: 'drop',
    value: '',
    required: true,
    info: '(Es obligatorio adjuntar copia del DNI del beneficiario nuevo o actualizado para el envío) - Max 2MB',
    isMultiple: true,
    boldInfo: true,
  },
];

export const formEXdb = [
  // exclusion de beneficiario
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  {
    field: 'afiliado',
    label: 'Contratante:',
    type: 'input',
    value: '',
    required: true,
    readOnly: true,
  },

  {
    field: 'cobrante',
    label: 'Beneficiario a excluir:',
    type: 'input',
    value: '',
    required: true,
  },
  {
    field: 'tipoDocument',
    label: 'Tipo de documento:',
    type: 'select',
    value: '',
    options: [
      { label: 'Seleccione', value: '' },
      { label: 'DNI', value: 'dni' },
      { label: 'RUC', value: 'ruc' },
      { label: 'CE', value: 'ce' },
    ],
    required: true,
  },
  {
    field: 'nroDocument',
    label: 'Nº de documento:',
    type: 'number',
    value: '',
    required: true,
    maxLength: 8,
  },
  { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
  {
    field: 'vinculo',
    label: 'Vínculo:',
    type: 'select',
    options: listParent,
    value: '',
    required: true,
  },
  {
    field: 'capitalA',
    label: '% de Capital Asegurado:',
    type: 'number',
    value: '',
    required: true,
    lastFieldDefault: true,
    maxValue: 100,
  },
  {
    field: 'addField',
    label: 'Excluir más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 0,
    disabled: false,
  },
  {
    field: 'documents',
    label: 'Adjuntar DNI de los nuevos beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los nuevos beneficiarios) - Max 2MB',
    required: true,
    isMultiple: true,
  },
];

export const formCpc = [
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  { field: 'contratante', label: 'Contratante:', type: 'input', value: '', readOnly: true },

  { field: 'beneficiario1', label: 'Beneficiario 1:', type: 'input', value: '', required: true },

  {
    field: 'capitalAsegurado1',
    label: 'Ingrese el nuevo % del beneficiario 1:',
    type: 'number',
    value: '',
    required: true,
    lastFieldDefault: true,
    numberFields: 3,
    maxValue: 100,
  },
  {
    field: 'addField',
    label: 'Agregar más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 1,
    disabled: false,
  },
  {
    field: 'documents',
    label: 'Adjuntar DNI de los beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los beneficiarios) - Max 2MB',
    required: true,
    isMultiple: true,
  },
];

export const formMgs = [
  // gasto sepelio (Modificación)
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  {
    field: 'afiliado',
    label: 'Contratante:',
    type: 'input',
    value: '',
    readOnly: true,
    lastFieldDefault: true,
    numberFields: 6,
  },

  // {
  //   field: 'beneficiario1',
  //   label: 'Nombres y Apellidos del nuevo beneficiario 1:',
  //   className: 'text-uppercase',
  //   type: 'input',
  //   value: '',
  //   required: true,
  // },
  // {
  //   field: ' tipoDocument1',
  //   label: 'Tipo de documento:',
  //   type: 'select',
  //   value: '',
  //   options: [
  //     { label: 'Seleccione', value: '' },
  //     { label: 'DNI', value: 'D.N.I' },
  //     { label: 'RUC', value: 'R.U.C' },
  //     { label: 'CE', value: 'C.E' },
  //   ],
  //   required: true,
  // },
  // {
  //   field: 'nroDocument1',
  //   label: 'Nº de documento:',
  //   type: 'number',
  //   value: '',
  //   required: true,
  //   maxLength: 8,
  // },
  // { field: 'date1', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
  // {
  //   field: 'parent1',
  //   label: 'Vínculo:',
  //   type: 'select',
  //   options: listParent,
  //   value: '',
  //   required: true,
  //   lastFieldDefault: true,
  // },
  {
    field: 'addField',
    label: 'Agregar más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 1,
    disabled: false,
  },

  {
    field: 'documents',
    label: 'Adjuntar DNI de los beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los beneficiarios) - Max 2MB',
    required: true,
    isMultiple: true,
  },
];

export const formIgs = [
  // gasto sepelio (inclusion)
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  { field: 'afiliado', label: 'Contratante:', type: 'input', value: '', readOnly: true },
  {
    field: 'cobrante',
    label: 'Nombres y Apellidos del nuevo beneficiario 1:',
    className: 'text-uppercase',
    type: 'input',
    value: '',
    required: true,
  },
  {
    field: 'tipoDocument:',
    label: 'Tipo de documento:',
    type: 'select',
    value: '',
    options: [
      { label: 'Seleccione', value: '' },
      { label: 'DNI', value: 'dni' },
      { label: 'RUC', value: 'ruc' },
      { label: 'CE', value: 'ce' },
    ],
    required: true,
  },
  {
    field: 'nroDocument',
    label: 'Nº de documento:',
    type: 'number',
    value: '',
    required: true,
    maxLength: 8,
  },
  { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
  {
    field: 'vinculo',
    label: 'Vínculo:',
    type: 'select',
    options: listParent,
    value: '',
    required: true,
    lastFieldDefault: true,
  },
  // { field: 'capitalA', label: '% de Capital Asegurado:', type: 'input', value: '' },
  {
    field: 'addField',
    label: 'Agregar más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 0,
    disabled: false,
  },

  {
    field: 'documents',
    label: 'Adjuntar DNI de los nuevos beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los nuevos beneficiarios) - Max 2MB',
    required: true,
    isMultiple: true,
  },
];

export const formEgs = [
  // gasto sepelio (exclusion)
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '', readOnly: true },
  { field: 'afiliado', label: 'Contratante:', type: 'input', value: '', readOnly: true },
  {
    field: 'cobrante',
    label: 'Beneficiario a excluir:',
    type: 'input',
    value: '',
    required: true,
  },
  {
    field: 'tipoDocument',
    label: 'Tipo de documento:',
    type: 'select',
    value: '',
    options: [
      { label: 'Seleccione', value: '' },
      { label: 'DNI', value: 'dni' },
      { label: 'RUC', value: 'ruc' },
      { label: 'CE', value: 'ce' },
    ],
    required: true,
  },
  {
    field: 'nroDocument',
    label: 'Nº de documento:',
    type: 'number',
    value: '',
    required: true,
    maxLength: 8,
  },
  { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
  {
    field: 'vinculo',
    label: 'Vínculo:',
    type: 'select',
    options: listParent,
    value: '',
    required: true,
    lastFieldDefault: true,
  },
  // { field: 'capitalA', label: '% de Capital Asegurado:', type: 'input', value: '' },
  {
    field: 'addField',
    label: 'Excluir más beneficiarios',
    type: 'action',
    value: '',
    action: () => {
      console.log('add function');
    },
    fieldAdd: 0,
    disabled: false,
  },

  {
    field: 'documents',
    label: 'Adjuntar DNI de los nuevos beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los nuevos beneficiarios) - Max 2MB',
    required: true,
    isMultiple: true,
  },
];
