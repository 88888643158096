import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPsSubtitle = styled.div`
  letter-spacing: 0px;
  text-align: center;
  line-height: initial;
  color: #242425;
  opacity: 0.3;
  font-size: 25px;
  margin: 4px 2px;
  font-family: 'BlissPro-Light', sans-serif;
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`;

const PSSubtitle = React.forwardRef(({ inline, children, className }, ref) => (
  <StyledPsSubtitle className={className} inline={inline} ref={ref}>
    <label>{children}</label>
  </StyledPsSubtitle>
));

PSSubtitle.displayName = 'PSSubtitle';

PSSubtitle.defaultProps = {
  inline: false,
};

PSSubtitle.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PSSubtitle;
