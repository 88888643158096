import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { pathServer } from '../../util/Constant';
import PSIcon from './PSIcon';
import PSCarousel from './PSCarousel';
import { GAProvider } from './PSAnalytics';

const StyledImg = styled.div`
  cursor: pointer;
  position: relative;
  line-height: 0;
  .icon {
    right: 3px;
    top: 3px;
    position: absolute;
  }
  .carousel {
    .container-children {
      .icon-left {
        position: absolute;
        left: 5px;
        bottom: 50%;
        svg {
          width: 25px;
          height: 25px;
          path {
            fill: #ed6e00 !important;
          }
        }
      }

      .icon-right {
        position: absolute;
        right: 5px;
        bottom: 50%;
        svg {
          width: 25px;
          height: 25px;
          path {
            fill: #ed6e00 !important;
          }
        }
      }
    }
  }
`;

const PSModalAdvertisements = ({ isMobile, showModal, onClose, types, ...props }) => {
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const history = useHistory();

  const goToPage = (url) => {
    if (!url) return;
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Advertisement`,
      action: 'click',
      label: `Un usuario dio click en el anuncio`,
      value: 1,
    });
    const linkUrl = url.toLowerCase().trim();
    if (linkUrl.startsWith('netprivada/')) {
      const redirect = linkUrl.substring(11);
      history.push(`intranet/${redirect}`);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Modal animationDuration={0} open={showModal} onClose={onClose} showCloseIcon={false}>
      <StyledImg>
        <PSIcon
          className="icon"
          type="close"
          onClick={() => {
            onClose();
          }}
        />
        {types.length > 1 ? (
          <PSCarousel typeIcon="secondary" className="carousel">
            {types.map((type) => (
              <img
                key={type.name}
                src={isMobile ? type.imgResponse : type.img}
                onClick={() => goToPage(type.url)}
              />
            ))}
          </PSCarousel>
        ) : (
          types.map((type) => (
            <img
              key={type.name}
              src={isMobile ? type.imgResponse : type.img}
              onClick={() => goToPage(type.url)}
            />
          ))
        )}
      </StyledImg>
    </Modal>
  );
};

PSModalAdvertisements.propTypes = {};

export default PSModalAdvertisements;
