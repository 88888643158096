import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import TokenAuthorization from '../shared/services/TokenAuthorization';
import DownloadPDFClaim from '../shared/services/DownloadPDFClaim';
import { createErrorPage } from '../redux/auth';

import PSIcon from './PSIcon';

const PSDownloadPDFButton = ({ path, fileName, setLoading }) => {
  const dispatch = useDispatch();

  const downloadFile = async () => {
    try {
      setLoading(true);
      const { key } = await TokenAuthorization.responseTokenAuthorization();
      const statusCodeResponse = await DownloadPDFClaim.responseFiles(path, key, fileName);
      if (statusCodeResponse !== 200) {
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message:
              'El documento no se encuentra disponible para la descarga o no cuenta con los permisos suficientes.',
          })
        );
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <a onClick={downloadFile}>
      <PSIcon type="downloadFile" />
    </a>
  );
};

PSDownloadPDFButton.displayName = 'PSDownloadPDFButton';

PSDownloadPDFButton.defaultProps = {
  path: '',
  fileName: '',
};

PSDownloadPDFButton.propTypes = {
  path: PropTypes.string,
  fileName: PropTypes.string,
  setLoading: PropTypes.func,
};

export default PSDownloadPDFButton;
