import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSIcon from './PSIcon';

const StyledPSGrayDownloadFormat = styled.div`
  width: 100%;
  height: 100%;
  .bd-notification {
    height: 100%;
  }
  .is-primary {
    background-color: #e4e4e4;
    border-radius: 7px;
  }
  
  .container-content {
    padding:5px 0 0 5px;
    .description-title {
      color: #ed6e00;
      font-family: 'BlissPro-ExtraBold',sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 25px;
  }
  .container-icon{
    cursor:pointer;
    text-align: center;
    margin-top: 2em;
    svg{
      max-width:100px;
      width: 76%;
      height: 100px;
    }
  }
 
`;

const PSGrayDownloadFormat = React.forwardRef(({ className, title, onClick, subtitle }, ref) => (
  <StyledPSGrayDownloadFormat ref={ref} className={className}>
    <div className="bd-notification is-primary">
      <div className="container-content columns">
        <div className="description-title column">
          <label>{title}</label>
        </div>
        <div className="container-icon column">
          <PSIcon type="downloadBig" onClick={onClick} target="_blank" />
        </div>
      </div>

    </div>
  </StyledPSGrayDownloadFormat>
));

PSGrayDownloadFormat.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  onClick: () => {},
};

PSGrayDownloadFormat.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default PSGrayDownloadFormat;
