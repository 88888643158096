import { findValue } from '../../../util/Util';

export const formInitial = [
  {
    field: 'pais',
    label: 'País:',
    type: 'select',
    value: '1',
    width: 46,
    hasToolTip: true,
    htmlData: true,
    toolTipMessage:
      "<p style='font-size : 120%;'> Si tu residencia es fuera del territorio peruano, por favor, enviar tus datos al buzón clientes@protectasecurity,</p><p style='font-size : 120%;'>con la copia de tu documentos de identidad para actualizar tu información </p>",
    options: [{ value: 1, label: 'Perú' }],
  },
  {
    field: 'departamento',
    label: 'Departamento:',
    type: 'select',
    value: '',
    width: 46,
    options: [],
  },
  { field: 'provincia', label: 'Provincia:', type: 'select', value: '', width: 46, options: [] },
  { field: 'distrito', label: 'Distrito:', type: 'select', value: '', width: 46, options: [] },

  {
    field: 'tipoDireccion',
    label: 'Tipo de dirección:',
    type: 'select',
    value: '',
    width: 29,
    options: [
      { value: '', label: 'Seleccione' },
      { value: 1, label: 'Comercial' },
      { value: 2, label: 'Particular/Legal' },
      { value: 6, label: 'Cobranzas' },
    ],
  },
  {
    field: 'tipoVia:',
    label: 'Tipo de vía:',
    type: 'select',
    value: '',
    width: 29,
    options: [
      { value: '', label: 'Seleccione' },
      { value: '01', label: 'AV.' },
      { value: '02', label: 'JR.' },
      { value: '03', label: 'CALLE' },
      { value: '04', label: 'PARQUE' },
      { value: '05', label: 'PSJ.' },
      { value: '06', label: 'OVALO' },
      { value: '88', label: 'SIN TIPO VIA' },
      { value: '99', label: 'NO ESPECIFICADO' },
    ],
  },
  { field: 'numero', label: 'Número:', type: 'input', value: '', width: 29 },
  { field: 'address', label: 'Nombre de vía:', type: 'input', value: '', width: 100 },
  {
    field: 'tipoVivienda',
    label: 'Tipo de vivienda:',
    type: 'select',
    value: '',
    width: 46,
    options: [
      { value: '', label: 'Seleccione' },
      { value: '-1', label: 'NINGUNO' },
      { value: '01', label: 'DPTO.' },
      { value: '02', label: 'EDIF.' },
      { value: '03', label: 'INT' },
      { value: '04', label: 'PISO' },
    ],
  },
  { field: 'interior:', label: 'Interior:', type: 'input', value: '', width: 46, disabled: true },
  { field: 'referencia', label: 'Referencia:', type: 'textarea', value: '', width: 100 },
];

export const optionsTipoVia = [
  { value: '01', label: 'AV.' },
  { value: '02', label: 'JR.' },
  { value: '03', label: 'CALLE' },
  { value: '04', label: 'PARQUE' },
  { value: '05', label: 'PSJ.' },
  { value: '06', label: 'OVALO' },
  { value: '88', label: 'SIN TIPO VIA' },
  { value: '99', label: 'NO ESPECIFICADO' },
];

export const MAX_LENGTH = 2;

export const structFields = {
  email: {
    fieldOption: 'P_SRECTYPE',
    fieldValue: 'P_SE_MAIL',
    typeValue: 'text',
  },

  phone: {
    fieldOption: 'P_NPHONE_TYPE',
    fieldValue: 'P_SPHONE',
    typeValue: 'number',
  },
};

export const options = {
  optionsEmail: [{ label: 'Correo personal', value: 4 }],
  optionsPhone: [
    { label: 'Celular', value: 2 },
    // { label: 'Telefono', value: 4 },
  ],
};

export const getTypeVia = (codeVia) => {
  let viaDescription = '';
  if (codeVia) {
    const findTypeVia = optionsTipoVia.find((item) => item.value === codeVia.toString());
    if (findTypeVia) {
      viaDescription = findTypeVia.label;
    }
  }
  return viaDescription;
};
export const detailAddress = (form) => {
  if (form && form[0]?.value) {
    const via = `${getTypeVia(form[5].value)} ${form[7]?.value?.toLocaleUpperCase() || ''} ${
      form[6].value || ''
    }`?.trim();
    const department = `${findValue(form[1].options, form[1].value, 'value')}`;
    const province = `${findValue(form[2].options, form[2].value, 'value')} `;
    const district = `${findValue(form[3].options, form[3].value, 'value')}`;

    const dir = `${via}${via && ', '}${department}, ${province}, ${district}`;
    return dir;
  }
  return '';
};
