import React from 'react';
import styled from 'styled-components';

const StyledPSTextArea = styled.textarea`
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  font-size: 16px;
  text-align: left;
  width: 100%;
  height: 100px;
  margin: 4px 2px;
  border-radius: 4px;
  border: none;
  padding-left: 15px;
  resize: none;
`;

const PSTextArea = React.forwardRef((props, ref) => <StyledPSTextArea {...props} ref={ref} />);

PSTextArea.displayName = 'PSTextArea';

export default PSTextArea;
