import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGoogleMaps } from 'react-hook-google-maps';
import PropTypes from 'prop-types';
import PSIcon from './PSIcon';
import PSMapSearch from './PSMapSearch';
import { pathServer } from '../../util/Constant';

const coordinatesDefault = { lat: -12.113892, lng: -77.032317 };
const coordenadaClinica1 = { lat: -12.113614, lng: -77.033249 };

const StyledPSMap = styled.div`
  width: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 500px;
  }
  .map-controls {
    background: transparent linear-gradient(90deg, #e16d19 0%, #2b265a 100%) 0% 0% no-repeat
      padding-box;
    display: grid;
    border-radius: 16px;
    grid-gap: 10px;
    width: 37px;
    padding: 10px 0 13px;
    grid-template-rows: 1fr;
    position: absolute;
    top: 15%;
    right: 10px;

    svg {
      width: 25px;
      height: auto;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
    }
    .custom-zoom-in {
      width: 34px;
      height: 24px;
      border: none;
    }
    .custom-zoom-out {
      width: 34px;
      height: 24px;
    }
    .a {
      fill: white !important;
    }
    .separator {
      height: 2px;
      border-bottom: 0.5px solid white;
      margin: 0 10%;
    }
  }
  .map-separator {
    width: 100%;
    height: 5px;
    border: none;
    background: transparent linear-gradient(60deg, #ed6e00 0%, #2b0d61 100%) 0% 0% no-repeat;
  }
  .map-search {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    width: 100%;
    background: white;
    border-radius: 0 0 15px 15px;
    .search-first {
      display: grid;
      grid-template-columns: 0.8fr 0.2fr;
    }
    .input-container {
      display: grid;
      padding-top: 5%;
      padding-bottom: 10%;
      grid-gap: 20px;
      & div:nth-child(1) {
        display: grid;
        grid-gap: 15px;
      }
    }
    .search-container {
      display: flex;
      align-items: flex-end;
      margin-bottom: calc(50% - 20px);
      padding: 0 0 0 10px;
    }
    .info-container {
      padding: 9% 7%;
      border-left: 1px solid #dfdfdf;
      h6 {
        color: #2b265a;
        font-size: 13px;
        text-align: justify;
        line-height: 15px;
        font-family: 'BlissPro-Regular', sans-serif;
      }
      b {
        font-weight: 700;
      }
      .info-att-direct{
       color: #e16d19
      }
    }
    label {
      font-family: 'BlissPro-Heavy', sans-serif !important;
      color: #2b265a;
      opacity: 1;
      font-size: 22px;
      font-weight: 700;
    }
    .icon-search-btn {
      &:hover {
        cursor: pointer;
      }
      .a {
        fill: #2c255a !important;
      }
      .b {
        stroke: white;
      }
      svg {
        width: 50px;
        height: 40px;
      }
    }

    .icon-search-btn-disable {
      &:hover {
        cursor: pointer;
      }
      .a {
        fill: #dfdfdf !important;
      }
      .b {
        stroke: white;
      }
      svg {
        width: 50px;
        height: 40px;
      }
    }

    .container-clinicas {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-content: center;
      height: 100%;
      & > div {
        display: flex;
      }
      .b {
        stroke: white;
        stroke-width: 0px !important;
      }
      .clinica-title1 {
        h6 {
          max-width: 50px;
          font-weight: 600;
          line-height: 12px;
          color: #e16d19 !important;
        }
      }
      .clinica-title2 {
        .a {
          fill: #2e2a59 !important;
        }
        h6 {
          max-width: 50px;
          font-weight: 600;
          line-height: 12px;
          color: #2e2a59 !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .map {
      width: 100%;
      height: 350px;
    }
    .map-search {
      display: block;
      .search-first {
        grid-template-columns: 1fr 0.1fr;
      }
      .icon-search-btn {
        svg {
          width: 36px !important;
          height: auto !important;
        }
      }
    }
    .info-container {
      border-left: none !important;
    }

    .search-container {
      margin-bottom: calc(50%) !important;
    }

    .input-container {
      & div:nth-child(1) {
        margin-right: 2px;
      }
    }
  }
`;
const PSMap = React.forwardRef(({ pointTo, ...props }) => {
  const [valueZoom, setValueZoom] = useState(0);
  const [hasValue, setHasValue] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: pointTo.lat, lng: pointTo.lon });
  const { ref, map, google } = useGoogleMaps(process.env.REACT_APP_KEY_GOOGLE_MAPS, {
    center: { lat: pointTo.lat, lng: pointTo.lng },
    zoom: 18,
    disableDefaultUI: true,
  });

  const [mapSecondary, setMapSecondary] = useState(null);

  let directionsRenderer = null;
  let directionsService = null;

  const initMap = () => {
    const { affiliated } = pointTo;
    new google.maps.Marker({
      position: { lat: pointTo.lat, lng: pointTo.lng },
      map,
      icon: affiliated
        ? `${pathServer.PATH_ICONS}marker_blue.png`
        : `${pathServer.PATH_ICONS}marker_orange.png`,
    });
    directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });
    directionsService = new google.maps.DirectionsService();
    directionsRenderer.setMap(map);
  };

  const initMapRoute = () => {
    const { affiliated } = pointTo;
    const map = mapSecondary;
    new google.maps.Marker({
      position: { lat: pointTo.lat, lng: pointTo.lng },
      map,
      icon: affiliated
        ? `${pathServer.PATH_ICONS}marker_blue.png`
        : `${pathServer.PATH_ICONS}marker_orange.png`,
    });
    directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });
    directionsService = new google.maps.DirectionsService();
    directionsRenderer.setMap(map);

    if (!hasValue) {
      alert('Debe ingresar punto de partida');
    } else {
      directionsService.route(
        {
          origin: {
            lat: coordinates.lat,
            lng: coordinates.lng,
          },
          destination: {
            lat: pointTo.lat,
            lng: pointTo.lng,
          },
          provideRouteAlternatives: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(response);
          } else {
            console.log('Directions request failed due to', status);
          }
        }
      );
    }
  };

  if (map) {
    initMap();
  }

  const updateCoordenadas = (point) => {
    setCoordinates(point);
    addMarker(point);
    setHasValue(true);
  };

  const addMarker = (point) => {
    const { affiliated } = pointTo;
    const map = new google.maps.Map(document.getElementById('map-canvas'), {
      center: { lat: pointTo.lat, lng: pointTo.lng },
      zoom: 18,
      disableDefaultUI: true,
    });
    new google.maps.Marker({
      position: { lat: pointTo.lat, lng: pointTo.lng },
      map,
      icon: affiliated
        ? `${pathServer.PATH_ICONS}marker_blue.png`
        : `${pathServer.PATH_ICONS}marker_orange.png`,
    });
    new google.maps.Marker({
      position: point,
      map,
      icon: `${pathServer.PATH_ICONS}map_from.svg`,
    });
    setMapSecondary(map);
  };

  const handleZoomUpdate = (zoomChange = 1) => {
    const nextZoom = valueZoom + zoomChange;
    if (nextZoom < 0) {
      return;
    }
    map.setZoom(nextZoom);
  };

  useEffect(() => {
    if (!map) {
      return;
    }
    setValueZoom(map.getZoom());

    const listener = map.addListener('zoom_changed', () => {
      setValueZoom(map.getZoom());
    });
    return () => google.maps.event.removeListener(listener);
  }, [map, google]);

  return (
    <StyledPSMap {...props}>
      {pointTo.name && (
        <>
          <h5 className="mb-1"> {pointTo.name}</h5>
          <h5 className="mb-1"> {`${pointTo.district} , ${pointTo.department}`}</h5>
          <h5 className="mb-2"> {pointTo.phone}</h5>
        </>
      )}
      <div className="map" ref={ref} id="map-canvas" />
      <div className="map-controls">
        <button type="button" className="custom-zoom-in" onClick={() => handleZoomUpdate(-1)}>
          <PSIcon type="zoomIn" />
        </button>
        <div className="separator" />
        <button
          type="button"
          className="custom-zoom-out"
          onClick={() => handleZoomUpdate(1)}
          disabled={valueZoom < 1}
        >
          <PSIcon type="zoomOut" />
        </button>
      </div>
      <div className="map-separator" />
      <div className="map-search columns is-mobile">
        <div className="search-first column">
          <div className="input-container">
            <div>
              <label htmlFor="">¿Cómo llegar?</label>
              <PSMapSearch
                icon="mapFrom"
                placeholder="Ingresar punto de partida"
                updateCoordenadas={updateCoordenadas}
              />
              <PSMapSearch isOnlyRead icon="mapTo" placeholder={pointTo.name} />
            </div>
          </div>
          <div className="search-container">
            <PSIcon
              onClick={initMapRoute}
              type="mapSearch"
              className={hasValue ? 'icon-search-btn' : 'icon-search-btn-disable'}
            />
          </div>
        </div>
        <div className="info-container column">
          <h6>
            <b className='info-att-direct'>Atención directa</b> : No se realiza ningún pago en la clínica.
          </h6>
          <h6>
            <b>Pago con reembolso</b> : Se realiza el pago en la clínica y luego
            deberás solicitar el reembolso.
          </h6>
          <div className="container-clinicas">
            <div className="clinica-title1">
              <PSIcon type="mapClinica2" />
              <h6>Atención directa</h6>
            </div>
            <div className="clinica-title2">
              <PSIcon type="mapClinica1" />
              <h6>Pago con reembolso</h6>
            </div>
          </div>
        </div>
      </div>
    </StyledPSMap>
  );
});

PSMap.displayName = 'PSMap';

PSMap.defaultProps = {
  pointTo: coordenadaClinica1,
};

PSMap.propTypes = {
  pointTo: PropTypes.object,
};

export default PSMap;
