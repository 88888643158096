import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPSContainerPrize = styled.div`
  background-color: #fff;
  border-radius: 7px;
  font-family: BlissPro;
  .img-prize {
    cursor: pointer;
    border-radius: 7px 7px 0 0;
    width: 100%;
    height: 190px;
  }
  .title-prize {
    font-size: 15px;
    color: #5b5b5f;
    font-family: 'BlissPro-Regular', sans-serif !important;
    font-weight: 600;
    margin-left: 1rem;
    text-transform: uppercase;
  }
  .subtitle-prize {
    font-size: 13px;
    color: #5b5b5f;
    line-height: 12px;
    font-family: 'BlissPro-Regular', sans-serif !important;
    margin-left: 1rem;
    padding-bottom: 1rem;
    width: 90%;
    min-height: 39px;
  }
  @media (min-width: 1600px) {
    .img-prize {
      height: 250px;
    }
  }

  @media (max-width: 700px) and (min-width: 400px) {
    .img-prize {
      height: 222px;
    }
  }
  
  @media (max-width: 576px) {
    .subtitle-prize{
     min-height: 52px;
    }
  }
`;

const PSContainerPrize = ({ className, img, title, subtitle, onClick }) => {
  return (
    <StyledPSContainerPrize className={className} onClick={onClick}>
      <div>
        <img src={img} className="img-prize" />
      </div>
      <div className="title-prize">{title}</div>
      <div className="subtitle-prize">{subtitle}</div>
    </StyledPSContainerPrize>
  );
};

PSContainerPrize.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PSContainerPrize;
