import { setAuthLoading } from './auth-loading';
import TokenAuthorization from '../../../shared/services/TokenAuthorization';

export const GET_TOKEN = 'Auth/GET_TOKEN';

export const setTokenAuthorization = (token) => ({
  type: GET_TOKEN,
  payload: token,
});

export const getTokenAuthorization = () => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await TokenAuthorization.responseTokenAuthorization();
      dispatch(setTokenAuthorization(response));
      dispatch(setAuthLoading(false));
    } catch (error) {
      setTokenAuthorization(null);
      dispatch(setAuthLoading(false));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  token: action.payload,
});
