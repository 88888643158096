export const USER_VALIDATION_INFO = 'Auth/USER_VALIDATION_INFO';

export const setUserValidationInfo = (userInfo) => ({
  type: USER_VALIDATION_INFO,
  payload: userInfo,
});

export const reducer = (state, action) => ({
  ...state,
  userValidationInfo: action.payload,
});
