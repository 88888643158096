import React from 'react';
import styled from 'styled-components';

const StyledPSTextInput = styled.input`
  letter-spacing: 0px;
  color: #7a7a7a;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 16px;
  text-align: left;
  width: 100%;
  height: 42px;
  margin: 4px 2px;
  border-radius: 4px;
  border: none;
  padding-left: 15px;
  font-family: 'BlissPro-Regular', sans-serif;
  ${(props) =>
    props.withIcon
      ? 'background: url(/images/icons/pencil.svg) #ffffff00 no-repeat calc(100% - 10px);' +
      'cursor: pointer '
      : ''};
`;

const PSTextInput = React.forwardRef(({ withIcon, ...props }, ref) => (
  <StyledPSTextInput {...props} ref={ref} withIcon={withIcon} />
));

PSTextInput.displayName = 'PSTextInput';

export default PSTextInput;
