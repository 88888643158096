import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import PSBox from './PSBox';
import PSLabel from './PSLabel';
import PSRadio from './PSRadio';

const StyledPSValidation = styled.div`
  text-align: left;
  margin: 10px;

  .pslogin-radio-email {
    label {
      text-align: left;
      margin: 4px 0px 4px 2px;
    }
  }
`;

const PSLoginSelectValidation = React.forwardRef(({ className, onChange, value }, ref) => {
  const { userValidationInfo } = useSelector((state) => state.auth);
  const showSMS = process.env.REACT_APP_SHOW_OTP_SMS === 'true';
  return (
    <div ref={ref}>
      <PSBox className={className}>
        <PSLabel className="text-info f-bold">¿Cómo le enviamos el código de activación?</PSLabel>
        <StyledPSValidation>
          {showSMS && userValidationInfo?.phoneNumber && (
            <PSRadio onChange={onChange} value="phoneNumber" checked={value === 'phoneNumber'}>
              Enviar sms a {userValidationInfo.phoneNumber}
            </PSRadio>
          )}
          {userValidationInfo?.email && (
            <PSRadio
              onChange={onChange}
              value="email"
              checked={value === 'email'}
              className="pslogin-radio-email"
            >
              {`Enviar e-mail a ${userValidationInfo.email}`}
            </PSRadio>
          )}
        </StyledPSValidation>
      </PSBox>
    </div>
  );
});

PSLoginSelectValidation.displayName = 'PSLoginSelectValidation';

PSLoginSelectValidation.defaultProps = {
  onChange: () => {},
};

PSLoginSelectValidation.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['phoneNumber', 'email']),
};

export default PSLoginSelectValidation;
