import { setAuthLoading } from './auth-loading';
import AuthService from '../../../shared/services/AuthService';

export const VALIDATE_VERIFICATION_CODE = 'Auth/VALIDATE_VERIFICATION_CODE';

export const setValidCode = (valid) => ({
  type: VALIDATE_VERIFICATION_CODE,
  payload: valid,
});

export const validateVerificationCode = (code, user) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));

      const response = await AuthService.validateOTPChallenge({
        code,
        documentId: user.documentId,
        documentType: user.documentType,
        validationType: user.validationType,
      });
      dispatch(setValidCode(response));
      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch(setValidCode(null));
      dispatch(setAuthLoading(false));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  validCode: action.payload,
});
