import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/index';

import ReactTooltip from 'react-tooltip';
import PSTextInput from './PSTextInput';
import PSSelect from './PSSelect';
import PSDopzone from './PSDopzone';
import PSTextArea from './PSTextArea';
import { maxLengthCheck } from '../../util/Util';
import PSLoading from './PSLoading';

const StyledPSComponentFromGroup = styled.div`
  display: flex;
  width: ${({ width }) => width}%;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  margin: 0.8rem;
  .label-from-group {
    width: 27%;
    color: #2b265a;
    font-size: 18px;
    margin: auto 0;
  }
  .label-from-action{
     width: 100%;
     cursor: pointer;
     text-decoration: underline;
     :disabled {
      cursor: not-allowed
    }
    label{
      cursor: pointer;
    }
  }
  .content-type-input{
    position: relative;

    .content-loading{
      position: absolute;
      right: -37px;
      top: 16px;
    }
  }

  .input-from-group {
    border: 1px solid #7a7a7a;
    border-radius: 3px;
    color: #7A7A7A;
    background-color: #f0f0f0;
    padding:  ${({ type }) => (type === 'textarea' ? 10 : 14)}px;
    height: ${({ type }) => (type === 'textarea' ? 100 : 52)}px;
    :focus{
      background: ${({ readOnly }) => (readOnly ? '#f0f0f0' : '#fff !important')};
    }
   }

  .text-info-input {
    font-size: 14px;
    font-family: 'BlissPro-Regular',sans-serif;
  }

  .bold{
    font-weight: 600;
  }
  .text-info-error{
    text-align: left;
    font-size: 12px;
    color : red;
    font-family: 'BlissPro-Regular',sans-serif;
  }

  .input-error{
    border: 1px solid red;
  }

  @media (max-width: 768px) {
    .label-from-group {
     font-size: 13px;
  }

  .content-type-input{
    display:flex;
    align-items:center;
    gap: 15px;
    .content-loading{
      position: static;
    }
  }

}

  }
`;

const TypeComponent = ({ className, item, onChange, onBlur, index }) => {
  const today = moment(Date.now()).format('YYYY-MM-DD');
  const itemValue = item.value ? item.value : '';
  const getBodyType = () => {
    switch (item.type) {
      case 'input':
        return (
          <PSTextInput
            className={className}
            name={item.field}
            onChange={(e) => onChange(e, index)}
            value={itemValue}
            disabled={item.disabled}
            onBlur={() => onBlur(index)}
            // readOnly={item.readOnly}
            readOnly={item.readOnly || false}
          />
        );

      case 'onlyText':
        return (
          <PSTextInput
            className={className}
            name={item.field}
            onChange={(e) => {
              if (/^[a-zA-Z ]*$/.test(e.target.value) || e.target.value === '') {
                onChange(e, index);
              }
            }}
            value={itemValue}
            disabled={item.disabled}
            onBlur={() => onBlur(index)}
            // readOnly={item.readOnly}
            readOnly={item.readOnly || false}
          />
        );

      case 'date':
        return (
          <PSTextInput
            className={className}
            name={item.field}
            onChange={(e) => onChange(e, index)}
            value={item.value}
            disabled={item.disabled}
            type="date"
            max={item.maxValue ? item.maxValue : today}
            // readOnly={item.readOnly}
            readOnly={item.readOnly || false}
          />
        );

      case 'number':
        return (
          <PSTextInput
            className={className}
            name={item.field}
            onChange={(e) => onChange(e, index)}
            value={item.value}
            type="number"
            onBlur={() => onBlur(index)}
            onKeyPress={(e) => maxLengthCheck(e)}
            disabled={item.disabled}
            readOnly={item.readOnly}
          />
        );

      case 'textarea':
        return (
          <PSTextArea
            className={className}
            name={item.field}
            onChange={(e) => onChange(e, index)}
            value={itemValue}
            disabled={item.disabled}
          />
        );
      case 'select':
        return (
          <PSSelect
            selectedValue={itemValue}
            options={
              item.options
                ? item.options
                : [
                    { value: '', label: 'Seleccione' },
                    { value: 'L', label: 'Ejemplo 2' },
                    { value: 'E', label: 'Ejemplo 3' },
                  ]
            }
            onChange={(e) => onChange(e, index)}
            className={className}
            disabled={item.disabled}
          />
        );
      case 'drop':
        return (
          <PSDopzone
            onChange={(file) => onChange(file, index)}
            FileProps={item.value}
            isMultiple={item.isMultiple}
          />
        );
      case 'action':
        return <div />;
      default:
        return (
          <PSTextInput
            className={className}
            name={item.field}
            onChange={(e) => onChange(e, index)}
            value={item.value}
            disabled={item.disabled}
          />
        );
    }
  };

  return getBodyType();
};

const PSComponentFormGroup = ({ className, item, onChange, onBlur, index }) => {
  const isTypeAction = item.type === 'action';
  return (
    <StyledPSComponentFromGroup
      className={className}
      width={item.width ? item.width : 98}
      type={item.type}
      disabled={item.disabled}
      readOnly={item.readOnly}
    >
      <div
        className={`label-from-group ${isTypeAction ? 'label-from-action' : ''}`}
        disabled={item.disabled}
        onClick={isTypeAction && item.action ? item.action : () => {}}
      >
        <label>{item.label}</label>
      </div>
      {!isTypeAction && (
        <div
          className="w-100"
          data-tip={item.hasToolTip ? item.toolTipMessage : null}
          data-html={item.htmlData ? item.htmlData : false}
        >
          <div className="content-type-input">
            <TypeComponent
              item={item}
              className={`input-from-group ${item.error ? ' input-error' : ''}`}
              onChange={onChange}
              onBlur={onBlur}
              index={index}
            />
            {item.loading && (
              <div className="content-loading">
                <PSLoading width={25} height={25} color="#2B0D61" />
              </div>
            )}
          </div>
          {item.error && <p className="text-info-error">{item.error}</p>}
          {item.info && (
            <p className={`text-info-input ${item.boldInfo ? 'bold' : ''}`}>{item.info}</p>
          )}
          <ReactTooltip place="right" type="dark" />
        </div>
      )}
    </StyledPSComponentFromGroup>
  );
};

PSComponentFormGroup.defaultProps = {
  className: null,
  onChange: () => {},
  onBlur: () => {},
};

PSComponentFormGroup.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default PSComponentFormGroup;
