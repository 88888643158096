import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSLabel from './PSLabel';

const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;

const StyledPSRadio = styled.input`
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  font-size: 14px;
  text-align: left;
  width: 15px;
  height: 15px;
  margin: 4px 2px;
  border-radius: 4px;
  border: none;
`;

const PSRadio = React.forwardRef(({ onChange, value, checked, className, children }, ref) => {
  const onChangeHandler = (event) => {
    const val = event ? event.target.value : value;
    onChange(val);
  };

  return (
    <RadioButtonContainer className={className} ref={ref}>
      <StyledPSRadio onChange={onChangeHandler} value={value} checked={checked} type="radio" />
      <PSLabel inline onClick={() => onChangeHandler()}>
        {children}
      </PSLabel>
    </RadioButtonContainer>
  );
});

PSRadio.displayName = 'PSRadio';

PSRadio.defaultProps = {
  className: null,
  onChange: () => {},
};

PSRadio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
};

export default PSRadio;
