import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pathServer } from '../../util/Constant';
import PSIcon from './PSIcon';
import PSLabel from './PSLabel';
import { PRODUCT_VIDA_DEVOLUCION_PROTECTA } from '../shared/constants/Strings';

const StyledPSHeaderIntranet = styled.div`
  background: url('${(props) => (props.url ? props.url : '#ffff')}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => (props.hasEffectGray ? '0px 0px' : 'initial')};
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family : BlissPro-ExtraBold;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  .btn-back{
    font-size: 14px !important;
    text-decoration:underline;
    position:absolute;
    right:1%;
    bottom:20px;
    &:hover{
      cursor:pointer;
    }
  }
  .container-transparent{
    position:relative;
    background-color:${(props) => (props.hasEffectGray ? '#00000025' : 'initial')};
    opacity: ${(props) => (props.hasEffectGray ? 0.9 : 1)};
    width:100%;
    height:100%;
  }
  .children {
    position: absolute;
    bottom: 0;
    margin: 1rem 1rem 1rem 2rem;
    display: flex;
    width:95%;
    .a{
      fill: #fff !important;
    }
    label{
      color: #FFFFFF;
      font-size: 30px;
    }
    .align-icon{
      margin: 0.5rem;
    }
  }

    @media (max-width: 768px) {
      background: url('${(props) => (props.urlMobile ? props.urlMobile : '#ffff')}');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0px 25px;

    .btn-back{
       bottom:8px;
    }
    .children{
    margin:0.5rem 1rem;
    label{
      font-size: 25px;
    }
    .title-header{
      font-size: 30px;
    }
    .align-icon{
      margin:0;
      svg{
        width:27px;
        padding-top: 10px;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 576px) {
    .children{
     .title-header{
        font-size: 25px;
      }
    }
  }

`;

const PSHeaderIntranet = React.forwardRef(
  ({ img, imgMobile, description, icon, hasEffectGray, goBackFunction, ...props }, ref) => {
    const url = `${pathServer.PATH_ICONS + img}.png`;
    const urlMobile = `${pathServer.PATH_ICONS + imgMobile}.png`;
    const goBack = () => {
      if (goBackFunction) {
        goBackFunction();
      } else if (props.history) {
        props.history.goBack();
      }
    };
    const useExponent = description === PRODUCT_VIDA_DEVOLUCION_PROTECTA;
    return (
      <StyledPSHeaderIntranet
        ref={ref}
        url={url}
        urlMobile={urlMobile}
        hasEffectGray={hasEffectGray}
        {...props}
      >
        <div className="container-transparent">
          <div className="children">
            {/*{ icon && <PSIcon type={icon} className="align-icon" />}*/}
            <label className="title-header">{description}{useExponent && <span className="exponent">+</span>} </label>
            <PSLabel className="btn-back" onClick={goBack}>
              {'<< Atrás'}{' '}
            </PSLabel>
          </div>
        </div>
      </StyledPSHeaderIntranet>
    );
  }
);

PSHeaderIntranet.defaultProps = {
  img: '',
  description: '',
  icon: '',
  hasEffectGray: true,
};

PSHeaderIntranet.propTypes = {
  img: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  hasEffectGray: PropTypes.bool,
  goBackFunction: PropTypes.func,
};

export default PSHeaderIntranet;
