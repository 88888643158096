import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledPSLink = styled.span`
  text-decoration: underline;
  font-size: 15px;
  color: #242425;
`;

const PSLink = React.forwardRef(({ children, href, className }, ref) => (
  <Router>
    <Link to={href} className={className} ref={ref}>
      <StyledPSLink>{children}</StyledPSLink>
    </Link>
  </Router>
));

PSLink.displayName = 'PSLink';

PSLink.defaultProps = {
  className: null,
  href: "#",
};

PSLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.elementType.isRequired,
  className: PropTypes.string,
};

export default PSLink;
