import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import 'bulma/css/bulma.css';

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import ReduxProvider from './app/redux/provider';
import { Routes } from './routes';

import './assets/styles/app.scss';

import Home from './app/pages/Home';
import SignUp from './app/pages/SignUp';
import SignUpValidation from './app/pages/SignUpValidation';
import SignUpPassword from './app/pages/SignUpPassword';
import ForgotPassword from './app/pages/ForgotPassword';
import ForgotPasswordValidation from './app/pages/ForgotPasswordValidation';
import Login from './app/pages/Login';
import HomeServices from './app/pages/HomeServices';
import HomeDownloadFormat from './app/pages/HomeDownloadFormat';
import TicketRAT from './app/pages/TicketRAT';

import HomeRewards from './app/pages/HomeRewards';
import HomeCoupons from './app/pages/HomeCoupons';

import HomeFrequentQuestion from './app/pages/HomeFrequentQuestion';
import HomeSoat from './app/pages/HomeProductSoat';
import HomeProductRRVV from './app/pages/HomeProductRRVV';
import HomeProductDesgravamen from './app/pages/HomeProductDesgravamen';
import HomeProductRentaTotal from './app/pages/HomeProductRentaTotal';
import HomeProductAhorroTotal from './app/pages/HomeProductAhorroTotal';
import HomeProductLifeDevolution from './app/pages/HomeProductLifeDevolution';
import HomeProductLifeCreditEasy from './app/pages/HomeProductLifeCreditEasy';

import { withLanding } from './app/components/PSLanding';
import { withHome } from './app/components/PSHome';
import HomeSchedule from './app/pages/HomeSchedule';
import HomeProductVidaLey from './app/pages/HomeProductVidaLey';
import HomeProductAccidentesPersonales from './app/pages/HomeProductAccidentesPersonales';
import HomeProductSCTR from './app/pages/HomeProductSCTR';
import HomeProductVidaIndividual from './app/pages/HomeProductVidaIndividual';
import HomeClaims from './app/pages/HomeClaims';
import HomeStateClaims from './app/pages/HomeStateClaims';
import HomeStateSinister from './app/pages/HomeStateSinister';
import HomeStateRequest from './app/pages/HomeStateRequest';
import HomeUpdateData from './app/pages/HomeUpdateData';
import HomeNewsletter from './app/pages/HomeNewsletter';
import { withHeader } from './app/components/PSNewSletterHome';
import SignUpRegisterCompany from './app/pages/SignUpRegisterCompany';
import PSLoadingPayment from './app/components/templates/PSLoadingPayment';

const httpLink = createHttpLink({
  uri: 'https://api.core.stg.protectasecuritycloud.pe/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': 'da2-fcv7m7ecmbhxlfuz72lw2zx7ce',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink2 = createHttpLink({
  uri: 'https://rentas-api.core.prd.protectasecuritycloud.pe/graphql',
});

const authLink2 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': 'da2-j7e5axjtwzewhdbfnpf4q6wmme',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const client2 = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink2.concat(httpLink2),
});

function App() {
  const isEnabledTestRoute = process.env.REACT_APP_USE_ROUTE_TEST === "true";
  return (
    <ReduxProvider>
      <Router>
        <Route exact path="/mis-datos" render={(props) => <Redirect to={Routes.LOGIN} />} />
        <Route exact path="/pensioner/:path" render={(props) => <Redirect to={Routes.LOGIN} />} />
        <Route
          exact
          path="/pensioner/:path/:path2"
          render={(props) => <Redirect to={Routes.LOGIN} />}
        />
        {isEnabledTestRoute ? (
          <Route path={Routes.TEST} component={Home} />
        ) : (
          <Route path={Routes.TEST} render={(props) => <Redirect to={Routes.LOGIN} />} />
        )}
        <Route path={Routes.BASE} exact component={Login} />
        <Route path={`${Routes.LOGIN}/:id`} component={Login} />
        <Route path={Routes.LOGIN} component={Login} />
        <Route path={Routes.SIGN_UP} exact component={withLanding(SignUp)} />
        <Route path={Routes.SIGN_UP_VALIDATION} exact component={withLanding(SignUpValidation)} />
        <Route
          path={Routes.SIGN_UP_REGISTER_COMPANY}
          exact
          component={withLanding(SignUpRegisterCompany)}
        />
        <Route
          path={Routes.SIGN_UP_CREATE_PASSWORD}
          exact
          component={withLanding(SignUpPassword)}
        />
        <Route path={Routes.FORGOT_PASSWORD} exact component={withLanding(ForgotPassword)} />
        <Route
          path={Routes.FORGOT_PASSWORD_VALIDATION}
          exact
          component={withLanding(ForgotPasswordValidation)}
        />
        <Route
          path={Routes.FORGOT_PASSWORD_CREATE_PASSWORD}
          exact
          component={withLanding(SignUpPassword)}
        />
        <ApolloProvider client={client}>
          <Route path={Routes.HOME} exact component={withHome(HomeServices)} />
        </ApolloProvider>
        <Route path={Routes.HOME_DOWNLOAD_FORMAT} exact component={withHome(HomeDownloadFormat)} />

        <Route
          path={`${Routes.HOME_PRODUCT_RRVV_TICKET}/:id`}
          exact
          component={withHome(TicketRAT)}
        />

        <Route path={Routes.HOME_REWARDS} exact component={withHome(HomeRewards)} />
        <Route path={Routes.HOME_COUPONS} exact component={withHome(HomeCoupons)} />
        <Route path={Routes.HOME_PRODUCT_SOAT} exact component={withHome(HomeSoat)} />
        <ApolloProvider client={client2}>
          <Route path={Routes.HOME_PRODUCT_RRVV} exact component={withHome(HomeProductRRVV)} />
          <Route
            path={Routes.HOME_PRODUCT_AHORROTOTAL}
            exact
            component={withHome(HomeProductAhorroTotal)}
          />

          <Route
            path={Routes.HOME_PRODUCT_RENTATOTAL}
            exact
            component={withHome(HomeProductRentaTotal)}
          />
          <Route
            path={Routes.HOME_PRODUCT_LIFE_DEVOLUTION}
            exact
            component={withHome(HomeProductLifeDevolution)}
          />
          <Route
            path={Routes.HOME_PRODUCT_LIFE_CREDIT}
            exact
            component={withHome(HomeProductLifeCreditEasy)}
          />
          <Route
            path={`${Routes.HOME_PRODUCT_RENTATOTAL_TICKET}/:id`}
            exact
            component={withHome(TicketRAT)}
          />
          <Route
            path={`${Routes.HOME_PRODUCT_AHORROTOTAL_TICKET}/:id`}
            exact
            component={withHome(TicketRAT)}
          />
          <Route
            path={Routes.HOME_PRODUCT_VIDALEY}
            exact
            component={withHome(HomeProductVidaLey)}
          />
          <Route
            path={Routes.HOME_PRODUCT_DESGRAVAMEN}
            exact
            component={withHome(HomeProductDesgravamen)}
          />

          <Route
            path={Routes.HOME_PRODUCT_VIDAINDIVIDUAL}
            exact
            component={withHome(HomeProductVidaIndividual)}
          />

          <Route
            path={Routes.HOME_PRODUCT_ACCIDENTES}
            exact
            component={withHome(HomeProductAccidentesPersonales)}
          />
          <Route path={Routes.HOME_PRODUCT_SCTR} exact component={withHome(HomeProductSCTR)} />
        </ApolloProvider>

        <Route
          path={Routes.HOME_PRODUCT_RENTATOTAL_SCHEDULE}
          exact
          component={withHome(HomeSchedule)}
        />
        <Route
          path={Routes.HOME_PRODUCT_AHORROTOTAL_SCHEDULE}
          exact
          component={withHome(HomeSchedule)}
        />
        <Route path={Routes.HOME_PRODUCT_RRVV_SCHEDULE} exact component={withHome(HomeSchedule)} />

        <Route
          path={Routes.HOME_FREQUENT_QUESTION}
          exact
          component={withHome(HomeFrequentQuestion)}
        />

        <Route path={Routes.HOME_REGISTER_CLAIM} exact component={withHome(HomeClaims)} />
        <Route path={Routes.HOME_STATE_CLAIM} exact component={withHome(HomeStateClaims)} />
        <Route path={Routes.HOME_STATE_PROCEDURE} exact component={withHome(HomeStateSinister)} />
        <Route path={Routes.HOME_STATE_REQUEST} exact component={withHome(HomeStateRequest)} />
        <ApolloProvider client={client}>
          <Route path={Routes.HOME_UPDATE_DATA} exact component={withHome(HomeUpdateData)} />
        </ApolloProvider>
        <Route path={Routes.HOME_NEWSLETTER} exact component={withHeader(HomeNewsletter)} />
        <Route
          path={`${Routes.HOME_NEWSLETTER}/:id`}
          exact
          component={withHeader(HomeNewsletter)}
        />

        <Route
          path={`${Routes.LOADING_PAYMENT}/:transactionalToken`}
          exact
          component={PSLoadingPayment}
        />
      </Router>
    </ReduxProvider>
  );
}

export default App;
