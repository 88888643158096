import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { cleanAuth, setUserForgotPassword, validateCognito, validateUser } from '../../redux/auth';
import { Routes } from '../../../routes';
import { maxLengthCheck } from '../../../util/Util';
import { Strings } from '../../shared/constants/Strings';
import { STORAGE_TOKEN_APP, typeDocument } from '../../../util/Constant';
import AuthService from '../../shared/services/AuthService';
import { GAProvider } from '../../components/PSAnalytics';

import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSTextInput from '../../components/PSTextInput';
import PSButton from '../../components/PSButton';
import PSBox from '../../components/PSBox';
import PSSelect from '../../components/PSSelect';
import PSError from '../../components/PSError';
import PSMessageResponse from '../../components/PSMessageResponse';
import { getPlaceholderInput } from '../SignUp';
import PSLoading from '../../components/PSLoading';
import PSCaptcha from '../../components/molecules/PSCaptcha';

import './index.scss';

const ForgotPasswordForm = ({
  formik,
  onClose,
  loading,
  loadingToken,
  captcha,
  onChangeCaptcha,
  validCaptcha,
  hasKeyCaptcha,
}) => (
  <PSLoginContainer
    className={`user-container  ${loadingToken ? 'container-loading' : ''}`}
    onClose={onClose}
  >
    {loadingToken ? (
      <PSLoading />
    ) : (
      <>
        <PSHeaderLogin className="user-header-login" title="Solicitud de validación" icon="user" />
        <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
          <PSBox className="user-box-login">
            <PSSelect
              {...formik.getFieldProps('documentType')}
              options={[{ value: '', label: 'Seleccione documento' }, ...typeDocument]}
              selectedValue={formik.values.documentType}
            />
            <PSError>{formik.touched.documentType && formik.errors.documentType}</PSError>
            <PSTextInput
              type="text"
              maxLength={formik.values.documentType === 'L' ? 8 : 11}
              onKeyPress={(e) => (formik.values.documentType !== 'G' ? maxLengthCheck(e) : null)}
              placeholder={getPlaceholderInput(formik.values.documentType)}
              {...formik.getFieldProps('documentId')}
              onChange={(e) => {
                e.target.value = e.target.value?.toUpperCase();
                formik.getFieldProps('documentId').onChange(e);
              }}
            />
            <PSError>{formik.touched.documentId && formik.errors.documentId}</PSError>
          </PSBox>
          {hasKeyCaptcha && <PSCaptcha ref={captcha} onChange={onChangeCaptcha} />}
          <PSBox className="user-footer">
            <PSButton
              className="btn-submit"
              type="submit"
              loading={loading}
              disabled={hasKeyCaptcha && !validCaptcha}
            >
              Validar
            </PSButton>
          </PSBox>
        </form>
      </>
    )}
  </PSLoginContainer>
);

const ErrorMessage = ({ userStatus, onClose, history, userBlocked }) => {
  const dispatch = useDispatch();
  const getErrorMessage = () => {
    if (userStatus === 'INCOMPLETE_USER') {
      return Strings.USER_INCOMPLETE;
    }

    if (userStatus === 'USER_INVALID_POLICIES') {
      return Strings.USER_INVALID_POLICIES;
    }

    if (userBlocked) {
      return Strings.USER_BLOCKED;
    }

    return Strings.USER_NOT_FOUND;
  };

  const goRegister = () => {
    dispatch(cleanAuth());
    history.push(Routes.SIGN_UP);
  };

  return (
    <PSMessageResponse icon="face" onClose={onClose}>
      {getErrorMessage()}
      {userStatus === 'USER_NOT_FOUND' && (
        <div className="m-1">
          <PSButton className="f-extraBold" onClick={goRegister}>
            Registrate
          </PSButton>
        </div>
      )}
    </PSMessageResponse>
  );
};

export default function SignUp({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const ga = React.useContext(GAProvider);
  const captcha = useRef(null);
  const { isUserValid, loading, cognitoValid, forgotPassword } = useSelector((state) => state.auth);
  const [showError, setError] = useState(false);
  const [userBlocked, setUserBlocked] = useState(false);
  const [loadingToken, setLoadingToken] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const hasKeyCaptcha = process.env.REACT_APP_RECAPTCHA_KEY ? true : false;
  const formik = useFormik({
    initialValues: {
      documentId: '',
      documentType: '',
    },
    validationSchema: Yup.object().shape({
      documentId: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('Documento es requerido')
        .when('documentType', {
          is: 'L',
          then: Yup.string()
            .max(8, 'El documento solo tiene 8 caracteres')
            .required('DNI es requerido'),
        })
        .when('documentType', {
          is: 'R',
          then: Yup.string()
            .min(11, 'El documento debe tener al menos 11 dígitos')
            .required('RUC es requerido'),
        }),
      documentType: Yup.string().required('Seleccione el tipo de documento'),
    }),

    onSubmit(form) {
      dispatch(setUserForgotPassword(form));
      dispatch(validateCognito(form.documentId, form.documentType, validCaptcha));
    },
  });

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (
      ['INVALID_USER', 'USER_INVALID_POLICIES', 'USER_NOT_FOUND', 'NO_DATA_USER'].includes(
        isUserValid
      )
    ) {
      setError(true);
    }

    if (isUserValid === 'VALID_USER' && forgotPassword.documentType) {
      const documentType = forgotPassword.documentType.toString();
      const typeUser = documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
      const labelGA = 'El usuario inició el flujo de recover password';

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${typeUser}Recover Password`,
        action: 'click',
        label: labelGA,
        value: 1,
      });
      history.push(Routes.FORGOT_PASSWORD_VALIDATION);
    }
  }, [isUserValid]);

  useEffect(() => {
    if (cognitoValid === 'INVALID_USER') {
      setError(true);
    }
    if (cognitoValid === 'BLOCKED_USER') {
      setUserBlocked(true);
      setError(true);
    }

    if (cognitoValid === 'VALID_USER') {
      dispatch(validateUser(forgotPassword));
    }
  }, [cognitoValid]);

  const loadToken = async () => {
    try {
      const response = await AuthService.getTokenApp();
      if (
        response.authenticationResponse &&
        response.authenticationResponse.authenticationState === 200
      ) {
        const token = response.authenticationResponse.authenticationData.securityToken;
        localStorage.setItem(STORAGE_TOKEN_APP, token);
      }
      setLoadingToken(false);
    } catch (error) {
      setLoadingToken(false);
      console.log(error);
    }
  };
  const onChangeCaptcha = () => {
    const valueCaptcha = captcha.current.getValue();
    if (valueCaptcha) setValidCaptcha(valueCaptcha);
  };

  useEffect(() => {
    loadToken();
  }, []);

  return (
    <div className="forgot-password-validation-container">
      {!showError && (
        <ForgotPasswordForm
          formik={formik}
          userStatus={isUserValid}
          onClose={onClose}
          loading={loading}
          loadingToken={loadingToken}
          captcha={captcha}
          onChangeCaptcha={onChangeCaptcha}
          validCaptcha={validCaptcha}
          hasKeyCaptcha={hasKeyCaptcha}
        />
      )}
      {showError && (
        <ErrorMessage
          userBlocked={userBlocked}
          userStatus={isUserValid}
          onClose={onClose}
          history={history}
        />
      )}
    </div>
  );
}
