import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { pathServer, userDefault } from '../../util/Constant';

const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
let dateFormatted = dateNow.replace('-', 'de');
dateFormatted = dateFormatted.replace('-', 'de');

const StyledPSFileConstanciaAsegurado = styled.div`
  width: 100%;
  color: black;
  background: white;
  padding: 10% 6%;
  font-size: 14px;
  .report-header {
    width: 35%;
    img {
      height: auto !important;
    }
  }
  .report-body {
    .report-body-title {
      padding: 9% 0%;
      text-align: center;
      label {
        color: black;
        font-size: 18px;
        font-weight: 700;
      }
      .title-contancia {
        padding-right: 4px;
      }
    }
    .report-description {
      text-align: justify;
      .description-fullName {
        font-weight: 700;
      }
    }
    .report-filters {
      padding-top: 5%;
      .filter-row {
        display: flex;
        margin-bottom: 8px;
        h6 {
          min-width: 180px;
        }
        span {
          // padding-right: 8%;
        }
        .span-right {
          margin-left: 10px;
        }
      }
    }
    .report-footer {
      .footer-description {
        padding: 15% 0 10%;
      }
    }
    .report-firm {
      display: flex;
      justify-content: flex-end;
      & div:nth-child(1) {
        width: 200px;
      }
      .report-firm-asignee {
        text-align: center;
        h5 {
          font-size: 17px;
          line-height: 18px;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .report-body-title {
      label {
        font-size: 24px !important;
      }
    }
    .report-firm {
      padding-right: 3%;
    }
  }
  @media screen and (max-width: 450px) {
    .report-body-title {
      label {
        font-size: 12px !important;
      }
    }
    .report-body {
      font-size: 2vw;
      .report-filters {
        .filter-row {
          h6 {
            min-width: 42%;
          }
        }
      }
      .report-firm {
        & div:nth-child(1) {
          width: 120px;
        }
        .report-firm-asignee {
          h5 {
            font-size: 2vw;
          }
        }
      }
    }
  }
`;

const PSFileConstanciaAsegurado = React.forwardRef(
  ({ poliza, descriptionHeader, id, className }, ref) => {
    const { userInfo } = useSelector((state) => state.auth);
    const attributes = userInfo ? userInfo.attributes : userDefault;
    const document = userInfo ? userInfo.document : '40608067';
    let fullName = `${attributes.middleName} ${attributes.familyName} ${attributes.givenName}`;
    const isBusiness = userInfo ? userInfo.isBusiness : false;

    const datePoliza = poliza.dateVigencia ? poliza.dateVigencia.split(' ') : null;
    const dateInitial = datePoliza ? datePoliza[0] : null;
    const dateEnd = datePoliza ? datePoliza[datePoliza.length - 1] : null;
    if (isBusiness) {
      fullName = attributes.legalName;
    }
    return (
      <StyledPSFileConstanciaAsegurado ref={ref} id={id} className={className}>
        <div className="report-header">
          <img src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`} />
        </div>
        <div className="report-body">
          <div className="report-body-title">
            <label className="title-contancia">CONSTANCIA</label>
            <label>DE SEGURO</label>
          </div>
          <div className="report-body-v2">
            <div className="report-description">
              <label>Por medio de la presente dejamos constancia que en nuestros registros, {isBusiness ? 'la empresa' : 'el Sr(a)'} </label>
              <label className="description-fullName">{` ${fullName}, identificado con ${
                isBusiness ? 'RUC' : 'Documento Nacional de Identidad'
              }  Nº ${document} `}</label>
              <label>se encuentra asegurada por nuestra compañía desde el {poliza.dateStart || '-'}, a través de la
                Póliza Nº {poliza.nroPolicy || '-'}, conforme se detalla a continuación:</label>
            </div>
            <div className="report-filters">
              <div className="filter-row">
                <h6>Tipo de Seguro</h6>
                <span>:</span>
                <label className="span-right"> {`Seguro de ${descriptionHeader}`} </label>
              </div>
              {/*{descriptionHeader === 'Accidentes Personales' && (*/}
              {/*  <div className="filter-row">*/}
              {/*    <h6>Contratante</h6>*/}
              {/*    <span>:</span>*/}
              {/*    <h5  className="span-right">Universidad Continental </h5>*/}
              {/*  </div>*/}
              {/*)}*/}
              <div className="filter-row">
                <h6>Nº de Póliza</h6>
                <span>:</span>
                <h5  className="span-right">{poliza.nroPolicy || '-'}</h5>
              </div>
              <div className="filter-row">
                <h6>Fecha de Inicio de Vigencia</h6>
                <span>:</span>
                <h5  className="span-right">{dateInitial || '-'} </h5>
              </div>
              <div className="filter-row">
                <h6>Fecha de Fin de Vigencia</h6>
                <span>:</span>
                {descriptionHeader === 'Rentas Vitalicias' ? (
                  <h5  className="span-right"> Vitalicia </h5>
                ) : (
                  <h5  className="span-right">{dateEnd || '-'} </h5>
                )}
              </div>
              <div className="filter-row">
                <h6>Suma Asegurada</h6>
                <span>:</span>
                <h5  className="span-right">Según condiciones de la Póliza </h5>
              </div>
            </div>
          </div>
          <div className="report-footer">
            <h5 className="footer-description">
              Se expide la presente Constancia a solicitud del Asegurado(a), para los fines que
              estime conveniente.
            </h5>
            <h5 className="footer-date"> {`Surquillo, ${dateFormatted}`}</h5>
          </div>
          <div className="report-firm">
            <div>
              <img src={`${pathServer.PATH_ICONS}firma.png`} />
              <div className="report-firm-asignee">
                <h5>Guadalupe Robles Benzaquen</h5>
                <h5>Jefe de Soluciones de Clientes</h5>
              </div>
            </div>
          </div>
        </div>
      </StyledPSFileConstanciaAsegurado>
    );
  }
);

PSFileConstanciaAsegurado.displayName = 'PSFileConstanciaAsegurado';

PSFileConstanciaAsegurado.defaultProps = {
  poliza: {},
  descriptionHeader: null,
};

PSFileConstanciaAsegurado.propTypes = {
  poliza: PropTypes.object,
  descriptionHeader: PropTypes.string,
};

export default PSFileConstanciaAsegurado;
