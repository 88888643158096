import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import moment from 'moment-timezone/index';
import ReactPaginate from 'react-paginate';
import { createErrorPage } from '../../../redux/auth';

import {
  base64toBlob,
  isEmptyValue,
  maxLengthCheck,
  sortArrayByDates,
} from '../../../../util/Util';
import { PARAMS_PAGINATE } from '../../../../util/Constant';
import PaymentVouchersServices from '../../../shared/services/PaymentVouchersServices';

import { GAProvider } from '../../PSAnalytics';
import PSLoading from '../../PSLoading';
import PSIcon from '../../PSIcon';

import './index.scss';

function voucherType(type) {
  let value = 'Factura';
  switch (type) {
    case '01':
      value = 'Factura';
      break;
    case '03':
      value = 'Boleta de venta';
      break;
    case '07':
      value = 'Nota de crédito';
      break;
    case '08':
      value = 'Nota de débito';
      break;
  }
  return value;
}

const HEADERS = [
  { elementProp: 'voucherType', headerColumn: 'Tipo' },
  { elementProp: 'serialNumber', headerColumn: 'Número de Serie' },
  { elementProp: 'number', headerColumn: 'Número' },
  { elementProp: 'date', headerColumn: 'Fecha' },
  { elementProp: 'amount', headerColumn: 'Monto' },
  { elementProp: 'status', headerColumn: 'Estado' },
];
const MAX_PER_PAGE = 10;

const PSTableComprobantes = ({
  onCloseOption,
  descriptionHeader,
  typeDocument,
  paramsComprobantes,
}) => {
  const [loadingComprobantes, setLoadingComprobantes] = useState(true);
  const [listComprobantes, setListComprobantes] = useState([]);
  const [hasStartDate, setHasStartDate] = useState(false);
  const [loadingConstancySelect, setLoadingConstancySelect] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [listVouchersFilter, setListVouchersFilter] = useState([]);
  const [comprobanteSelected, setComprobanteSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState('pdf');
  const [actualPage, setActualPage] = useState(1);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
  const ga = React.useContext(GAProvider);
  const numberVoucherRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const listPaginate = listVouchersFilter?.slice(
    (actualPage - 1) * MAX_PER_PAGE,
    actualPage * MAX_PER_PAGE
  );
  const itemsIterable = Math.ceil(listVouchersFilter.length / MAX_PER_PAGE);

  const changeFormatRow = (item, elementProp) => {
    let element = '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };

    switch (elementProp) {
      case 'voucherType':
        element = (
          <td key={elementProp} className="table-item">
            {voucherType(item[elementProp]) || '-'}
          </td>
        );
        break;
      case 'date':
        const newDate = new Date(item[elementProp]).toLocaleString('en-GB', options).split(',')[0];
        element = (
          <td key={elementProp} className="table-item">
            {newDate}
          </td>
        );
        break;
      case 'amount':
        const roundAmount = Number(item[elementProp].toFixed(2));
        element = (
          <td key={elementProp} className="table-item">
            {item.currency || ''} {roundAmount || '-'}
          </td>
        );
        break;
      default: {
        element = (
          <td key={elementProp} className="table-item">
            {item[elementProp] || '-'}
          </td>
        );
      }
    }
    return element;
  };
  const getListComprobantes = async () => {
    try {
      setLoadingComprobantes(true);
      const response = await PaymentVouchersServices.responseValidateComprobantePago(
        paramsComprobantes
      );
      if (response && response.status.id === 200 && response.data) {
        const orderList = sortArrayByDates(response.data);
        setListComprobantes(orderList);
        setListVouchersFilter(orderList);
      } else {
        setListComprobantes(null);
      }
      setLoadingComprobantes(false);
    } catch (error) {
      setLoadingComprobantes(false);
      setListComprobantes(null);
      console.error(error);
    }
  };
  const responseComprobante = async () => {
    try {
      setLoadingConstancySelect(true);
      const responseDocument = await PaymentVouchersServices.responseDocumentComprobantePago({
        ...comprobanteSelected,
        isPDF: typeSelected !== 'xml',
      });

      if (responseDocument.status.id === 200) {
        const fixBase64 = responseDocument.data[0].file;
        const fileName = `Comprobante de Pago-${comprobanteSelected.serialNumber}-${comprobanteSelected.number}`;
        if (typeSelected === 'pdf' || typeSelected === 'xml') {
          const blob = base64toBlob(fixBase64, `application/${typeSelected}`);
          FileSaver.saveAs(blob, fileName);
        }
        ga.pageview(window.location.pathname + window.location.search);
        ga.event({
          category: `${messageTypeUser}Producto ${descriptionHeader}-comprobante de pago`,
          action: 'download',
          label: `El usuario descargó su comprobante de pago en formato ${typeSelected} de ${descriptionHeader}`,
          value: 1,
        });
      }
    } finally {
      setLoadingConstancySelect(false);
      setComprobanteSelected(null);
    }
  };

  useEffect(() => {
    if (paramsComprobantes) {
      getListComprobantes();
    }
  }, [paramsComprobantes]);

  useEffect(() => {
    if (listComprobantes === null) {
      dispatch(
        createErrorPage({
          onAction: () => {},
          state: 'true',
          icon: 'broken',
          title: 'Error!',
          message: 'La póliza no se encuentra habilitada para Descarga de Comprobante de Pagos',
        })
      );
      onCloseOption();
    }
  }, [listComprobantes]);

  useEffect(() => {
    if (comprobanteSelected) {
      responseComprobante();
    }
  }, [comprobanteSelected, typeSelected]);

  useEffect(() => {
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${descriptionHeader}-comprobante de pago`,
      action: 'click',
      label: `El usuario visitó la página de comprobante de pago de ${descriptionHeader}`,
      value: 1,
    });
  }, [ga]);

  const onSearch = () => {
    const valueNumber = numberVoucherRef.current.value.trim();
    const valueStartDate = startDateRef.current.value.trim();
    const valueEndDate = endDateRef.current.value.trim();

    let filter = listComprobantes;

    if (isEmptyValue(valueNumber) && isEmptyValue(valueStartDate) && isEmptyValue(valueEndDate)) {
      setListVouchersFilter(filter);
    } else {
      if (!isEmptyValue(valueNumber)) {
        filter = filter.filter((item) => {
          return item.number.search(new RegExp(valueNumber, 'i')) !== -1;
        });
      }
      if (!isEmptyValue(valueStartDate)) {
        setHasStartDate(true);
        filter = filter.filter((item) => {
          return moment(item.date.split('T')[0]) >= moment(valueStartDate);
        });
      } else {
        setHasStartDate(false);
      }
      if (!isEmptyValue(valueEndDate)) {
        filter = filter.filter((item) => {
          return moment(item.date.split('T')[0]) <= moment(valueEndDate);
        });
      }
      setListVouchersFilter(filter);
      setActualPage(1);
    }
  };

  return loadingComprobantes ? (
    <div className="flex-center mt-1">
      <PSLoading />
    </div>
  ) : listComprobantes ? (
    <div>
      <div className="table-component">
        <div className="title">
          <h4>Listado de Comprobantes de Pago</h4>
        </div>
        {listComprobantes.length > 0 ? (
          <>
            {listComprobantes.length > MAX_PER_PAGE && (
              <div className="table-filters d-flex">
                <div className="filter-group">
                  <label htmlFor="searchNumber">Número de Comprobante</label>
                  <input
                    id="searchNumber"
                    ref={numberVoucherRef}
                    type="text"
                    placeholder="Buscar"
                    onChange={onSearch}
                    onKeyPress={(e) => maxLengthCheck(e)}
                    maxLength="10"
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="searchStartDate">Rango de Fecha de Emisión</label>
                  <div className="row-date">
                    <input
                      id="searchStartDate"
                      ref={startDateRef}
                      type="date"
                      placeholder="Buscar"
                      min="1900-12-31"
                      max="2050-12-31"
                      onChange={onSearch}
                    />
                    <input
                      id="searchEndDate"
                      ref={endDateRef}
                      type="date"
                      placeholder="Buscar"
                      min={hasStartDate ? startDateRef.current.value : '1900-12-31'}
                      max="2050-12-31"
                      onChange={onSearch}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="container-table">
              <table>
                <thead>
                  <tr className="">
                    <th />
                    {HEADERS.map((header) => (
                      <th key={header.elementProp} className="table-header" colSpan={1}>
                        {header.headerColumn}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {listPaginate.length === 0 ? (
                    <tr>
                      <td className="table-item" colSpan="6">
                        No se encontraron comprobantes de pago en el filtro aplicado
                      </td>
                    </tr>
                  ) : (
                    listPaginate.map((item, index) => {
                      return (
                        <tr key={item.number}>
                          <td className="table-item">
                            {comprobanteSelected?.number === item.number &&
                            loadingConstancySelect &&
                            typeSelected === 'pdf' ? (
                              <div className="loading margin-icon">
                                <PSLoading width={22} height={22} color="#2B0D61" />
                              </div>
                            ) : (
                              <PSIcon
                                className="icon mx-1"
                                type="filePDF"
                                onClick={() => {
                                  setComprobanteSelected(item);
                                  setTypeSelected('pdf');
                                }}
                              />
                            )}
                            {comprobanteSelected?.number === item.number &&
                            loadingConstancySelect &&
                            typeSelected === 'xml' ? (
                              <div className="loading margin-icon">
                                <PSLoading width={22} height={22} color="#ed6e00" />
                              </div>
                            ) : (
                              <PSIcon
                                className="icon mx-1"
                                type="fileXML"
                                onClick={() => {
                                  setComprobanteSelected(item);
                                  setTypeSelected('xml');
                                }}
                              />
                            )}
                          </td>
                          {HEADERS.map((header) => changeFormatRow(item, header.elementProp))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="flex-center empty-vouchers">
            <span>No se encontraron Comprobantes de Pago relacionados a esta póliza </span>
          </div>
        )}
        {listComprobantes?.length > MAX_PER_PAGE && listVouchersFilter.length > 0 && (
          <div className="mt-2">
            <ReactPaginate
              pageCount={itemsIterable}
              initialPage={actualPage - 1}
              onPageChange={(page) => {
                setActualPage(page.selected + 1);
              }}
              forcePage={actualPage - 1}
              {...PARAMS_PAGINATE}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    false
  );
};

PSTableComprobantes.displayName = 'PSTableComprobantes';

PSTableComprobantes.defaultProps = {
  onCloseOption: () => {},
  descriptionHeader: '',
  typeDocument: '',
};

PSTableComprobantes.propTypes = {
  onCloseOption: PropTypes.func,
  descriptionHeader: PropTypes.string,
  typeDocument: PropTypes.string,
};

export default PSTableComprobantes;
