import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import ReactPaginate from 'react-paginate';
import map from 'lodash/map';
import { props } from 'bluebird';

import { scrollToTop, carouselProps } from '../../../../util/Util';

import PSCouponCard from '../../organisms/PSCouponCard/PSCouponCard';
import PSLoading from '../../PSLoading';
import PSBox from '../../PSBox';
import PSIcon from '../../PSIcon';

import './index.scss';

const MAX_PER_PAGE = 6;

const CouponsList = ({ listPaginate, onClickShowCodeCoupon, ...props }) => {
  return (
    <div className="coupon-container">
      {map(listPaginate, (item, index) => (
        <div key={index}>
          <PSCouponCard
            data={item}
            isMyCoupon
            {...props}
            onClickShowCodeCoupon={onClickShowCodeCoupon}
          />
        </div>
      ))}
    </div>
  );
};

const MyCoupons = React.memo(
  ({
    loading,
    setLoading,
    listCoupon,
    availableCoupons,
    onClickShowCodeCoupon,
    currentCouponSelected,
    ...props
  }) => {
    const [pageSelected, setPageSelected] = useState(1);
    const listPaginate = availableCoupons.slice(
      (pageSelected - 1) * MAX_PER_PAGE,
      pageSelected * MAX_PER_PAGE
    );
    const carouselRef = useRef(null);
    const itemsIterable = Math.ceil(availableCoupons.length / MAX_PER_PAGE);
    const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(0);
      }
    };

    const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(listCoupon.length);
      }
    };

    return (
      <div className="my-coupons">
        <div className="my-coupons__header">
          <div className="section-title">
            <PSBox className="icon-title">
              <PSIcon type="myCoupons" />
            </PSBox>
            <div className="text-title">
              {availableCoupons.length === 1 ? (
                <h3 className="title">¡Tienes {availableCoupons.length} cupón!</h3>
              ) : (
                <h3 className="title">¡Tienes {availableCoupons.length} cupones!</h3>
              )}
            </div>
          </div>
        </div>
        <div className="my-coupons__body">
          {loading ? (
            <div className="loading-pages-intranet">
              <PSLoading />
            </div>
          ) : availableCoupons.length === 0 ? (
            <div className="body-optionRegister">
              <div className="content-coupons">
                <div className="content-message">
                  <label>Su lista de cupones esta vacía.</label>
                </div>
              </div>
            </div>
          ) : (
            <>
              <CouponsList
                listPaginate={listPaginate}
                onClickShowCodeCoupon={onClickShowCodeCoupon}
                {...props}
              />
              {availableCoupons.length > MAX_PER_PAGE && (
                <div className="container-pagination">
                  <ReactPaginate
                    pageCount={itemsIterable}
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    marginPagesDisplayed={2}
                    initialPage={pageSelected - 1}
                    onPageChange={(page) => {
                      setPageSelected(page.selected + 1);
                      scrollToTop();
                    }}
                    pageRangeDisplayed={MAX_PER_PAGE}
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="my-coupons__footer">
          {listCoupon.length > 1 && (
            <div className="content-footer-detail">
              <p>LOS CUPONES MÁS VISTOS POR LOS USUARIOS</p>

              <Carousel
                ref={carouselRef}
                {...carouselProps}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
              >
                {map(
                  listCoupon,
                  (item, index) =>
                    item.availableCoupons > 0 && (
                      <div
                        className="column"
                        key={index}
                        onClick={() => currentCouponSelected(item)}
                        id={index}
                      >
                        <PSCouponCard data={item} onClick />
                      </div>
                    )
                )}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    );
  }
);

MyCoupons.displayName = 'MyCoupons';

MyCoupons.defaultProps = {
  loading: true,
};

MyCoupons.propTypes = {
  loading: PropTypes.bool,
};

export default MyCoupons;
