import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PSIcon from './PSIcon';

const StyledPSInputIcon = styled.div`
  position: relative;
  color: #aaa;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  span {
    left: auto;
    right: 10px;
    top: 15px;
    position: absolute;
    svg {
      fill: ${(props) => (props.isShow ? 'black' : '#e4e4e4')};
      width: 20px;
      height: auto;s
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const PSInputIcon = React.forwardRef(({ children, showPassword, isShow }, ref) => (
  <StyledPSInputIcon isShow={isShow} ref={ref}>
    <span onClick={showPassword}>
      <PSIcon type="password" />
    </span>
    {children}
  </StyledPSInputIcon>
));

PSInputIcon.displayName = 'PSInputIcon';

PSIcon.defaultProps = {
  showPassword: () => {},
  isShow: false,
};

PSIcon.propTypes = {
  showPassword: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
};

export default PSInputIcon;
