/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createErrorPage } from '../../../redux/auth';

import FormsService from '../../../shared/services/FormsService';
import PSLoading from '../../PSLoading';
import PSComponentForm from '../../PSComponentForm';
import PSList from '../../PSList';
import { CodeOptProduct, menuCode } from '../../../shared/constants/Strings';
import PSButton from '../../PSButton';

import './index.scss';

const PSFormChangePayment = React.forwardRef(
  ({ onCloseOption, policyCurrent, menuId, form, isPreview, loadingSaveForm, ...props }) => {
    const { onChangeModePage, getInfoPreview, setIsPreview, onSaveForm, availableSendForm } = props;
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const dispatch = useDispatch();
    const enabledForm = async () => {
      try {
        setLoading(true);
        const { data, status } = await FormsService.validateFormByPolicy(policyCurrent, menuId);
        if (status.id === 200 || status.name === 'SUCCESS OPERATION') {
          const { beneficary, receiver } = data;
          const nameBeneficary = `${beneficary.firstName ? beneficary.firstName : ''} ${
            beneficary.middleName ? beneficary.middleName : ''
          } ${beneficary.lastName ? beneficary.lastName : ''}  ${
            beneficary.secondLastName ? beneficary.secondLastName : ''
          }`;
          const nameReceiver = `${receiver.firstName ? receiver.firstName : ''} ${
            receiver.middleName ? receiver.middleName : ''
          } ${receiver.lastName ? receiver.lastName : ''}  ${
            receiver.secondLastName ? receiver.secondLastName : ''
          }`;
          form[CodeOptProduct.CMP][1].value = nameBeneficary || '';
          form[CodeOptProduct.CMP][2].value = nameReceiver || '';
          form[CodeOptProduct.CMP][1].readOnly = true;
          form[CodeOptProduct.CMP][2].readOnly = true;
        } else {
          setIsValid(false);
          dispatch(
            createErrorPage({
              onAction: () => {},
              state: 'true',
              icon: 'broken',
              title: 'Error!',
              message: 'La póliza no se encuentra habilitada para cambio de modalidad de pagos',
            })
          );
          onCloseOption();
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (menuId === menuCode.RENTA_TOTAL) {
        enabledForm();
      }
    }, []);

    return loading ? (
      <div className="loading">
        <h1>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aspernatur at dolorem fugiat
          hic magni molestias mollitia non officiis optio. Alias architecto consequatur facilis
          maxime nisi quos recusandae sapiente tempora?
        </h1>
        <PSLoading />
      </div>
    ) : (
      isValid && (
        <>
          {!isPreview ? (
            <PSComponentForm
              from={form[CodeOptProduct.CMP]}
              onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.CMP)}
            />
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.CMP])} />
          )}
          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.CMP)}
              disabled={!availableSendForm(CodeOptProduct.CMP)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      )
    );
  }
);

PSFormChangePayment.displayName = 'PSFormChangePayment';

PSFormChangePayment.defaultProps = {
  policyCurrent: null,
  menuId: null,
  onCloseOption: () => {},
};

PSFormChangePayment.propTypes = {
  policyCurrent: PropTypes.number,
  menuId: PropTypes.string,
  onCloseOption: PropTypes.func,
};

export default PSFormChangePayment;
