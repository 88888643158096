import { CodeOptProduct } from './Strings';

export const OptionsProduct = [
  { id: 0, code: CodeOptProduct.LDP, title: 'Liquidación de pago', icon: 'liquidationPayment' },
  {
    id: 1,
    code: CodeOptProduct.DCD,
    title: 'Descarga de certificado digital',
    icon: 'certificate',
  },
  { id: 2, code: CodeOptProduct.BLT, title: 'Mis boletas', icon: 'boletas' },
  {
    id: 3,
    code: CodeOptProduct.CPS,
    title: 'Constancia de pensionista',
    icon: 'pensionerCertificate',
  },
  {
    id: 4,
    code: CodeOptProduct.CDA,
    title: 'Constancia de Asegurado',
    icon: 'pensionerCertificate',
  },
  {
    id: 14,
    code: CodeOptProduct.CDLA,
    title: 'Constancia de Listado de Asegurados',
    icon: 'pensionerCertificate',
  },
  {
    id: 15,
    code: CodeOptProduct.CICA,
    title: 'Constancia Individual de Asegurados',
    icon: 'pensionerCertificate',
  },
  { id: 16, code: CodeOptProduct.DCDVI, title: 'Póliza Digital', icon: 'certificate' },
  {
    id: 5,
    code: CodeOptProduct.DCP,
    title: 'Descarga de comprobante de pago',
    icon: 'pensionerCertificate',
  },
  { id: 6, code: CodeOptProduct.CP, title: 'Cronograma de pago', icon: 'cronograma' },
  { id: 7, code: CodeOptProduct.CMP, title: 'Cambio de modalidad de pago', icon: 'modePage' },
  { id: 8, code: CodeOptProduct.DP, title: 'Descarga de póliza', icon: 'downloadPoliza' },
  { id: 9, code: CodeOptProduct.CDG, title: 'Cuadro de valores garantizados', icon: 'secureValue' },
  { id: 10, code: CodeOptProduct.ADB, title: 'Modificación de beneficiarios', icon: 'updateData' },

  { id: 101, code: CodeOptProduct.INCB, title: 'Inclusión de Beneficiario', icon: 'updateData' },
  { id: 102, code: CodeOptProduct.EXCB, title: 'Exclusión de Beneficiario', icon: 'updateData' },

  {
    id: 11,
    code: CodeOptProduct.CPC,
    title: 'Cambio de % de Capital asegurado',
    icon: 'updateCapital',
  },
  {
    id: 12,
    code: CodeOptProduct.CGS,
    title: 'Modificación de Beneficiarios de gasto de Sepelio',
    icon: 'updateSepelio',
  },
  {
    id: 121,
    code: CodeOptProduct.IBGS,
    title: 'Inclusión de Beneficiario por gasto de sepelio ',
    icon: 'updateSepelio',
  },
  {
    id: 122,
    code: CodeOptProduct.EBGS,
    title: 'Exclusión de Beneficiario por gasto de sepelio',
    icon: 'updateSepelio',
  },
  { id: 13, code: CodeOptProduct.GLC, title: 'Geolocalización de clínicas', icon: 'geolocation' },
  { id: 14, code: CodeOptProduct.MDT, title: 'Modificación de tarjeta para cobro recurrente', icon: 'downloadPoliza' },
  {
    id: 15,
    code: CodeOptProduct.HCT,
    title: 'Historial de cambio de tarjeta para cobro recurrente',
    icon: 'historySolid',
  },
  { id: 16, code: CodeOptProduct.CUP, title: 'Cuotas Pendientes', icon: 'pensionerCertificate' },
];
