import { doGet } from './Api';

export default class GetSoatCertificate {
  static async responseGetSoatProduct() {
    const response = await doGet(`${process.env.REACT_APP_GET_PRODUCT_SOAT}`);
    return response.data;
  }

  static async responseGetSoatKey() {
    const response = await doGet(`${process.env.REACT_APP_GET_KEY_SOAT}`);
    return response.data;
  }

  static async responseGetSoatConstancia(token, nroPolicy) {
    const response = await doGet(`${process.env.REACT_APP_GET_CONSTANCIA_SOAT+nroPolicy}`,token);
    return response.data;
  }
}
