import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPsBox = styled.div`
  text-align: center;
  margin: 2px;
  padding: 0;
  width: 100%;
  border: none;
  height: initial;
  display: block;
  align-items: initial;
  max-height: ${(props) => (props.useScroll ? '280px' : '100%')};
  overflow-y: ${(props) => (props.useScroll ? 'scroll' : 'initial')};
`;

const PSBox = React.forwardRef(({ children, useScroll, className }, ref) => (
  <StyledPsBox useScroll={useScroll} className={className} ref={ref}>
    {children}
  </StyledPsBox>
));

PSBox.displayName = 'PSBox';

PSBox.defaultProps = {
  className: null,
  useScroll: false,
};

PSBox.propTypes = {
  useScroll: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PSBox;
