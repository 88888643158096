import React , {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSRadio from './PSRadio';
import PSIcon from "./PSIcon";

const StyledSearch = styled.div`
  background: #fff;
  margin: 1rem;
  border-radius: 25px;
  .container-search {
    display: flex;
    padding: 0.7rem 1rem;
    .icon-search{
      cursor:pointer;
      svg{
        width: 16px;
        height: 16px;
      }
    }
    input {
      width: 100%;
      border: none;
      font-size: 18px;
       // background: url(/images/icons/search.svg) #ffffff00 no-repeat calc(10px);
      background-size: 17px;
      padding-left: 10px;
      color: #bbbbbb;
    }

    input::-webkit-input-placeholder {
      color: #bbbbbb;
    }
  }

  @media (max-width: 576px) {
    .container-search {
      margin-top: 1rem;
      padding: 0.4rem 0.6rem;
      .icon-search{
       svg{
        width: 13px;
        height: 13px;
        }
      }
      input {
        font-size: 15px;
        background-size: 14px;
      }
    }
  }
`;

const PSSearch = React.forwardRef(({ onSearch, className, ...props }, ref) => {
  const [valueSearch, setValueSearch] = useState();

  const handleSearchEnter = (evt) => {
    if(evt.key === 'Enter'){
      onSearch(valueSearch);
    }
  };

  return (
    <StyledSearch ref={ref}  className={className} {...props}>
      <div className="container-search">
        <PSIcon type={'search'} className="icon-search" onClick={()=>onSearch(valueSearch)}/>
        <input onKeyPress={handleSearchEnter} type="text" placeholder="Buscar"  value={valueSearch} onChange={(event)=>setValueSearch(event.target.value)}/>
      </div>
    </StyledSearch>
  );
});

PSRadio.defaultProps = {
  className: null,
  onSearch: () => {},
};

PSSearch.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
}

export default PSSearch;
