import { doGet, doGetWithParams, doPost } from './Api';

export default class GetCertificates {
  static async responseGetProductCertificates(type) {
    let response;
    switch (type) {
      case 'rv':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000001`);
        break;
      case 'd':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000002`);
        break;
      case 'rt':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000008`);
        break;
      case 'at':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000003`);
        break;
      case 'vl':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000004`);
        break;
      case 'vi':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000010`);
        break;
      case 'ap':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000006`);
        break;
      case 'sctr':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000005`);
        break;
      case 'vd':
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000011`);
        break;
      default:
        response = await doGet(`${process.env.REACT_APP_GET_PRODUCT}/20000001`);
    }

    response.data.data = response.data.data.sort((a, b) => {
      if (a.fechaFinVigencia.includes('/') && b.fechaFinVigencia.includes('/')) {
        const dateA = a.fechaFinVigencia.split('/');
        const dateB = b.fechaFinVigencia.split('/');
        if (dateA && dateB) {
          const dateAFormatted = `${dateA[1]}/${dateA[0]}/${dateA[2]}`;
          const dateBFormatted = `${dateB[1]}/${dateB[0]}/${dateB[2]}`;
          return new Date(dateBFormatted) - new Date(dateAFormatted);
        }
      }
    });
    return response.data;
  }

  static async responseListaAsegurados(poliza, count = null, page = null, filters = null) {
    let response;
    const params = {
      branchId: poliza ? poliza.ramo.idRamo : 61,
      productId: poliza ? poliza.idProduct : 5,
      policyId: poliza ? poliza.nroPolicy : 0,
      certificateId: poliza ? poliza.nroCertificate : 0,
      endosoId: poliza ? poliza.endoso : 0,
      page: page ? page.toString() : '1',
      count: count ? count.toString() : '1000',
      filter: filters ? filters.lastName + filters.firstName : '',
    };
    try {
      response = await doPost(`${process.env.REACT_APP_GET_ASEGURADOS}`, params);
      return response.data;
    } catch (e) {
      return { data: [], statusCode: 500 };
    }
  }

  static async responseDigitalDocument(document) {
    const response = await doGet(`${process.env.REACT_APP_GET_DOCUMENT_DIGITAL}${document}`);
    return response?.data?.data;
  }

  static async responseFromPressingUrl(url, headerParams) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_GET_DOCUMENT_DIGITAL}get/${url}`,
      headerParams
    );
    return response?.data;
  }

  static async responseGetDigitaPolicy(params) {
    const response = await doPost(`${process.env.REACT_APP_DIGITAL_POLICY}`, params);
    return response?.data;
  }
}
