import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import "./index.scss";
import { pathServer, userDefault } from '../../../../util/Constant';

const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
let dateFormatted = dateNow.replace('-', 'de');
dateFormatted = dateFormatted.replace('-', 'de');
const rows= [1,2,3,4,5,6,7,8];
const PSConstanciaAseguradoSCTR = React.forwardRef(
  ({ poliza, descriptionHeader, id, className }, ref) => {
    const { userInfo } = useSelector((state) => state.auth);
    const attributes = userInfo ? userInfo.attributes : userDefault;
    const document = userInfo ? userInfo.document : '40608067';
    let fullName = attributes ? `${attributes.middleName} ${attributes.familyName} ${attributes.givenName}` : '';
    const isBusiness = userInfo ? userInfo.isBusiness : false;

    const datePoliza = poliza.dateVigencia ? poliza.dateVigencia.split(' ') : null;
    const dateInitial = datePoliza ? datePoliza[0] : null;
    const dateEnd = datePoliza ? datePoliza[datePoliza.length - 1] : null;
    if (isBusiness) {
      fullName = attributes.legalName;
    }
    return (
      <div ref={ref} id={id} className={ "download-file-constancia app-file-constancia-sctr " + className}>
        <div className="report-header">
          <div>
            <img src={`${pathServer.PATH_BOLETA}logo_sanitas.png`} />
          </div>
          <div>
            <img src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`} />
          </div>
        </div>
        <div className="report-body">
          <div className="report-body-title">
            <h6 >CONSTANCIA</h6>
            <h6 >SEGURO COMPLEMENTARIO DE TRABAJO DE RIESGO PENSIÓN Y SALUD</h6>
            <h6 >CRUZ QUIÑONES JORGE LUIS</h6>
            <h6 >VIGENCIA: 16/11/2020 10:00 al 15/12/2020</h6>
            <h6>ACTIVIDAD: FAB. DE MAQUINAS HERRAMIENTA.</h6>
          </div>
          <div className="report-description">
            <label> Por medio del presente dejamos constancia que los asegurados detallados líneas abajo,
              conforme al Decreto Supremo 003-98-SA, se encuentran amparados bajo la cobertura de salud de trabajo de riesgo y pensión del Seguro Complementario de Trabajo de Riesgo*.</label>
          </div>
          <div className="report-table">
            <table className="table-header">
              <tr>
                <td>Contrato SCTR - Salud N°: 322227</td>
                <td>Póliza SCTR - Pensión N°: 1000067003</td>
              </tr>
            </table>
            <div className="table-title">
              <h6>Sede: PRINCIPAL</h6>
            </div>
            <table>
              <thead>
                <tr>
                  <th width="5%">Nro.</th>
                  <th width="20%">Nombres</th>
                  <th width="20%">Apellido Paterno</th>
                  <th width="20%">Apellido Materno</th>
                  <th width="15%">Nro. Documento</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((index) => (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>LUIS ANGEL</td>
                    <td>SALAZAR</td>
                    <td>CERVANTES</td>
                    <td>DNI - 41375878</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="report-footer">
            <h5 className="footer-description">
              Extendemos la presente constancia a solicitud de nuestro cliente CRUZ QUIÑONES JORGE LUIS para los fines que considere pertinentes.
            </h5>
            <h5 className="footer-date mt-2"> {`Lima, ${dateFormatted}`}</h5>
          </div>
          <div className="report-firm">
            <div className="">
              <img src={`${pathServer.PATH_BOLETA}firma1.png`} />
              <div className="report-firm-asignee">
                <h5>SANITAS PERÚ S.A. -EPS</h5>
              </div>
            </div>
            <div>
              <img src={`${pathServer.PATH_BOLETA}firma2.png`} />
              <div className="report-firm-asignee">
                <h5>PROTECTA S.A. COMPAÑIA DE SEGUROS</h5>
              </div>
            </div>
          </div>
          <div className="report-message">
            <h5 className="text-justify">*No se brindara cobertura de pensión a los accidentes ocurridos en explotaciones de minas y canteras a menos que se consigne esta actividad en las Condiciones Particulares de la Póliza.</h5>
          </div>
        </div>
      </div>
    );
  }
);

PSConstanciaAseguradoSCTR.displayName = 'PSConstanciaAseguradoSCTR';

PSConstanciaAseguradoSCTR.defaultProps = {
  poliza: {},
  descriptionHeader: null,
};

PSConstanciaAseguradoSCTR.propTypes = {
  poliza: PropTypes.object,
  descriptionHeader: PropTypes.string,
};

export default PSConstanciaAseguradoSCTR;
