import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MIN_ROWS_TABLE = 3;
const StyledPSSimpleTable = styled.div`
  /* Track */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #cbcbcb;
    margin-left: 0.8rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ed6e00;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #cbcbcb;
  // }

  overflow-y: scroll;
  height: ${(props) => (props.lengthTable > MIN_ROWS_TABLE ? '200px' : '100%')};
  .container-simpletable {
    width: 93%;
    margin: 0 16px 25px 16px;
    .table-header,
    .table-item {
      text-align: center;
      vertical-align: middle;
    }
    .table-header {
      font-weight: bold;
      color: #ed6e00;
      padding: 0.2rem 0.2rem 0.4rem 0.2rem;
    }
    .table-item {
      background-color: #fff;
      color: #7a7a7a;
      padding: 0.4rem 0.2rem 0.1rem 0.2rem;
    }
    .first-column,
    .table-header:first-of-type {
      text-align: left;
      border-radius: 25px 0 0 25px;
      padding-left: 40px;
    }
    .last-column,
    .table-header:last-of-type {
      text-align: center;
      border-radius: 0 25px 25px 0;
      padding-right: 20px;
    }
  }

  @media (max-width: 600px) {
    .container-simpletable {
      min-width: 350px;
    }
  }
`;

const StyledTR = styled.table`
  border-collapse: separate;
  border-spacing: 0 15px;
`;

const PSSimpleTable = ({ headers, items }) => {
  const lengthTable = items ? items.length : 1;

  return (
    <StyledPSSimpleTable lengthTable={lengthTable} numColumn={headers.length}>
      <StyledTR className="container-simpletable">
        <thead>
          <tr className="">
            {headers.map((header) => (
              <th key={header.elementProp} className="table-header">
                {header.headerColumn}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <tr key={item.id}>
                {headers.map((header, i) => {
                  return (
                    <td
                      key={item.id + header.elementProp}
                      className={`table-item ${i === 0 ? 'first-column' : ''}${
                        i === headers.length - 1 ? ' last-column' : ''
                      }`}
                    >
                      {item[header.elementProp]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTR>
    </StyledPSSimpleTable>
  );
};

PSSimpleTable.displayName = 'PSSimpleTable';

PSSimpleTable.defaultProps = {
  headers: [],
};

PSSimpleTable.propTypes = {
  headers: PropTypes.array,
};

export default PSSimpleTable;
