import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { optionsHeaderNewSletter, pathServer } from '../../util/Constant';
import { existInUrl } from '../../util/Util';

// const optionImgCarousel = ['testheaderBoletin.png', 'testheaderBoletin2.png',];
const optionImgCarousel = [
  // 'headerBannerFirst.jpg', //Ocultar banner en Vivo
  'headerBannerSecond.png',
  'headerBannerThird.png',
  'headerBannerFourth.png',
];

const StyledPSHeaderCarouselNewsletter = styled.div`
  background: url('${(props) => (props.url ? props.url : '#ffff')}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  object-fit: cover;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
  .ellipse {
    margin-right: 0.7rem;
    width: 18px;
    height: 18px;
    background-color: #fff;
    opacity: 1;
    border-radius: 50%;
  }
  .ellipse-selected {
    background-color: #2b0d61;
  }
  @media (max-width: 576px) {
    background-size: 100% 90%;
    height: 175px;
    .ellipse,
    .ellipse-selected {
      width: 13px;
      height: 13px;
    }
  }
`;

const PSNewsletterHeaderCarousel = React.forwardRef(({ className, ...props }, ref) => {
  const [indexSelected, setIndexSelected] = useState(0);
  const url = `${pathServer.SLETTER_IMG + optionImgCarousel[indexSelected]}`;
  const hasNotCarousel = optionsHeaderNewSletter.find((option) => existInUrl(option.value));
  const bannerImg = hasNotCarousel ? `${pathServer.SLETTER_IMG + hasNotCarousel.banner}` : url;
  return (
    <StyledPSHeaderCarouselNewsletter {...props} ref={ref} className={className} url={bannerImg}>
      {!hasNotCarousel &&
        optionImgCarousel.map((option, index) => (
          <div
            key={index}
            className={`ellipse ${index === indexSelected ? 'ellipse-selected' : ''}`}
            onClick={() => setIndexSelected(index)}
          />
        ))}
    </StyledPSHeaderCarouselNewsletter>
  );
});

PSNewsletterHeaderCarousel.defaultProps = {
  className: null,
};

PSNewsletterHeaderCarousel.propTypes = {
  className: PropTypes.string,
};

export default PSNewsletterHeaderCarousel;
