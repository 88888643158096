import * as axios from 'axios/index';
import { STORAGE_TOKEN_COGNITO } from '../../../util/Constant';
import { doPost } from './Api';
import { capitalizeSentence } from '../../../util/Util';

export default class SendBoletasService {
  static async responseFiles(files, attributes, key) {
    const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
    const fullName = capitalizeSentence(
      `${attributes.names} ${attributes.lastName} ${attributes.lastName2}`
    );
    const data = {
      data: {
        files,
        email: 'jordy.villazana@gmail.com',
        name: fullName,
      },
      key,
    };

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_SEND_BOLETAS,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${idTokenCognito}`,
      },
      data,
    });
    return response.data;
  }

  static async responseFilesRentas(ids, attributes, type) {
    const fullName = capitalizeSentence(
      `${attributes.names} ${attributes.lastName} ${attributes.lastName2}`
    );

    const data = {
      data: ids,
      email: attributes.email,
      name: fullName,
    };
    const typeUrl = (type==='rrvv' ? 'nntp-sendRentaVitalicia' : 'nntp-sendRentaTotal');

    const response = await doPost(`${process.env.REACT_APP_SEND_EMAIL_BOLETAS}/${typeUrl}`, data);
    return response.data;
  }
}
