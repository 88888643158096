import { setAuthLoading } from './auth-loading';
import AuthService from '../../../shared/services/AuthService';

export const VALIDATE_COGNITO = 'Auth/VALIDATE_COGNITO';

export const setValidateCognito = (valid) => ({
  type: VALIDATE_COGNITO,
  payload: valid,
});

export const validateCognito = (documentId, documentType, validCaptcha = null) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await AuthService.validateUserDigitalStatus(documentId, documentType, validCaptcha);

      dispatch(setValidateCognito(response));
    } catch (error) {
      dispatch(setAuthLoading(false));
      dispatch(setValidateCognito(null));
    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  cognitoValid: action.payload,
});
