import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import './index.scss';
import { pathServer, userDefault } from '../../../../util/Constant';
const RowTitle = ({ field, value }) => (
  <div className="filter-row">
    <h6>{field}</h6>
    <span>:</span>
    <h5 className="span-right">{value}</h5>
  </div>
);
const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
let dateFormatted = dateNow.replace('-', 'de');
dateFormatted = dateFormatted.replace('-', 'de');


const PSConstanciaPersonalAsegurado = React.forwardRef(
  ({ asegurado,poliza, ...props }, ref) => {
  const { userInfo } = useSelector((state) => state.auth);
  const attributes = userInfo ? userInfo.attributes : userDefault;
  const fullName = attributes ? attributes.legalName : '';
  const dateInitial = asegurado ? asegurado.fechaInicio : '';
  const dateEnd = asegurado ? asegurado.fechaFin : '';

  return <div ref={ref} className={`app-file-constancia-2 hidden-content-force`} {...props}>
      <div className="report-header">
        <div>
          <img alt="" src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`} />
        </div>
      </div>
      <div className="report-body">
        <div className="report-body-title">
          <h6>CONSTANCIA DE SEGURO</h6>
        </div>
        <div className="report-body-title-secondary">
          <h6 className="mb-1">
            Seguro de {poliza ? poliza.ramo.descripcion : 'Accidentes Personales'}
          </h6>
          <RowTitle field="Contratante" value={fullName} />
          <RowTitle field="Nº de Póliza" value={poliza ? poliza.nroPolicy : 2045} />
          <RowTitle field="Fecha de Inicio de Vigencia" value={dateInitial} />
          <RowTitle field="Fecha de Fin de Vigencia" value={dateEnd} />
          <RowTitle field="Suma Asegurada" value="Según las condiciones de la Póliza" />
        </div>
        <div className="report-description">
          <h6>
            {' '}
            En atención a lo solicitado y de acuerdo con la información proporcionada por el
            contratante, por medio del presente documento dejamos constancia de que el trabajador
            mencionado a continuación se encuentra <b>asegurado</b> bajo los alcance de la presente
            póliza.
          </h6>
        </div>
        <div className="report-table">
          <table>
            <thead>
            <tr>
              <th width="5%">N°</th>
              <th width="14%">DOC. IDENTIDAD</th>
              <th width="17%">APELLIDOS</th>
              <th width="20%">NOMBRES</th>
              <th width="15%">INICIO DE VIGENCIA</th>
              <th width="15%">FIN DE VIGENCIA</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td align="center">{1}</td>
              <td>
                <div style={{ height: '30px', overflow: 'hidden' }}>{asegurado ? asegurado.documento : ''}</div>
              </td>
              <td>{asegurado ?asegurado.lastName : ''}</td>
              <td>{asegurado?  asegurado.names: ''}</td>
              <td align="center">{asegurado ? asegurado.fechaInicio: ''}</td>
              <td align="center">{asegurado? asegurado.fechaFin : ''}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="report-footer">
          <h5 className="footer-date"> {`Surquillo, ${dateFormatted}`}</h5>
        </div>
        <div className="report-firm">
          <div>
            <img src={`${pathServer.PATH_ICONS}firma.png`} />
            <div className="report-firm-asignee">
              <h5>Guadalupe Robles Benzaquen</h5>
              <h5>Jefe de Soluciones de Clientes</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
);

PSConstanciaPersonalAsegurado.displayName = 'PSConstanciaPersonalAsegurado';

PSConstanciaPersonalAsegurado.defaultProps = {
  asegurado: null,
  poliza:null,
};

PSConstanciaPersonalAsegurado.propTypes = {
  asegurado: PropTypes.object,
  poliza: PropTypes.object,
};

export default PSConstanciaPersonalAsegurado;
