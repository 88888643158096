import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  MAGAZINE_BOLETIN_EN_VIVO,
  MAGAZINE_BOLETIN_FINANCIERO,
  MAGAZINE_BOLETIN_QUEDATE_CASA,
  MAGAZINE_BOLETIN_VIVE_SALUD,
  optionsHeaderNewSletter,
} from '../../util/Constant';
import PSButton from './PSButton';
import { Magazine } from '../shared/model/magazine/Magazine';
import LoadMagazinesService from '../shared/services/newSletter/LoadMagazinesService';
import { existInUrl, isEmptyValue, scrollToTop } from '../../util/Util';
import { GAProvider } from './PSAnalytics';

const StyledDetailNewsletter = styled.div`
  display: flex;
  justify-content: center;
  .section-img {
    width: 32%;
    margin: 1rem;
    .container-img {
      background: #fff;
      border-radius: 30px;
      padding: 2rem;
      min-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
  .section-info {
    margin: 2rem;
    width: 32%;
    .container-back {
      margin: 1rem 0;
      color: #ed6e00;
      label {
        cursor: pointer;
      }
    }
    .container-children {
      margin: 1rem 0;
      color: #2b0d61;
      .children-title {
        font-family: 'BlissPro-Heavy', sans-serif;
        font-size: 30px;
      }
      .children-subTitle {
        margin-top: -2px;
        font-family: 'BlissPro-Light', sans-serif;
        font-size: 16px;
      }
      .children-content {
        font-family: 'BlissPro-Light', sans-serif;
        font-size: 17px;
        line-height: 19px;
        text-align: justify;
      }
    }
    .container-button {
      margin: 1rem 0;
      .button-style {
        width: 30%;
        border-radius: 10px;
        height: 33px;
        font-size: 17px;
      }
    }
    .container-share {
      color: #242425;
      .container-share-icons {
        display: flex;
        .share-icons {
          margin-right: 0.3rem;
          margin-top: 0.2rem;
        }
      }
    }
  }

  .content-salud {
    margin: 4% 0;
    width: 60%;
    .salud-title {
      font-family: 'BlissPro-Heavy', sans-serif;
    }
    .salud-description {
      font-size: 16px;
    }
  }

  .content-casa {
    margin: 4% 0;
    width: 85%;
    content-description {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    display: block;
    .section-img {
      display: flex;
      width: 100%;
      .container-img {
        width: 70%;
        padding: 1.2rem;
      }
      .container-back {
        color: #ed6e00;
        margin: 2rem 1rem;
        label {
          cursor: pointer;
        }
      }
    }
    .content-salud,
    .content-casa {
      width: 100%;
    }

    .container-img {
      min-height: 250px;
    }
    .section-info {
      width: 85%;
      .container-button {
        text-align: center;
      }
      .container-share {
        text-align: center;
        .container-share-icons {
          justify-content: center;
        }
      }
      .container-button {
        .button-style {
          width: 40%;
        }
      }
    }
  }
`;

const DescriptionVivesalud = ({ magazine }) =>
  magazine.content.map((op, index) => (
    <div className="content-salud" key={index}>
      <div className="salud-title">{op.title}</div>
      <div className="salud-description">{op.description}</div>
    </div>
  ));

const DescriptionQuedateCasa = ({ magazine }) =>
  magazine.paragraphs.map((op, index) => (
    <div className="content-casa" key={index}>
      <div className="casa-description">{op.paragraph}</div>
    </div>
  ));

const PATH_NEWSLETTER = '/newsletter/get/';

const PSNewsletterDetail = React.forwardRef(
  ({ className, img, onBack, magazine, ...props }, ref) => {
    const optionSelected =
      optionsHeaderNewSletter.find((option) => existInUrl(option.value)) || magazine;
    const [responseImage, setResponseImage] = useState(null);
    const { userInfo } = useSelector((state) => state.auth);
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    const [showButtonDownload, setShowButtonDownload] = useState(false);

    const ga = React.useContext(GAProvider);

    const downLoadFile = async () => {
      const responseFile = await LoadMagazinesService.responseDownloadMagazine(
        magazine.buttons[0].link
      );
      const link = document.createElement('a');
      const detailBoletin = magazine.title + magazine.description;

      link.href = responseFile.data;
      link.download = 'test.pdf';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Newsletter-${optionSelected.description}-Detalle`,
        action: 'download',
        label: `El usuario descargó el boletín: ${detailBoletin}`,
        value: 1,
      });
    };

    const linkPage = () => {
      const link = document.createElement('a');
      const detailBoletin = magazine.title + magazine.description;

      link.href = magazine.buttons[0].link;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Newsletter-${optionSelected.description}-Detalle`,
        action: 'click',
        label: `El usuario entró al link de redireccionamiento del boletín: ${detailBoletin}`,
        value: 1,
      });
    };

    const loadImages = async () => {
      const responseImage = await LoadMagazinesService.responseGetImages(magazine);
      optionSelected.s3UrlImageNew = responseImage;
      setResponseImage(responseImage);
    };

    useEffect(() => {
      scrollToTop();
      const detailBoletin = magazine.title + magazine.description;
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Newsletter-${optionSelected.description}-Detalle`,
        action: 'click',
        label: `El usuario visitó el detalle del boletín: ${detailBoletin}`,
        value: 1,
      });
    }, []);

    useEffect(() => {
      const { buttons } = magazine;
      if (!magazine.s3UrlImageNew) {
        loadImages();
      }
      if (buttons.length > 0 && !isEmptyValue(buttons[0].link)) {
        const { link } = buttons[0];
        setShowButtonDownload(link.includes(PATH_NEWSLETTER));
      }
    }, [magazine]);

    return (
      <StyledDetailNewsletter ref={ref} className={className} {...props}>
        <div className="section-img">
          <div className="container-img">
            <img src={magazine.s3UrlImageNew || responseImage} />
          </div>
          <div className="container-back display-only-mobile" onClick={onBack}>
            <label>{'<< Atrás'}</label>
          </div>
        </div>

        <div className="section-info">
          <div className="container-back display-none-mobile" onClick={onBack}>
            <label>{'<< Atrás'}</label>
          </div>
          <div className="container-children">
            <div className="children-title">
              <label>{magazine.title}</label>
            </div>
            <div className="children-subTitle">
              <label>{magazine.description}</label>
            </div>
            <div className="children-content">
              {magazine ? (
                magazine.idTipo === MAGAZINE_BOLETIN_VIVE_SALUD ? (
                  <DescriptionVivesalud magazine={magazine} />
                ) : magazine.idTipo === MAGAZINE_BOLETIN_FINANCIERO ? (
                  <DescriptionVivesalud magazine={magazine} />
                ) : magazine.idTipo === MAGAZINE_BOLETIN_QUEDATE_CASA ? (
                  <DescriptionQuedateCasa magazine={magazine} />
                ) : magazine.idTipo === MAGAZINE_BOLETIN_EN_VIVO ? (
                  <DescriptionVivesalud magazine={magazine} />
                ) : (
                  <DescriptionVivesalud magazine={magazine} />
                )
              ) : (
                <DescriptionVivesalud magazine={magazine} />
              )}
            </div>
          </div>
          <div className="container-button">
            {magazine.buttons.length > 0 && !isEmptyValue(magazine.buttons[0].link) && (
              <PSButton
                className="button-style"
                onClick={() => (showButtonDownload ? downLoadFile() : linkPage())}
              >
                {magazine.buttons[0].title || 'Click aquí'}
              </PSButton>
            )}
          </div>
        </div>
      </StyledDetailNewsletter>
    );
  }
);

PSNewsletterDetail.defaultProps = {
  className: null,
  onBack: () => {},
  magazine: new Magazine(),
};

PSNewsletterDetail.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func,
  magazine: PropTypes.object,
};

export default PSNewsletterDetail;
