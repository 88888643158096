export const STATE_PRODUCT = 'Auth/STATE_PRODUCT';

export const setStateProduct = (state) => ({
  type: STATE_PRODUCT,
  payload: state,
});

export const reducer = (state, action) => ({
  ...state,
  stateProduct: action.payload,
});
