import AuthService from '../../../shared/services/AuthService';
import { setAuthLoading } from './auth-loading';
import { setUserValidationInfo } from './user-info-validation';
import { setValidateCognito } from './validate-cognito';

export const IS_USER_VALID = 'Auth/IS_USER_VALID';

export const setUserValid = (isUserValid) => ({
  type: IS_USER_VALID,
  payload: isUserValid,
});

export const validateUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const [response, userValidation] = await AuthService.validateUserConditions(payload);
      dispatch(setUserValidationInfo(userValidation));
      dispatch(setUserValid(response));
      dispatch(setAuthLoading(false));
      dispatch(setValidateCognito(null));
    } catch (error) {
      if (error.response && error.response.status === 504) {
        dispatch(setUserValid('ERROR_TIME'));
      } else {
        dispatch(setUserValid(null));
      }

      dispatch(setAuthLoading(false));

    }
  };
};

export const reducer = (state, action) => ({
  ...state,
  isUserValid: action.payload,
});
