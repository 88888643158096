import React from 'react';
import PropTypes, { bool } from 'prop-types';

import PSComponentFormGroup from './PSComponentFormGroup';
import PSIcon from './PSIcon';

const PSComponentForm = ({ className, from, onChange, onBlur, title, showInfo }) => {
  return (
    <div className={className}>
      {title && (
        <div className="mx-3 my-1 d-flex">
          {showInfo && (
            <PSIcon
              type="info"
              className="mr-2 input-row-icon"
              data-html="true"
              data-tip="
          <p style='font-size : 120%;'>
          Si el beneficiario tiene Carnet de Extranjería u otro documento
          <br>
          enviar solicitud al correo clientes@protectasecurity.pe
          </p>"
            />
          )}
          <span>{title}</span>
        </div>
      )}
      {from.map(
        (item, index) =>
          !item.onlySelect && (
            <PSComponentFormGroup
              key={item.field}
              item={item}
              onChange={onChange}
              onBlur={onBlur}
              index={index}
              className={`container-form-group ${item.className ? item.className : ''}`}
            />
          )
      )}
    </div>
  );
};

PSComponentForm.defaultProps = {
  className: null,
  onChange: () => {},
  title: null,
  showInfo: false,
};

PSComponentForm.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  showInfo: PropTypes.bool,
};

export default PSComponentForm;
