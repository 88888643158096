import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from "../../PSIcon";

const PSSatisfactionRate = React.forwardRef(({ className, numValues, valueSelected, onSelectValue}, ref) =>{
  const [rows, setRows] = useState([]);
  useEffect(()=>{
    for(let i=0;i<numValues; i++){
      rows.push(i);
    }
    setRows([...rows]);
  },[]);

  return (
    <div className={`container-satisfaction-rate ${className}`} ref={ref}>
      <div className="wrapper-satisfaction-icons">
        {
          rows.map(item=> {
            return (
              <div key={item} className="container-section-icon" onClick={()=>onSelectValue(item)}>
                <PSIcon  className="container-icon-start" type={`star${valueSelected>=item ?'Selected':''}`}>
                </PSIcon>
                <div className={`container-values ${item>=10? 'values-up':''}`}><p>{item}</p></div>
              </div>
            )
          })
        }
      </div>
      <div className='container-description'>
        <div className="description">
          <p>Totalmente improbable</p>
        </div>
        <div className="description">
          <p>Totalmente probable</p>
        </div>
      </div>
    </div>
)});

PSSatisfactionRate.displayName = 'PSSatisfactionRate';

PSSatisfactionRate.defaultProps = {
  className: '',
  numValues:11,
  valueSelected:5,
  onSelectValue: ()=>{}
};

PSSatisfactionRate.propTypes = {
  className: PropTypes.string,
  numValues: PropTypes.number,
  valueSelected : PropTypes.number,
  onSelectValue:  PropTypes.func,
};

export default PSSatisfactionRate;
