import { doGetWithParams, doPost } from './Api';

export default class PaymentVouchersServices {
  // @Deprecated
  static async responseGetComprobantePoliza(data) {
    const response = await doPost(`${process.env.REACT_APP_GET_COMPROBANTES_POLIZA_SOAT}`, data);
    return response.data;
  }

  // @Deprecated
  static async responseValidateComprobantePoliza(data) {
    const response = await doPost(
      `${process.env.REACT_APP_VALIDATE_COMPROBANTES_POLIZA_SOAT}`,
      data
    );
    return response.data;
  }

  // @Deprecated
  static async responseGetBoletaPoliza(data) {
    const response = await doPost(`${process.env.REACT_APP_GET_BOLETA_POLIZA_SOAT}`, data);
    return response.data;
  }

  static async responseValidateComprobantePago(headerParams) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_PAYMENT_VOUCHERS_VALIDATE}`,
      headerParams
    );
    return response ? response.data : undefined;
  }

  static async responseDocumentComprobantePago(data) {
    const response = await doPost(`${process.env.REACT_APP_PAYMENT_VOUCHERS_DOCUMENT}`, data);
    return response.data;
  }
}
