import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import './index.scss';
// TODO: reCAPTCHA v2, because is a free service
const PSCaptcha = React.forwardRef(({ onChange }, ref) => {
  const isMobile = window.innerWidth <= 440;  
  return (
    <div className="recaptcha">
      <ReCAPTCHA
        ref={ref}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={onChange}
        size={`${isMobile ? 'compact' : 'normal'}`}
      />
    </div>
  );
});

PSCaptcha.displayName = 'PSCaptcha';

export default PSCaptcha;