import { IS_USER_VALID, reducer as isUserValidReducer } from './actions/is-user-valid';
import { AUTH_LOADING, reducer as loadingReducer } from './actions/auth-loading';
import { STATE_PRODUCT, reducer as stateProductReducer } from './actions/state-product';
 import { CLEAN_AUTH, reducer as cleanReducer } from './actions/clean-auth';
import {
  USER_VALIDATION_INFO,
  reducer as userValidationReducer,
} from './actions/user-info-validation';
import { USER_SIGNUP, reducer as userSignUpReducer } from './actions/user-signup';
import {
  ERROR_VERIFICATION_CODE,
  reducer as errorReducer,
} from './actions/error-verification-code';
import { SEND_VERIFICATION_CODE, reducer as sendReducer } from './actions/send-verification-code';
import {
  VALIDATE_VERIFICATION_CODE,
  reducer as validReducer,
} from './actions/validate-verification-code';
import { CHANGE_PASSWORD, reducer as updatePasswordReducer } from './actions/change-password';
import { INVALID_PASSWORD, reducer as invalidPasswordReducer } from './actions/invalid-password';
import { VALIDATE_COGNITO, reducer as validateCognitoReducer } from './actions/validate-cognito';
import {
  CREATE_USER_COGNITO,
  reducer as createCognitoReducer,
} from './actions/create-user-cognito';
import {
  USER_FORGOT_PASSWORD,
  reducer as forgotPasswordReducer,
} from './actions/user-forgot-password';
import {
  USER_LOGIN,
  reducer as validateLoginUsuerReducer,
  reducerAttributesCore as validateAttributesCoreReducer,
  CORE_INFO,
} from './actions/user-login';
import { GET_TOKEN, reducer as getTokenReducer } from './actions/get-token';
import { SET_MODE_EDIT, reducer as setEditReducer } from './actions/set-mode-edit';
import { CREATE_BANNERS, reducer as setCreateBanners } from './actions/set-banners';

import { initialState } from './state';
import { SET_ERROR_PAGE, reducer as createErrorPageReducer } from './actions/set-error-page';
import { SET_ERROR_PAYMENT, reducer as createErrorPayment } from './actions/payment-validation';
import { LOAD_DIRECTIONS, reducer as setLoadAddressReducer } from './actions/load-address';

export { setUserValid, validateUser } from './actions/is-user-valid';
export { setAuthLoading } from './actions/auth-loading';
 export { cleanAuth } from './actions/clean-auth';
export { setUserValidationInfo } from './actions/user-info-validation';
export { setUserSignUp } from './actions/user-signup';
export { setVerificationCodeStatus, sendVerificationCode } from './actions/send-verification-code';
export { setValidCode, validateVerificationCode } from './actions/validate-verification-code';
export { setChangePassword, updatePassword } from './actions/change-password';
export { setValidateCognito, validateCognito } from './actions/validate-cognito';
export { setCreatedUserCognito, createUserCognito } from './actions/create-user-cognito';
export { setUserForgotPassword } from './actions/user-forgot-password';
export { setLoginUser, validateLoginUser } from './actions/user-login';
export { setTokenAuthorization, getTokenAuthorization } from './actions/get-token';
export { setEdit } from './actions/set-mode-edit';
export { setCreateBanners } from './actions/set-banners';
export { createErrorPage } from './actions/set-error-page';

const actionsHandlers = {
  [IS_USER_VALID]: isUserValidReducer,
  [AUTH_LOADING]: loadingReducer,
  [STATE_PRODUCT]: stateProductReducer,
  [CLEAN_AUTH]: cleanReducer,
  [USER_VALIDATION_INFO]: userValidationReducer,
  [USER_SIGNUP]: userSignUpReducer,
  [SEND_VERIFICATION_CODE]: sendReducer,
  [ERROR_VERIFICATION_CODE]: errorReducer,
  [VALIDATE_VERIFICATION_CODE]: validReducer,
  [CHANGE_PASSWORD]: updatePasswordReducer,
  [INVALID_PASSWORD]: invalidPasswordReducer,
  [VALIDATE_COGNITO]: validateCognitoReducer,
  [CREATE_USER_COGNITO]: createCognitoReducer,
  [USER_FORGOT_PASSWORD]: forgotPasswordReducer,
  [USER_LOGIN]: validateLoginUsuerReducer,
  [CORE_INFO]: validateAttributesCoreReducer,
  [GET_TOKEN]: getTokenReducer,
  [SET_MODE_EDIT]: setEditReducer,
  [CREATE_BANNERS]: setCreateBanners,
  [SET_ERROR_PAGE]: createErrorPageReducer,
  [SET_ERROR_PAYMENT]: createErrorPayment,
  [LOAD_DIRECTIONS]: setLoadAddressReducer,
};

export default function reducer(state = initialState, action) {
  const handler = actionsHandlers[action.type];
  return handler ? handler(state, action) : state;
}
